import { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioUsuarioColaboraProyecto.css";
import { DeleteEliminarColaborador, GetInformacionColaborador, PostAsignarColaborador, PostListaUsuariosNoColaboraProyecto } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import { validarInputSelect } from '../../../Services/Validaciones';
import SelectRol from './Componentes/SelectRol';
import SelectUsuarios from './Componentes/SelectUsuarios';
import InfoUsuario from './Componentes/InfoUsuario';
import { usuario, usuarioestado } from './Interfaces';

interface props {
  isOpen: boolean,
  rol:number
  token:number
  closeModal:(accion:number) => void,
  idproyecto:number,
  idusuario:number,
  titulomodal:string,
  accion:number
}

interface states {
  isOpen: boolean,

  selectrol:number,
  vermsgerrorselectrol:boolean,
  msgerrorselectrol:string,

  usuarioslista:usuario[],
  selectusuarioseleccionado:number,
  vermsgerrorselectusuario:boolean,
  msgerrorselecusuario:string,
  idusuario:number,

  usuario:usuario
}
export default class ModalFormularioUsuarioColaboraProyecto extends Component<props,states>{
  constructor(props:props) {
    super(props);
    this.state = {
      isOpen:this.props.isOpen,
      selectrol:0,
      vermsgerrorselectrol:false,
      msgerrorselectrol:'',
      usuarioslista:[],
      selectusuarioseleccionado:0,
      vermsgerrorselectusuario:false,
      msgerrorselecusuario:'',

      idusuario:0,
      usuario:usuarioestado
    }
  }

  async componentDidMount() {
    if(this.props.rol === 2 || this.props.rol === 3){
      this.showModal(0)
    }

    if(this.props.accion == 2){
      const apiusuariodetalles = await GetInformacionColaborador(this.props.token,this.props.idusuario, this.props.idproyecto)
      this.setState({usuario:apiusuariodetalles.data})
    }
  }
    
  showModal=(accion:number)=> {
    this.setState({isOpen:false})
    setTimeout(() => {
      this.props.closeModal(accion);
    }, 200);
  }

  rol = (rol:number) => {
    this.setState({selectrol:rol}, async () => {
      const usuarios = await PostListaUsuariosNoColaboraProyecto(this.props.token,this.props.idproyecto,rol);
      this.setState({ usuarioslista: usuarios.data });
    });
  }

  usuario = (usuario:number) => {
    const usuarioseleccionado = usuario;
    this.setState({ selectusuarioseleccionado:usuarioseleccionado });
  }

  guardarColaboracion = async () => {
    this.setState({ vermsgerrorselectrol: false});
    this.setState({ vermsgerrorselectusuario: false});

    let selectrol = validarInputSelect(String(this.state.selectrol));
    let selectusuario = validarInputSelect(String(this.state.selectusuarioseleccionado));

    let validacionescorrectas = true;

    if (selectrol.codigo == "invalid") {
      this.setState({ msgerrorselectrol: selectrol.mensaje });
      this.setState({ vermsgerrorselectrol: true });
      validacionescorrectas = false;
    }

    if (selectusuario.codigo == "invalid") {
      this.setState({ msgerrorselecusuario: selectusuario.mensaje });
      this.setState({ vermsgerrorselectusuario: true });
      validacionescorrectas = false;
    }

    if (validacionescorrectas) {
      let json = {
        "token":this.props.token,
        "id_proyecto":this.props.idproyecto,
        "id_rol":this.state.selectrol,
        "id_usuario":this.state.selectusuarioseleccionado
      }

      const resp = await PostAsignarColaborador(json);

      if (resp.code == 0) {
        this.showModal(1)
      }
      else if(resp.code==100){
        this.showModal(2)
        toast.success(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      else if (resp.code == 200) {
        toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 300) {
        toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(2)
      }
      else if (resp.code == 1000 || resp == -1) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
    }
  }

  EliminarColaboracion = async () => {
    let json = {
      "token":this.props.token,
      "id_proyecto":this.props.idproyecto,
      "id_usuario":this.props.idusuario
    }

    const resp = await DeleteEliminarColaborador(json);
    if (resp.code == 0) {
      this.showModal(3)
    }
    else if(resp.code==100){
      this.showModal(2)
      toast.success(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    else if (resp.code == 200) {
      toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
    else if (resp.code == 300) {
      toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(2)
    }
    else if (resp.code == 1000 || resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal isOpen={this.state.isOpen} size="md">
          <ModalHeader className="modalHeader">
              <label className='labelTituloModal'> {this.props.titulomodal} </label>
              <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
          </ModalHeader>
          <ModalBody>
            {this.props.accion == 1 ? 
              <>
                <div className="divContenedorModal">
                  <div className="row">
                    <SelectRol
                      rol={this.state.selectrol}
                      vermsgerror={this.state.vermsgerrorselectrol}
                      msgerror={this.state.msgerrorselectrol}
                      seleccionar={this.rol}
                    />
                  </div>
                  <div className="row">
                    <SelectUsuarios
                      usuarios={this.state.usuarioslista}
                      vermsgerror={this.state.vermsgerrorselectusuario}
                      msgerror={this.state.msgerrorselecusuario}
                      seleccionar={this.usuario}
                    />
                  </div>
                </div>
                <div className="divContenedorBotonesModal">
                  <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                  <Button className="btnAceptarModal"onClick={this.guardarColaboracion}>Añadir</Button>
                </div>
              </>
            :this.props.accion == 2 ?
              <>
                <div className="divContenedorModal mb-3">
                  <InfoUsuario usuario={this.state.usuario}/>
                </div>
                <div className="divContenedorBotonesModal">
                  <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                  <Button className="btnAceptarModal"onClick={this.EliminarColaboracion}>Eliminar</Button>
                </div>
              </>
            :null}
          </ModalBody>
        </Modal>
      </>
    );
  }
}