import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import { FormGroup, Input, Label } from 'reactstrap';
import { getListaTiposSolicitud } from '../../../../Services/Api';
import { api, tiposolicitud } from '../Interfaces';

interface props {
    listaapis:api[],
    token:number
    apis: (listaapis:api[]) => void;
}

interface states {
    listaapis:api[],
    tipossolicitud:tiposolicitud[]
}

export default class FormularioApis extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            listaapis:this.props.listaapis,
            tipossolicitud:[]
        }
    }

    async componentDidMount() {
        const apitipossolicitud = await getListaTiposSolicitud(this.props.token);
        this.setState({tipossolicitud: apitipossolicitud.data});
    }
    
    componentDidUpdate(prevProps: props) {
        if (prevProps.listaapis !== this.props.listaapis) {
          this.setState({ listaapis: this.props.listaapis });
        }
    }

    //Función para agregar ejemplo de salida.
    agregarApi = () => {
        const  apis = this.state.listaapis;
        const nuevaApi: api = {
          index: 0,
          idsolicitud_tiene_api: 0,
          id_tipo_solicitud: 0,
          txtapi:'',
          vermsgerrortapi:false,
          msgerrortapi:''
        };
        if (apis.length < 10) {
            const  apisActualizadas= [...apis, nuevaApi];
            this.setState({ listaapis: apisActualizadas });
            this.props.apis(apisActualizadas)
        } 
        else {
            toast.warning("No puedes agregar más de 10 apis.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
       
    };
  
    actualizarTipoSolicitudApi =(event: React.ChangeEvent<HTMLInputElement>, apiIndex: number) => {
        const apis  = this.state.listaapis;
        const apisActualizadas = apis.map((api, index) => {
            if (index === apiIndex) {
            return {
                ...api,
                id_tipo_solicitud: Number(event.target.value)
            };
            }
            return api;
        });
        this.setState({ listaapis: apisActualizadas });
        this.props.apis(apisActualizadas)
    };
      
    //Función para eliminar una api.
    eliminarApi = (indexApi: number) => {
        const apis = this.state.listaapis;
        if (indexApi !== 0) {
            const apisActualizadas = apis.filter((api, index) => index !== indexApi);
            this.setState({ listaapis: apisActualizadas });
            this.props.apis(apisActualizadas)
        }
    };    

    render() {
        return (
           <>
                {this.state.listaapis.map((api, index) => (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-1" key={index}>
                      <FormGroup className='formContenedor'>
                        <div className="row">
                          <div className="col-9">
                            <Label className='labelInputModal'>
                              Api no.{index + 1} <span className="spanObligatorio">*</span>
                            </Label>
                          </div>
                          <div className="col-3 divContenedorBotonEliminarEjemplo">
                            {index !== 0 && api.idsolicitud_tiene_api === 0 ?
                              <button className="botonEliminarEjemplo" onClick={() => this.eliminarApi(index)}>
                                  <IoMdClose size={25} color="white"/>
                              </button>
                            :null}
                          </div>
                        </div>
                        <Input type="select" value={api.id_tipo_solicitud}  onChange={(event) => this.actualizarTipoSolicitudApi(event, index)}>
                          <option disabled value="0" selected>Seleccione una opción</option>
                            {this.state.tipossolicitud.map((tiposolicitud, index)=>(	
                                <option key={index} value= {tiposolicitud.id_tipo_solicitud}>
                                    {tiposolicitud.tipo_solicitud}
                                </option>
                            ))}
                        </Input>
                        {api.vermsgerrortapi ? 
                            <div className="row">
                              <div className="col-10 contenedorMensajeError">
                                <span className="mensajeError">{api.msgerrortapi}</span>
                              </div>
                            </div>
                        :null}
                      </FormGroup>
                    </div>
                ))}
                {this.state.listaapis.length < 10 ? 
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <FormGroup className='formContenedor'>
                            <Label className='labelInputModal'>
                                Apis
                            </Label>
                            <button className='botonAgregar' type='button' onClick={this.agregarApi}>
                                Agregar api
                            </button>
                        </FormGroup>
                    </div>
                :null}
           </>
        );
    }
}