import React, { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";
import { IoMdClose } from "react-icons/io";
import { BiPlus } from "react-icons/bi";

interface props{
    titulo:string
    txtmodelo:string,
	vermsgerrormodelo:boolean,
    msgerrormodelo:string,
    placeholder?:string
    modelo:(modelo:string) => void;
}

interface states{
	txtmodelo:string,
	txtmodelolength:number

}

export class InputModelo extends React.Component<props, states> {
    constructor(props: props) {
		super(props);
		this.state = {
            txtmodelo:this.props.txtmodelo,
            txtmodelolength:this.props.txtmodelo.length
        }
    } 

    componentDidUpdate(prevProps: props) {
        if (prevProps.txtmodelo !== this.props.txtmodelo) {
          this.setState({ txtmodelo: this.props.txtmodelo });
        }
    }

    escribir = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({txtmodelo:event.target.value, txtmodelolength:event.target.value.length})
        this.props.modelo(event.target.value)
    };

    //Función para insertar tabulación dentro de los textarea
	teclaTab = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Tab") {
			const textarea = event.target as HTMLTextAreaElement;

			//Se obtiene la posición del cursor dentro del textarea
			const cursor = textarea.selectionStart;
		
			//Inserta la tabulación en la posición del cursor
			textarea.value = textarea.value.slice(0, cursor) + "\t" + textarea.value.slice(cursor);
		
			//Mueve el cursor a su posición
			textarea.setSelectionRange(cursor + 1, cursor + 1);
		
			//Evita que al presionar tab se cambie de elemento como al botón u otro input.
			event.preventDefault();
		}
	};

    render() {
		return(
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-1">
                <Label className='labelInputModal'>
                    {this.props.titulo} <span className="spanObligatorio">*</span>
                </Label>
                <Input
                    className="textAreaModelo"
                    type="textarea"
                    maxLength={3000}
                    onChange={this.escribir}
                    placeholder={this.props.placeholder}
                    value={this.state.txtmodelo} 
                    onKeyDown={this.teclaTab}/>
                    {this.props.vermsgerrormodelo
                        ? 
                            <div className="row">
                            <div className="col-10 contenedorMensajeError">
                                <span className="mensajeError">{this.props.msgerrormodelo}</span>
                            </div>
                            <div className="col-2 contenedorContadorCaracteres">
                                <span className='contadorCaracteres'>{this.state.txtmodelolength} / 3000</span>
                            </div>
                            </div>
                        : 
                            <div className='contenedorContadorCaracteres'>
                                <span className='contadorCaracteres'>{this.state.txtmodelolength} / 3000</span>
                            </div>
                    }
            </div>
        )
    }
}