export function estiloContenedorPrincipal() {
    var json = {
        display:"flex"
    }
    return json;
}

export function estiloMensajeColorError() {
    var json = {
        msgerror: "#ff3e3e"
    }
    return json;
}

