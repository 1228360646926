import React, { ChangeEvent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import "./EstilosFormularios.css";

interface props {
    label:string,
    placeholder:string,
    limitecaracteres:number,
    txtvalor:string,
    vermsgerror:boolean,
    msgerror:string,
    escribir: (prop: string) => void;
}

interface states {
    txtvalor:string,
    txtvalorlength:number,
}

export default class InputText extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            txtvalor:this.props.txtvalor,
            txtvalorlength:this.props.txtvalor.length
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.txtvalor !== this.props.txtvalor) {
          this.setState({ txtvalor: this.props.txtvalor, txtvalorlength:this.props.txtvalor.length });
        }
    }

    escribir = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({txtvalor:event.target.value, txtvalorlength:event.target.value.length})
        this.props.escribir(event.target.value)
    };
   
    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    {this.props.label} <span className="spanObligatorio">*</span>
                </Label>
                <Input
                    type="text" 
                    className="inputModal"
                    placeholder={this.props.placeholder}
                    onChange={this.escribir}
                    maxLength={this.props.limitecaracteres}
                    value={this.state.txtvalor}
                    />
                {this.props.vermsgerror
                    ? 
                    <div className="row">
                        <div className="col-10 contenedorMensajeError">
                        <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                        <div className="col-2 contenedorContadorCaracteres">
                        <span className='contadorCaracteres'>{this.state.txtvalorlength} / {this.props.limitecaracteres}</span>
                        </div>
                    </div>
                    : 
                    <div className='contenedorContadorCaracteres'>
                        <span className='contadorCaracteres'>{this.state.txtvalorlength} / {this.props.limitecaracteres}</span>
                    </div>
                }
            </FormGroup>
        );
    }
}