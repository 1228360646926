export interface api{
    index:number,
    idsolicitud_tiene_api:number,
    id_tipo_solicitud: number,
    txtapi:string,
    vermsgerrortapi:boolean,
    msgerrortapi:string
}
  
export interface proyecto{
    index:number,
    id_proyecto:number,
    nombre_proyecto:string,
    id_rol:number
}
  
export interface modulo{
    id_modulo:number,
    nombre_modulo:string,
    id_submodulo:string
}
  
export interface submodulo{
    id_submodulo:number,
    nombre_submodulo:string,
    submodulo_general:number
}

export interface tiposolicitud{
    id_tipo_solicitud:number,
    tipo_solicitud:string
}

export const apiestado:api = {
    index:1,
    idsolicitud_tiene_api:0,
    id_tipo_solicitud: 0,
    txtapi:'',
    vermsgerrortapi:false,
    msgerrortapi:''
}