//Interfaz de registros de actividad
export interface registro{
    detalles: string,
    hora: string,
    fecha: string,
    fecha_formateada:string,
    nombre_usuario: string,
    apellido_paterno: string,
    apellido_materno: string,
    nombre_completo:string
}

export interface api {
    id_api: number,
    nombre_api:string,
    id_proyecto:number,
    nombre_proyecto:string,
    id_modulo:number,
    nombre_modulo:string
    id_submodulo:number,
    nombre_submodulo:string
    submodulo_general:number
}

export const apiestado:api = {
    id_api: 0,
    nombre_api:'',
    id_proyecto:0,
    nombre_proyecto:'',
    id_modulo:0,
    nombre_modulo:'',
    id_submodulo:0,
    nombre_submodulo:'',
    submodulo_general:0
}