import React from 'react';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FaCrown, FaDesktop, FaLaptopCode } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';
import { SlOptions } from 'react-icons/sl';
import { HiPencil } from 'react-icons/hi';
import { RiDeleteBinFill } from 'react-icons/ri';
import { GrServer } from 'react-icons/gr';
import { Tooltip } from 'react-tooltip';
import { format, formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { getListaComentariosReporte, getListaComentariosSolicitud } from '../../Services/Api';
import ModalEditarComentario from '../Modales/ModalEditarComentario/ModalEditarComentario';

//Interfaz de la estructura de datos de cada comentario.
interface comentario{
	id_comentario:number,
	idcomentarioclass:string,
	idcomentatiotooltip:string,
	comentario:string,
	accion:string,
	fecha_generacion:string,
	tiempotranscurrido:string,
	id_usuario:number,
	token:number,
	nombre_usuario:string,
	apellido_paterno:string,
	apellido_materno:string,
	rol:number,
	opciones:boolean
}

interface props{
    idrol:number
    token:number
    estado:string
    cambios:boolean
    tipo:string
    accion:number //1=reporte  2=solicitudes
    actualizarComentarios:() => void;
}

interface states {
    redireccionando:number
    cambios:boolean,

    comentarios:comentario[]
    accionmodal:number,
    idcomentario:number,
    comentario:string,
    showmodaleditarcomentario:boolean,
}

export default class SeccionComentarios extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            redireccionando:0,
            cambios:this.props.cambios,

            accionmodal:0,
            comentarios:[],
            idcomentario:0,
            showmodaleditarcomentario:false,
            comentario:'',
        }
    }

    async componentDidMount(){
        var id_solicitud = await localStorage.getItem('id_solicitud');
		var id_proyecto = await localStorage.getItem('id_proyecto')
		var id_reporte = await localStorage.getItem('id_reporte');

        if(this.props.tipo === 'reporte'){
            const apicomentarios = await getListaComentariosReporte(this.props.token,Number(id_reporte),Number(id_proyecto));
            if(this.respuestaApi (apicomentarios)){
                return;
            }
		    this.comentariosFechaModificada(apicomentarios);
        }else if(this.props.tipo === 'solicitud'){
            const apicomentarios = await getListaComentariosSolicitud(this.props.token,Number(id_solicitud),Number(id_proyecto));
            if(this.respuestaApi (apicomentarios)){
                return;
            }
            this.comentariosFechaModificada(apicomentarios);
        }

    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.cambios !== this.props.cambios) {
            this.setState({ cambios: this.props.cambios });
            this.componentDidMount()
        }
    }

    //Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return
		}
		return false
	}

    //Función para modificar la fecha, trauducirla y aisgnarla de los comentarios.
	comentariosFechaModificada = (comentarios: any) =>{
		const comentariosfechahoramodificada = comentarios.data.map((comentario:any, index:number) => {
			//se asignan valores a las variables para desplegar el tooltip de cada comentario.
			const idcomentarioclass = `comentario${index}`
			const idcomentatiotooltip = `.comentario${index}`
			const fecha = new Date(comentario.fecha_generacion);
			const fecha_generacion = format(fecha, "d 'de' MMMM 'del' yyyy 'a las' hh:mm a", {locale: es});

			//Se calcula el tiempo transcurrido de la fecha de generación del comentatio. 
			const tiempotranscurrido = formatDistanceToNow(fecha, { locale: es });

			//Se retornan las variables formateadas y el resto de los valores del comentario.
			return { ...comentario, idcomentarioclass,idcomentatiotooltip,fecha_generacion, tiempotranscurrido };
		});

		//Se actualiza el estado de comentarios con el nuevo array de comentarios con fechas formateadas. 
		this.setState({comentarios: comentariosfechahoramodificada});

	}

    //Función para desplegar opciones de cada comentario.
	desplegarOpciones = (index:number) => {
		const comentarios = [...this.state.comentarios];
		comentarios[index].opciones = !comentarios[index].opciones;
		this.setState({ comentarios });
	};

	//Función para abrir modal para eliminar el comentario.
	verModalEditarComentario = (id_comentario: number, comentario:string) => {
		this.setState({ idcomentario: Number(id_comentario), comentario:comentario,showmodaleditarcomentario: true }) 
	}

	//Función para cerrar modal para eliminar el comentario.
	cerrarModalEditarComentario = (accion: number) => {
		this.setState({ showmodaleditarcomentario: !this.state.showmodaleditarcomentario });
		if (accion == 1) {
			this.props.actualizarComentarios()
			this.componentDidMount();
		}
	}

    render() {
        return (
            <>
                {/*////////// Sección de activación de modales y spinner //////////*/}
                {(this.state.showmodaleditarcomentario == true) ? (
					<ModalEditarComentario isOpen={true} closeModal={this.cerrarModalEditarComentario} idcomentario={this.state.idcomentario}
					token={this.props.token} comentario={this.state.comentario} accion={this.props.accion}/>
				) : null}

                {/*////////// Sección de mapeo de comentarios //////////*/}
                {this.state.comentarios.map((comentario, index) => (
                    <div key={index} className="row contenedorComentario mt-1">
                        <div className="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 contenedorIconoComentario">
                            {comentario.rol === 1 ?
                                <FaCrown size={40} color="black" title="Administrador"/>
                            :comentario.rol === 2 ?
                                <GrServer size={40} color="black" title="Backend"/>
                            :comentario.rol === 3 ?
                                <FaDesktop size={40} color="black" title="Frontend"/>
                            :comentario.rol === 4 ?
                                <FaLaptopCode size={40} color="black" title="Fullstack"/>
                            : null}
                        </div>
                        <div className="col-10 col-sm-10 col-md-10 col-lg-11 col-xl-11">
                        <div className="row encabezadoComentario">
                                <span className="nombreUsuario">{comentario.nombre_usuario} {comentario.apellido_paterno} {comentario.apellido_materno}</span>
                                <span className={`fechaGeneracionReporte ${comentario.idcomentarioclass}`} >{comentario.tiempotranscurrido}</span>
                                <Tooltip anchorSelect={comentario.idcomentatiotooltip} className="toolTipDiseño" place="top">
                                    {comentario.fecha_generacion}
                                </Tooltip>      											
                            </div>
                            <div className="row divComentario">
                                {comentario.comentario === null ? 
                                    <p style={{fontStyle:'italic'}}>No añadió comentario</p>
                                :
                                    <p>{comentario.comentario}</p>
                                }
                                <span className="textoAccion"> {comentario.accion} </span>
                            </div>
                            {comentario.token === this.props.token && (this.props.estado !== "Resuelto")? 
                                <Dropdown className="dropdownOpcionesComentario" isOpen={comentario.opciones} onClick={() => this.desplegarOpciones(index)}>
                                    <DropdownToggle data-toggle="dropdown" tag="span">
                                        <SlOptions size={15} color="black" title="Frontend"/>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.verModalEditarComentario(comentario.id_comentario, comentario.comentario)}> 
                                            <HiPencil size={15} color="white"/> Editar
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            :null}
                        </div>
                    </div>
                ))}
            </>
        );
    }
}
function Acciones(bandera:string) {
    if(bandera=="modulo"){
		return <Navigate to="/modulo" />;
	}
	else if(bandera=="submodulo"){
		return <Navigate to="/submodulo" />;
	}
}
