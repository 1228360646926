import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalEliminarSolicitud.css";
import { postEliminarSolicitud } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';

interface props {
    isOpen: boolean,
    closeModal:(accion:number) => void,
    idsolicitud:number,
    idproyecto:number,
    accion:number,
    token:number
  
}

interface states {
    isOpen: boolean,
    redireccionando:boolean,
    confirmar:boolean
   
}
export default class ModalEliminarSolicitud extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            isOpen:this.props.isOpen,
            redireccionando:false,
            confirmar:false
        }
    }

    showModal=(accion:number)=> {
      this.setState({isOpen:false})
      setTimeout(() => {
        this.props.closeModal(accion);
      }, 200);
    }

    eliminarSolicitud = async () => {
      let json = {
        "token":this.props.token,
        "id_solicitud":this.props.idsolicitud,
        "id_proyecto":this.props.idproyecto
      }

      const resp = await postEliminarSolicitud(json);

      if (resp.code == 0) {
        this.showModal(1)
      }
      else if (resp.code == 200) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 300) {
        toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 1000 || resp == -1) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
    }
    
    render() {
        return (
          <>
            <ToastContainer />
            <Modal isOpen={this.state.isOpen}>
              <ModalHeader className="modalHeader">
                <label className='labelTituloModal'> 
                  Eliminar solicitud
                </label>
                <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
              </ModalHeader>
              <ModalBody>
                <div className="divContenedorModal">
                  <div className="contenidoTextoModal">
                    <span className='textoPreguntaModal'>¿Está seguro de eliminar la solicitud?</span>
                  </div>
                  <div className="contenedorCentro mb-1 mt-1" onClick={() => this.setState({ confirmar: !this.state.confirmar })}>
                    <input type="checkbox" checked={this.state.confirmar} /> Confirmar eliminación
                  </div>
                  <div className="contenidoTextoModal mb-2">
                    <span className='spanNota'>Nota: </span>
                    {this.props.accion === 1 ? 
                      <span className='textoAviso'>
                        Eliminar la solicitud eliminará el acceso de todos los usuarios que colaboran en ella. 
                        Asegúrate de que las APIs cumplan con los requisitos solicitados antes de eliminar la solicitud.
                      </span>
                    :
                      <span className='textoAviso'>
                        Al eliminar la solicitud ya no tendrás acceso a ellla. Asegúrate de que ya estén todos notificados de
                        que la solicitud haya sido resuelta exitósamente para no generar confusiones en el equipo.
                      </span>
                    }
                  </div>
                  
                </div>
                <div className="divContenedorBotonesModal">
                  <Button className="btnCancelModal" onClick={() => this.showModal(0)} >Cancelar</Button>
                  <Button className="btnAceptarModal"  onClick={this.eliminarSolicitud} disabled={!this.state.confirmar}>Eliminar</Button>           
                </div>
              </ModalBody>
            </Modal>
          </>
          
        );
      }
    }
    