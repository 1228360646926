import React from "react";
import { FaCloud } from "react-icons/fa";
import { Input, Button, Card, CardBody, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, InputGroupText } from 'reactstrap';
import { getListaApisDelModulo, getListaSubmodulosDelModulo, getModuloDetalles } from "../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './DetallesModulo.css';
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { Navigate } from "react-router-dom";
import ModalEliminarModulo from "../../Complements/Modales/ModalEliminarModulo/ModalEliminarModulo";
import ModalFormularioModulo from "../../Complements/Modales/ModalFormularioModulo/ModalFormularioModulo";
import ModalFormularioSubmodulo from "../../Complements/Modales/ModalFormularioSubmodulo/ModalFormularioSubmodulo";
import { RiDeleteBinFill } from "react-icons/ri";
import { CgDetailsMore } from "react-icons/cg";
import MenuNavbar from "../../Complements/Navbar/Navbar";
import { HiPencil, HiPuzzle } from "react-icons/hi";
import Paginacion, { paginarElementos } from '../../Complements/Paginacion/Paginacion';
import { SlOptions } from "react-icons/sl";
import { es } from "date-fns/locale";
import { format } from "date-fns";
import { IoMdAdd } from "react-icons/io";
import CardSubmodulo from "./Componentes/Submodulo";
import Api from "./Componentes/Api";
import { api, modulo, moduloestado, submodulo } from "./Interfaces";
import Headers from "../../Complements/Headers/Headers";
import Navegacion from "../../Complements/Navegacion/Navegacion";
import { HiMagnifyingGlass } from "react-icons/hi2";

//Interfaz general del componente.
interface states {
	id_rol:number,
	salir: boolean,
	cargando: boolean,
	perfil: {
		token: number,
		name: string,
		email: string,
		role:number
	},
	opcionesdetalles:boolean,
	modulo:modulo,

	txtbuscadorsubmodulos: string,
	visualizarsubmodulos:boolean,
	submodulos: submodulo[],
	submodulosfiltrados: submodulo[],
	submodulospaginados: submodulo[],
	submodulosfiltradospaginados: submodulo[],

	txtbuscadorapis: string,
	visualizarapis:boolean,
	apis: api[],
	apisfiltradas: api[],
	apispaginadas: api[],
	apisfiltradaspaginadas: api[],

	//Estados de modales
	titulomodal: string,
	accionmodalmodulo: number,
	showmodaleliminarmodulo: boolean,
	showmodalcrearsubmodulo: boolean,
	moduloseleccionado: modulo,
	showmodaleditmmodulo: boolean,

	//Estados de paginación de submódulos
	paginaactivasubmodulo: number,
	itemsporpaginasubmodulo: number,
	totalpaginassubmodulo:number,
	totalpaginasfiltradassubmodulo:number,

	//Estados de paginación de apis
	paginaactivaapi: number,
	itemsporpaginaapi: number,
	totalpaginasapi:number,
	totalpaginasfiltradasapi:number,

	redireccionando: number,

}

export default class DetallesModulo extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
			id_rol:0,
			salir: false,
			cargando: true,
			perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "", role:""}'),
			opcionesdetalles:false,
			modulo: moduloestado,

			txtbuscadorsubmodulos:"",
			visualizarsubmodulos:true,
			submodulos: [],
			submodulosfiltrados: [],
			submodulospaginados:[],
			submodulosfiltradospaginados:[],

			txtbuscadorapis: "",
			visualizarapis:true,
			apis:[],
			apisfiltradas:[],
			apispaginadas:[],
			apisfiltradaspaginadas:[],

			//Estados de modales
			titulomodal: "",
			accionmodalmodulo: 0,
			showmodaleliminarmodulo: false,
			showmodalcrearsubmodulo: false,
			moduloseleccionado:moduloestado,
			showmodaleditmmodulo: false,

			//Estados de paginación de submódulos
			paginaactivasubmodulo: 1,
			itemsporpaginasubmodulo: 4,
			totalpaginassubmodulo:0,
			totalpaginasfiltradassubmodulo:0,

			//Estados de paginación de apis
			paginaactivaapi: 1,
			itemsporpaginaapi: 4,
			totalpaginasapi:0,
			totalpaginasfiltradasapi:0,

			redireccionando: 0,
		};
	}

	async componentDidMount() {
		/*
			Si no existen datos del usuario almacenados en localStorage, se interpretará que no ha iniciado sesión
			y se redirigirá automáticamente al login.
		*/
		if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
			const datos = localStorage.getItem('acceso');
		}

		//Se obtiene el calor del item "id_rol" y se almacena en su correspondiente estado.
		const id_rol = localStorage.getItem("id_rol")
		this.setState({id_rol:Number(id_rol)})

		//Se obtiene el valor de el item "accion", se hacen validaciones y se muestra el mensaje correspondiente,
		const accion = localStorage.getItem("accion");
		if(accion !== null){
			this.mensajesToast(accion);
			localStorage.removeItem("accion");
		}

		const id_modulo = await localStorage.getItem('id_modulo');
		const id_proyecto = await localStorage.getItem('id_proyecto');

		const apimodulo = await getModuloDetalles(this.state.perfil.token, Number(id_modulo), Number(id_proyecto));
		if(this.respuestaApi (apimodulo,0)){
			return;
		}
		this.moduloFechaModificada(apimodulo)

		const apisubmodulos = await getListaSubmodulosDelModulo(this.state.perfil.token, Number(id_modulo), Number(id_proyecto));
		if(this.respuestaApi (apisubmodulos,1)){
			return;
		}

		const apiapis = await getListaApisDelModulo(this.state.perfil.token, Number(id_modulo), Number(id_proyecto));
		if(this.respuestaApi (apiapis,2)){
			return;
		}
		
		this.setState({apis: apiapis.data,submodulos: apisubmodulos.data});
		this.cambiarPaginaSubmodulo(1)
		this.cambiarPaginaApi(1)
		
		setTimeout(() => {
			this.setState({ cargando: false });
		}, 1000);
	}

	//Función para mostrar mensajes toast al iniciar el componente.
	mensajesToast =  (accion:string) => {
		if (accion === "SubmoduloEliminado") {
			toast.success("Submódulo eliminado exitosamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		if (accion === "1") {
			toast.success("Módulo creado exitosamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		if (accion === "Documentacion eliminada") {
			toast.success("Documentación eliminada!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		if (accion === "accesorestringido") {
			toast.warning("No tienes acceso a esta sección.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		if (accion === "migracion") {
			toast.success("Migración exitosa!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		if (accion === "copia") {
			toast.success("Api copiada exitosamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
	}

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any, tipo:number) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}
		else if (resp.code == 100) {
			if(tipo === 1){
				this.setState({visualizarsubmodulos:false})
			}
			else if(tipo === 2){
				this.setState({visualizarapis:false})
			}
			this.setState({ cargando: false })
			return 
		}
		return false
	}

	//Función de regresar
	regresar = () => {
		window.history.back();
	};

	/////////////////////////////// Sección del módulo
	//Función para modificar la fecha, trauducirla y aisgnarla.
	moduloFechaModificada = (modulo: any) =>{
		//Se modifica la fecha.
		const fecha_creacion = modulo.data.fecha_creacion + " 00:00:00"; 
		const fecha = new Date(fecha_creacion);
		const fecha_formateada = format(fecha, "d 'de' MMMM 'del' yyyy", { locale: es });

		//Se asigna la nueva fecha.
		modulo.data.fecha_creacion = fecha_formateada;
		
		this.setState({ modulo: modulo.data });
	}

	//Función para desplegar opciones
	desplegarOpciones = () => {
		this.setState({opcionesdetalles: !this.state.opcionesdetalles})
	};

	//Función para abrir modal para editar módulo
	verModalFormularioModulo = (accion: number, modulo: any) => {
		if (accion == 2) {
			this.setState({ titulomodal: "Editar módulo", accionmodalmodulo: accion, moduloseleccionado: JSON.parse(JSON.stringify(modulo)) })
		}
		this.setState({ showmodaleditmmodulo: true })
	}

	//Función para cerrar modal para editar módulo
	cerrarModalCrearModulo = (accion: number) => {
		this.setState({ showmodaleditmmodulo: !this.state.showmodaleditmmodulo });
		if (accion == 2) {
			this.setState({cargando:true});
			this.componentDidMount();
		}
	}

	//Función para abrir modal para eliminar módulo
	verModalEliminarModulo = () => {
		this.setState({ showmodaleliminarmodulo: true })
	}

	//Función para cerrar modal para eliminar módulo
	cerrarModalEliminarModulo = (accion: number) => {
		this.setState({ showmodaleliminarmodulo: !this.state.showmodaleliminarmodulo });
		if (accion == 1) {
			localStorage.setItem("accion", "Moduloeliminado");
			this.setState({ redireccionando: 2 })
		}
	}

	/////////////////////////////// Sección de apis del módulo
	//Función para paginar apis y cambiar de página.
	cambiarPaginaApi = (numeroPagina:number) =>{
		this.setState({
			paginaactivaapi: numeroPagina,
			apisfiltradaspaginadas: [],
			apispaginadas: []
			});
		setTimeout(() => {
			this.setState({
				paginaactivaapi: numeroPagina,
				apispaginadas: paginarElementos(numeroPagina,this.state.itemsporpaginaapi,  this.state.apis),
				totalpaginasapi:Math.ceil(this.state.apis.length / this.state.itemsporpaginaapi),
				apisfiltradaspaginadas: paginarElementos(numeroPagina,this.state.itemsporpaginaapi, this.state.apisfiltradas)
			});
		}, 0);
	}

	//Función para buscar apis.
	buscarApi = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ txtbuscadorapis: e.target.value });

		if (e.target.value.trim().length > 2) {
			var data1 = JSON.parse(JSON.stringify(this.state.apis))
			
			const filtros = data1.filter((data1:any) => data1.nombre_api.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
			
			if (filtros.length < 1) {
				toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			this.filtroApis(1, filtros)
		}else{
			this.setState({apisfiltradaspaginadas:[]});
		}
	}

	//Función para obtener el resultado de la busqueda y paginarlos.
	filtroApis = (numeroPagina:number, array:any) => {
		this.setState({
			paginaactivaapi: numeroPagina,
			apisfiltradaspaginadas: [],
			totalpaginasfiltradasapi:0
		});
		setTimeout(() => {
			this.setState({
			apisfiltradas: array,
			paginaactivaapi: numeroPagina,
			apisfiltradaspaginadas: paginarElementos(numeroPagina,this.state.itemsporpaginaapi, array),
			totalpaginasfiltradasapi:Math.ceil(this.state.apisfiltradas.length / this.state.itemsporpaginaapi)
			});
		}, 0);
	}

	//Función para redireccionar al formulario para documentar una api.
	redireccionandoDocumentarApi = (submodulo_general: number, id_submodulo:number) => {
		localStorage.setItem("submodulo_general", submodulo_general.toString());
		localStorage.setItem("id_submodulo", id_submodulo.toString());
		this.setState({ redireccionando: 3 })
	}

	//Función para redirigir a la documentación de una api.
	redireccionandoDocumentacionApi = (id_api:number) => {
		localStorage.setItem("id_proyecto", this.state.modulo.id_proyecto.toString());
		localStorage.setItem("submodulo_general", this.state.modulo.submodulo_general.toString());
		localStorage.setItem("id_api", id_api.toString());
		this.setState({ redireccionando: 4 })
	}

	/////////////////////////////// Sección del submódulos del módulo.
	//Función para paginar submodulos y cambiar de página
	cambiarPaginaSubmodulo = (numeroPagina:number) =>{
		this.setState({
			paginaactivasubmodulo: numeroPagina,
			submodulosfiltradospaginados: [],
			submodulospaginados: []
		});
		setTimeout(() => {
			this.setState({
				paginaactivasubmodulo: numeroPagina,
				submodulospaginados: paginarElementos(numeroPagina,this.state.itemsporpaginasubmodulo,  this.state.submodulos),
				totalpaginassubmodulo:Math.ceil(this.state.submodulos.length / this.state.itemsporpaginasubmodulo),
				submodulosfiltradospaginados: paginarElementos(numeroPagina,this.state.itemsporpaginasubmodulo, this.state.submodulosfiltrados)
			});
		}, 0);
	}

	//Función para buscar submódulos.
	buscarSubmodulo = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ txtbuscadorsubmodulos: e.target.value });
		if (e.target.value.trim().length > 2) {
			var data1 = JSON.parse(JSON.stringify(this.state.submodulos))
			const filtros = data1.filter((data1:any) => data1.nombre_submodulo.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

			if (filtros.length < 1) {
				toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			this.filtroSubmodulo(1,filtros)
		}else{
			this.setState({submodulosfiltradospaginados:[]})
		}
	}

	//Función que obtiene el resultado de la búsqueda y paginarlos.
	filtroSubmodulo = (numeroPagina:number, array:any) => {
		this.setState({
		  paginaactivasubmodulo: numeroPagina,
		  submodulosfiltradospaginados: [],
		  totalpaginasfiltradassubmodulo:0
		});
		setTimeout(() => {
		  this.setState({
			submodulosfiltrados: array,
			paginaactivasubmodulo: numeroPagina,
			submodulosfiltradospaginados: paginarElementos(numeroPagina,this.state.itemsporpaginasubmodulo, array),
			totalpaginasfiltradassubmodulo:Math.ceil(this.state.submodulosfiltrados.length / this.state.itemsporpaginasubmodulo)
		  });
		}, 0);
	}

	//Función para abrir modal para crear un submódulo.
	verModalFormularioSubmodulo = (accion: number) => {
		if (accion == 1) {
			this.setState({ titulomodal: "Nuevo submódulo", accionmodalmodulo: accion })
		}
		this.setState({ showmodalcrearsubmodulo: true })
	}

	//Función para cerar el modal para crear un submódulo.
	cerrarModalFormularioSubmodulo = (accion: number) => {
		this.setState({ showmodalcrearsubmodulo: !this.state.showmodalcrearsubmodulo });
		if (accion == 1) {
			localStorage.setItem("accion", "1");
			this.setState({ redireccionando: 5 })
		}
	}

	//Función para redireccionar a un submódulo seleccionado.
	redireccionando = (id_submodulo: number) => {
		localStorage.setItem("id_submodulo", id_submodulo.toString());
		this.setState({ redireccionando: 5 })
	}

	render() {
		return (
			<div className="divContenedorPrincipal" >
				{/*////////// Sección de redirigir a paginas mediante condicionales //////////*/}
				{this.state.redireccionando == 1 ?
					<>
						{Acciones("accesorestringido")}
					</>
				: this.state.redireccionando == 2 ?
					<>
						{Acciones("moduloeliminadoexitosamente")}
					</>
				: this.state.redireccionando == 3 ?
					<>
						{Acciones("documentarapi")}
					</>
				: this.state.redireccionando == 4 ?
					<>
						{Acciones("documentacionapi")}
					</>
				: this.state.redireccionando == 5 ?
					<>
						{Acciones("detallessubmodulo")}
					</>
				:null}
				
				{/*////////// Sección de activación de modales y spinner //////////*/}
				{(this.state.cargando == true) ? (
					<SpinnerModal tipo="full" show={this.state.cargando} />
				) : null}
				{(this.state.showmodaleditmmodulo == true) ? (
					<ModalFormularioModulo isOpen={true} titulomodal={this.state.titulomodal} moduloseleccionado={this.state.moduloseleccionado} accion={this.state.accionmodalmodulo}
					closeModal={this.cerrarModalCrearModulo} idproyecto={this.state.modulo.id_proyecto}
					token={this.state.perfil.token} idsolicitud={0} />
				) : null}

				{(this.state.showmodaleliminarmodulo == true) ? (
					<ModalEliminarModulo isOpen={true} closeModal={this.cerrarModalEliminarModulo} idmodulo={this.state.modulo.id_modulo}
						token={this.state.perfil.token} idproyecto={this.state.modulo.id_proyecto} />
				) : null}

				{(this.state.showmodalcrearsubmodulo == true) ? (
					<ModalFormularioSubmodulo isOpen={true} titulomodal={this.state.titulomodal} closeModal={this.cerrarModalFormularioSubmodulo} idproyecto={this.state.modulo.id_proyecto}
					idmodulo={this.state.modulo.id_modulo} token={this.state.perfil.token} accion={this.state.accionmodalmodulo} submoduloseleccionado={undefined} idsolicitud={0} />
				) : null}
				<ToastContainer />
				<MenuNavbar/>
				<main >
					{/*////////// Sección de breadscrum //////////*/}
					<Navegacion
						seccion="modulo"
						proyecto={this.state.modulo.nombre_proyecto}
						modulo={this.state.modulo.nombre_modulo}
					/>
					{/*////////// Sección de información del módulo //////////*/}
					<Card className="cardSeccion">
						<CardHeader className="cardSeccionHeader">
							<div className="contenedorTituloSeccionDetalles">
								<div className="divTituloSeccion">
									<CgDetailsMore size={23} color="black"/>
									<span className="tituloSeccion">Detalles</span>
								</div>
								{/*////////// Sección de botón para editar módulo, el cual se oculta dependiendo del rol //////////*/}
								{this.state.id_rol == 1 || this.state.id_rol == 2 || this.state.id_rol == 4 ? 
									<Dropdown className="dropdownOpcionesDetalles" isOpen={this.state.opcionesdetalles} onClick={() => this.desplegarOpciones()}>
										<DropdownToggle data-toggle="dropdown" tag="span">
											<SlOptions size={20} color="black"/>
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem onClick={() => this.verModalFormularioModulo(2, this.state.modulo)}>
												<HiPencil size={15} color="white"/> Editar información
											</DropdownItem>
											<DropdownItem onClick={() => this.verModalEliminarModulo()}>
												<RiDeleteBinFill size={15} color="white"/> Eliminar
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								:null}
							</div>
						</CardHeader>
						<CardBody className="divContenidoCardBody">
							<div className="row d-flex">
								<div className="col">
									<div className="divTablaDetalles col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<table className="tableContenidoDetalles">
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Nombre:</span>
												</td>
												<td className="col-8">
													<span className="tituloProyecto">{this.state.modulo.nombre_modulo}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Fecha de creación:</span>
												</td>
												<td className="col-8">
													<span className="tituloProyecto">{this.state.modulo.fecha_creacion}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Apis completadas:</span>
												</td>
												<td className="col-8">
													<span className="tituloProyecto">{this.state.modulo.apis_completadas}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Apis con errores:</span>
												</td>
												<td className="col-8">
													<span className="tituloProyecto">{this.state.modulo.apis_reportadas}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-1">
													<span className="tituloEncabezado">Apis en desarrollo:</span>
												</td>
												<td className="col-1">
													<span className="tituloProyecto">{this.state.modulo.apis_en_desarrollo}</span>
												</td>
											</tr>
										</table>
									</div>
									<div className="divHeaders col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
										<Headers
											headers_proyecto={this.state.modulo.headers_proyecto}
											headers_modulo={this.state.modulo.headers_modulo}
											headers_submodulo={null}
											headers_api={null}
										/>
									</div>
									{this.state.modulo.descripcion_modulo !== null && this.state.modulo.descripcion_modulo.trim() !== '<p><br></p>' && this.state.modulo.descripcion_modulo.trim() !== ''? 
										<div className="div">
											<span className="tituloEncabezado">Descripción del módulo:</span><br />
											<div className="divDescripcion" dangerouslySetInnerHTML={{ __html: this.state.modulo.descripcion_modulo }} />		
										</div>
									:
										<div className="div">
											<span className="tituloEncabezado">Descripción del módulo:</span><br />
											<em style={{fontSize:'15px'}}>Agrega una breve descripción para entender el funcionamiento del módulo.</em>	
										</div>
									}
								</div>
							</div>
						</CardBody>
					</Card>
					<div className="row">
						{/*////////// Sección de apis del módulo //////////*/}
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3">
							<Card className="cardSeccion">
								<CardHeader className="cardSeccionHeader">
									<div className="contenidoTituloSeccion">
										<FaCloud size={25} color="black"/>
										<span className="tituloSeccion">Apis</span>
									</div>
								</CardHeader>
								<CardBody>
									<div className="row">
										{/*////////// Sección de buscador de apis //////////*/}
										<div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 divContenedorBuscador">
											<InputGroup>
												<Input 
													type="text" 
													className="inputBuscador"
													placeholder="Buscar por nombre"
													onChange={this.buscarApi} 
													value={this.state.txtbuscadorapis} 
												/>
												<InputGroupText className="logoBuscador">
													<HiMagnifyingGlass />
												</InputGroupText>
											</InputGroup>
										</div>
										{/*////////// Sección de botón para documentar una api , el cual se oculta dependiendo del rol //////////*/}
										{this.state.id_rol == 1 || this.state.id_rol == 2 || this.state.id_rol == 4 ? 
											<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 divContenedorBotones">
												<Button 
													className="botonCrearModSubApi"
													color="success"
													onClick={() => this.redireccionandoDocumentarApi(this.state.modulo.submodulo_general, this.state.modulo.id_submodulo)}>
													<IoMdAdd size={20} color="white"/>&nbsp;Api
												</Button>
											</div>
										:null}
									</div>
									{/*////////// Sección de lista de apis del módulo //////////*/}
									{this.state.visualizarapis === false ? 
											<div className="row mt-4">
												<div className="d-flex justify-content-center">
													<em style={{fontSize:'14px'}}>El módulo aún no cuenta con apis.</em>
												</div>
											</div>
										:
											<>
												<div className="row">
													{this.state.txtbuscadorapis.length < 3 ?
														this.state.apispaginadas.map((api, index) => (
															<Api 
																key={index}
																clase="col-12 mt-3"
																api={api} 
																verapi={() => this.redireccionandoDocumentacionApi(api.id_api)} 
															/>
														))
														:this.state.apisfiltradaspaginadas.map((api, index) => (
															<Api 
																key={index}
																clase="col-12 mt-3"
																api={api} 
																verapi={() => this.redireccionandoDocumentacionApi(api.id_api)} 
															/>
														))
													}
												</div>
												{/*////////// Sección de paginación de apis//////////*/}
												<div className="row mt-3">
													{this.state.txtbuscadorapis.length < 3 ?
														<Paginacion cambiarPagina={this.cambiarPaginaApi} paginaActiva={this.state.paginaactivaapi}
															itemsPorPagina={this.state.itemsporpaginaapi} totalPaginas={this.state.totalpaginasapi} tamañoPaginacion="sm" />
														:
														<Paginacion cambiarPagina={this.cambiarPaginaApi} paginaActiva={this.state.paginaactivaapi}
															itemsPorPagina={this.state.itemsporpaginaapi} totalPaginas={this.state.totalpaginasfiltradasapi} tamañoPaginacion="sm" />}
												</div>
											</>
										}
								</CardBody>
							</Card> 
						</div>
						{/*////////// Sección de submódulos del módulo//////////*/}
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3">
							<Card className="cardSeccion seccionDerecha">
								<CardHeader className="cardSeccionHeader">
									<div className="contenidoTituloSeccion">
										<HiPuzzle size={23} color="black"/>
										<span className="tituloSeccion">Submódulos</span>
									</div>
								</CardHeader>
								<CardBody className="divContenidoCardBody">
									<div className="row">
										{/*////////// Sección de buscador de submódulos //////////*/}
										<div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 divContenedorBuscador">
											<InputGroup>
												<Input 
													type="text" 
													className="inputBuscador"
													placeholder="Buscar por nombre"
													onChange={this.buscarSubmodulo} 
													value={this.state.txtbuscadorsubmodulos} 
												/>
												<InputGroupText className="logoBuscador">
													<HiMagnifyingGlass />
												</InputGroupText>
											</InputGroup>
										</div>
										{/*////////// Sección de botón para abrir modal para crear submódulos, se oculta dependiendo del rol //////////*/}
										{this.state.id_rol == 1 || this.state.id_rol == 2 || this.state.id_rol == 4 ? 
											<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 divContenedorBotones">
												<Button
													className="botonCrearModSubApi"
													color="success"
													onClick={() => this.verModalFormularioSubmodulo(1)}>
													<IoMdAdd size={20} color="white"/>&nbsp;Submódulo
												</Button>
											</div>
										:null}
									</div>
									{/*////////// Sección de la lista de los submódulos del módulo //////////*/}
									{this.state.visualizarsubmodulos === false ? 
											<div className="row mt-4">
												<div className="d-flex justify-content-center">
													<em style={{fontSize:'14px'}}>El módulo no cuenta con submódulos.</em>
												</div>
											</div>
										:
											<>
												<div className="row">
													{this.state.txtbuscadorsubmodulos.length < 3 ?
														this.state.submodulospaginados.map((submodulo, index) => (
															<CardSubmodulo 
																key={index}
																clase={"col-12 mt-3"}
																submodulo={submodulo}
																versubmodulo={() => this.redireccionando(submodulo.id_submodulo)} 										
															/>
														)):
														this.state.submodulosfiltradospaginados.map((submodulo, index)=>(	
															<CardSubmodulo 
																key={index}
																clase={"col-12 mt-3"}
																submodulo={submodulo}
																versubmodulo={() => this.redireccionando(submodulo.id_submodulo)} 										
															/>
														)
													)}
												</div>
												{/*////////// Sección de la paginación de los submódulo //////////*/}
												<div className="row mt-3">
													{this.state.txtbuscadorsubmodulos.length < 3 ?
														<Paginacion cambiarPagina={this.cambiarPaginaSubmodulo} paginaActiva={this.state.paginaactivasubmodulo}
														itemsPorPagina={this.state.itemsporpaginasubmodulo} totalPaginas={this.state.totalpaginassubmodulo} tamañoPaginacion="sm"/>
													: 
														<Paginacion cambiarPagina={this.cambiarPaginaSubmodulo} paginaActiva={this.state.paginaactivasubmodulo}
														itemsPorPagina={this.state.itemsporpaginasubmodulo} totalPaginas={this.state.totalpaginasfiltradassubmodulo} tamañoPaginacion="sm"/>
													}
												</div>
											</>
										}
								</CardBody>
							</Card>
						</div>
					</div>
				</main>
			</div>
		);
	};
}
//Función para redireccionar a diferentes páginas mediante condiciones del valor recibida
function Acciones(bandera: string) {
	if(bandera=="accesorestringido"){
		return <Navigate to="/catalogoproyectos" />;
	}else if (bandera == 'moduloeliminadoexitosamente') {
		return <Navigate to="/proyecto" />;
	} else if (bandera == 'detallessubmodulo') {
		return <Navigate to="/submodulo" />;
	} else if(bandera=='documentarapi'){
		return <Navigate to="/documentarapi" />;
	} else if(bandera=='documentacionapi'){
		return <Navigate to="/documentacionapi" />;
	}
}


