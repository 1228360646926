import React from "react";
import { FaArrowRight, FaCheckCircle, FaClock, FaExclamationCircle } from "react-icons/fa";
import { Button, Card, CardBody } from "reactstrap";
import { Tooltip } from 'react-tooltip'
import { modulo } from "../Interfaces";

interface props{
    clase:string
    modulo:modulo
    vermodulo:(idmodulo:number) => void,
}

const Modulo: React.FC<props> = ({ clase,modulo,vermodulo }) => {
    return (
        <div className={clase}>
            <Card className="cardModSubApi">
                <CardBody className="cardBodyModSubApi">
                    <div className="row">
                        <div className="col-12 col-sm-9 col-md-9 col-lg-8 col-xl-8">
                            <div className="row">
                                <div className="col-12 divCentradoVerticalmente">
                                    <div className="divTituloModSubApi">
                                        {modulo.nombre_modulo.length > 24
                                            ? `${modulo.nombre_modulo.substring(0, 24)}...`
                                            : modulo.nombre_modulo}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 divCentradoVerticalmente">
                                    <span className="apisInformacion">
                                        <FaCheckCircle color="green" size={16} className="apisCompletadas" data-tooltip-content='Apis completadas' />
                                        <span>{modulo.apis_completadas} </span>
                                    </span>
                                    <Tooltip anchorSelect=".apisCompletadas" className="toolTipDiseño" place="top" />
                                </div>
                                <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 divCentradoVerticalmente">
                                    <span className="apisInformacion">
                                        <FaClock color="orange" size={16} className="apisDesarrollo" data-tooltip-content='Apis en desarrollo' />
                                        <span>{modulo.apis_en_desarrollo}</span>
                                    </span>
                                    <Tooltip anchorSelect=".apisDesarrollo" className="toolTipDiseño" place="top" />
                                </div>
                                <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 divCentradoVerticalmente">
                                    <span className="apisInformacion">
                                        <FaExclamationCircle color="red" size={16} className="apisConErrores" data-tooltip-content='Apis con errores' />
                                        <span>{modulo.apis_reportadas} </span>
                                    </span>
                                    <Tooltip anchorSelect=".apisConErrores" className="toolTipDiseño" place="top" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-3 col-md-3 col-lg-4 col-xl-4 divContenedorBotonVer">
                            <Button
                                className="botonVer"
                                color="primary"
                                onClick={() => vermodulo(modulo.id_modulo)}>
                                Ver módulo&nbsp;<FaArrowRight size={16} />
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}
export default Modulo;
