import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { toast } from 'react-toastify';

interface props {
    vermsgerror:boolean,
    msgerror:string,
    evicencias: (prop: File[]) => void;
}

interface states {
    evidencias:File[] | null
    numimagenes:number
}

export default class InputEvidencias extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            evidencias:null,
            numimagenes:0
        }
    }

    //Función para guardar los archivos cargados en su estado correspondiente.
	seleccionarArchivos(event: React.ChangeEvent<HTMLInputElement>) {
        try {
            const archivos = event.target.files;
            if (archivos && archivos.length > 0) {
                const archivosCargados: File[] = Array.from(archivos);
                this.setState({ evidencias: archivosCargados, numimagenes:archivos.length });
                this.props.evicencias(archivosCargados)
            }
        } 
		catch (error) {
			toast.error("Error de al subir el archivo.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
	}

    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Evidencias <span className="spanOpcional">(opcional)</span>
                </Label>
                <Input 
                    type="file"
                    accept="image/*"
                    multiple 
                    onChange={(event) => this.seleccionarArchivos(event)} />
                {this.props.vermsgerror ? 
                    <div className="row">
                        <div className="col-10 contenedorMensajeError">
                        <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    </div>
                :null}
            </FormGroup>
        );
    }
}