export const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['code-block']
    ],
};
  
export const formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'code-block'
];