import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { usuario } from '../Interfaces';

interface props {
    usuarios:usuario[]
    vermsgerror:boolean,
    msgerror:string,
    seleccionar: (prop: number) => void;
}

interface states {
    usuarioseleccionado:number
}

export default class SelectUsuarios extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            usuarioseleccionado:0
        }
    }

    usuarioSeleccionado = (event: React.ChangeEvent<HTMLInputElement>) => {
        const usuario = Number(event.target.value);
        this.setState({ usuarioseleccionado:usuario });
        this.props.seleccionar(usuario)
    };
   
    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Usuarios
                </Label>
                <Input type="select" value={this.state.usuarioseleccionado} onChange={this.usuarioSeleccionado}>
                    <option disabled value="0">Seleccione una opción</option>
                    {this.props.usuarios.map((usuario, index)=>(	
                        <option key={index} value= {usuario.id_usuario}>
                        {usuario.nombre_usuario}
                        </option>
                    ))}
                </Input>
                <div className="row">
                    {this.props.vermsgerror ? 
                        <div className="col-10 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    :null}
                </div>
            </FormGroup>
        );
    }
}