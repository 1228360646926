import React from "react";
import { Button } from 'reactstrap';
import { postDetallesApi, postEditarExcepciones, postEjemplosExcepciones } from "../../Services/Api";
import { validarLongitud } from "../../Services/Validaciones";
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from "react-router-dom";
import './DocumentarApi.css';
import MenuNavbar from "../../Complements/Navbar/Navbar";
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { FormularioEjemplosExcepciones } from "./Componentes.tsx/FormularioEjemplosExcepciones";
import { ejemploexcepciones, ejemploexcepcionesestado } from "./Interfaces";
import { InputModelo } from "./Componentes.tsx/InputModelo";
import SelectExcepciones from "./Componentes.tsx/SelectExcepciones";
import { api, apiestado } from "../DocumentacionApi/Interfaces";
import Navegacion from "../../Complements/Navegacion/Navegacion";

//Interfaz general del componente.
interface states{
    api:api,
	idproyecto:number,
    idapi:number,
    submodulo_general:number,

    selectexcepciones:string,
    vermsgerrorexcepciones:boolean,
    msgerrorexcepciones:string,

	txtmodeloexcepciones:string,
	vermsgerrormodeloexcepciones:boolean,
    msgerrormodeloexcepciones:string,

	ejemploExcepciones: ejemploexcepciones[];
	ejemploContadorExcepciones: number,
	
	perfil:{
		token:number,
        name:string,
		email:string
  	},
	redireccionando:number,
	cargando:boolean

}

export default class EditarExcepciones extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
            api:apiestado,
			idproyecto:0,
			idapi: 0,
            submodulo_general:0,

            selectexcepciones:'',
            vermsgerrorexcepciones:false,
            msgerrorexcepciones:'',

			txtmodeloexcepciones:'',
			vermsgerrormodeloexcepciones:false,
			msgerrormodeloexcepciones:'',

			ejemploExcepciones: [ejemploexcepcionesestado],
			ejemploContadorExcepciones:2,

            perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": ""}'),
			redireccionando:0,
			cargando:true
	  	}
    }
    async componentDidMount() {
        if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
            //this.setState({ redireccionando: true });
        }

        const id_rol = Number(localStorage.getItem("id_rol"))
		this.restriccionRol(id_rol)

        const submodulo_general = await localStorage.getItem('submodulo_general');
        const id_proyecto = await localStorage.getItem('id_proyecto');
        const id_api = await localStorage.getItem('id_api');

        this.setState({idproyecto:Number(id_proyecto),idapi:Number(id_api), submodulo_general:Number(submodulo_general)});

		const modelo = await postDetallesApi(this.state.perfil.token,Number(id_proyecto),Number(id_api));
        if(this.respuestaApi (modelo)){
			return;
		}
        this.mostrarModelo(modelo)

        const ejemplosExcepciones = await postEjemplosExcepciones(this.state.perfil.token,Number(id_proyecto),Number(id_api));
        if(this.respuestaApi (ejemplosExcepciones)){
			return;
		}
        this.ejemplosExcepcionesFaltantes(ejemplosExcepciones)
        
        setTimeout(() => {
           this.setState({cargando:false})
        }, 1000);
    }

    //Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}
		return false
	}

	//Función para restringir el acceso al componente de acuerdo a su rol.
	restriccionRol = (id_rol:number) => {
		if(id_rol === 3){
            localStorage.setItem("accion","6");
			this.setState({redireccionando:2})
		}
	}

    //Función de regresar
	regresar = () => {
		window.history.back();
	};

    //Función para capturar la información del modelo.
    mostrarModelo = (modelo:any) =>{
        this.setState({txtmodeloexcepciones:modelo.data.modelo_excepciones !== null ? modelo.data.modelo_excepciones : '', 
            selectexcepciones:String(modelo.data.excepciones),
            api:modelo.data})
    }

    //Función para agregar los datos faltantes a los ejemplos de entradas.
    ejemplosExcepcionesFaltantes = (ejemplosexcepciones:any) => {
        const ejemplosExcepcionesFaltantes = ejemplosexcepciones.data.map((ejemplo:any) => ({
            ...ejemplo,
            nombre_ejemplo_excepcionlength: ejemplo.nombre_ejemplo_excepcion.length,
            txtnombreejemploexcepcion: '',
            vermsgerrornombreejemploexcepcion:false,
            msgerrornombreejemploexcepcion:'',
            ejemplo_excepcionlength: ejemplo.ejemplo_excepcion.length,
            txtejemploexcepcion:'',
            vermsgerrorejemploexcepcion:false,
            msgerrorejemploexcepcion:''
        }));
        this.setState({ ejemploExcepciones: ejemplosExcepcionesFaltantes });
    }

    //Función para cambiar el estado del select de habilitar excepciones.
    excepcionesSeleccionado = (prop:string) => {
        this.setState({ selectexcepciones:prop});
    };

    modeloExcepciones = (prop:string) => {
		this.setState({ txtmodeloexcepciones: prop });
	}

	ejemplosExcepciones = (prop:ejemploexcepciones[]) => {
		this.setState({ ejemploExcepciones: prop });
	}

    /////////////////////////////// Sección de función para guardar los cambios realizados.
    guardarCambios = async () => {
        this.setState({ vermsgerrormodeloexcepciones: false });

        //Actualiza estado de la visualización de los mensajes de error de cada nombre de ejemplo de excepcion.
        const vermsgerrornombreejemploexcepcion = this.state.ejemploExcepciones.map((ejemplo) => ({
            ...ejemplo,
            vermsgerrornombreejemploexcepcion: false
        }));
        this.setState({ ejemploExcepciones: vermsgerrornombreejemploexcepcion });

        //Actualiza estado de la visualización de los mensajes de error de cada ejemplo de excepcion.
        const vermsgerrorejemploexcepcion = this.state.ejemploExcepciones.map((ejemplo) => ({
            ...ejemplo,
            vermsgerrorejemploexcepcion: false
        }));
        this.setState({ ejemploExcepciones: vermsgerrorejemploexcepcion });

        let longitudvalidamodelo = { longitudminima: 30, longitudmaxima: 3000 };
        let longitudvalidanombreejemplo = {longitudminima: 3, longitudmaxima: 30};
        let longitudvalidaejemplo = { longitudminima: 30, longitudmaxima: 3000 };

        let txtmodeloexcepciones = validarLongitud(this.state.txtmodeloexcepciones, longitudvalidamodelo);

        //Nombres de ejemplos de excepcion.
        const validarNombreEjemploExcepciones = this.state.ejemploExcepciones.map((ejemplo) => {
            const resultadoValidacionNombre = validarLongitud(ejemplo.nombre_ejemplo_excepcion, longitudvalidanombreejemplo);
            return {
                ...ejemplo,
                txtnombreejemploexcepcion: resultadoValidacionNombre.codigo,
                msgerrornombreejemploexcepcion: resultadoValidacionNombre.mensaje
            };
        });
        await this.setState({ ejemploExcepciones: validarNombreEjemploExcepciones });

        //Ejemplos de excepcion.
        const validarEjemploExcepciones = this.state.ejemploExcepciones.map((ejemplo) => {
            const resultadoValidacion = validarLongitud(ejemplo.ejemplo_excepcion, longitudvalidaejemplo);
            return {
                ...ejemplo,
                txtejemploexcepcion: resultadoValidacion.codigo,
                msgerrorejemploexcepcion: resultadoValidacion.mensaje
            };
        });
        await this.setState({ ejemploExcepciones: validarEjemploExcepciones });

        let validacionescorrectas = true;
        let excepcionesCorrectas = true;

        if(this.state.selectexcepciones !== '0'){
            if (txtmodeloexcepciones.codigo == "invalid") {
                this.setState({ msgerrormodeloexcepciones: txtmodeloexcepciones.mensaje });
                this.setState({ vermsgerrormodeloexcepciones: true });
                validacionescorrectas = false;
            }

            //Nombres de ejemplos de excepcion.
            const validarNombreEjemploExcepcionesRespuesta = this.state.ejemploExcepciones.map((ejemplo) => {
                if (ejemplo.txtnombreejemploexcepcion === "invalid") {
                    return {
                        ...ejemplo,
                        vermsgerrornombreejemploexcepcion: true
                    };
                }
                return ejemplo;
            });
            await this.setState({ ejemploExcepciones: validarNombreEjemploExcepcionesRespuesta });
            //Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemploNombresExcepcionesCorrectas su valor booleano inverso.
            //En pocas la palabras se si se cumple la condición se guardará como falso.
            const ejemploNombresExcepcionesCorrectas =  !validarNombreEjemploExcepcionesRespuesta.some((ejemplo) => ejemplo.txtnombreejemploexcepcion === "invalid");  
            
            //Ejemplos de excepcion.
            const validarEjemploExcepcionesRespuesta = this.state.ejemploExcepciones.map((ejemplo) => {
                if (ejemplo.txtejemploexcepcion === "invalid") {
                return {
                    ...ejemplo,
                    vermsgerrorejemploexcepcion: true
                };

                }
                return ejemplo; // Devuelve el ejemplo sin modificar
            });

            await this.setState({ ejemploExcepciones: validarEjemploExcepcionesRespuesta });
            //Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemploExcepcionesCorrectas su valor booleano inverso.
            //En pocas la palabras se si se cumple la condición se guardará como falso.
            const ejemploExcepcionesCorrectas = !validarEjemploExcepcionesRespuesta.some((ejemplo) => ejemplo.txtejemploexcepcion === "invalid");
        
            //Se suman ambos valores. Si es true y false. Se guarda como false. Si ambos son true, se guarda como true y se actualiza el valor en salidasCorrectas.
            //Si el valor de excepcionesCorrectas es true significa que todos los nombres y sus correspondientes ejemplos son aptos para guardar.     
            excepcionesCorrectas= ejemploNombresExcepcionesCorrectas && ejemploExcepcionesCorrectas;
        }
        
        //Se evalua el valor de validacionescorrectas para tomar en cuenta los demás campos.
		//Si es true, significa que el campo de modelo es apto para guardar. Entonces se le asigna el valor de salidaCorrectas para
        //poder evaluar los campos por completo.
        if(validacionescorrectas){
            validacionescorrectas = excepcionesCorrectas;
        }
        
        //Si validacionescorrectas es true se crea el json y se manda a la api.
        if (validacionescorrectas) {
            let json = {
                "token": this.state.perfil.token,
                "id_proyecto": this.state.idproyecto,
                "id_api": this.state.idapi,
                "excepciones":this.state.selectexcepciones,
                "modelo_excepciones":this.state.txtmodeloexcepciones,
                "ejemplos_excepciones":this.state.ejemploExcepciones  
            } 
           
            const resp = await postEditarExcepciones(json);
            
            if (resp.code == 0) {
                localStorage.setItem("accion", "9");
                this.setState({ redireccionando: 1 })
            }else if (resp.code == 100) {
                this.setState({ redireccionando: 1 })
            }else if (resp.code == 200) {
                toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }else if (resp.code == 300) {
                toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }else if (resp.code == 400) {
                localStorage.setItem("accion", '7');
                this.setState({ redireccionando: 1 })
            }else if (resp.code == 1000 || resp == -1) {
				toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
        }
    }

    //Función para cancelar y redirigir a la documentación de la api.
    cancelar = () => {
        this.setState({ redireccionando: 2})
    }

    render() {
        return ( 
            <div className="divContenedorPrincipal">
                {/*////////// Sección de redirigir a paginas mediante condicionales //////////*/}
                {this.state.redireccionando == 1 ?
                    <>
                        {Acciones("documentacionapi")}
                    </>
                :this.state.redireccionando == 2 ?
                    <>
                        {Acciones("cancelar")}
                    </>
                :null}

                {/*////////// Sección de activación de spinner //////////*/}
                {(this.state.cargando == true) ? (
                        <SpinnerModal tipo="full" show={this.state.cargando}/>
                ) : null}
                <ToastContainer />
                <MenuNavbar/>
                <main>
                    {/*////////// Sección de breadscrum //////////*/}
                    <Navegacion
						seccion={"edit-excepciones"}
						proyecto={this.state.api.nombre_proyecto}
						modulo={this.state.api.nombre_modulo}
						submodulo={this.state.api.nombre_submodulo}
						accion={this.state.api.submodulo_general}
						api={this.state.api.nombre_api}
					/>
                    {/*////////// Sección de habilitar excepciones //////////*/}
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <SelectExcepciones 
								excepciones={this.state.selectexcepciones} 
								vermsgerror={this.state.vermsgerrorexcepciones} 
								msgerror={this.state.msgerrorexcepciones} 
								habilitarExcepciones={this.excepcionesSeleccionado}						
							/>
                        </div>
                    </div> 
                    {/*////////// Sección de excepciones //////////*/}
                    {this.state.selectexcepciones === "1" ? 
                        <div className="row">
                            <InputModelo
                                titulo={"Modelo de excepciones "}
                                txtmodelo={this.state.txtmodeloexcepciones}
                                vermsgerrormodelo={this.state.vermsgerrormodeloexcepciones}
                                msgerrormodelo={this.state.msgerrormodeloexcepciones}
                                modelo={this.modeloExcepciones} 
                            />
                            <FormularioEjemplosExcepciones
                                ejemplosexcepciones={this.state.ejemploExcepciones}
                                ejemplos={this.ejemplosExcepciones} 
                            />
                        </div>
                    :null}
                    <div className="row mt-4">
                        <div className="col divContenedorBotonesGuardar">
                            <Button className="botonCancelarDocApi" color="danger" onClick={this.cancelar} >Cancelar</Button>
                            <Button color="success" onClick={this.guardarCambios} >Guardar cambios</Button>
                        </div>
                    </div>
                </main>
            </div>
        );
    };
}
function Acciones(bandera:string) {
    if (bandera=='documentacionapi'){
        return <Navigate to="/documentacionapi" />;
    }else if(bandera=='cancelar'){
        return <Navigate to="/documentacionapi" />;
    }
}
    
    
