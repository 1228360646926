import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalEliminarReporte.css";
import { deleteEliminarReporte } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';

interface ModalProps {
  isOpen: boolean,
  closeModal:(accion:number) => void,
  idproyecto:number,
  idreporte:number,
  token:number,
  accion:number
}

interface ModalState {
  isOpen: boolean,
  confirmar:boolean
}

export default class ModalEliminarReporte extends React.Component<ModalProps,ModalState>{
  constructor(props:ModalProps) {
    super(props);
    this.state = {
      isOpen:this.props.isOpen,
      confirmar:false
    }
  }

  showModal=(accion:number)=> {
    this.setState({isOpen:false})
    setTimeout(() => {
      this.props.closeModal(accion);
    }, 200);
  }

  eliminarReporte = async () => {
    let json = {
      "token":this.props.token,
      "id_proyecto":this.props.idproyecto,
      "id_reporte":this.props.idreporte
    }

    const resp = await deleteEliminarReporte(json);
    
    if (resp.code == 0) {
      this.showModal(1)
    }
    else if (resp.code == 200) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
    else if (resp.code == 300) {
      toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
    else if (resp.code == 1000 || resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
  }
    
  render() {
    return (
      <>
        <ToastContainer />
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader className="modalHeader">
            <label className='labelTituloModal'> Eliminar reporte </label>
              <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
          </ModalHeader>
          <ModalBody>
            <div className="divContenedorModal">
              <div className="contenidoTextoModal">
                <span className='textoPreguntaModal'>¿Está seguro de eliminar el reporte?</span>
                <div className="contenedorCentro mb-1 mt-1" onClick={() => this.setState({ confirmar: !this.state.confirmar })}>
                  <input type="checkbox" checked={this.state.confirmar} /> Confirmar eliminación
                </div>
              </div>
              <div className="contenidoTextoModal mb-2">
                <span className='spanNota'>Nota: </span>
                {this.props.accion === 1 ? 
                  <span className='textoAviso'>
                    Eliminar el reporte eliminará el acceso de todos los usuarios que colaboran en él. 
                    También actualizará el estado de la API a "Completado". 
                    Esto puede significar que el problema reportado no se haya resuelto.
                  </span>
                :
                  <span className='textoAviso'>
                    Eliminar el reporte eliminará el acceso de todos los usuarios que colaboran en él. 
                    Antes de eliminarlo, asegúrate de que todos los usuarios involucrados estén notificados de 
                    que el problema ha sido resuelto. Esto ayudará a evitar confusiones en el equipo.
                  </span>
                }
              </div>
            </div>
            <div className="divContenedorBotonesModal">
              <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={this.eliminarReporte} disabled={!this.state.confirmar}>Eliminar</Button>           
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
    