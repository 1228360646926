import React from "react";
import "./CatalogoReportes.css";
import { Input, Table, Button, FormGroup, Label, InputGroup, InputGroupText } from 'reactstrap';
import { getListaApisGeneral, getListaProyectos, getListaReportes, getListaSubmodulosDelModuloSinFiltro, getModulosDelProyecto } from "../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { Navigate } from "react-router-dom";
import MenuNavbar from "../../Complements/Navbar/Navbar";
import Paginacion, { paginarElementos } from '../../Complements/Paginacion/Paginacion';
import { format, formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import Reporte from "./Componentes/Reporte";
import { reporte } from "./Interfaces";
import Navegacion from "../../Complements/Navegacion/Navegacion";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { proyecto } from "../CatalogoProyectos/Interfaces";
import { modulo, submodulo } from "../../Complements/Modales/ModalFormularioSolicitud/Interfaces";
import { api } from "../CatalogoApis/Interfaces";

//Interfaz general del componente.
interface states{
	isExpanded: boolean,
	salir: boolean,
  	cargando:boolean,
	perfil:{
	  token:number,
	  name:string,
	  email:string,
	  role:number
	},
	tabla: {
		encabezados: string[],
		dimensiones: string[],
		reportes:reporte[],
	},

	proyectoslista:proyecto[],
	selectproyecto:number,

	moduloslista:modulo[],
	selectmodulo:number,

	submoduloslista:submodulo[],
 	selectsubmodulo:number,

	apislista:api[],
 	selectapi:number,

	visualizarreportes:number,
	reportesapi:boolean,
	reportesfiltrados:reporte[],
	reportespaginados:reporte[],
	reportesfiltradospaginados:reporte[],
	
	txtbuscador:string,
	redireccionando:boolean,

	paginaActiva: number,
	itemsPorPagina: number,
    totalPaginas:number
    totalPaginasFiltradas:number
}

export default class CatalogoReportes extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
		  isExpanded: false,
		  salir:false,
		  cargando:true,
		  perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": ""}'),
		  tabla: {
			encabezados: [
			  "No. Reporte",
			  "Título",
			  "Ubicación",
			  "Fecha de entrega",
			  "Prioridad",
			  "Estado",
			  ""],
			dimensiones: [
				"120px",//No. Reporte
				"220px",//Título
				"320px",//Ubicación
				"230px",//Fecha de generación
				"50px",//Prioridad
				"100px",//Estado
				"150px"//Mas información
			],
			reportes:[]
		  },

		  proyectoslista:[],
		  selectproyecto: 0,

		  moduloslista:[],
		  selectmodulo: 0,

		  submoduloslista:[],
		  selectsubmodulo:0,

		  apislista:[],
 		  selectapi:0,

		  visualizarreportes:0,
		  reportesapi:false,
		  reportesfiltrados:[],
		  reportespaginados:[],
		  reportesfiltradospaginados:[],
	
		  txtbuscador:"",
		  redireccionando:false,
		  paginaActiva: 1,
		  itemsPorPagina: 5,
		  totalPaginas:0,
		  totalPaginasFiltradas:0
		};
	}

	async componentDidMount() {
		if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
			//this.setState({ redireccionando: true });
        } 
		
		const accion = localStorage.getItem("accion");
		if(accion !== null){
			this.mensajesToast(accion);
			localStorage.removeItem("accion");
		}
		
		const proyecto = Number(localStorage.getItem("proyecto"));
		const modulo = Number(localStorage.getItem("modulo"));
		const submodulo = Number(localStorage.getItem("submodulo"));
		const id_api = Number(localStorage.getItem("id_api"));
		if(id_api){
			this.apiReportes(proyecto, modulo, submodulo,id_api);
		}
		else{
			const apireportes = await getListaReportes(this.state.perfil.token,"todos",0);
			if(this.respuestaApi (apireportes,"todos")){
				return;
			}
			this.setState({reportesapi:false})
			this.reportesModificados(apireportes);
			this.cambiarPagina(1)
		}

		const apiproyectos = await getListaProyectos(this.state.perfil.token);
		this.setState({proyectoslista: apiproyectos.data});
		
		setTimeout(() => {
			this.setState({cargando: false});
		}, 1000);
    }

	//Función para mostrar mensajes toast al iniciar el componente.
	mensajesToast =  (accion:string) => {
		//300 = Acceso denegado
		if (accion === "300") { 
			setTimeout(() => {
				toast.warning("No tienes acceso al reporte.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
		if (accion === "Reporte eliminado") { 
			setTimeout(() => {
				toast.success("Reporte eliminado!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
	}

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any, tipo_busqueda:string) => {
		if(resp.code == 0){
			this.setState({visualizarreportes:5})
			return
		}
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 100) {
			if(tipo_busqueda === "todos"){
				this.setState({visualizarreportes:0})
			}
			else if(tipo_busqueda === "proyecto"){
				this.setState({visualizarreportes:1})
			}
			else if(tipo_busqueda === "modulo"){
				this.setState({visualizarreportes:2})
			}
			else if(tipo_busqueda === "submodulo"){
				this.setState({visualizarreportes:3})
			}
			else if(tipo_busqueda === "api"){
				this.setState({visualizarreportes:4})
			}
			this.setState({ cargando: false })
		}
	}

	///////////////////////////////Sección de reportes de una api
	//Función para motrar los reportes de una api
	apiReportes = async (proyecto:number, modulo:number, submodulo:number,id_api:number) => {
		const reportes = await getListaReportes(this.state.perfil.token, "api",Number(id_api));
		const modulos = await getModulosDelProyecto(this.state.perfil.token,proyecto);
		this.setState({moduloslista:modulos.data})
		const submodulos = await getListaSubmodulosDelModuloSinFiltro(this.state.perfil.token, proyecto, modulo);
		this.setState({submoduloslista:submodulos.data})
		const apis = await getListaApisGeneral(this.state.perfil.token, "submodulo",submodulo);
		this.setState({apislista:apis.data})
		if(this.respuestaApi (reportes,"api")){
			return;
		}
		
		this.setState({reportesapi:true,selectproyecto:proyecto,selectmodulo:modulo, selectsubmodulo:submodulo, selectapi:id_api})
		this.reportesModificados(reportes);
		this.cambiarPagina(1)
	}
	/////////////////////////////// Sección de reportes
	//Función para modificar la fecha, trauducirla, aisgnarla y crear la ubicación.
	reportesModificados = (reporte: any) =>{
		const reportes = reporte.data.map((reporte:any, index:number) => {
			const idreporteclass = `reporte${index}`
			const idreportetooltip = `.reporte${index}`
			//Se agrega un nuevo formato a la fecha de fecha de entrega del reporte.
			const fecha = new Date(reporte.fecha_entrega);
			const fecha_entrega = format(fecha, "d 'de' MMM 'del' yyyy 'a las' hh:mm a", {locale: es});

			const tiempo_faltante = formatDistanceToNow(fecha, { locale: es })
			
			let ubicacion;
			if(reporte.submodulo_general == 1){
				ubicacion = reporte.nombre_proyecto + ' / ' + reporte.nombre_modulo + ' / ' + reporte.nombre_api;
			}else{
				ubicacion = reporte.nombre_proyecto + ' / ' + reporte.nombre_modulo + ' / ' + reporte.nombre_submodulo + ' / ' + reporte.nombre_api;
			}
			return { ...reporte, fecha_entrega, idreporteclass, idreportetooltip, tiempo_faltante,ubicacion };
		});
		
		var tabla = this.state.tabla;
		tabla.reportes = JSON.parse(JSON.stringify(reportes));
	}

	//Función para buscar un reporte por su título.
	buscarReporte = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ txtbuscador: e.target.value });
		if (e.target.value.trim().length > 2) {
			var data1 = JSON.parse(JSON.stringify(this.state.tabla.reportes))
			
			const filtros = data1.filter((data1:any) => (data1.id_reporte + " " + data1.titulo_reporte).toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
			
			if (filtros.length < 1) {
				toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			this.filtro(1, filtros)
		}else{
			this.setState({reportesfiltradospaginados:[]});
		}
	}

	//Función para cambiar de página
	cambiarPagina = (numeroPagina:number) =>{
		this.setState({
			paginaActiva: numeroPagina,
			reportespaginados: [],
			reportesfiltradospaginados: []
		  });
	  
		setTimeout(() => {
			this.setState({
				paginaActiva: numeroPagina,
				reportespaginados: paginarElementos(numeroPagina,this.state.itemsPorPagina,  this.state.tabla.reportes),
				totalPaginas:Math.ceil(this.state.tabla.reportes.length / this.state.itemsPorPagina),
				reportesfiltradospaginados: paginarElementos(numeroPagina,this.state.itemsPorPagina, this.state.reportesfiltrados)
			});
		}, 0);
		
	}

	proyectoSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const proyecto = Number(event.target.value);
		if(proyecto === 0){
			const reportes = await getListaReportes(this.state.perfil.token, "todos",0);
			this.reportesModificados(reportes);
			this.cambiarPagina(1);
			this.respuestaApi(reportes,"todos");
			this.setState({selectproyecto:proyecto,selectmodulo:0, moduloslista:[],selectsubmodulo:0,submoduloslista:[],selectapi:0, apislista:[],txtbuscador:""});
		}else{
			this.setState({ selectproyecto:proyecto,selectmodulo:0, selectsubmodulo:0 }, async () => {
				const modulos = await getModulosDelProyecto(this.state.perfil.token, Number(this.state.selectproyecto));
				const reportes = await getListaReportes(this.state.perfil.token, "proyecto",proyecto);
				this.reportesModificados(reportes);
				this.cambiarPagina(1);
				this.respuestaApi(reportes,"proyecto");
				this.setState({moduloslista: modulos.data,submoduloslista:[],selectsubmodulo:0,apislista:[],selectapi:0,txtbuscador:""});
			});
		}
	};

	moduloSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const modulo = Number(event.target.value);
		if(modulo === 0){
			const reportes = await getListaReportes(this.state.perfil.token, "proyecto",this.state.selectproyecto);
			this.reportesModificados(reportes);
			this.cambiarPagina(1);
			this.respuestaApi(reportes,"proyecto");
			this.setState({selectmodulo:0, selectsubmodulo:0,submoduloslista:[],apislista:[],txtbuscador:""});
		}else{
			this.setState({ selectmodulo:modulo,selectsubmodulo:0, apislista:[]}, async () => {
				const submodulos = await getListaSubmodulosDelModuloSinFiltro(this.state.perfil.token, Number(this.state.selectproyecto), Number(modulo));
				const apis = await getListaApisGeneral(this.state.perfil.token,"modulo",this.state.selectmodulo)
				const reportes = await getListaReportes(this.state.perfil.token, "modulo",modulo);
				this.reportesModificados(reportes);
				this.cambiarPagina(1);
				this.respuestaApi(reportes,"modulo");
				this.setState({ submoduloslista: submodulos.data,
								apislista:apis.data,
								selectsubmodulo:0,
								txtbuscador:""
				});
			});
		}
	};

	submoduloSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const submodulo = Number(event.target.value);
		if(submodulo === 0){
			const apis = await getListaApisGeneral(this.state.perfil.token, "modulo", this.state.selectmodulo);
			const reportes = await getListaReportes(this.state.perfil.token, "modulo",this.state.selectmodulo);
			this.respuestaApi(reportes,"submodulo");
			this.reportesModificados(reportes);
			this.setState({apislista:apis.data,selectsubmodulo:submodulo, selectapi:0,txtbuscador:""});
			this.cambiarPagina(1);
		}else{
			const apis = await getListaApisGeneral(this.state.perfil.token, "submodulo", Number(submodulo));
			const reportes = await getListaReportes(this.state.perfil.token, "submodulo",submodulo);
			this.respuestaApi(reportes,"submodulo");
			this.reportesModificados(reportes);
			this.setState({apislista:apis.data,selectsubmodulo:submodulo, txtbuscador:""});
			this.cambiarPagina(1);
		}
		
	};

	apiSeleccionada = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const api = Number(event.target.value);
		if(api === 0){
			const reportes = await getListaReportes(this.state.perfil.token, "submodulo",this.state.selectsubmodulo);
			this.respuestaApi(reportes,"api");
			this.reportesModificados(reportes);
			this.setState({selectapi:api,txtbuscador:""});
			this.cambiarPagina(1);
		}else{
			const reportes = await getListaReportes(this.state.perfil.token, "api",api);
			this.respuestaApi(reportes,"api");
			this.reportesModificados(reportes);
			this.setState({selectapi:api, txtbuscador:""});
			this.cambiarPagina(1);
		}
	};
	
	//Función obtener resultado de la busqueda y paginarlos.
	filtro = (numeroPagina:number, array:any) => {
		this.setState({
		  paginaActiva: numeroPagina,
		  reportesfiltradospaginados: [],
		  totalPaginasFiltradas:0
		});
		setTimeout(() => {
		  this.setState({
			reportesfiltrados: array,
			paginaActiva: numeroPagina,
			reportesfiltradospaginados: paginarElementos(numeroPagina,this.state.itemsPorPagina, array),
			totalPaginasFiltradas:Math.ceil(this.state.reportesfiltrados.length / this.state.itemsPorPagina)
		  });
		}, 0);
	}

	limpiarBusqueda = () => {
		this.setState({selectproyecto:0,
					   selectmodulo:0,moduloslista:[], 
					   selectsubmodulo:0,submoduloslista:[], 
					   apislista:[],selectapi:0,txtbuscador:""});
		localStorage.removeItem("id_proyecto");
		localStorage.removeItem("id_modulo");
		localStorage.removeItem("id_submodulo");
		localStorage.removeItem("id_api");
		this.componentDidMount();
	}

	itemsPorPagina = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const items = Number(e.target.value);
		this.setState({itemsPorPagina:items});
		this.cambiarPagina(1);
	}

	//Función para abrir un reporte.
	redireccionando = (id_reporte:number, id_proyecto:number,id_rol:number) => {
		localStorage.setItem("id_reporte", id_reporte.toString());
		localStorage.setItem("id_proyecto", id_proyecto.toString());
		localStorage.setItem("id_rol", id_rol.toString());
		this.setState({ redireccionando: true })
	}

	render() {
		return (  		
			<div className="divContenedorPrincipal">
				{/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/ }
				{this.state.redireccionando
					?
						<>{Acciones("detallesreporte")}</>
					: null
				}
				{/*////////// Sección de activación de spinner //////////*/}
				{(this.state.cargando == true) ? (
					<SpinnerModal tipo="full" show={this.state.cargando}/>
				) : null}
				<ToastContainer />
				<MenuNavbar/>
				<main>
					{/*////////// Sección de breadcrumb //////////*/ }
					<Navegacion
						seccion={"reportes"}
						rol={this.state.perfil.role}
					/>
					{/*////////// Sección de buscador de reportes //////////*/}
					<div className="row divContenedorFiltros">
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
							<FormGroup className='formSelectFiltro'>
								<Label className='labelFiltro'>
									Proyecto
								</Label>
								<Input className="selectFiltro" type="select" value={this.state.selectproyecto} onChange={this.proyectoSeleccionado}>
									<option value="0" selected>Todos</option>
									{this.state.proyectoslista.map((Proyecto, key)=>(	
										<option key={key} value= {Proyecto.id_proyecto}>
										{Proyecto.nombre_proyecto}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
							<FormGroup className='formSelectFiltro'>
								<Label className='labelFiltro'>
									Módulo
								</Label>
								<Input className="selectFiltro" type="select" value={this.state.selectmodulo} onChange={this.moduloSeleccionado}>
									<option value="0" selected>Todos</option>
									{this.state.moduloslista.map((Modulo, index) => (	
										<option value={Modulo.id_modulo} key={index}>
										{Modulo.nombre_modulo}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
							<FormGroup className='formSelectFiltro'>
								<Label className='labelFiltro'>
									Submódulo
								</Label>
								<Input className="selectFiltro" type="select" value={this.state.selectsubmodulo} onChange={this.submoduloSeleccionado}>
									<option value="0">Todos</option>
									{this.state.submoduloslista.map((Submodulo, index)=>(
										(Submodulo.submodulo_general == 1) ?
											<option key={index} value={Submodulo.id_submodulo}>
												Sin submódulo
											</option>
										:
											<option key={index} value= {Submodulo.id_submodulo}>
												{Submodulo.nombre_submodulo}
											</option>
										))
									}
								</Input>
							</FormGroup>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
							<FormGroup className='formSelectFiltro'>
								<Label className='labelFiltro'>
									Apis
								</Label>
								<Input className="selectFiltro" type="select" value={this.state.selectapi} onChange={this.apiSeleccionada}>
									<option value="0" selected>Todos</option>
									{this.state.apislista.map((api, index) => (	
										<option value={api.id_api} key={index}>
											{api.nombre_api}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
							<InputGroup>
								<Input 
									type="text" 
									className="inputBuscador"
									placeholder="Filtrar por título o no. reporte"
									onChange={this.buscarReporte} 
									value={this.state.txtbuscador} 
								/>
								<InputGroupText className="logoBuscador">
									<HiMagnifyingGlass />
								</InputGroupText>
							</InputGroup>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-1">
							<Button
								className="botonLimpiarBusqueda"
								color="primary"
								onClick={() => this.limpiarBusqueda()}
								>
								Limpiar
							</Button>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-1">
							<FormGroup className='formSelectFiltro'>
								<Label className='labelFiltro'>
									Mostrar&nbsp;
								</Label>
								<select className="selectItemsPorPagina" value={this.state.itemsPorPagina} onChange={this.itemsPorPagina}>
									<option value={5}>5</option>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={50}>50</option>
									<option value={100}>100</option>
								</select>
							</FormGroup>
						</div>
					</div>
					{/*////////// Sección de catalogo de reportes //////////*/}
					<div className="row">
						<Table className="tablaListaElementos" hover responsive >
							<thead className="titulosTabla">
								<tr>
									{this.state.tabla.encabezados.map((encabezado, index) => (
										<th key={"tabla" + index}
										style={{width: this.state.tabla.dimensiones[index] , minWidth:this.state.tabla.dimensiones[index]}}>
											{encabezado}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{this.state.visualizarreportes === 0 ?
									<tr>
										<td colSpan={6} style={{ textAlign: "center" }}>
											<em style={{fontSize: '14px'}}>No tienes acceso a ningún proyecto</em>
										</td>
									</tr>
								:this.state.visualizarreportes === 1 ?
									<tr>
										<td colSpan={6} style={{ textAlign: "center" }}>
											<em style={{fontSize: '14px'}}>El proyecto no cuenta con reportes</em>
										</td>
									</tr>
								:this.state.visualizarreportes === 2 ?
									<tr>
										<td colSpan={6} style={{ textAlign: "center" }}>
											<em style={{fontSize: '14px'}}>El módulo no cuenta con reportes</em>
										</td>
									</tr>
								:this.state.visualizarreportes === 3 ?
									<tr>
										<td colSpan={6} style={{ textAlign: "center" }}>
											<em style={{fontSize: '14px'}}>El submódulo no cuenta con reportes</em>
										</td>
									</tr>
								:this.state.visualizarreportes === 4 ?
									<tr>
										<td colSpan={6} style={{ textAlign: "center" }}>
											<em style={{fontSize: '14px'}}>La api no cuenta con reportes</em>
										</td>
									</tr>
								:this.state.visualizarreportes === 5 ?
									this.state.txtbuscador.length < 3 ?
										this.state.reportespaginados.map((reporte, index)=>(
											<Reporte
												key={index} 
												reporte={reporte} 
												dimensiones={this.state.tabla.dimensiones} 
												verreporte={() => this.redireccionando(reporte.id_reporte, reporte.id_proyecto, reporte.id_rol)} 
											/>
										))
										:this.state.reportesfiltradospaginados.map((reporte, index)=>(	
											<Reporte 
												key={index}
												reporte={reporte} 
												dimensiones={this.state.tabla.dimensiones} 
												verreporte={() => this.redireccionando(reporte.id_reporte, reporte.id_proyecto, reporte.id_rol)} 
											/>
										))
								:null}
							</tbody>
						</Table>
					</div>
					{this.state.visualizarreportes === 5 &&
						<div className="row mt-3">
							{this.state.txtbuscador.length < 3 ?
								<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaActiva}
								itemsPorPagina={this.state.itemsPorPagina} totalPaginas={this.state.totalPaginas} tamañoPaginacion="sm"/>
							: 
								<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaActiva}
								itemsPorPagina={this.state.itemsPorPagina} totalPaginas={this.state.totalPaginasFiltradas} tamañoPaginacion="sm"/>
							}
						</div>
					}
				</main>
			</div>
		);
	};
}
function Acciones(bandera:string) {
    if (bandera == 'detallesreporte') {
		return <Navigate to="/reporte" />;
    } 
}