import React from "react";
import { FaArrowRight, FaCheckCircle, FaClock, FaExclamationCircle } from "react-icons/fa";
import { Badge, Button, Card, CardBody } from "reactstrap";
import { Tooltip } from 'react-tooltip'
import { api } from "../Interfaces";

interface props{
    clase:string
    api:api
    verapi:(id_api:number) => void
}

const Api: React.FC<props> = ({ clase,api,verapi }) => {
    return (
        <div className={clase}>
            <Card className="cardModSubApi">
                <CardBody className="cardBodyModSubApi">
                    <div className="row">
                        <div className="col-12 col-sm-9 col-md-9 col-lg-8 col-xl-8">
                            <div className="row">
                                <div className="col-12 divCentradoVerticalmente">
                                    <div className="divTituloModSubApi">
                                        {api.nombre_api.length > 19
                                            ? `${api.nombre_api.substring(0, 19)}...`
                                            : api.nombre_api}
                                    </div>
                                    <div>
                                        {api.estado == "Completada" ?
                                            <>
                                                <span className="apisInformacion" style={{ marginLeft: "3px" }}>
                                                    <FaCheckCircle color="green" size={16} className="apisCompletadas" data-tooltip-content='Api completada' />
                                                </span>
                                                <Tooltip anchorSelect=".apisCompletada" className="toolTipDiseño" place="top" />
                                            </>
                                        :api.estado == "En desarrollo" ?
                                            <>
                                                <span className="apisInformacion" style={{ marginLeft: "3px" }}>
                                                    <FaClock color="orange" size={16} className="apisDesarrollo" data-tooltip-content='Api en desarrollo' />
                                                </span>
                                                <Tooltip anchorSelect=".apisDesarrollo" className="toolTipDiseño" place="top" />
                                            </>
                                        :api.estado == "Reportada" ?
                                            <>
                                                <span className="apisInformacion" style={{ marginLeft: "3px" }}>
                                                    <FaExclamationCircle color="red" size={16} className="apisConErrores" data-tooltip-content='Api con errores' />
                                                </span>
                                                <Tooltip anchorSelect=".apisConErrores" className="toolTipDiseño" place="top" />
                                            </>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {api.tipo_solicitud == "GET" ?
                                        <Badge className="badgeGet" pill>
                                            {api.tipo_solicitud}
                                        </Badge>
                                    :api.tipo_solicitud == "POST" ?
                                        <Badge className="badgePost" pill>
                                            {api.tipo_solicitud}
                                        </Badge>
                                    :api.tipo_solicitud == "PUT" ?
                                        <Badge className="badgePut" pill>
                                            {api.tipo_solicitud}
                                        </Badge>
                                    :api.tipo_solicitud == "DELETE" ?
                                        <Badge className="badgeDelete" pill>
                                            {api.tipo_solicitud}
                                        </Badge>
                                    :null}
                                    {api.formato_cuerpo == "Json" ?
                                        <Badge color="success" className="badgeJson" pill>
                                            <span className="spanFormatoCuerpo">
                                                {api.formato_cuerpo}
                                            </span>
                                        </Badge>
                                    :api.formato_cuerpo == "Form-data" ?
                                        <Badge color="success" className="badgeFormDataEncode" pill>
                                            <span className="spanFormatoCuerpo">
                                                {api.formato_cuerpo}
                                            </span>
                                        </Badge>
                                    :api.formato_cuerpo == "Form-encode" ?
                                        <Badge color="success" className="badgeFormDataEncode" pill>
                                            <span className="spanFormatoCuerpo">
                                                {api.formato_cuerpo}
                                            </span>
                                        </Badge>
                                    :api.formato_cuerpo == "Text" ?
                                        <Badge color="success" className="badgeText" pill>
                                            <span className="spanFormatoCuerpo">
                                                {api.formato_cuerpo}
                                            </span>
                                        </Badge>
                                    :api.formato_cuerpo == "Sin formato" ?
                                        <Badge color="success" className="badgeSinFormato" pill>
                                            <span className="spanFormatoCuerpo">
                                                {api.formato_cuerpo}
                                            </span>
                                        </Badge>
                                    :null}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-3 col-md-3 col-lg-4 col-xl-4 divContenedorBotonVer">
                            <Button
                                className="botonVer"
                                color="primary"
                                onClick={() => verapi(api.id_api)}>
                                Ver api&nbsp;<FaArrowRight size={16} />
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
        
    )
}
export default Api