import React, { Component, ReactNode } from "react";
import { FaArrowRight, FaCheckCircle, FaClock, FaExclamationCircle } from "react-icons/fa";
import { Badge, Button, Card, CardBody, Label } from "reactstrap";
import { ChildrenType, Tooltip } from 'react-tooltip'
import { api } from "../Interfaces";

interface props{
    clase:string
    api:api
    verapi:(id_proyecto:number, id_modulo:number,id_submodulo:number,submodulo_general:number,id_api:number, id_rol:number) => void
}

const Api: React.FC<props> = ({ clase,api, verapi }) => {
    return (
        <div className={clase}>
            <Card className="cardModulo">
                <CardBody className="divContenedorModulo">
                    <div className="row">
                        <div className="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                            <div className="row">
                                <div className="col-12 divCentradoVerticalmente">
                                    <div className="divTituloModSubApi">
                                        {api.nombre_api}
                                    </div>
                                    <div>
                                        {api.estado == "Completada" ? 
                                            <>
                                                <span className="apisInformacion" style={{ marginLeft: "3px"}}>
                                                    <FaCheckCircle color="green" size={16} className="apisCompletadas" data-tooltip-content='Api completada' />
                                                </span>
                                                <Tooltip anchorSelect=".apisCompletadas" className="toolTipDiseño" place="top" />
                                            </>
                                        :api.estado == "En desarrollo" ? 
                                            <>
                                                <span className="apisInformacion" style={{ marginLeft: "3px"}}>  
                                                    <FaClock color="orange" size={16} className="apisDesarrollo" data-tooltip-content='Api en desarrollo'/> 
                                                </span>
                                                <Tooltip anchorSelect=".apisDesarrollo" className="toolTipDiseño" place="top" />	
                                            </> 
                                        :api.estado == "Reportada" ? 
                                            <>
                                                <span className="apisInformacion" style={{ marginLeft: "3px"}}>
                                                    <FaExclamationCircle color="red" size={16} className="apisConErrores" data-tooltip-content='Api con errores'/>	
                                                </span>
                                                <Tooltip anchorSelect=".apisConErrores" className="toolTipDiseño" place="top" />	
                                            </> 
                                        :null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col divCentradoVerticalmente">
                                    {api.tipo_solicitud == "GET" ?
                                        <Badge className="badgeGet" pill>
                                            {api.tipo_solicitud}
                                        </Badge>
                                    :api.tipo_solicitud == "POST" ?
                                        <Badge className="badgePost" pill>
                                            {api.tipo_solicitud}
                                        </Badge>
                                    :api.tipo_solicitud == "PUT" ?
                                        <Badge className="badgePut" pill>
                                            {api.tipo_solicitud}
                                        </Badge>
                                    :api.tipo_solicitud == "DELETE" ?
                                        <Badge className="badgeDelete" pill>
                                            {api.tipo_solicitud}
                                        </Badge>
                                    :null}
                                    {api.formato_cuerpo == "Json" ? 
                                        <Badge color="success" className="badgeJson" pill>
                                            <span className="spanFormatoCuerpo">
                                                {api.formato_cuerpo}
                                            </span>
                                        </Badge>
                                    :api.formato_cuerpo == "Form-data" ?
                                        <Badge color="success" className="badgeFormDataEncode" pill>
                                            <span className="spanFormatoCuerpo">
                                                {api.formato_cuerpo}
                                            </span>
                                        </Badge>
                                    :api.formato_cuerpo == "Form-encode" ?
                                        <Badge color="success" className="badgeFormDataEncode" pill>
                                            <span className="spanFormatoCuerpo">
                                                {api.formato_cuerpo}
                                            </span>
                                        </Badge>
                                    :api.formato_cuerpo == "Text" ?
                                        <Badge color="success" className="badgeText" pill>
                                            <span className="spanFormatoCuerpo">
                                                {api.formato_cuerpo}
                                            </span>
                                        </Badge>
                                    :api.formato_cuerpo == "Sin formato" ? 
                                        <Badge color="success" className="badgeSinFormato" pill>
                                            <span className="spanFormatoCuerpo">
                                                {api.formato_cuerpo}
                                            </span>
                                        </Badge>
                                    :null }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 col-md-5 col-lg-6 col-xl-6 divContenedorUbicacion">
                            <div className="divTituloUbicacion">
                                Ubicacion:
                            </div>
                            {(api.submodulo_general == 0) ?
                                (
                                    <div className="divUbicacionApi">
                                        {api.nombre_proyecto} / {api.nombre_modulo} / {api.nombre_submodulo}
                                    </div>
                                )
                                :
                                <div className="divUbicacionApi">
                                    {api.nombre_proyecto} / {api.nombre_modulo}
                                </div>
                            }
                        </div>
                        <div className="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2 divContenedorBotonVer">
                            <Button
                                className="botonVer"
                                color="primary"
                                onClick={() => verapi(api.id_proyecto, api.id_modulo, api.id_submodulo, api.submodulo_general, api.id_api, api.id_rol)}>
                                Ver api&nbsp;<FaArrowRight size={16} />
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}
export default Api