import React from "react";
import { Input, Button, Table, FormGroup, Label, InputGroup, InputGroupText } from 'reactstrap';
import {getListaProyectos, getListaSolicitudes, getListaSubmodulosDelModuloSinFiltro, getModulosDelProyecto } from "../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CatalogoSolicitudes.css';
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { Navigate } from "react-router-dom";
import ModalFormularioSolicitud from "../../Complements/Modales/ModalFormularioSolicitud/ModalFormularioSolicitud";
import MenuNavbar from "../../Complements/Navbar/Navbar";
import Paginacion, { paginarElementos } from '../../Complements/Paginacion/Paginacion';
import { format, formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import { IoMdAdd } from "react-icons/io";
import Solicitud from "./Componentes/Solicitud";
import { solicitud } from "./Interfaces";
import Navegacion from "../../Complements/Navegacion/Navegacion";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { proyecto } from "../CatalogoProyectos/Interfaces";
import { modulo, submodulo } from "../../Complements/Modales/ModalFormularioSolicitud/Interfaces";

//Interfaz general del componente.
interface states{
	isExpanded: boolean,
	salir: boolean,
	cargando:boolean,
	perfil:{
		token:number,
		name:string,
		email:string,
		role:number
	},
	
	tabla: {
		encabezados: string[],
		dimensiones: string[],
		solicitudes:solicitud[],
	},

	proyectoslista:proyecto[],
	selectproyecto:number,

	moduloslista:modulo[],
	selectmodulo:number,

	submoduloslista:submodulo[],
 	selectsubmodulo:number,

	visualizarsolicitudes:number,

	solicitudesfiltradas:solicitud[],
	solicitudespaginadas:solicitud[],
	solicitudesfiltradaspaginadas:solicitud[],
	
	txtbuscador:string,
	txtbuscadorubi:string,
	redireccionando:boolean,
	
	mostrarboton:boolean,
	showmodalformsolicitud: boolean,
	titulomodal: string,
	accionmodalsolicitud: number,
	solicitudseleccionada: solicitud[],

	idsolicitud:number,
	showmodaleliminarsolicitud:boolean,

	paginaActiva: number,
	itemsPorPagina: number,
	totalPaginas:number,
	totalPaginasFiltradas:number
}

export default class CatalogoSolicitudes extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
			isExpanded: false,
			salir:false,
			cargando:true,
			perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": ""}'),
			tabla: {
				encabezados: [
				  "No. Solicitud",
				  "Título",
				  "Prioridad",
				  "Estado",
				  "Fecha de entrega",
				  "Ubicación",
				  "" 
				],
				dimensiones: [
					"120px",//No. Solicitud
					"260px",//Título
					"100px",//Prioridad
					"100px",//Estado
					"225px",//Fecha de entrega
					"310px",//Ubicación
					"150px"//Mas información
				],
				solicitudes:[]
			},
			proyectoslista:[],
			selectproyecto: 0,
  
			moduloslista:[],
			selectmodulo: 0,
  
			submoduloslista:[],
			selectsubmodulo:0,

			visualizarsolicitudes:0,
			solicitudesfiltradas:[],
			solicitudespaginadas:[],
			solicitudesfiltradaspaginadas:[],
		
			txtbuscador:"",
			txtbuscadorubi:"",
			redireccionando:false,

			mostrarboton:false,
			showmodalformsolicitud: false,
			titulomodal: "",
			accionmodalsolicitud: 0,
			solicitudseleccionada:[],
			
			idsolicitud:0,
			showmodaleliminarsolicitud:false,

			paginaActiva: 1,
			itemsPorPagina: 5,
			totalPaginas:0,
			totalPaginasFiltradas:0
		
			};
	}
	async componentDidMount() {
		if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
			const datos = localStorage.getItem('acceso');
			//this.setState({ redireccionando: true });
		} 
		
		const accion = localStorage.getItem("accion");
		if(accion !== null){
			this.mensajesToast(accion);
			localStorage.removeItem("accion");
		}
		
		const apiproyectos = await getListaProyectos(this.state.perfil.token);
		if(this.respuestaApi(apiproyectos,"No")){
			return;
		}
		this.setState({proyectoslista:apiproyectos.data})
		this.mostrarBotonCrearSolicitud(apiproyectos);

		const apisolicitudes = await getListaSolicitudes(this.state.perfil.token,"todas",0);
		if(this.respuestaApi (apisolicitudes,"todas")){
			return;
		}

		this.solicitudesModificados(apisolicitudes);
		this.cambiarPagina(1);
		
		setTimeout(() => {
			this.setState({cargando: false});
		}, 1000);
	
	}

	//Función para mostrar mensajes toast al iniciar el componente.
	mensajesToast =  (accion:string) => {
		//300 = Acceso denegado
		if (accion === "300") { 
			setTimeout(() => {
				toast.warning("No tienes acceso a la solicitud.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
		if (accion === "Solicitud eliminada") { 
			setTimeout(() => {
				toast.success("Solicitud eliminada!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
	}

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any, tipo_busqueda:string) => {
		if(resp.code == 0){
			this.setState({visualizarsolicitudes:4})
			return
		}
		else if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 100) {
			if(tipo_busqueda === "todas"){
				this.setState({visualizarsolicitudes:0})
			}
			else if(tipo_busqueda === "proyecto"){
				this.setState({visualizarsolicitudes:1})
			}
			else if(tipo_busqueda === "modulo"){
				this.setState({visualizarsolicitudes:2})
			}
			else if(tipo_busqueda === "submodulo"){
				this.setState({visualizarsolicitudes:3})
			}
			this.setState({ cargando: false })
		}
		return false
	}

	//Función para mostrar botón de crear solicitud
	mostrarBotonCrearSolicitud = (proyectos:any) => {
		const habilitarBoton = proyectos.data.some((item: { id_rol: number; }) => [1, 3, 4].includes(item.id_rol));
		
		if (habilitarBoton) {
			this.setState({mostrarboton:true})
		} else {
			this.setState({mostrarboton:false})
		}
	}
	/////////////////////////////// Sección de solicitudes
	//Función para modificar la fecha, trauducirla, aisgnarla y crear la ubicación.
	solicitudesModificados = (solicitudes: any) =>{
		const solicitudesmodificadas = solicitudes.data.map((solicitud:any, index:number) => {
			const idsolicitudclass = `solicitud${index}`
			const idsolicitudtooltip = `.solicitud${index}`

			//Se agrega un nuevo formato a la fecha de generación del reporte.
			const fecha = new Date(solicitud.fecha_entrega);
			const fecha_entrega = format(fecha, "d 'de' MMM 'del' yyyy 'a las' hh:mm a", {locale: es});

			const tiempo_faltante = formatDistanceToNow(fecha, { locale: es })

			let ubicacion = ""; 
			if(solicitud.id_submodulo != 190){
				if(solicitud.submodulo_general == 1){
					ubicacion = solicitud.nombre_proyecto + "/" + solicitud.nombre_modulo;
				}else{
					ubicacion = solicitud.nombre_proyecto + "/" + solicitud.nombre_modulo + "/" + solicitud.nombre_submodulo;
				}
			}else{
				ubicacion = solicitud.nombre_proyecto;
			}
			return { ...solicitud, idsolicitudclass, idsolicitudtooltip, fecha_entrega, tiempo_faltante, ubicacion };
		});

		var tabla = this.state.tabla;
		tabla.solicitudes = JSON.parse(JSON.stringify(solicitudesmodificadas));
	}

	//Función para mostrar formulario para crear solicitud.
	verModalFormularioSolicitud = (accion: number, solicitud: any) => {
		if (accion == 1) {
			this.setState({ titulomodal: "Crear solicitud", accionmodalsolicitud: accion })
		}
		this.setState({ showmodalformsolicitud: true })
	}

	//Función para cerrar formulario para crear solicitud.
	cerrarModalFormularioSolicitud = (accion: number) => {
		this.setState({ showmodalformsolicitud: !this.state.showmodalformsolicitud });
		if (accion == 1) {
			this.setState({ redireccionando: true })
		}
	}

	proyectoSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const proyecto = Number(event.target.value);
		if(proyecto === 0){
			const solicitudes = await getListaSolicitudes(this.state.perfil.token, "todas",0);
			this.solicitudesModificados(solicitudes);
			this.cambiarPagina(1);
			this.respuestaApi(solicitudes,"todas");
			this.setState({selectproyecto:proyecto,selectmodulo:0, moduloslista:[],selectsubmodulo:0,submoduloslista:[],txtbuscador:""});
		}else{
			this.setState({ selectproyecto:proyecto,selectmodulo:0, selectsubmodulo:0 }, async () => {
				const modulos = await getModulosDelProyecto(this.state.perfil.token, Number(this.state.selectproyecto));
				const solicitudes = await getListaSolicitudes(this.state.perfil.token, "proyecto",proyecto);
				this.solicitudesModificados(solicitudes);
				this.cambiarPagina(1);
				this.respuestaApi(solicitudes,"proyecto");
				this.setState({moduloslista: modulos.data,submoduloslista:[],selectsubmodulo:0,txtbuscador:""});
			});
		}
	};

	moduloSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const modulo = Number(event.target.value);
		if(modulo === 0){
			const solicitudes = await getListaSolicitudes(this.state.perfil.token, "proyecto",this.state.selectproyecto);
			this.solicitudesModificados(solicitudes);
			this.cambiarPagina(1);
			this.respuestaApi(solicitudes,"proyecto");
			this.setState({selectmodulo:0, selectsubmodulo:0,submoduloslista:[],txtbuscador:""});
		}else{
			this.setState({ selectmodulo:modulo,selectsubmodulo:0 }, async () => {
				const submodulos = await getListaSubmodulosDelModuloSinFiltro(this.state.perfil.token, Number(this.state.selectproyecto), Number(modulo));
				const solicitudes = await getListaSolicitudes(this.state.perfil.token, "modulo",modulo);
				this.solicitudesModificados(solicitudes);
				this.cambiarPagina(1);
				this.respuestaApi(solicitudes,"modulo");
				this.setState({ submoduloslista: submodulos.data,
								selectsubmodulo:submodulos.data[0].id_submodulo,
								txtbuscador:""
				});
			});
		}
	};

	submoduloSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const submodulo = Number(event.target.value);
		if(submodulo === 0){
			const solicitudes = await getListaSolicitudes(this.state.perfil.token, "modulo",this.state.selectmodulo);
			this.respuestaApi(solicitudes,"modulo");
			this.solicitudesModificados(solicitudes);
			this.setState({selectsubmodulo:submodulo,txtbuscador:""});
			this.cambiarPagina(1);
		}else{
			const solicitudes = await getListaSolicitudes(this.state.perfil.token, "submodulo",submodulo);
			this.respuestaApi(solicitudes,"submodulo");
			this.solicitudesModificados(solicitudes);
			this.setState({selectsubmodulo:submodulo, txtbuscador:""});
			this.cambiarPagina(1);
		}
		
	};

	//Función para buscar una solicitud por su título.
	buscarSolicitud = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ txtbuscador: e.target.value });
		
		if (e.target.value.trim().length > 2) {
			var data1 = JSON.parse(JSON.stringify(this.state.tabla.solicitudes))
			
			const filtros = data1.filter((data1:any) => (data1.id_solicitud + " " + data1.titulo_solicitud + " " + data1.ubicacion).toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
			
			if (filtros.length < 1) {
				toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			this.filtro(1, filtros)
		}
		else{
			this.setState({solicitudesfiltradaspaginadas:[]})
		}

	}

	//Función para cambiar de página
	cambiarPagina = (numeroPagina:number) =>{
		this.setState({
			paginaActiva: numeroPagina,
			solicitudespaginadas: [],
			solicitudesfiltradaspaginadas: []
		  });
	  
		setTimeout(() => {
			this.setState({
				paginaActiva: numeroPagina,
				solicitudespaginadas: paginarElementos(numeroPagina,this.state.itemsPorPagina,  this.state.tabla.solicitudes),
				totalPaginas:Math.ceil(this.state.tabla.solicitudes.length / this.state.itemsPorPagina),
				solicitudesfiltradaspaginadas: paginarElementos(numeroPagina,this.state.itemsPorPagina, this.state.solicitudesfiltradas)
			});
		}, 0);
		
	}

	//Función obtener resultado de la busqueda y paginarlos.
	filtro = (numeroPagina:number, array:any) => {
		this.setState({
		  paginaActiva: numeroPagina,
		  solicitudesfiltradaspaginadas: [],
		  totalPaginasFiltradas:0
		});
		setTimeout(() => {
		  this.setState({
			solicitudesfiltradas: array,
			paginaActiva: numeroPagina,
			solicitudesfiltradaspaginadas: paginarElementos(numeroPagina,this.state.itemsPorPagina, array),
			totalPaginasFiltradas:Math.ceil(this.state.solicitudesfiltradas.length / this.state.itemsPorPagina)
		  });
		}, 0);
	}

	limpiarBusqueda = () => {
		this.setState({selectproyecto:0, selectmodulo:0,moduloslista:[], selectsubmodulo:0,submoduloslista:[],txtbuscador:""})
		this.componentDidMount();
	}

	itemsPorPagina = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const items = Number(e.target.value);
		this.setState({itemsPorPagina:items});
		this.cambiarPagina(1);
	}

	//Función para redireccionar a la solicitud seleccionada.
	redireccionando = (id_solicitud:number,id_rol:number, id_proyecto:number) => {
		localStorage.setItem("id_solicitud", id_solicitud.toString());
		localStorage.setItem("id_rol", id_rol.toString());
		localStorage.setItem("id_proyecto", id_proyecto.toString());
		this.setState({ redireccionando: true })
	}

	render() {
		return (
			<div className="divContenedorPrincipal">
				{/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/ }
				{this.state.redireccionando == true ? 
					<>{Acciones("detallessolicitud")}</>
				: null}

				{/*////////// Sección de activación de modales y el spinner //////////*/}
				{(this.state.cargando == true) ? (
					<SpinnerModal tipo="full" show={this.state.cargando}/>
				) : null}
				
				{(this.state.showmodalformsolicitud == true) ? (
					<ModalFormularioSolicitud isOpen={true} titulomodal={this.state.titulomodal} solicitudseleccionada={this.state.solicitudseleccionada} accion={this.state.accionmodalsolicitud}
					closeModal={this.cerrarModalFormularioSolicitud}
					token={this.state.perfil.token}/>
				) : null}

				<ToastContainer />
				<MenuNavbar/>
				<main>
					{/*////////// Sección de breadcrumb //////////*/ }
					<Navegacion
						seccion={"solicitudes"}
						rol={this.state.perfil.role}
					>
						{this.state.mostrarboton === true && 
							<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 divContenedorBotones">
								<div className="divBotonCrear">
									<Button
										className="botonIconoCrear"
										color="success"
										onClick={() => this.verModalFormularioSolicitud(1,null)}>
										<IoMdAdd size={20} color="white"/>&nbsp;Crear solicitud
									</Button>
								</div>
							</div>
						}
					</Navegacion>
					{/*////////// Sección de buscador de solicitudes //////////*/}
					<div className="row divContenedorFiltros">
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
							<FormGroup className='formSelectFiltro'>
								<Label className='labelFiltro'>
									Proyecto
								</Label>
								<Input className="selectFiltro" type="select" value={this.state.selectproyecto} onChange={this.proyectoSeleccionado}>
									<option value="0" selected>Todos</option>
									{this.state.proyectoslista.map((Proyecto, key)=>(	
										<option key={key} value= {Proyecto.id_proyecto}>
										{Proyecto.nombre_proyecto}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
							<FormGroup className='formSelectFiltro'>
								<Label className='labelFiltro'>
									Módulo
								</Label>
								<Input className="selectFiltro" type="select" value={this.state.selectmodulo} onChange={this.moduloSeleccionado}>
									<option value="0" selected>Todos</option>
									{this.state.moduloslista.map((Modulo, index) => (	
										<option value={Modulo.id_modulo} key={index}>
										{Modulo.nombre_modulo}
										</option>
									))}
								</Input>
							</FormGroup>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
							<FormGroup className='formSelectFiltro'>
								<Label className='labelFiltro'>
									Submódulo
								</Label>
								<Input className="selectFiltro" type="select" value={this.state.selectsubmodulo} onChange={this.submoduloSeleccionado}>
									{this.state.submoduloslista.length !== 0 ?
										this.state.submoduloslista.map((Submodulo, index)=>(
											(Submodulo.submodulo_general == 1) ?
												<option key={index} value="0">
													Todos
												</option>
											:
												<option key={index} value= {Submodulo.id_submodulo}>
													{Submodulo.nombre_submodulo}
												</option>
										))
									:
										<option disabled value="0">Todos</option>
									}
								</Input>
							</FormGroup>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 ">
							<InputGroup>
								<Input 
									type="text" 
									className="inputBuscador"
									placeholder="Filtrar por título"
									onChange={this.buscarSolicitud} 
									value={this.state.txtbuscador} 
								/>
								<InputGroupText className="logoBuscador">
									<HiMagnifyingGlass />
								</InputGroupText>
							</InputGroup>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-1">
							<Button
								className="botonLimpiarBusqueda"
								color="primary"
								onClick={() => this.limpiarBusqueda()}
								>
								Limpiar
							</Button>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-1">
							<FormGroup className='formSelectFiltro'>
								<Label className='labelFiltro'>
									Mostrar&nbsp;
								</Label>
								<select className="selectItemsPorPagina" value={this.state.itemsPorPagina} onChange={this.itemsPorPagina}>
									<option value={5}>5</option>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={50}>50</option>
									<option value={100}>100</option>
								</select>
							</FormGroup>
						</div>
					</div>
					{/*////////// Sección de catalogo de solicitudes //////////*/}
					<div className="row">
						<Table className="tablaListaElementos" hover responsive >
							<thead className="titulosTabla">
								<tr>
									{this.state.tabla.encabezados.map((encabezado, index) => (
										<th key={"tabla" + index}
										style={{width: this.state.tabla.dimensiones[index] , minWidth:this.state.tabla.dimensiones[index]}}>
											{encabezado}
										</th>
									))}
								</tr>
							</thead>
							<tbody >
								{this.state.visualizarsolicitudes === 0 ?
									<tr>
										<td colSpan={6} style={{ textAlign: "center" }}>
											<em style={{fontSize: '14px'}}>No tienes acceso a ningún proyecto</em>
										</td>
									</tr>
								:this.state.visualizarsolicitudes === 1 ?
									<tr>
										<td colSpan={6} style={{ textAlign: "center" }}>
											<em style={{fontSize: '14px'}}>El proyecto no cuenta con solicitudes</em>
										</td>
									</tr>
								:this.state.visualizarsolicitudes === 2 ?
									<tr>
										<td colSpan={6} style={{ textAlign: "center" }}>
											<em style={{fontSize: '14px'}}>El módulo no cuenta con solicitudes</em>
										</td>
									</tr>
								:this.state.visualizarsolicitudes === 3 ?
									<tr>
										<td colSpan={6} style={{ textAlign: "center" }}>
											<em style={{fontSize: '14px'}}>El submódulo no cuenta con solicitudes</em>
										</td>
									</tr>
								:this.state.visualizarsolicitudes === 4 ?
									this.state.txtbuscador.length < 3 ?
										this.state.solicitudespaginadas.map((solicitud, index)=>(	
											<Solicitud
												key={index}
												solicitud={solicitud}
												dimensiones={this.state.tabla.dimensiones}
												versolicitud={() => this.redireccionando(solicitud.id_solicitud, solicitud.id_rol, solicitud.id_proyecto)} 
											/>
										))
										:this.state.solicitudesfiltradaspaginadas.map((solicitud, index)=>(	
											<Solicitud
												key={index}
												solicitud={solicitud}
												dimensiones={this.state.tabla.dimensiones}
												versolicitud={() => this.redireccionando(solicitud.id_solicitud, solicitud.id_rol, solicitud.id_proyecto)} 
											/>
										))

								:null}
							</tbody>
						</Table>
					</div>
					{this.state.visualizarsolicitudes === 4 &&
						<div className="row mt-3">
							{this.state.txtbuscador.length < 3 ?
								<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaActiva}
								itemsPorPagina={this.state.itemsPorPagina} totalPaginas={this.state.totalPaginas} tamañoPaginacion="sm"/>
							: 
								<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaActiva}
								itemsPorPagina={this.state.itemsPorPagina} totalPaginas={this.state.totalPaginasFiltradas} tamañoPaginacion="sm"/>
							}
						</div>
					}
				</main>
			</div>
		);
	};
}
function Acciones(bandera:string) {
    if (bandera == 'detallessolicitud') {
		return <Navigate to="/solicitud" />;
    } 
}