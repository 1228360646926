import React, { ChangeEvent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { FaQuestion } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';

interface props {
    accion:number
    logourl:string,
    vermsgerror:boolean,
    msgerror:string,
    logo: (prop: File) => void;
}

interface states {
    logo:File | null,
    logourl:string
    url:string,
}

export default class InputLogo extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            logo:null,
            logourl:this.props.logourl,
            //url:'http://localhost/apibox-api/',
            url:'https://www.apibox.izttra.com/apibox-api/'
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.logourl !== this.props.logourl) {
          this.setState({ logourl: this.props.logourl });
        }
    }

    //Función para guardar el archivo cargado en su estado correspondiente.
	seleccionarArchivo(event: React.ChangeEvent<HTMLInputElement>) {
		try {
			const archivo = event.target.files;
			if (archivo && archivo.length > 0) {
                const logo = archivo[0];
                this.setState({ logo: logo });
                this.props.logo(logo)
			}
		} 
		catch (error) {
			toast.error("Error de al subir el archivo.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
	}

    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Logo del proyecto <span className="spanOpcional">(opcional)</span> 
                </Label>
                {this.props.accion === 2 ?
                    <>
                        <a className="logoProyectoImg">
                            <FaQuestion color="white" size={12} />
                        </a>
                        <Tooltip anchorSelect=".logoProyectoImg" className="toolTipDiseño" place="top">
                            <img src={this.state.url + this.state.logourl} className="logoProyecto" />
                        </Tooltip>
                    </> 
                :null}
                <Input 
                    type="file" 
                    accept=".jpg, .jpeg, .png"  
                    onChange={(event) => this.seleccionarArchivo(event)} 
                />	
                <div className="row">
                    {this.props.vermsgerror ? 
                        <div className="col-12 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    :null}
                </div>
            </FormGroup>
        );
    }
}