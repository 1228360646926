import React from "react";
import { Button } from 'reactstrap';
import { postDetallesApi, postEditarEntradas, postEjemplosEntradas } from "../../Services/Api";
import { validarLongitud } from "../../Services/Validaciones";
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from "react-router-dom";
import './DocumentarApi.css';
import MenuNavbar from "../../Complements/Navbar/Navbar";
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { FormularioEjemplosEntradas } from "./Componentes.tsx/FormularioEjemplosEntradas";
import { ejemploentrada, ejemploentradaestado } from "./Interfaces";
import { InputModelo } from "./Componentes.tsx/InputModelo";
import SelectFormatoCuerpo from "./Componentes.tsx/SelectFormatoCuerpo";
import { api, apiestado } from "../DocumentacionApi/Interfaces";
import Navegacion from "../../Complements/Navegacion/Navegacion";

//Interfaz general del componente.
interface states{
    api:api,
    submodulo_general:number,
	idproyecto:number,
    idapi:number,

    formatocuerpo:number,
    vermsgerrorformatocuerpo:boolean,
    msgerrorformatocuerpo:string,

	txtmodeloentradas:string,
	txtmodeloentradaslength:number,
	vermsgerrormodeloentradas:boolean,
    msgerrormodeloentradas:string,

	ejemploEntradas: ejemploentrada[];
	
	perfil:{
		token:number,
        name:string,
		email:string
  	},
	redireccionando:number,
	cargando:boolean

}

export default class EditarEntradas extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
            api:apiestado,
            submodulo_general:0,
			idproyecto:0,
			idapi: 0,

    		formatocuerpo:0,
			vermsgerrorformatocuerpo:false,
    		msgerrorformatocuerpo:'',

			txtmodeloentradas:'',
			txtmodeloentradaslength:0,
			vermsgerrormodeloentradas:false,
			msgerrormodeloentradas:'',

			ejemploEntradas: [ejemploentradaestado],

            perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": ""}'),
			redireccionando:0,
			cargando:true
	  	}
    }

    async componentDidMount() {
        if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
            //this.setState({ redireccionando: true });
        }

        const id_rol = Number(localStorage.getItem("id_rol"))
		if(id_rol === 3){
            localStorage.setItem("accion","6");
			this.setState({redireccionando:2})
		}
        
		const id_modulo = await localStorage.getItem('id_modulo');
        const id_proyecto = await localStorage.getItem('id_proyecto');
        const id_api = await localStorage.getItem('id_api');

        this.setState({idproyecto:Number(id_proyecto),idapi:Number(id_api)});

		const detallesapi = await postDetallesApi(this.state.perfil.token,Number(id_proyecto),Number(id_api));
        if(this.respuestaApi (detallesapi)){
			return;
		}
        this.informacionGeneral(detallesapi);
            
        const ejemplosentradas = await postEjemplosEntradas(this.state.perfil.token,Number(id_proyecto),Number(id_api));
        if(this.respuestaApi (ejemplosentradas)){
			return;
		}
        this.ejemplosEntradasFaltantes(ejemplosentradas);

        setTimeout(() => {
           this.setState({cargando:false})
        }, 1000);
    }

    //Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}
		return false
	}

    //Función de regresar
	regresar = () => {
		window.history.back();
	};

    //Función para capturar la información de formato de cuerpo y el modelo de la api.
    informacionGeneral = (detallesapi:any) =>{
        this.setState({formatocuerpo:detallesapi.data.id_formatos_cuerpo,
            txtmodeloentradas:detallesapi.data.modelo_entradas !== null ? detallesapi.data.modelo_entradas:'', 
            txtmodeloentradaslength:detallesapi.data.modelo_entradas !== null ? detallesapi.data.modelo_entradas.length :0,
            submodulo_general:detallesapi.data.submodulo_general,
            api:detallesapi.data
        });
    }

    //Función para agregar los datos faltantes a los ejemplos de entradas.
    ejemplosEntradasFaltantes = (ejemplosentradas:any) => {
        const ejemplosEntradasFaltantes = ejemplosentradas.data.map((ejemplo:any) => ({
            ...ejemplo,
            nombre_ejemplo_entradalength: ejemplo.nombre_ejemplo_entrada.length,
            txtnombreejemploentrada: '',
            vermsgerrornombreejemploentrada: false,
            msgerrornombreejemploentrada: '',
            ejemplo_entradalength: ejemplo.ejemplo_entrada.length,
            txtejemploentrada: '',
            vermsgerrorejemploentrada: false,
            msgerrorejemploentrada: '',
        }));
        this.setState({ ejemploEntradas: ejemplosEntradasFaltantes });
    }

    formatoCuerpoSeleccionado = (prop: number) => {
		this.setState({ formatocuerpo: prop});
	};

    modeloentradas = (prop:string) => {
		this.setState({ txtmodeloentradas: prop });
	}

	ejemplosEntradas = (prop:ejemploentrada[]) => {
		this.setState({ ejemploEntradas: prop });
	}
    
    /////////////////////////////// Sección de función para guardar los cambios realizados.
    guardarCambios = async () => {
        //Si formato cuerpo es diferente a 5(Sin formato) se toman en cuenta los ejemplos entrada dentro de la validación de campos.
        if(this.state.formatocuerpo !== 5){
            this.setState({ vermsgerrormodeloentradas: false });
            //Actualizar estado de la visualización de los mensajes de error de cada ejemplo de entrada
            const vermsgerrornombreejemploentrada = this.state.ejemploEntradas.map((ejemplo) => {
                return {
                    ...ejemplo,
                    vermsgerrornombreejemploentrada:false
                    };
                });
            await this.setState({ ejemploEntradas: vermsgerrornombreejemploentrada });

            //Actualiza el estado de la visualización de los mensajes de error de cada ejemplo de entrada.
            const vermsgerrorejemploentrada = this.state.ejemploEntradas.map((ejemplo) => {
                return {
                    ...ejemplo,
                    vermsgerrorejemploentrada:false
                    };
                });
            await this.setState({ ejemploEntradas: vermsgerrorejemploentrada });
        
        }
       
        let longitudvalidamodelojson = { longitudminima: 30, longitudmaxima: 3000 };
        let longitudvalidanombreejemplo = {longitudminima: 3, longitudmaxima: 30};
        let longitudvalidaejemplojson = { longitudminima: 30, longitudmaxima: 3000 };

        let txtmodeloentradasjson = validarLongitud(this.state.txtmodeloentradas, longitudvalidamodelojson);

        //Si formato cuerpo es diferente a 5(Sin formato) se toman en cuenta los ejemplos entrada dentro de la validación de campos.
        if(this.state.formatocuerpo !== 5){
            //Nombre de ejemplos de entrada.
            const validarNombreEjemploEntradas = this.state.ejemploEntradas.map((ejemplo) => {
                const resultadoValidacionNombre = validarLongitud(ejemplo.nombre_ejemplo_entrada, longitudvalidanombreejemplo);
                return {
                    ...ejemplo,
                    txtnombreejemploentrada: resultadoValidacionNombre.codigo,
                    msgerrornombreejemploentrada: resultadoValidacionNombre.mensaje
                };
            });
            await this.setState({ ejemploEntradas: validarNombreEjemploEntradas });

            //Ejemplos de entrada.
            const validarEjemploEntradas = this.state.ejemploEntradas.map((ejemplo) => {
                const resultadoValidacion = validarLongitud(ejemplo.ejemplo_entrada, longitudvalidaejemplojson);
                return {
                ...ejemplo,
                txtejemploentrada: resultadoValidacion.codigo,
                msgerrorejemploentrada: resultadoValidacion.mensaje
                };
            });
            await this.setState({ ejemploEntradas: validarEjemploEntradas });
        }
        
        let validacionescorrectas = true;
        let entradasCorrectas = true;

        //Si formato cuerpo es diferente a 5(Sin formato) se toman en cuenta los ejemplos entrada dentro de la validación de campos.
        if(this.state.formatocuerpo !== 5){
            if (txtmodeloentradasjson.codigo == "invalid") {
                this.setState({ msgerrormodeloentradas: txtmodeloentradasjson.mensaje });
                this.setState({ vermsgerrormodeloentradas: true });
                validacionescorrectas = false;
            }

            //Nombre de ejemplos de entrada.
            const validarNombreEjemploEntradasRespuesta = this.state.ejemploEntradas.map((ejemplo) => {
                if (ejemplo.txtnombreejemploentrada === "invalid") {
                    return {
                        ...ejemplo,
                        vermsgerrornombreejemploentrada: true
                    };
                }
                return ejemplo;
            });
            await this.setState({ ejemploEntradas: validarNombreEjemploEntradasRespuesta });
            //Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemploNombresEntradasCorrectas su valor booleano inverso.
			//En pocas la palabras se si se cumple la condición se guardará como falso.
            const ejemploNombresEntradasCorrectas =  !validarNombreEjemploEntradasRespuesta.some((ejemplo) => ejemplo.txtnombreejemploentrada === "invalid");  
        
            //Ejemplos de entrada.
            const validarEjemploEntradasRespuesta = this.state.ejemploEntradas.map((ejemplo) => {
                if (ejemplo.txtejemploentrada === "invalid") {
                    return {
                        ...ejemplo,
                        vermsgerrorejemploentrada: true
                    };
                }
                return ejemplo;
            });
            await this.setState({ ejemploEntradas: validarEjemploEntradasRespuesta });
            //Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemploEntradasCorrectas su valor booleano inverso.
			//En pocas la palabras se si se cumple la condición se guardará como falso.
            const ejemploEntradasCorrectas = !validarEjemploEntradasRespuesta.some((ejemplo) => ejemplo.txtejemploentrada === "invalid");
            
            //Se suman ambos valores. Si es true y false. Se guarda como false. Si ambos son true, se guarda como true y se actualiza el valor en entradasCorrectas.
			//Si el valor de entradasCorrectas es true significa que todos los nombres y sus correspondientes ejemplos son aptos para guardar.
            entradasCorrectas= ejemploNombresEntradasCorrectas && ejemploEntradasCorrectas;
        }
        
        if(validacionescorrectas){
            validacionescorrectas = entradasCorrectas;
        }
        
        if (validacionescorrectas) {
            let json = {
                "token": this.state.perfil.token,
                "id_proyecto": this.state.idproyecto,
                "id_api": this.state.idapi,
                "id_formatos_cuerpo": this.state.formatocuerpo,
                "modelo_entradas":this.state.txtmodeloentradas,
                "ejemplos_entradas":this.state.ejemploEntradas,
                
            } 
            const resp = await postEditarEntradas(json);
        
            if (resp.code == 0) {
                localStorage.setItem("accion", "2");
                this.setState({ redireccionando: 1 })
            }
            else if (resp.code == 100) {
                this.setState({ redireccionando: 1 })
            }
            else if (resp.code == 200) {
                toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (resp.code == 300) {
                toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (resp.code == 400) {
                localStorage.setItem("accion", '7');
                this.setState({ redireccionando: 1 })
            }
            else if (resp.code == 1000 || resp == -1) {
				toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
        }
    }

    //Función para cancelar y redirigir a la documentación de la api.
    cancelar = () => {
        this.setState({ redireccionando: 2})
    }

    render() {
        return ( 
            <div className="divContenedorPrincipal" >
                {/*////////// Sección de redirigir a paginas mediante condicionales //////////*/}
                {this.state.redireccionando == 1 ?
                    <>
                        {Acciones("documentacionapi")}
                    </>
                : this.state.redireccionando == 2 ?
                    <>
                        {Acciones("cancelar")}
                    </>
                :null}

                {/*////////// Sección de activación de spinner //////////*/}
                {(this.state.cargando == true) ? (
                        <SpinnerModal tipo="full" show={this.state.cargando}/>
                ) : null}

                <ToastContainer />
                <MenuNavbar/>
                <main>
                    {/*////////// Sección de breadscrum //////////*/}
					<Navegacion
						seccion={"edit-entradas"}
						proyecto={this.state.api.nombre_proyecto}
						modulo={this.state.api.nombre_modulo}
						submodulo={this.state.api.nombre_submodulo}
						accion={this.state.api.submodulo_general}
						api={this.state.api.nombre_api}
					/>
                    {/*////////// Sección de formato de cuerpo //////////*/}
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <SelectFormatoCuerpo 
								token={this.state.perfil.token} 
								formatocuerpo={this.state.formatocuerpo} 
								vermsgerror={this.state.vermsgerrorformatocuerpo} 
								msgerror={this.state.msgerrorformatocuerpo} 
								formatoCuerpoSeleccionado={this.formatoCuerpoSeleccionado}
							/>
                        </div>
                    </div> 
                    {/*////////// Sección de entradas //////////*/}
                    {this.state.formatocuerpo != 5 && (
                        <div className="row">
                            <InputModelo 
                                titulo={"Modelo de entradas"} 
                                txtmodelo={this.state.txtmodeloentradas} 
                                vermsgerrormodelo={this.state.vermsgerrormodeloentradas} 
                                msgerrormodelo={this.state.msgerrormodeloentradas} 
                                modelo={this.modeloentradas}
                            />
                            <FormularioEjemplosEntradas
                                ejemplosentradas={this.state.ejemploEntradas}
                                ejemplos={this.ejemplosEntradas} 
                            /> 
                        </div>
                    )}
                    <div className="row mt-4">
                        <div className="col divContenedorBotonesGuardar">
                            <Button className="botonCancelarDocApi" color="danger" onClick={this.cancelar} >Cancelar</Button>
                            <Button color="success" onClick={this.guardarCambios} >Guardar cambios</Button>
                        </div>
                    </div>
                </main>
            </div>
        );
    };
}
function Acciones(bandera:string) {
    if (bandera=='documentacionapi'){
        return <Navigate to="/documentacionapi" />;
    }else if(bandera=='cancelar'){
        return  <Navigate to="/documentacionapi" />;
    }
}
    
    
