import React from "react";
import { FaArrowLeft, FaArrowRight, FaCloud, FaFileAlt, FaFolderOpen, FaHistory, FaPencilAlt, FaSave } from "react-icons/fa";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { TbArrowBackUp } from "react-icons/tb";
import { HiPuzzle } from "react-icons/hi";
import { RiErrorWarningFill } from "react-icons/ri";
import { FiClock } from "react-icons/fi";

interface props{
    seccion:string
    proyecto?:string
    modulo?:string
    submodulo?:string
    api?:string
    reporte?:number
    solicitud?:number
    accion?:number
    rol?:number,
    children?:React.ReactNode;
}

const Navegacion: React.FC<props> = ({seccion, proyecto, modulo, submodulo, accion, api, rol, reporte, solicitud, children}) => {
	const regresar = () => {
		window.history.back();
	};
    return(
        <>
            {/* Catálogo de proyectos */}
            {seccion === "proyectos" ?
                <div className="row">
                    <div className="col">
                        <Breadcrumb>
                            <BreadcrumbItem className="breadcrumbSecundario" active>
                                Proyectos
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <Breadcrumb>
                            <BreadcrumbItem className="breadcrumbPrincipal" active>
                                <FaFolderOpen size={28} color="black"/> <span>Proyectos</span>
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                </div>
            /* Proyecto */
            :seccion === "proyecto" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        <Breadcrumb className="breadcrumSecundarioContenedor">
                            <BreadcrumbItem className="breadcrumbSecundario">
                                <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem className="breadcrumbSecundario">
                                {proyecto}
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <div className="divBreadcrumbPrincipal">
                            <Breadcrumb>
                                <BreadcrumbItem className="breadcrumbPrincipal" active>
                                    <FaFolderOpen size={28} color="black"/>
                                    <span>{proyecto}</span>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="divRegresar">
                                <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                <span className="regresarTexto" onClick={regresar}>
                                    Regresar
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            :seccion === "guardar-proyecto" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        {accion === 1 &&
                            <>
                                <Breadcrumb className="breadcrumbSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario" active>
                                        Crear Proyecto
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaSave size={28} color="black" />
                                            <span>Crear proyecto</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </>
                        }
                        {accion === 2 && (
                            <>
                                <Breadcrumb className="breadcrumbSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">{proyecto}</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario" active>
                                        <span>Editar Proyecto</span>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaPencilAlt size={28} color="black" />
                                            <span>{proyecto}</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            /* Módulo */
            :seccion === "modulo" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        <Breadcrumb className="breadcrumSecundarioContenedor">
                            <BreadcrumbItem className="breadcrumbSecundario">
                                <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem className="breadcrumbSecundario">
                                <NavLink className="breadcrumbSecundario" to="/proyecto">
                                    {proyecto}
                                </NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem className="breadcrumbSecundario">
                                {modulo}
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <div className="divBreadcrumbPrincipal">
                            <Breadcrumb>
                                <BreadcrumbItem className="breadcrumbPrincipal" active>
                                    <HiPuzzle size={28} color="black"/>
                                    <span>{modulo}</span>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="divRegresar">
                                <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                <span className="regresarTexto" onClick={regresar}>
                                    Regresar
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            /* Submódulo */
            :seccion === "submodulo" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        <Breadcrumb className="breadcrumSecundarioContenedor">
                            <BreadcrumbItem className="breadcrumbSecundario">
                                <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem className="breadcrumbSecundario">
                                <NavLink className="breadcrumbSecundario" to="/proyecto">
                                    {proyecto}
                                </NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem className="breadcrumbSecundario">
                                <NavLink className="breadcrumbSecundario" to="/modulo">
                                    {modulo}
                                </NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem className="breadcrumbSecundario">
                                {submodulo}
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <div className="divBreadcrumbPrincipal">
                            <Breadcrumb>
                                <BreadcrumbItem className="breadcrumbPrincipal" active>
                                    <HiPuzzle size={28} color="black"/><span> {submodulo} </span>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="divRegresar">
                                <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                <span className="regresarTexto" onClick={regresar}>
                                    Regresar
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            /* Documentar api */
            :seccion === "documentacion-api" ?
                <div className="row">
					<div className="col breadcrumbNavegacion">
						{accion === 1 ?
							<>
								<Breadcrumb className="breadcrumSecundarioContenedor">
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink  className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink >
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink  className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink >
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink  className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink >
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										{api}
									</BreadcrumbItem>
								</Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaCloud size={28} color="black"/><span>
                                                {api}
                                            </span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
							</>
						:accion === 0 ?
							<>
								<Breadcrumb className="breadcrumSecundarioContenedor">
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink  className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink >
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink  className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink >
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink  className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink >
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink  className="breadcrumbSecundario" to="/submodulo">
                                            {submodulo}
                                        </NavLink >
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
                                        {api}
									</BreadcrumbItem>
								</Breadcrumb>
								<div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaCloud size={28} color="black"/><span> 
                                                {api}
                                            </span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
							</>
						:null}
					</div>
				</div>
            /* Documentar api */
            :seccion === "documentar-api" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        {accion === 1 ?
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Documentar api
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaCloud size={28} color="black"/><span> Documentar api</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <span className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </span>
                                    </div>
                                </div>
                            </>
                        :accion === 0 ?
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/submodulo">
                                            {submodulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Documentar api
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaCloud size={28} color="black"/><span> Documentar api</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <span className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </span>
                                    </div>
                                </div>
                            </>
                        :null}
                    </div>
                </div>
            :seccion === "registro-apis" ?
                <div className="row">
					<div className="col breadcrumbNavegacion">
						{accion === 1 ? (
							<>
								<Breadcrumb className="breadcrumSecundarioContenedor">
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
									</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
										<NavLink className="breadcrumbSecundario" to="/documentacionapi">
                                            {api}
                                        </NavLink>
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										Registro de actividad
									</BreadcrumbItem>
								</Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaHistory size={28} color="black"/><span> Registro de actividad</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
							</>
						) : accion === 0 ? (
							<>
								<Breadcrumb className="breadcrumSecundarioContenedor">
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										<NavLink className="breadcrumbSecundario" to="/submodulo">
                                            {submodulo}
                                        </NavLink>
									</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
										<NavLink className="breadcrumbSecundario" to="/documentacionapi">
                                            {api}
                                        </NavLink>
									</BreadcrumbItem>
									<BreadcrumbItem className="breadcrumbSecundario">
										Registro de actividad
									</BreadcrumbItem>
								</Breadcrumb>
								<div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaHistory size={28} color="black"/><span>Registro de actividad</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
							</>
						) : null}
					</div>
				</div>
            //Información general
            :seccion === "edit-info-general" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        {accion === 1 ? (
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/documentacionapi">
                                            {api}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Editar información general
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaFileAlt size={28} color="black"/><span> Editar información general</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </>
                        ) : accion === 0 ? (
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/submodulo">
                                            {submodulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/documentacionapi">
                                            {api}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Editar información general
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaFileAlt size={28} color="black"/><span> Editar información general</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            :seccion === "edit-entradas" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        {accion === 1 ? 
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/documentacionapi">
                                            {api}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Editar entradas
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaArrowRight size={28} color="black"/>
                                            <span>Editar entradas</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </>
                        :accion === 0 ? (
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/submodulo">
                                            {submodulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/documentacionapi">
                                            {api}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                    Editar entradas
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaArrowRight size={28} color="black"/>
                                            <span>Editar entradas</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            :seccion === "edit-salidas" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        {accion === 1 ? 
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/documentacionapi">
                                            {api}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Editar salidas
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaArrowLeft size={28} color="black"/>
                                            <span> Editar salidas</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </>
                        :accion=== 0 ? (
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/submodulo">
                                            {submodulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/documentacionapi">
                                            {api}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Editar salidas
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaArrowLeft size={28} color="black"/>
                                            <span> Editar salidas</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            :seccion === "edit-excepciones" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        {accion === 1 ?
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/documentacionapi">
                                            {api}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Editar excepciones
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaArrowLeft size={28} color="black"/>
                                            <span>Editar excepciones</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </>
                        :accion === 0 ? 
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/catalogoproyectos">Proyectos</NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/proyecto">
                                            {proyecto}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/modulo">
                                            {modulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/submodulo">
                                            {submodulo}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        <NavLink className="breadcrumbSecundario" to="/documentacionapi">
                                            {api}
                                        </NavLink>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Editar excepciones
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <div className="divBreadcrumbPrincipal">
                                    <Breadcrumb>
                                        <BreadcrumbItem className="breadcrumbPrincipal" active>
                                            <FaArrowLeft size={28} color="black"/>
                                            <span>Editar excepciones</span>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="divRegresar">
                                        <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                        <a className="regresarTexto" onClick={regresar}>
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </>
                        :null}
                    </div>
                </div>
            :seccion === "apis" ?
                <div className="row">
					<div className="col breadcrumbNavegacion">
						<Breadcrumb className="breadcrumbSecundarioContenedor">
							<BreadcrumbItem className="breadcrumbSecundario">
								Apis
							</BreadcrumbItem>
						</Breadcrumb>
						<Breadcrumb>
							<BreadcrumbItem className="breadcrumbPrincipal" active>
								<FaCloud size={28} color="black"/><span> Apis</span>
							</BreadcrumbItem>
						</Breadcrumb>
					</div>
				</div>
            :seccion === "reportes" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        {(rol == 2 || rol == 3) ?
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Mis reportes
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <Breadcrumb>
                                    <BreadcrumbItem className="breadcrumbPrincipal" active>
                                        <RiErrorWarningFill size={28} color="black" /><span> Mis reportes</span>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </>
                        :
                            <>
                                <Breadcrumb className="breadcrumSecundarioContenedor">
                                    <BreadcrumbItem className="breadcrumbSecundario">
                                        Reportes
                                    </BreadcrumbItem>
                                </Breadcrumb>
                                <Breadcrumb>
                                    <BreadcrumbItem className="breadcrumbPrincipal" active>
                                        <RiErrorWarningFill size={28} color="black"/><span>Reportes</span>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </>
                        }
                    </div>
                </div>
            :seccion === "reporte" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        <Breadcrumb className="breadcrumSecundarioContenedor">
                            <BreadcrumbItem className="breadcrumbSecundario">
                                {(rol == 2 || rol == 3) ? (
                                        <NavLink className="breadcrumbSecundario" to="/catalogoreportes">Mis reportes</NavLink>
                                ) : (
                                    <NavLink className="breadcrumbSecundario" to="/catalogoreportes">Reportes</NavLink>
                                )}
                            </BreadcrumbItem>
                            <BreadcrumbItem className="breadcrumbSecundario">
                                Reporte No. {reporte}
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <div className="divBreadcrumbPrincipal">
                            <Breadcrumb>
                                <BreadcrumbItem className="breadcrumbPrincipal" active>
                                    <RiErrorWarningFill size={28} color="black"/><span> 
                                        Reporte No. {reporte}
                                    </span>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="divRegresar">
                                <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                <a className="regresarTexto" onClick={regresar}>
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            :seccion === "solicitudes" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        <Breadcrumb className="breadcrumbSecundarioContenedor">
                            <BreadcrumbItem className="breadcrumbSecundario">
                                Solicitudes
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <Breadcrumb>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <BreadcrumbItem className="breadcrumbPrincipal" active>
                                    <FiClock size={28} color="black"/><span> Solicitudes</span>
                                </BreadcrumbItem>
                            </div>
                            {children}
                        </Breadcrumb>
                    </div>
                   
                </div>
            :seccion === "solicitud" ?
                <div className="row">
                    <div className="col breadcrumbNavegacion">
                        <Breadcrumb className="breadcrumSecundarioContenedor">
                            <BreadcrumbItem className="breadcrumbSecundario">
                                {(rol == 2 || rol == 3) ? (
                                        <NavLink className="breadcrumbSecundario" to="/catalogosolicitudes">Mis solicitudes</NavLink>
                                ) : (
                                    <NavLink className="breadcrumbSecundario" to="/catalogosolicitudes">Solicitudes</NavLink>
                                )}
                            </BreadcrumbItem>
                            <BreadcrumbItem className="breadcrumbSecundario">
                                Solicitud No. {solicitud}
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <div className="divBreadcrumbPrincipal">
                            <Breadcrumb>
                                <BreadcrumbItem className="breadcrumbPrincipal" active>
                                    <FiClock size={28} color="black"/>
                                    <span> Solicitud No. {solicitud}</span>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="divRegresar">
                                <TbArrowBackUp className="regresarIcono" onClick={regresar} size={20}/>
                                <a className="regresarTexto" onClick={regresar}>
                                    Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            :null}
        </>
    )
}
export default Navegacion;