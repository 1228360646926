import axios from "axios";
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

//var ruta_api = "http://localhost";//En local
var ruta_api = "https://www.apibox.izttra.com";//En el host
var apigeneral = "/apibox-api/"
var x_accesskey = "1081592e5bcf4d20a3233e044a0171f4";
var SecretKey = "116971e670ad4dc6821dd3ea75d7f671";

export async function consumirApi(apis: any, metodo: any, json: any) {
    var dia_ac = new Date(),
        month = '' + (dia_ac.getUTCMonth() + 1),
        day = '' + dia_ac.getUTCDate(),
        year = dia_ac.getUTCFullYear(),

        h = '' + dia_ac.getUTCHours(),
        m = '' + dia_ac.getUTCMinutes(),
        ss = '' + dia_ac.getUTCSeconds();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    if (h.length < 2) {
        h = '0' + h;
    }
    if (m.length < 2) {
        m = '0' + m;
    }
    if (ss.length < 2) {
        ss = '0' + ss;
    }

    let dia_actual = [year, month, day].join('-');
    let hora_actual = [h, m, ss].join(':');
    var fecha = dia_actual + ' ' + hora_actual;
    //console.log(fecha + " " + x_accesskey + " " + apis);
    var Signature = Base64.stringify(hmacSHA512(fecha + x_accesskey + apis, SecretKey));
    // console.log("Bearer " + Signature);

    var headersGenerales = {
        "content-type": "application/json",
        "X-AccessKey": x_accesskey,
        "X-Date": fecha,
        "X-Signature": "Bearer " + Signature,
    };

    var headersGeneralesFormData = {
        "content-type": "multipart/form-data",
        "X-AccessKey": x_accesskey,
        "X-Date": fecha,
        "X-Signature": "Bearer " + Signature,
    };
    try {
        var consulta:any;
        if (metodo == 'get') {
            consulta = await axios.get(ruta_api + apis, {
                headers: headersGenerales,
            });
        }
        else if (metodo == 'post') {
            consulta = await axios.post(ruta_api + apis, json, {
                headers: headersGenerales,
            });
        } else if (metodo == 'post-form') {
            consulta = await axios.post(ruta_api + apis, json, {
                headers: headersGeneralesFormData,
            });
        }

        var resp = consulta.data;
        return resp;
    } catch (error) {
        return -1;
    }

}

//API de Login
export async function login(usuario:string, clave:string) {
    let jsonfile = { "usuario": usuario, "clave": clave };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "login/get-apikey", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//APIs de proyectos
export async function getListaProyectos(token: number) {
    var resp = [];
    
    let jsonfile = { "token": token };
    try {
        var datos = await consumirApi(apigeneral + "proyectos/lista-proyectos", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postCrearProyecto(FormData:any) {
    var resp = [];
    
    try {
        var datos = await consumirApi(apigeneral + "proyectos/guardar-proyecto", 'post-form', FormData);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
    
}

//APIs de DetallesProyecto
export async function getProyectoDetalles(token: number, id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "proyectos/detalles", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaUsuariosProyecto(token: number, id_proyecto:number, id_rol:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_rol":id_rol
                    };
    try {
        var datos = await consumirApi(apigeneral + "usuario-colabora-proyecto/lista-usuarios-colabora-proyecto", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function PostListaUsuariosNoColaboraProyecto(token: number, id_proyecto:number, id_rol:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_rol":id_rol
                    };
    try {
        var datos = await consumirApi(apigeneral + "usuario-colabora-proyecto/lista-usuarios-no-colabora-proyecto", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function PostAsignarColaborador(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto":json.id_proyecto,
                     "id_usuario":json.id_usuario,
                     "id_rol":json.id_rol
                    };
    try {
        var datos = await consumirApi(apigeneral + "usuario-colabora-proyecto/asignar-colaborador", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function GetInformacionColaborador(token:number, id_usuario:number,id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                    "id_usuario":id_usuario,
                    "id_proyecto":id_proyecto
                };
    try {
        var datos = await consumirApi(apigeneral + "usuario-colabora-proyecto/informacion-colaborador", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function DeleteEliminarColaborador(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto":json.id_proyecto,
                     "id_usuario":json.id_usuario
                    };
    try {
        var datos = await consumirApi(apigeneral + "usuario-colabora-proyecto/eliminar-colaborador", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getModulosDelProyecto(token: number, id_proyecto:number) {
    var resp = [];
    
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "modulos/lista-modulos-proyecto", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp; 
}

export async function postEliminarProyecto(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto":json.id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "proyectos/eliminar-proyecto", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarModulo(json: any) {
    var resp = [];
    let jsonfile = {
        "token": json.token,
        "id_proyecto": json.id_proyecto,
        "id_modulo": json.id_modulo,
        "nombre_modulo": json.nombre_modulo,
        "descripcion_modulo": json.descripcion_modulo,
        "headers_checkbox": json.headers_checkbox,
        "headers_modulo": json.headers_modulo,
        "id_solicitud": json.id_solicitud,
        "comentario_checkbox":json.comentario_checkbox,
		"comentario":json.comentario
    };
    try {
        var datos = await consumirApi(apigeneral + "modulos/guardar-modulo", 'post', jsonfile);
        resp = datos;
    }catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        });
    }
    return resp;
}


export async function getModuloDetalles(token: number, id_modulo:number, id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_modulo":id_modulo
                    };
    try {
        var datos = await consumirApi(apigeneral + "modulos/detalles-modulo", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEliminarModulo(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto":json.id_proyecto,
                     "id_modulo":json.id_modulo
                    };
    try {
        var datos = await consumirApi(apigeneral + "modulos/eliminar-modulo", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//APIs de detalles del modulo
export async function getListaSubmodulosDelModulo(token: number, id_modulo:number, id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_modulo":id_modulo
                    };
    try {
        var datos = await consumirApi(apigeneral + "submodulos/lista-submodulos-modulo", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaApisDelModulo(token: number, id_modulo:number, id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_modulo":id_modulo
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/lista-apis-modulo", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarSubmodulo(json: any) {
    var resp = [];
    
    let jsonfile = { "token": json.token,
                     "id_proyecto":json.id_proyecto,
                     "id_submodulo":json.id_submodulo,
                     "nombre_submodulo":json.nombre_submodulo,
                     "descripcion_submodulo":json.descripcion_submodulo,
                     "headers_checkbox":json.headers_checkbox,
                     "headers_submodulo":json.headers_submodulo,
                     "id_modulo":json.id_modulo,
                     "id_solicitud":json.id_solicitud,
                     "comentario_checkbox":json.comentario_checkbox,
                     "comentario":json.comentario
                    };
    try {
        var datos = await consumirApi(apigeneral + "submodulos/guardar-submodulo", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp; 
}

export async function getSubmoduloDetalles(token: number, id_submodulo:number, id_proyecto:number, id_modulo:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_modulo":id_modulo,
                     "id_submodulo":id_submodulo
                    };
    try {
        var datos = await consumirApi(apigeneral + "submodulos/detalles-submodulo", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function postEliminarSubmodulo(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto":json.id_proyecto,
                     "id_submodulo":json.id_submodulo
                    };
    try {
        var datos = await consumirApi(apigeneral + "submodulos/eliminar-submodulo", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
} 
export async function getListaApisDelSubmodulo(token: number, id_submodulo:number, id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_submodulo":id_submodulo,
                     "id_proyecto":id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/lista-apis-submodulo", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaApisGeneral(token: number, tipo_busqueda:string, id:number) {
    var resp = [];
    let jsonfile = { 
                    "token": token,
                    "tipo_busqueda": tipo_busqueda,
                    "id":id
                };
    try {
        var datos = await consumirApi(apigeneral + "apis/lista-general", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getNotificacionesReportes(token: number) {
    var resp = [];
    let jsonfile = { "token": token
                    };
    try {
        var datos = await consumirApi(apigeneral + "reportes/notificaciones-reportes", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaReportes(token: number, tipo_busqueda:string, id:number) {
    var resp = [];
    let jsonfile = { 
                    "token": token,
                    "tipo_busqueda": tipo_busqueda,
                    "id":id
                };
    try {
        var datos = await consumirApi(apigeneral + "reportes/lista-reportes", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarReporte(FormData:any) {
    var resp = [];

    try {
        var datos = await consumirApi(apigeneral + "reportes/guardar-reporte", 'post-form', FormData);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getReporteDetalles(token: number,id_reporte:number, id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_reporte":id_reporte,
                     "id_proyecto": id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "reportes/detalles-reporte", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteEliminarReporte(FormData:any) {
    var resp = [];

    try {
        var datos = await consumirApi(apigeneral + "reportes/eliminar-reporte", 'post', FormData);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaImagenesReporte(token: number,id_reporte:number, id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_reporte":id_reporte,
                     "id_proyecto":id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "reportes/lista-imagenes-reporte", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteImagenReporte(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto":json.id_proyecto,
                     "id_imagen":json.id_imagen,
                     "id_reporte":json.id_reporte,
                     "comentario_checkbox":json.comentario_checkbox,
                     "comentario":json.comentario
                    };
    try {
        var datos = await consumirApi(apigeneral + "reportes/eliminar-imagen-reporte", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaComentariosReporte(token:number, id_reporte:number, id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_reporte":id_reporte,
                     "id_proyecto":id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "reportes/lista-comentarios-reporte", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function PostActualizarEstadoReporte(json:any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_reporte":json.id_reporte,
                     "estado":json.estado,
                     "id_proyecto":json.id_proyecto,
                     "comentario_checkbox":json.comentario_checkbox,
			         "comentario":json.comentario
                    };
    try {
        var datos = await consumirApi(apigeneral + "reportes/actualizar-estado", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEditarComentarioReporte(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_comentario": json.id_comentario,
                     "comentario_checkbox":json.comentario_checkbox,
			         "comentario":json.comentario
                    };
    try {
        var datos = await consumirApi(apigeneral + "reportes/editar-comentario", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postFinalizarReporte(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_reporte": json.id_reporte,
                     "id_proyecto":json.id_proyecto,
                     "id_api":json.id_api,
                     "comentario_checkbox":json.comentario_checkbox,
                     "comentario":json.comentario
                    };
    try {
        var datos = await consumirApi(apigeneral + "reportes/finalizar-reporte", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getNotificacionesSolicitudes(token: number) {
    var resp = [];
    let jsonfile = { "token": token
                    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/notificaciones-solicitudes", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaSolicitudes(token: number, tipo_busqueda:string, id:number) {
    var resp = [];
    let jsonfile = { 
                    "token": token,
                    "tipo_busqueda": tipo_busqueda,
                    "id":id
                };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/lista-solicitudes", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaSubmodulosDelModuloSinFiltro(token: number, id_proyecto:number, id_modulo:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_modulo":id_modulo
                    };
    try {
        var datos = await consumirApi(apigeneral + "submodulos/lista-submodulos-modulo-sin-filtro", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTiposSolicitud(token: number) {
    var resp = [];
    let jsonfile = { "token": token
                    };
    try {
        var datos = await consumirApi(apigeneral + "tipos-solicitud/lista", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function postGuardarSolicitud(json:any) {
    var resp = [];
    let jsonfile = { 
        "token":json.token,
        "id_solicitud":json.id_solicitud,
        "titulo_solicitud":json.titulo_solicitud,
        "descripcion_solicitud":json.descripcion_solicitud,
        "solicitud_categoria":json.solicitud_categoria,
        "num_apis":json.num_apis,
        "apis":json.apis,
        "prioridad":json.prioridad,
        "fecha_entrega":json.fecha_entrega,
        //llaves foraneas
        "id_proyecto":json.id_proyecto,
        "id_tipo_solicitud":json.id_tipo_solicitud,
        "id_submodulo":json.id_submodulo,

        "comentario_checkbox":json.comentario_checkbox,
        "comentario":json.comentario
    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/guardar-solicitud", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getSolicitudDetalles(token: number, id_solicitud:number, id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_solicitud":id_solicitud,
                     "id_proyecto":id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/solicitud-detalles", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEliminarSolicitud(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_solicitud":json.id_solicitud,
                     "id_proyecto":json.id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/eliminar-solicitud", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
 
  //Apis de apis
  export async function getListaFormatosCuerpo(token: number) {
    var resp = [];
    let jsonfile = { "token": token
                    };
    try {
        var datos = await consumirApi(apigeneral + "formatos-cuerpo/lista", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteEliminarApi(json:any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "idsolicitud_tiene_api":json.idsolicitud_tiene_api,
                     "comentario_checkbox":json.comentario_checkbox,
		             "comentario":json.comentario
                    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/eliminar-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaSolicitudTieneApi(token:number, id_solicitud:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_solicitud":id_solicitud
                    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/lista-solicitud-tiene-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaApisSolicitud(token:number, id_submodulo:number, id_proyecto: number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_submodulo":id_submodulo,
                     "id_proyecto":id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/lista-apis", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postAgregarCambiarApi(json:any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto":json.id_proyecto,
                     "id_api":json.id_api,
                     "idsolicitud_tiene_api":json.idsolicitud_tiene_api,
                     "comentario_checkbox":json.comentario_checkbox,
		             "comentario":json.comentario
                    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/agregar-cambiar-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaComentariosSolicitud(token:number, id_solicitud:number, id_proyecto:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_solicitud":id_solicitud,
                     "id_proyecto":id_proyecto
                    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/lista-comentarios-solicitud", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function PostActualizarEstadoSolicitud(json:any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_solicitud":json.id_solicitud,
                     "estado":json.estado,
                     "id_proyecto":json.id_proyecto,
                     "comentario_checkbox":json.comentario_checkbox,
			         "comentario":json.comentario
                    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/actualizar-estado", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEditarComentarioSolicitud(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_comentario": json.id_comentario,
                     "comentario_checkbox":json.comentario_checkbox,
                     "comentario":json.comentario
    };
    
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/editar-comentario", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postFinalizarSolicitud(json: any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_solicitud": json.id_solicitud,
                     "id_proyecto":json.id_proyecto,
                     "comentario_checkbox":json.comentario_checkbox,
                     "comentario":json.comentario
                    };
    try {
        var datos = await consumirApi(apigeneral + "solicitudes/finalizar-solicitud", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarApi(json:any) {
    var resp = [];
   
    try {
        var datos = await consumirApi(apigeneral + "apis/guardar-api", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postDetallesApi(token: number, id_proyecto:number, id_api:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_api":id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/detalles-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getReporteApi(token: number, id_proyecto:number,id_api:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto": id_proyecto,
                     "id_api":id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "reportes/reporte-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getUltimaModifiacionApi(token: number, id_api:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_api":id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/ultima-modificacion-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaRegistroActividad(token: number, id_api:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_api":id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/lista-registro-actividad", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaColaboradoresApi(token: number, id_api:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_api":id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/lista-colaboradores", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaReportesApi(token: number, id_api:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_api":id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/lista-reportes", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getSolicitudApi(token: number, id_proyecto:number,id_api:number) {
    var resp = [];
    let jsonfile = { 
                        "token": token,
                        "id_proyecto":id_proyecto,
                        "id_api":id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/solicitud-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function deleteEliminarDocumentacionApi(json:any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto": json.id_proyecto,
                     "id_api":json.id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/eliminar-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postMigrarDocumentacionApi(json:any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto": json.id_proyecto,
                     "id_api":json.id_api,
                     "id_submodulo": json.id_submodulo
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/migrar-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postCopiarDocumentacionApi(json:any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto": json.id_proyecto,
                     "id_api":json.id_api,
                     "id_submodulo": json.id_submodulo,
                     "nombre_api":json.nombre_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/copiar-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEditarInformacionTecnica(json:any) {
    var resp = [];
   
    try {
        var datos = await consumirApi(apigeneral + "apis/editar-informacion-tecnica", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEjemplosEntradas(token: number, id_proyecto:number, id_api:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_api":id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/ejemplos-entradas", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEditarEntradas(json:any) {
    var resp = [];
   
    try {
        var datos = await consumirApi(apigeneral + "apis/editar-ejemplos-entradas", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteEliminarEjemploEntrada(json:any) {
    var resp = [];
   
    try {
        var datos = await consumirApi(apigeneral + "apis/eliminar-ejemplo-entrada", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEjemplosSalidas(token: number, id_proyecto:number, id_api:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_api":id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/ejemplos-salidas", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEditarSalidas(json:any) {
    var resp = [];
   
    try {
        var datos = await consumirApi(apigeneral + "apis/editar-ejemplos-salidas", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteEliminarEjemploSalida(json:any) {
    var resp = [];
   
    try {
        var datos = await consumirApi(apigeneral + "apis/eliminar-ejemplo-salida", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEjemplosExcepciones(token: number, id_proyecto:number, id_api:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_api":id_api
                    };
    try {
        var datos = await consumirApi(apigeneral + "apis/ejemplos-excepciones", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEditarExcepciones(json:any) {
    var resp = [];
   
    try {
        var datos = await consumirApi(apigeneral + "apis/editar-ejemplos-excepciones", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteEliminarEjemploExcepcion(json:any) {
    var resp = [];
   
    try {
        var datos = await consumirApi(apigeneral + "apis/eliminar-ejemplo-excepcion", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getNotasApi(token: number, id_proyecto:number, id_api:number) {
    var resp = [];
    let jsonfile = { "token": token,
                     "id_proyecto":id_proyecto,
                     "id_api":id_api
                    };
   
    try {
        var datos = await consumirApi(apigeneral + "apis/lista-notas-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarNotasApi(json:any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_proyecto": json.id_proyecto,
                     "id_api": json.id_api,
                     "id_nota":json.id_nota,
                     "nota":json.nota
                    };
   
    try {
        var datos = await consumirApi(apigeneral + "apis/guardar-nota-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function DeleteNotasApi(json:any) {
    var resp = [];
    let jsonfile = { "token": json.token,
                     "id_nota":json.id_nota
                    };
   
    try {
        var datos = await consumirApi(apigeneral + "apis/eliminar-nota-api", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}