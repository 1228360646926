import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { tiposolicitud } from '../Interfaces';
import { getListaTiposSolicitud } from '../../../Services/Api';
import { toast } from 'react-toastify';

interface props {
    token:number
    tiposolicitud:string
    vermsgerror:boolean,
    msgerror:string,
    tipoSolicitudSeleccionada: (tiposolicitud:string) => void;
}

interface states {
    tiposolicitud:string
    tipossolicitudlista:tiposolicitud[]
}

export default class SelectTipoSolicitud extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            tiposolicitud:this.props.tiposolicitud,
            tipossolicitudlista:[]
        }
    }

    async componentDidMount() {
		const apitipossolicitud = await getListaTiposSolicitud(this.props.token);
		if(this.respuestaApi (apitipossolicitud)){
			return;
		}
		this.setState({tipossolicitudlista: apitipossolicitud.data});	
    }

    //Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return 
		}
		return false
	}

    componentDidUpdate(prevProps: props) {
        if (prevProps.tiposolicitud !== this.props.tiposolicitud) {
          this.setState({ tiposolicitud: this.props.tiposolicitud });
        }
    }
    
    //Función para cambiar el estado del select de tipo solicitud.
	tipoSolicitudSeleccionada = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const tiposolicitud = event.target.value;
		this.setState( {tiposolicitud:tiposolicitud});
        this.props.tipoSolicitudSeleccionada(tiposolicitud)
	};

    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Tipo de solicitud <span className="spanObligatorio">*</span>
                </Label>
                <Input type="select" value={this.state.tiposolicitud} onChange={this.tipoSolicitudSeleccionada}>
                    <option value="" disabled>Seleccione una opción</option>
                    {this.state.tipossolicitudlista.map((tiposolicitud,index)=>(	
                        <option key={index} value= {tiposolicitud.tipo_solicitud}>
                            {tiposolicitud.tipo_solicitud}
                        </option>
                    ))}
                </Input>
                {this.props.vermsgerror ? 
                    <div className="row">
                        <div className="col-10 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    </div>
                :
                    <div className="row">
                        <div className="col-10 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    </div>
                }
            </FormGroup>
        );
    }
}