import React from "react";
import "./DetallesReporte.css";
import { FaEye, FaCheck } from "react-icons/fa";
import { Button, Card, CardBody, CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { RiDeleteBinFill } from "react-icons/ri";
import { ToastContainer, toast } from 'react-toastify';
import MenuNavbar from "../../Complements/Navbar/Navbar";
import { HiPencil } from "react-icons/hi";
import { FaComments } from 'react-icons/fa';
import { getListaComentariosReporte, getReporteDetalles } from "../../Services/Api";
import { CgDetailsMore } from "react-icons/cg";
import { SlOptions } from "react-icons/sl";
import { Navigate } from "react-router-dom";
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import ModalFormularioReporte from "../../Complements/Modales/ModalFormularioReporte/ModalFormularioReporte";
import ModalFinalizarReporte from "../../Complements/Modales/ModalFinalizarReporte/ModalFinalizarReporte";
import { format, formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import ModalEliminarReporte from "../../Complements/Modales/ModalEliminarReporte/ModalEliminarReporte";
import SeccionComentarios from "../../Complements/SeccionComentarios/SeccionComentarios";
import { reporte, reporteestado } from "./Interfaces";
import FormularioComentario from "../DetallesSolicitud/Componentes/FormularioComentario";
import Evidencias from "./Componentes/Evidencias";
import Importancia from "./Componentes/Importancia";
import Navegacion from "../../Complements/Navegacion/Navegacion";

//Interfaz general del componente.
interface states{
	id_rol:number,
  	cargando:boolean,
	opciones:boolean,
	perfil:{
	  token:number,
	  name:string,
	  email:string,
	  role:number
	},
	reporte:reporte,
	visualizarcomentarios:boolean,
	
	redireccionando:number,

	titulomodal:string,
	accionmodal:number,
	showmodalgenerarreporte:boolean,
	showmodaleliminarreporte:boolean,

	reporteseleccionado:reporte,

	comentario:string,

	showmodalfinalizarreporte:boolean,
	cambios:boolean
}

export default class DetallesReporte extends React.Component<{}, states> {
    constructor(props: {}) {
		super(props);
		this.state = {
			id_rol:0,
            cargando:true,
			opciones:false,
            perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "","role":""}'),
            reporte:reporteestado,

			visualizarcomentarios:true,

			redireccionando:0,

			titulomodal:'',
			accionmodal:0,
			showmodalgenerarreporte:false,
			showmodaleliminarreporte:false,
			
			reporteseleccionado:reporteestado,

			comentario:'',
			showmodalfinalizarreporte:false,

			cambios:false
        }
    }
	
    async componentDidMount() {
		if (await localStorage.getItem('acceso') !== null || await localStorage.getItem('acceso') !== undefined) {
            const datos = localStorage.getItem('acceso');
			//this.setState({ redireccionando: true });
        } 

		//Se obtiene el calor del item "id_rol" y se almacena en su correspondiente estado.
		const id_rol = localStorage.getItem("id_rol")
		this.setState({id_rol:Number(id_rol)})

		//Se obtiene el valor de el item "accion", se hacen validaciones y se muestra el mensaje correspondiente,
		const accion = localStorage.getItem("accion");
		if(accion !== null){
			this.mensajesToast(accion);
			localStorage.removeItem("accion");
		}

        var id_reporte = await localStorage.getItem('id_reporte');
		var id_proyecto = await localStorage.getItem('id_proyecto')

		const apireporte = await getReporteDetalles(this.state.perfil.token,Number(id_reporte), Number(id_proyecto));
		if(this.respuestaApi (apireporte, 0)){
			return;
		}
		this.reporteFechaModificada(apireporte)
		
		const apicomentarios = await getListaComentariosReporte(this.state.perfil.token,Number(id_reporte),Number(id_proyecto));
		if(this.respuestaApi (apicomentarios, 2)){
			return;
		}
		
		setTimeout(() => {
			this.setState({cargando: false});
		}, 1000);
    }

	//Función para mostrar mensajes toast al iniciar el componente.
	mensajesToast = (accion:string) => {
		if (accion === "1") {
			setTimeout(() => {
				toast.success("Imagen eliminada!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
		else if (accion === "2"){
			setTimeout(() => {
				toast.success("Comentario eliminado!", {autoClose :1000, position:toast.POSITION.TOP_LEFT})
			})
		}
		else if (accion === "3"){
			setTimeout(() => {
				toast.success("Reporte finalizado!", {autoClose :1000, position:toast.POSITION.TOP_LEFT})
			})
		}
	}

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any, tipo:number) => {
		if (resp === -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code === 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code === 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}else if (resp.code == 100) {
			if(tipo === 2){
				this.setState({visualizarcomentarios:false})
			}
			this.setState({ cargando: false })
			return 
		}
		return false
	}

	//Función de regresar
	regresar = () => {
		window.history.back();
	};

	/////////////////////////////// Sección del reporte
	//Función para modificar la fecha, trauducirla y aisgnarla.
	reporteFechaModificada = (reporte: any) =>{
		//Se modifica la fecha.
		const fecha_actual = new Date();

		const fecha_generacion = new Date(reporte.data.fecha_generacion);
		const fecha_formateada = format(fecha_generacion, "d 'de' MMMM 'del' yyyy 'a las' hh:mm a", { locale: es });

		const fecha_entrega = new Date(reporte.data.fecha_entrega);
		const fecha_entrega_formateada = format(fecha_entrega, "d 'de' MMMM 'del' yyyy 'a las' hh:mm a", {locale: es});

		let tiempo_faltante = formatDistanceToNow(fecha_entrega, { locale: es })
		if (fecha_actual > fecha_entrega) {
			tiempo_faltante = "Te pasaste por " + tiempo_faltante;
		}else{
			tiempo_faltante = "Faltan " + tiempo_faltante;
		}

		//Se asigna la nueva fecha.
		reporte.data.fecha_generacion = fecha_formateada;
		reporte.data.fecha_entrega_formateada = fecha_entrega_formateada;
		reporte.data.tiempo_faltante = tiempo_faltante;
		
		this.setState({reporte: reporte.data});

	}
	
	//Función para actualizar comentarios
	actualizarComentarios = () =>{
		this.setState({visualizarcomentarios:true, cargando:true, cambios:!this.state.cambios})
		this.componentDidMount()
	}

	//Función para desplegar opciones de editar y eliminar.
	desplegarOpcionesReporte = () => {
		this.setState({opciones: !this.state.opciones})
	}

	//Función para mostrar formulario para editar el reporte.
	verModalFormularioReporte = (accion:number, reporte: any) => {
		if(accion==2){
			this.setState({ titulomodal:"Editar reporte", accionmodal:accion, reporteseleccionado: JSON.parse(JSON.stringify(reporte))})
		}
		this.setState({ showmodalgenerarreporte: true})
	}

	//Función para cerrar formulario para editar el reporte.
	cerrarModalFormularioReporte = (accion:number) =>{
        this.setState({showmodalgenerarreporte: !this.state.showmodalgenerarreporte });
		if(accion==2){
			this.setState({cargando:true, visualizarcomentarios:true});
			this.actualizarComentarios()
			this.componentDidMount();
		}
    }

	//Función para mostrar formulario para eliminar el reporte.
	verModalFormularioEliminarReporte = () => {
		if(this.state.reporte.estado !== "Resuelto"){
			this.setState({accionmodal:1})
		}else{
			this.setState({accionmodal:2})
		}
		this.setState({ showmodaleliminarreporte: true})
	}

	//Función para cerrar formulario para eliminar el reporte.
	cerrarModalFormularioEliminarReporte = (accion:number) =>{
		this.setState({showmodaleliminarreporte: !this.state.showmodaleliminarreporte });
		if(accion==1){
			localStorage.setItem("accion","Reporte eliminado");
			this.setState({redireccionando:1});
		}
	}

	//Función para abrir la documentación de la api reportada.
	redireccionandoApi(id_proyecto:number, id_modulo:number,id_submodulo:number,submodulo_general:number,id_api:number){
		localStorage.setItem("id_proyecto", id_proyecto.toString());
		localStorage.setItem("id_modulo", id_modulo.toString());
		localStorage.setItem("id_submodulo", id_submodulo.toString());
		localStorage.setItem("submodulo_general", submodulo_general.toString());
		localStorage.setItem("id_api",id_api.toString());
		this.setState({ redireccionando: 2 })
	}

	//Función para abrir modal para finalizar reporte
	verModalFinalizarReporte = () => {
		this.setState({ showmodalfinalizarreporte: true })
	}

	//Función para cerrar modal para finalizar reporte
	cerrarModalFinalizarReporte = (accion: number) => {
		this.setState({ showmodalfinalizarreporte: !this.state.showmodalfinalizarreporte });
		if (accion == 1) {
			this.setState({cargando:true})
			this.actualizarComentarios()
			localStorage.setItem("accion", "3");
			this.componentDidMount();
		}
	}

	/////////////////////////////// Sección de publicar comentario.
	generarComentario = (codigo:number, mensaje:string) => {
		if (codigo == 0) {
			toast.success(mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({cargando:true, visualizarcomentarios:true})
			this.actualizarComentarios()
			this.componentDidMount()
		}
		else if (codigo == 200) {
			toast.info(mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({cargando:true})
			this.componentDidMount()
		}
		else if (codigo == 250) {
			toast.info(mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({cargando:true})
			this.componentDidMount()
		}
		else if (codigo == 1000 || codigo == -1) {
			toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
	}	  


	render() {
		return ( 	
            <div className="divContenedorPrincipal">
				{/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/}
				{this.state.redireccionando == 1 ?
					<>
						{Acciones("accesorestringido")}
					</>
				: this.state.redireccionando == 2 ?
					<>
						{Acciones("documentacionapi")}
					</>
				:null }

				{/*////////// Sección de activación de modales y spinner //////////*/}
				{(this.state.cargando == true) ? (
           			<SpinnerModal tipo="full" show={this.state.cargando}/>
        		) : null}
				{(this.state.showmodalgenerarreporte == true) ? (
					<ModalFormularioReporte isOpen={true} titulomodal={this.state.titulomodal} reporteseleccionado={this.state.reporteseleccionado} accion={this.state.accionmodal} closeModal={this.cerrarModalFormularioReporte}
					token={this.state.perfil.token} idproyecto={this.state.reporte.id_proyecto} idapi={this.state.reporte.id_api}/>
				) : null}

				{(this.state.showmodaleliminarreporte == true) ? (
					<ModalEliminarReporte isOpen={true} accion={this.state.accionmodal} closeModal={this.cerrarModalFormularioEliminarReporte}
					token={this.state.perfil.token} idproyecto={this.state.reporte.id_proyecto} idreporte={this.state.reporte.id_reporte}/>
				) : null}

				{(this.state.showmodalfinalizarreporte == true) ? (
					<ModalFinalizarReporte isOpen={true} closeModal={this.cerrarModalFinalizarReporte} idreporte={this.state.reporte.id_reporte}
					idapi={this.state.reporte.id_api} token={this.state.perfil.token} idproyecto={this.state.reporte.id_proyecto}/>
				) : null}
                <ToastContainer />
                <MenuNavbar/>
                <main>
					{/*////////// Sección de breadscrum //////////*/}
					<Navegacion
						seccion={"reporte"}
						rol={this.state.perfil.role}
						reporte={this.state.reporte.id_reporte}
					/>
					{/*////////// Sección de información del reporte //////////*/}
					<div className="row">
						<Card className="cardSeccion">
							<CardHeader className="cardSeccionHeader">
								<div className="contenedorTituloSeccionDetalles">
									<div className="divTituloSeccion">
										<CgDetailsMore size={23} color="black"/>
										<span className="tituloSeccion">Detalles</span>
									</div>
									{/*////////// Sección de botón para editar el reporte, el cual se oculta dependiendo del rol //////////*/}
										<Dropdown className="dropdownOpcionesDetalles" isOpen={this.state.opciones} onClick={() => this.desplegarOpcionesReporte()}>
											{this.state.id_rol === 1 ? 
												<DropdownToggle data-toggle="dropdown" tag="span">
													<SlOptions size={20} color="black"/>
												</DropdownToggle>
											:(this.state.id_rol === 3 || this.state.id_rol === 4) && this.state.reporte.estado !== "Resuelto"? 
												<DropdownToggle data-toggle="dropdown" tag="span">
													<SlOptions size={20} color="black"/>
												</DropdownToggle>
											:null}
											<DropdownMenu>
												{this.state.id_rol === 1 ? 
													<>
														{this.state.reporte.estado !== "Resuelto" ? 
															<DropdownItem onClick={() => this.verModalFormularioReporte(2, this.state.reporte)}>
																<HiPencil size={15} color="white" /> Editar reporte
															</DropdownItem>
														:null}
														<DropdownItem onClick={() => this.verModalFormularioEliminarReporte()}>
															<RiDeleteBinFill size={15} color="white" /> Eliminar reporte
														</DropdownItem>
													</>
												:(this.state.id_rol === 3 || this.state.id_rol === 4) && this.state.reporte.estado !== "Resuelto"? 
													<>
														<DropdownItem onClick={() => this.verModalFormularioReporte(2, this.state.reporte)}>
															<HiPencil size={15} color="white" /> Editar reporte
														</DropdownItem>
													</>
												:null}
											</DropdownMenu>
										</Dropdown>
								</div>
							</CardHeader>
							<CardBody className="divContenidoCardBody">
								<div className="row">
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<table className="tableContenidoDetalles">
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Titulo del reporte:</span>
												</td>
												<td className="col-8">
													<span className="tituloProyecto">{this.state.reporte.titulo_reporte}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Fecha de generación:</span>
												</td>
												<td className="col-8">
													<span className="tituloProyecto">{this.state.reporte.fecha_generacion}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Fecha límite:</span>
												</td>
												<td className="col-8">
													<span className="tituloProyecto">{this.state.reporte.fecha_entrega_formateada}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Tiempo de entrega:</span>
												</td>
												<td className="col-8">
													<span className="tituloProyecto">{this.state.reporte.tiempo_faltante}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Estado:</span>
												</td>
												<td className="col-8">
													{this.state.reporte.estado}
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Importancia:</span>
												</td>
												<td className="col-8">
													<Importancia 
														importancia={this.state.reporte.importancia}
													/>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Generado por:</span>
												</td>
												<td className="col-8">
													<span className="tituloProyecto">{this.state.reporte.nombre_usuario} {this.state.reporte.apellido_paterno} {this.state.reporte.apellido_materno} </span>
												</td>
											</tr>
										</table>
									</div>
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<Evidencias
											cambios={this.state.cambios}
											idrol={this.state.id_rol}
											token={this.state.perfil.token}
											estado={this.state.reporte.estado}
											actualizarComentarios={this.actualizarComentarios}
										/>
									</div>
								</div>
								<div className="row mt-1">
									<div className="col-12">
										<span className="tituloEncabezado">Descripcion:</span>
										<div className="divDescripcion" dangerouslySetInnerHTML={{ __html: this.state.reporte.descripcion_reporte }} />	
									</div>
								</div>
								<div className="row mt-2">
									<div className="col">
										<Button 
											className="botonConIcono botonVerApi"
											color="primary"
											onClick={() => this.redireccionandoApi(this.state.reporte.id_proyecto, this.state.reporte.id_modulo, this.state.reporte.id_submodulo, this.state.reporte.submodulo_general, this.state.reporte.id_api)}>
											<FaEye size={20} color="white"/>&nbsp;Ver Api
										</Button>
										{this.state.reporte.estado === "Completado" && (this.state.id_rol === 1 || this.state.id_rol === 3 || this.state.id_rol === 4) ? 
											<Button className="botonConIcono" color="success" onClick={this.verModalFinalizarReporte}>
												<FaCheck size={20} color="white" />&nbsp;Finalizar
											</Button>
										: null}
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					{/*////////// Sección de comentarios del reporte //////////*/}
					<div className="row mt-3">
						<Card className="cardSeccion">
							<CardHeader className="cardSeccionHeader">
								<div className="contenedorTituloSeccionDetalles">
									<div className="divTituloSeccion">
										<FaComments size={23} color="black"/>
										<span className="tituloSeccion">Seguimiento</span>
									</div>
								</div>
							</CardHeader>
							<CardBody className="divContenidoCardBody">
								{this.state.visualizarcomentarios === false ? 
										<div className="row">
											<em style={{fontSize:'15px'}}>Aún no hay comentarios o actualizaciones del reporte.</em>
										</div>
										
									:
										<SeccionComentarios 
										idrol={this.state.id_rol}
										token={this.state.perfil.token}
										tipo={"reporte"}
										estado={this.state.reporte.estado}
										cambios={this.state.cambios}
										accion={1}
										actualizarComentarios={this.actualizarComentarios} 										/>
								}
								{this.state.reporte.estado !== "Resuelto" ? 
									<FormularioComentario 
										idrol={this.state.id_rol}
										tipo={"reporte"}
										id={this.state.reporte.id_reporte}
										token={this.state.perfil.token}
										idproyecto={this.state.reporte.id_proyecto}
										estado={this.state.reporte.estado}	
										generarComentario={this.generarComentario} 							
									/>
								:null}
							</CardBody>
						</Card>
					</div>
                </main>
            </div>
        );
    };
}
function Acciones(bandera:string) {
    if(bandera=='documentacionapi'){
		return <Navigate to="/documentacionapi" />;
	}
	else if(bandera=="accesorestringido"){
		return <Navigate to="/catalogoreportes" />;
	}
}
