import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFinalizarReporte.css";
import { postFinalizarReporte } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import { validarLetrasNumerosEspacios } from '../../../Services/Validaciones';

interface props {
  isOpen: boolean,
  closeModal:(accion:number) => void,
  idreporte:number,
  idapi:number,
  idproyecto:number
  token:number
}

interface states {
  isOpen: boolean,
  confirmar:boolean,

  confirmarcomentario:boolean,
  txtcomentario:string,
  txtcomentariolength:number,
  vermsgerrorcomentario:boolean,
  msgerrorcomentario:string
}

export default class ModalFinalizarReporte extends React.Component<props,states>{
  constructor(props:props) {
    super(props);
    this.state = {
      isOpen:this.props.isOpen,
      confirmar:false,

      confirmarcomentario:false,
      txtcomentario:'',
      txtcomentariolength:0,
      vermsgerrorcomentario:false,
      msgerrorcomentario:''
    }
  }

  showModal=(accion:number)=> {
    this.setState({isOpen:false})
    setTimeout(() => {
      this.props.closeModal(accion);
    }, 200);
  }

  //Funcion para cambiar el estado del valor y longitud del comentario.
  escribirComentario = (event: React.ChangeEvent<HTMLInputElement>) => {
    const modelo = event.target.value;
    this.setState({ txtcomentario: modelo, txtcomentariolength: modelo.length });
  };

  finalizarReporte = async () => {
    this.setState({ vermsgerrorcomentario: false});

    let longitudvalidacomentario = {longitudminima: 8, longitudmaxima: 300};

    let txtcomentario = validarLetrasNumerosEspacios(this.state.txtcomentario, longitudvalidacomentario);

    let validacionescorrectas = true;

    if (txtcomentario.codigo == "invalid" && this.state.confirmarcomentario === true) {
      this.setState({ msgerrorcomentario: txtcomentario.mensaje });
      this.setState({ vermsgerrorcomentario: true });
      validacionescorrectas = false;
    }

    if (validacionescorrectas) {
      let json = {
        "token":this.props.token,
        "id_reporte":this.props.idreporte,
        "id_proyecto": this.props.idproyecto,
        "id_api":this.props.idapi,
        "comentario_checkbox":this.state.confirmarcomentario,
        "comentario":this.state.txtcomentario
      }
      const resp = await postFinalizarReporte(json);

      if (resp.code == 0) {
        this.showModal(1)
      }
      else if (resp.code == 200) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 300) {
        toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 1000 || resp == -1) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
    }
  }
    
  render() {
    return (
      <>
        <ToastContainer />
        <Modal isOpen={this.state.isOpen} size="md">
          <ModalHeader className="modalHeader">
            <label className='labelTituloModal'> 
              Finalizar reporte
            </label>
            <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
          </ModalHeader>
          <ModalBody>
            <div className="divContenedorModal">
              <div className="contenidoTextoModal">
                <span>¿Está seguro de finalizar el reporte?</span>
              </div>
              <div className="row mt-1">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <FormGroup className='formContenedor'>
                    <Label className='labelInputModal'>
                      <input type="checkbox" onClick={() => this.setState({ confirmarcomentario: !this.state.confirmarcomentario })} checked={this.state.confirmarcomentario} /> Añadir un comentario acerca de la finalización del reporte. <span className="spanOpcional">(opcional)</span>
                    </Label>
                    <Input
                        type="textarea"
                        className="inputModal"
                        maxLength={300}
                        value={this.state.txtcomentario}
                        onChange={this.escribirComentario}
                        disabled={!this.state.confirmarcomentario} />
                    {this.state.vermsgerrorcomentario
                      ? 
                        <div className="row">
                          <div className="col-8 contenedorMensajeError">
                          <span className="mensajeError">{this.state.msgerrorcomentario}</span>
                          </div>
                          <div className="col-4 contenedorContadorCaracteres">
                          <span className='contadorCaracteres'>{this.state.txtcomentariolength} / 300</span>
                          </div>
                        </div>
                      : 
                        <div className='contenedorContadorCaracteres'>
                          <span className='contadorCaracteres'>{this.state.txtcomentariolength} / 300</span>
                        </div>
                    }
                  </FormGroup>
                </div>
              </div>
              <div className="contenedorCentro mb-2" onClick={() => this.setState({ confirmar: !this.state.confirmar })}>
                <input type="checkbox" checked={this.state.confirmar} /> Confirmar finalización
              </div>
            </div>
            <div className="divContenedorBotonesModal">
              <Button className="btnCancelModal" onClick={() => this.showModal(0)} >Cancelar</Button>
              <Button className="btnAceptarModal"  onClick={this.finalizarReporte} disabled={!this.state.confirmar}>Finalizar</Button>           
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
    