//Interface de la estructura de datos del submódulo
export interface submodulo {
    id_submodulo: number,
    nombre_submodulo: string,
    descripcion_submodulo: string,
    headers_submodulo: string,
    fecha_creacion: string,
    id_modulo: number,
    nombre_modulo:string,
    headers_modulo:string,
    headers_proyecto:string,
    submodulo_general:number,
    id_proyecto:number,
    nombre_proyecto:string,
    apis_completadas: number,
    apis_en_desarrollo: number,
    apis_reportadas: number
}

//Interfaz de la estructura de datos de cada api.
export interface api {
	id_api: number,
	nombre_api: string,
	tipo_solicitud: string,
	formato_cuerpo: string,
	estado:string
}

export const submoduloestado: submodulo = {
    id_submodulo: 0,
    nombre_submodulo: '',
    descripcion_submodulo: '',
    headers_submodulo: '',
    fecha_creacion: '',
    id_modulo: 0,
    nombre_modulo:'',
    headers_modulo:'',
    headers_proyecto:'',
    submodulo_general:0,
    id_proyecto:0,
    nombre_proyecto:'',
    apis_completadas: 0,
    apis_en_desarrollo: 0,
    apis_reportadas: 0
};