import React from "react";

interface props{
    headers_proyecto:string
    headers_modulo:string | null
    headers_submodulo:string | null
    headers_api:string | null
}

const Headers: React.FC<props> = ({ headers_proyecto,headers_modulo,headers_submodulo,headers_api }) => {
    return (
        <>
            <div>
                <span className="tituloHeaders ">Headers:</span>
            </div>
            <div className="divContenedorPrincipalCodigo">
                {(headers_api === null) ? (
                    (headers_submodulo === null) ? (
                        (headers_modulo === null) ? (
                            <>
                                <div className="divContenedorLineasCodigo">
                                    <div>
                                        {headers_proyecto.split("\n").map((line, lineIndex) => {
                                        const lineNumber = lineIndex + 1;
                                        return (
                                            <div key={lineIndex}>
                                                <span>{lineNumber}</span>
                                            </div>
                                        );
                                        })}
                                    </div>
                                </div>
                                <div className="divContenedorCodigo">
                                    <pre className="ql-syntax">
                                        {headers_proyecto}
                                    </pre>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="divContenedorLineasCodigo">
                                    <div>
                                        {headers_modulo.split("\n").map((line, lineIndex) => {
                                        const lineNumber = lineIndex + 1;
                                        return (
                                            <div key={lineIndex}>
                                                <span>{lineNumber}</span>
                                            </div>
                                        );
                                        })}
                                    </div>
                                </div>
                                <div className="divContenedorCodigo">
                                    <pre className="ql-syntax">
                                        {headers_modulo}
                                    </pre>
                                </div>
                            </>
                        )
                    ) : (
                        <>
                            <div className="divContenedorLineasCodigo">
                                <div>
                                    {headers_submodulo.split("\n").map((line, lineIndex) => {
                                    const lineNumber = lineIndex + 1;
                                    return (
                                        <div key={lineIndex}>
                                            <span>{lineNumber}</span>
                                        </div>
                                    );
                                    })}
                                </div>
                            </div>
                            <div className="divContenedorCodigo">
                                <pre className="ql-syntax">
                                    {headers_submodulo}
                                </pre>
                            </div>
                        </>
                    )
                ) : (
                    <>
                        <div className="divContenedorLineasCodigo">
                            <div>
                                {headers_api.split("\n").map((line, lineIndex) => {
                                const lineNumber = lineIndex + 1;
                                return (
                                    <div key={lineIndex}>
                                        <span>{lineNumber}</span>
                                    </div>
                                );
                                })}
                            </div>
                        </div>
                        <div className="divContenedorCodigo">
                            <pre className="ql-syntax">
                                {headers_api}
                            </pre>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
export default Headers