import React, { ChangeEvent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import "./EstilosFormularios.css";

interface props {
    checkbox?:boolean
    label:string,
    txtvalor:string,
    vermsgerror:boolean,
    msgerror:string
    escribir: (prop: string) => void;
    habilitar?: (prop:boolean) => void
}

interface states {
    checkbox?:boolean
    txtvalor:string,
    txtvalorlength:number
}

export default class InputComentario extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            checkbox:this.props.checkbox !== undefined ? this.props.checkbox : true,
            txtvalor:this.props.txtvalor,
            txtvalorlength:this.props.txtvalor ? this.props.txtvalor.length : 0,
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.txtvalor !== this.props.txtvalor) {
            this.setState({ txtvalor: this.props.txtvalor, txtvalorlength:this.props.txtvalor.length });
        }
        if (prevProps.checkbox !== this.props.checkbox) {
            this.setState({ checkbox: this.props.checkbox });
        }
    }

    escribir = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({txtvalor:event.target.value, txtvalorlength:event.target.value.length})
        this.props.escribir(event.target.value)
    };

    checked = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			checkbox: event.target.checked
		});
        this.props.habilitar?.(event.target.checked)
	};

    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    <input type="checkbox" onChange={this.checked} checked={this.state.checkbox} /> {this.props.label} <span className="spanOpcional">(opcional)</span>
                </Label>
                <Input
                    type="textarea"
                    className="inputModal"
                    maxLength={300}
                    value={this.state.txtvalor}
                    onChange={this.escribir}
                    disabled={!this.state.checkbox} />
                {this.props.vermsgerror ?
                    <div className="row">
                        <div className="col-10 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                        <div className="col-2 contenedorContadorCaracteres">
                            <span className='contadorCaracteres'>{this.state.txtvalorlength} / 300</span>
                        </div>
                    </div>
                :
                    <div className='contenedorContadorCaracteres'>
                        <span className='contadorCaracteres'>{this.state.txtvalorlength} / 300</span>
                    </div>
                }
            </FormGroup>
        );
    }
}