import React from "react";
import "./DetallesSolicitud.css";
import { FaCheck, FaCloud, FaInfoCircle } from "react-icons/fa";
import { Button, Card, CardBody, CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { RiDeleteBinFill } from "react-icons/ri";
import { ToastContainer, toast } from 'react-toastify';
import MenuNavbar from "../../Complements/Navbar/Navbar";
import { HiPencil } from "react-icons/hi";
import { FaComments } from 'react-icons/fa';
import {  getListaComentariosSolicitud, getListaSolicitudTieneApi, getSolicitudDetalles } from "../../Services/Api";
import { CgDetailsMore } from "react-icons/cg";
import { SlOptions } from "react-icons/sl";
import { Navigate } from "react-router-dom";
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { IoMdLocate } from "react-icons/io";
import { format, formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import { Tooltip } from "react-tooltip";
import ModalFormularioSolicitud from "../../Complements/Modales/ModalFormularioSolicitud/ModalFormularioSolicitud";
import ModalFinalizarSolicitud from "../../Complements/Modales/ModalFinalizarSolicitud/ModalFinalizarSolicitud";
import ModalEliminarSolicitud from "../../Complements/Modales/ModalEliminarSolicitud/ModalEliminarSolicitud";
import FormularioComentario from "./Componentes/FormularioComentario";
import TablaApis from "./Componentes/TablaApis";
import SeccionUbicacion from "./Componentes/SeccionUbicacion";
import SeccionComentarios from "../../Complements/SeccionComentarios/SeccionComentarios";
import { api, solicitud, solicitudestado } from "./Interfaces";
import Importancia from "../DetallesReporte/Componentes/Importancia";
import Navegacion from "../../Complements/Navegacion/Navegacion";

//Interfaz general del componente.
interface states{
	cambiosapis:boolean,
    isExpanded: boolean,
	id_rol:number,
	salir: boolean,
  	cargando:boolean,
	opciones:boolean,
	perfil:{
	  token:number,
	  name:string,
	  email:string,
	  role:number
	},
	solicitud:solicitud,
	apis:api[],
	visualizarcomentarios:boolean,
	redireccionando:number,

	selectestado:string

	titulomodal:string,
	accionmodal:number,
	showmodalsolicitud:boolean,
	solicitudseleccionada:solicitud,
	showmodaleliminarsolicitud:boolean,
	confirmar:boolean,
	txtcomentario:string,
	txtcomentariolength:number,
	vermsgerrorcomentario:boolean,
    msgerrorcomentario:string,

	habilitarfinalizarsolicitud:boolean,
	showmodalfinalizarsolicitud:boolean
}

export default class DetallesSolicitud extends React.Component<{}, states> {
    constructor(props: {}) {
		super(props);
		this.state = {
			cambiosapis:false,
            isExpanded: false,
			id_rol:0,
            salir:false,
            cargando:true,
			opciones:false,
            perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "","role":""}'),
            solicitud:solicitudestado,
			apis:[],
			visualizarcomentarios:true,
			redireccionando:0,
			selectestado:'',

			titulomodal:'',
			accionmodal:0,
			showmodalsolicitud:false,
			showmodaleliminarsolicitud:false,
			solicitudseleccionada:solicitudestado,
			confirmar:false,
			txtcomentario:'',
			txtcomentariolength:0,
			vermsgerrorcomentario:false,
    		msgerrorcomentario:'',
			
			habilitarfinalizarsolicitud:false,
			showmodalfinalizarsolicitud:false
        }
    }
	
    async componentDidMount() {
		if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
			//this.setState({ redireccionando: true });
        } 

		//Se obtiene el calor del item "id_rol" y se almacena en su correspondiente estado.
		const id_rol = localStorage.getItem("id_rol")
		this.setState({id_rol:Number(id_rol)})
		if(id_rol === "3"){
			this.setState({confirmar:true})
		}

		//Se obtiene el valor de el item "accion", se hacen validaciones y se muestra el mensaje correspondiente,
		const accion = localStorage.getItem("accion");
		if(accion !== null){
			this.mensajesToast(accion);
			localStorage.removeItem("accion");
		}
		
        var id_solicitud = await localStorage.getItem('id_solicitud');
		var id_proyecto = await localStorage.getItem('id_proyecto')
		
		const apisolicitud = await getSolicitudDetalles(this.state.perfil.token,Number(id_solicitud),Number(id_proyecto));
		if(this.respuestaApi (apisolicitud, 0)){
			return;
		}
		this.solicitudFechaModificada(apisolicitud);
	
				
		const apicomentarios = await getListaComentariosSolicitud(this.state.perfil.token,Number(id_solicitud),Number(id_proyecto));
		if(this.respuestaApi (apicomentarios, 1)){
			return;
		}
		
		const apisolicitudtieneapi = await getListaSolicitudTieneApi(this.state.perfil.token,Number(id_solicitud));
		if(this.respuestaApi (apisolicitudtieneapi, 0)){
			return;
		}
		this.setState({apis:apisolicitudtieneapi.data})
		this.habilitarBotonFinalizarSolicitud(apisolicitudtieneapi)
		
		setTimeout(() => {
			this.setState({cargando: false});
		}, 1000);
    }

	//Función para mostrar mensajes toast al iniciar el componente.
	mensajesToast = (accion:string) => {
		if (accion =="1"){
			setTimeout(() => {
				toast.success("Solicitud creada exitósamente!", {autoClose :1000, position:toast.POSITION.TOP_LEFT})
			}) 
		}
		if (accion =="2"){
			setTimeout(() => {
				toast.success("Comentario eliminado", {autoClose :1000, position:toast.POSITION.TOP_LEFT})
			})
		}
		if (accion =="3"){
			setTimeout(() => {
				toast.success("Api eliminada.", {autoClose :1000, position:toast.POSITION.TOP_LEFT})
			})
		}
		if (accion =="Documentacion creada"){
			setTimeout(() => {
				toast.success("Api creada exitósamente!", {autoClose :1000, position:toast.POSITION.TOP_LEFT})
			})
		}
	}

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any, tipo:number) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}
		else if (resp.code == 100) {
			if(tipo === 1){
				this.setState({visualizarcomentarios:false})
			}
			this.setState({ cargando: false })
			return 
		}
		return false
	}

	//Función de regresar
	regresar = () => {
		window.history.back();
	};

	/////////////////////////////// Sección de solicitud.
	//Función para modificar la fecha, trauducirla y aisgnarla.
	solicitudFechaModificada = (solicitud: any) =>{
		const fecha_actual = new Date();
		//Se modifica la fecha de fecha de generación.
		const fecha_generacion = solicitud.data.fecha_generacion; 
		const fecha_gen = new Date(fecha_generacion);
		const fecha_gen_formateada = format(fecha_gen, "d 'de' MMMM 'del' yyyy 'a las' hh:mm a", { locale: es });

		//Se modifica la fecha de fecha de entrega.
		const fecha_entrega = solicitud.data.fecha_entrega;
		const fecha_ent = new Date(fecha_entrega ); 
		const fecha_ent_formateada = format(fecha_ent, "d 'de' MMMM 'del' yyyy 'a las' hh:mm a", { locale: es });

		let tiempo_faltante = formatDistanceToNow(fecha_ent, { locale: es })
		if (fecha_actual > fecha_entrega) {
			tiempo_faltante = "Te pasaste por " + tiempo_faltante;
		}else{
			tiempo_faltante = "Faltan " + tiempo_faltante;
		}
		//Se asigna la nueva fecha.
		solicitud.data.fecha_generacion = fecha_gen_formateada;
		solicitud.data.fecha_ent_formateada = fecha_ent_formateada;
		solicitud.data.tiempo_faltante = tiempo_faltante;
		this.setState({solicitud: solicitud.data, selectestado:solicitud.data.estado});

	}

	//Función para desplegar las opciones de editar y eliminar solicitud.
	desplegarOpcionesSolicitud = () => {
		this.setState({opciones: !this.state.opciones})
	}

	//Función para mostrar formulario para editar solicitud.
	verModalFormularioSolicitud = (accion:number, solicitud: any) => {
		if(accion==2){
			this.setState({ titulomodal:"Editar solicitud", accionmodal:accion, solicitudseleccionada: JSON.parse(JSON.stringify(solicitud))})
		}
		this.setState({ showmodalsolicitud: true})
	}

	//Función para cerrar formulario para editar solicitud.
	cerrarModalFormularioSolicitud = (accion:number) =>{
        this.setState({showmodalsolicitud: !this.state.showmodalsolicitud });
		if(accion==2){
			this.setState({cargando:true, visualizarcomentarios:true,cambiosapis:!this.state.cambiosapis});
			this.componentDidMount();
		}
    }

	//Función para mostrar formulario para eliminar solicitud.
	verModalEliminarSolicitud = () => {
		if(this.state.solicitud.estado !== "Resuelto"){
			this.setState({accionmodal:1})
		}else{
			this.setState({accionmodal:2})
		}
		this.setState({ showmodaleliminarsolicitud: true})
	}

	//Función para cerrar formulario para eliminar solicitud.
	cerrarModalEliminarSolicitud = (accion:number) =>{
		this.setState({showmodaleliminarsolicitud: !this.state.showmodaleliminarsolicitud });
		if(accion==1){
			localStorage.setItem("accion","Solicitud eliminada");
			this.setState({redireccionando:1});
		}
	}

	//Función para habilitar la función de finalizar solicitud.
	habilitarBotonFinalizarSolicitud = (apis:any) => {
		const habilitarBoton = apis.data.some((api: { estado: string, id_api: number }) => api.id_api === 1 || "En desarrollo".includes(api.estado));

		if (habilitarBoton) {
			this.setState({habilitarfinalizarsolicitud:true})
		} else {
			this.setState({habilitarfinalizarsolicitud:false})
		}
	}
	//Función para abrir modal para finalizar solicitud
	verModalFinalizarSolicitud = () => {
		this.setState({ showmodalfinalizarsolicitud: true })
	}

	//Función para cerrar modal para finalizar solicitud
	cerrarModalFinalizarSolicitud = (accion: number) => {
		this.setState({ showmodalfinalizarsolicitud: !this.state.showmodalfinalizarsolicitud });
		if (accion == 1) {
			this.actualizarComentarios()
			this.componentDidMount();
		}
	}

	//Función para actualizar comentarios
	actualizarComentarios = () =>{
		this.setState({visualizarcomentarios:true, cargando:true, cambiosapis:!this.state.cambiosapis})
		this.componentDidMount()
	}

	/////////////////////////////// Sección de publicar comentario.
	generarComentario = (codigo:number, mensaje:string) => {
		if (codigo === 0) {
			toast.success(mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({txtcomentario:'', confirmar:false})
			this.actualizarComentarios()
			this.componentDidMount()
		}
		else if (codigo === 200) {
			toast.info(mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({cargando:true})
			this.componentDidMount()
		}
		else if (codigo === 250) {
			toast.info(mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({cargando:true})
			this.componentDidMount()
		}
		else if (codigo === 1000 || codigo === -1) {
			toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
	}	  

	render() {
		return ( 	
            <div className="divContenedorPrincipal">
				{/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/}
				{this.state.redireccionando == 1 ?
					<>
						{Acciones("catalogo")}
					</>
				:null}
				{/*////////// Sección de activación de modales y spinner //////////*/}
				{(this.state.cargando == true) ? (
           			<SpinnerModal tipo="full" show={this.state.cargando}/>
        		) : null}

				{(this.state.showmodalsolicitud == true) ? (
					<ModalFormularioSolicitud isOpen={true} titulomodal={this.state.titulomodal} solicitudseleccionada={this.state.solicitudseleccionada} accion={this.state.accionmodal}
					closeModal={this.cerrarModalFormularioSolicitud}
					token={this.state.perfil.token} apis={this.state.apis} />
				) : null}
				{(this.state.showmodaleliminarsolicitud == true) ? (
					<ModalEliminarSolicitud isOpen={true} closeModal={this.cerrarModalEliminarSolicitud} idsolicitud={this.state.solicitud.id_solicitud} idproyecto={this.state.solicitud.id_proyecto} accion={this.state.accionmodal} token={this.state.perfil.token}/>
				) : null}

				{(this.state.showmodalfinalizarsolicitud == true) ? (
					<ModalFinalizarSolicitud isOpen={true} closeModal={this.cerrarModalFinalizarSolicitud} idsolicitud={this.state.solicitud.id_solicitud}
					token={this.state.perfil.token} idproyecto={this.state.solicitud.id_proyecto}/>
				) : null}

                <ToastContainer />
                <MenuNavbar/>
                <main>
					{/*////////// Sección de breadscrum //////////*/}
					<Navegacion
						seccion={"solicitud"}
						rol={this.state.perfil.role}
						solicitud={this.state.solicitud.id_solicitud}
					/>
					<div className="row">
						<Card className="cardSeccion">
							<CardHeader className="cardSeccionHeader">
								<div className="contenedorTituloSeccionDetalles">
									<div className="divTituloSeccion">
										<CgDetailsMore size={23} color="black"/>
										<span className="tituloSeccion">Detalles</span>
									</div>
									{/*////////// Sección de botón para editar la solicitud, el cual se oculta dependiendo del rol //////////*/}
									<Dropdown className="dropdownOpcionesDetalles" isOpen={this.state.opciones} onClick={() => this.desplegarOpcionesSolicitud()}>
										{this.state.id_rol === 1 ? 
											<DropdownToggle data-toggle="dropdown" tag="span">
												<SlOptions size={20} color="black"/>
											</DropdownToggle>
										:(this.state.id_rol === 3 || this.state.id_rol === 4) && this.state.solicitud.estado !== "Resuelto"? 
											<DropdownToggle data-toggle="dropdown" tag="span">
												<SlOptions size={20} color="black"/>
											</DropdownToggle>
										:null}
										<DropdownMenu>
											{this.state.id_rol === 1 ? 
												<>
													{this.state.solicitud.estado !== "Resuelto" ? 
														<DropdownItem onClick={() => this.verModalFormularioSolicitud(2, this.state.solicitud)}> 
															<HiPencil size={15} color="white"/> Editar solicitud
														</DropdownItem>
													:null}
													<DropdownItem onClick={() => this.verModalEliminarSolicitud()}> 
														<RiDeleteBinFill size={15} color="white"/> Eliminar solicitud
													</DropdownItem>
												</>
											:(this.state.id_rol === 3 || this.state.id_rol === 4) && this.state.solicitud.estado !== "Resuelto"? 
												<>
													<DropdownItem onClick={() => this.verModalFormularioSolicitud(2, this.state.solicitud)}> 
														<HiPencil size={15} color="white"/> Editar solicitud
													</DropdownItem>
												</>
											:null}
										</DropdownMenu>
									</Dropdown>
								</div>
							</CardHeader>
							<CardBody className="divContenidoCardBody">
								<div className="row">
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<table className="tableContenidoDetalles">
											<tr className="trTabla">
												<td className="col-5">
													<span className="tituloEncabezado">Titulo de la solicitud:</span>
												</td>
												<td className="col-7">
													<span className="tituloProyecto">{this.state.solicitud.titulo_solicitud}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-5">
													<span className="tituloEncabezado">Fecha de generación:</span>
												</td>
												<td className="col-7">
													<span className="tituloProyecto">{this.state.solicitud.fecha_generacion}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-5">
													<span className="tituloEncabezado">Fecha límite:</span>
												</td>
												<td className="col-7">
													<span className="tituloProyecto">{this.state.solicitud.fecha_ent_formateada}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-4">
													<span className="tituloEncabezado">Tiempo de entrega:</span>
												</td>
												<td className="col-8">
													<span className="tituloProyecto">{this.state.solicitud.tiempo_faltante}</span>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-5">
													<span className="tituloEncabezado">Estado:</span>
												</td>
												<td className="col-7">
													{this.state.solicitud.estado}
												</td>
											</tr>
										</table>
									</div>
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<table className="tableContenidoDetalles">
											<tr className="trTabla">
												<td className="col-5">
													<span className="tituloEncabezado">Importancia:</span>
												</td>
												<td className="col-7">
													<Importancia
														importancia={this.state.solicitud.prioridad}
													/>
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-5">
													<span className="tituloEncabezado">Categoria:</span>
												</td>
												<td className="col-7">
													{this.state.solicitud.solicitud_categoria}
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-5">
													<span className="tituloEncabezado">Num. de apis a desarrollar:</span>
												</td>
												<td className="col-7">
													{this.state.solicitud.num_apis}
												</td>
											</tr>
											<tr className="trTabla">
												<td className="col-5">
													<span className="tituloEncabezado">Generado por:</span>
												</td>
												<td className="col-7">
													<span className="tituloProyecto">{this.state.solicitud.nombre_usuario} {this.state.solicitud.apellido_paterno} {this.state.solicitud.apellido_materno} </span>
												</td>
											</tr>
										</table>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<span className="tituloEncabezado">Descripcion:</span>
										<div className="divDescripcion" dangerouslySetInnerHTML={{ __html: this.state.solicitud.descripcion_solicitud }} />	
									</div>
								</div>
								<div className="row mt-2">
									<div className="col">
										{this.state.solicitud.estado === "Completado" && (this.state.id_rol === 1 || this.state.id_rol === 3 || this.state.id_rol === 4) ? 
											this.state.habilitarfinalizarsolicitud === true ? 
												<div className="divBotonFinalizar">
													<Button className="botonConIcono" color="success" onClick={this.verModalFinalizarSolicitud} disabled={this.state.habilitarfinalizarsolicitud}>
														<FaCheck size={20} color="white" />&nbsp;Finalizar
													</Button>
													<FaInfoCircle className="botonFinalizar iconoBotonFinalizar" size={20} color="orange"/>
													<Tooltip anchorSelect=".botonFinalizar" className="toolTipDiseño" place="top">
														Hay apis que no están completadas.
													</Tooltip>
												</div> 
											:
												<>
													<Button className="botonConIcono" color="success" onClick={this.verModalFinalizarSolicitud}>
														<FaCheck size={20} color="white" />&nbsp;Finalizar
													</Button>
												</> 
										: null}
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					{/*////////// Sección de ubicación de la solicitud //////////*/}
					<div className="row mt-3">
						<Card className="cardSeccion">
							<CardHeader className="cardSeccionHeader">
								<div className="contenedorTituloSeccionDetalles">
									<div className="divTituloSeccion">
										<IoMdLocate size={23} color="black"/>
										<span className="tituloSeccion">Ubicación</span>
									</div>
								</div>
							</CardHeader>
							<CardBody className="divContenidoCardBody">
								<div className="row">
									<SeccionUbicacion 
										idrol={this.state.id_rol} 
										token={this.state.perfil.token} 
										solicitud={this.state.solicitud} 
										cambios={this.state.cambiosapis} 
										actualizarComentarios={this.actualizarComentarios}/>
								</div>
							</CardBody>
						</Card>
					</div>
					{/*////////// Sección de apis de la solicitud //////////*/}
					<div className="row mt-3">
						<Card className="cardSeccion">
							<CardHeader className="cardSeccionHeader">
								<div className="contenedorTituloSeccionDetalles">
									<div className="divTituloSeccion">
										<FaCloud size={23} color="black"/>
										<span className="tituloSeccion">Apis</span>
									</div>
								</div>
							</CardHeader>
							<CardBody className="divContenidoCardBody">
								<div className="row">
									{this.state.solicitud.solicitud_categoria == "api" ?
										<TablaApis 
											cambios={this.state.cambiosapis}
											idrol={this.state.id_rol}
											estado={this.state.solicitud.estado}
											token={this.state.perfil.token}
											solicitud={this.state.solicitud}
											actualizarComentarios={this.actualizarComentarios}	
										/>
									:this.state.solicitud.solicitud_categoria == "modulo/api" ?
										this.state.solicitud.id_submodulo == 1 ? 
											<div className="d-flex justify-content-center">
												<em>Primero crea el módulo que se solicita</em>
											</div>
										:
											<TablaApis
												cambios={this.state.cambiosapis}
												idrol={this.state.id_rol}
												estado={this.state.solicitud.estado}
												token={this.state.perfil.token}
												solicitud={this.state.solicitud}	
												actualizarComentarios={this.actualizarComentarios}
											/>
									:this.state.solicitud.solicitud_categoria == "submodulo/api" ?
										this.state.solicitud.submodulo_general == 1 ? 
												<div className="d-flex justify-content-center">
													<em>Primero crea el submódulo que se solicita</em>
												</div>
											:
												<TablaApis 
													cambios={this.state.cambiosapis}
													idrol={this.state.id_rol}
													estado={this.state.solicitud.estado}
													token={this.state.perfil.token}
													solicitud={this.state.solicitud}
													actualizarComentarios={this.actualizarComentarios}
												/>
									:null}
								</div>
							</CardBody>
						</Card>
					</div>
					{/*////////// Sección de comentarios del reporte //////////*/}
					<div className="row mt-3">
						<Card className="cardSeccion">
							<CardHeader className="cardSeccionHeader">
								<div className="contenedorTituloSeccionDetalles">
									<div className="divTituloSeccion">
										<FaComments size={23} color="black"/>
										<span className="tituloSeccion">Seguimiento</span>
									</div>
								</div>
							</CardHeader>
							<CardBody className="divContenidoCardBody">
								{this.state.visualizarcomentarios === false ? 
										<div className="row">
											<em style={{fontSize:'15px'}}>Aún no hay comentarios o actualizaciones de la solicitud.</em>
										</div>
									:
										<SeccionComentarios 
											idrol={this.state.id_rol}
											token={this.state.perfil.token}
											tipo={"solicitud"}
											estado={this.state.solicitud.estado}
											cambios={this.state.cambiosapis}
											accion={2}
											actualizarComentarios={this.actualizarComentarios} 										
										/>
								}
								{this.state.solicitud.estado !== "Resuelto" ? 
									<FormularioComentario 
										idrol={this.state.id_rol}
										tipo={"solicitud"}
										id={this.state.solicitud.id_solicitud}
										token={this.state.perfil.token}
										idproyecto={this.state.solicitud.id_proyecto}
										estado={this.state.solicitud.estado}	
										generarComentario={this.generarComentario} 							
									/>
								:null}
							</CardBody>
						</Card>
					</div>
                </main>
            </div>
        );
    };
}
function Acciones(bandera:string) {
    if(bandera=="catalogo"){
		return <Navigate to="/catalogosolicitudes" />;
	}
}
