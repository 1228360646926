import React from "react";
import { Input, Button, FormGroup, Label, InputGroup, InputGroupText } from 'reactstrap';
import { Navigate } from "react-router-dom";
import { getListaProyectos } from "../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import Paginacion, { paginarElementos } from '../../Complements/Paginacion/Paginacion';
import './CatalogoProyectos.css'
import MenuNavbar from "../../Complements/Navbar/Navbar";
import { format } from "date-fns";
import { es } from 'date-fns/locale';
import { IoMdAdd } from "react-icons/io";
import Proyecto from "./Componentes/Proyecto";
import { proyecto } from "./Interfaces";
import Navegacion from "../../Complements/Navegacion/Navegacion";
import { HiMagnifyingGlass } from "react-icons/hi2";

//Interfaz general del componente.
interface states{
	salir: boolean,
	cargando:boolean,
	perfil:{
		token:number,
        name:string,
		email:string,
		role:number
	},
	txtbuscador:string,
	visualizarproyectos:boolean,
	proyectos:proyecto[],
	proyectosfiltrados:proyecto[],
	proyectospaginados:proyecto[],
	proyectosfiltradospaginados:proyecto[],
	url:string,
	//Estados de paginación
	paginaactiva: number,
	itemsporpagina: number,
	totalpaginas:number
	totalpaginasfiltradas:number,

	dimensiones:number,
	clasecard:string,
	redireccionando:number
}

export default class Dashboard extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
			salir: false,
			cargando:true,
			perfil:JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "","role":""}'),
			txtbuscador:"",
			visualizarproyectos:true,
			proyectos:[],
			proyectosfiltrados:[],
			proyectospaginados:[],
			proyectosfiltradospaginados:[],

			url:'https://www.apibox.izttra.com/apibox-api/',
			//url:'http://localhost/apibox-api/',

			//Estados de paginación
			paginaactiva: 1,
			itemsporpagina: 20,
			totalpaginas:0,
			totalpaginasfiltradas:0,

			dimensiones:window.innerWidth,
			clasecard:'',
			redireccionando:0,
			};
    }

	async componentDidMount() {
		/* Si no existen datos del usuario almacenados en localStorage, se interpretará que no ha iniciado sesión
		y se redireccionará automáticamente al login. */
		if (await localStorage.getItem('acceso') == null || await localStorage.getItem('acceso') == undefined || await localStorage.getItem('acceso') == '') {
            this.setState({ redireccionando: 3 })
        }

		const accion = localStorage.getItem("accion");
		if(accion !== null){
			this.mensajesToast(accion);
			localStorage.removeItem("accion");
		}

		if ( !this.state.cargando ) {
			this.setState({ cargando: true });
		}

        const apiproyectos = await getListaProyectos(this.state.perfil.token);
		if(this.respuestaApi (apiproyectos)){
			return;
		}

		this.proyectosFechaModificada(apiproyectos);
		this.cambiarPagina(1);
		this.claseCards(this.state.dimensiones)

		setTimeout(() => {
			this.setState({cargando: false });
		}, 1000);

    }

	//Función para mostrar mensajes toast al iniciar el componente.
	mensajesToast =  (accion:string) => {
		/*
			Se obtiene el valor de el item "accion", se hacen validaciones y se muestra el mensaje correspondiente,
			Las validaciones son:
				1 = Proyecto eliminado
				300 = Acceso denegado
				100 = Error
		*/
		if (accion === "204") {
			setTimeout(() => {
				toast.success("Proyecto eliminado exitosamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
		else if(accion === "300"){
			setTimeout(() => {
				toast.warning("No tienes acceso al proyecto.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
		else if(accion === "Sin permisos"){
			setTimeout(() => {
				toast.warning("No tienes los permisos necesarios.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
		else if(accion === "100"){
			setTimeout(() => {
				toast.warning("Se ha producido un error.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
	}

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return
		}else if (resp.code == 100) {
			this.setState({visualizarproyectos:false})
			this.setState({ cargando: false })
			return
		}
		return false
	}

	/////////////////////////////// Sección de proyectos
	//Función para modificar la fecha, trauducirla y aisgnarla.
	proyectosFechaModificada = (proyectos:any) =>{
		const proyectosfechamodificada = proyectos.data.map((proyecto:any) => {
			//Se modifica la fecha de fecha de generación.
			const fecha_generacion = proyecto.fecha_creacion + " 00:00:00";
			const fecha_gen = new Date(fecha_generacion);
			//Se traduce.
			const fecha_creacion = format(fecha_gen, "d 'de' MMMM 'del' yyyy", { locale: es });

			//Se asigna la nueva fecha.
			return { ...proyecto, fecha_creacion };
		});

		//Se cambia el estado de proyectos con los proyectos con la fecha modificada.
		this.setState({proyectos:proyectosfechamodificada})
	}

	//Función para asignar clase dependiento de las dimensiones de la pantalla.
	claseCards=(dimensiones:number)=>{
		if (dimensiones <= 1358) {
			this.setState({clasecard:'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-4',itemsporpagina:20})
		}
		else{
			this.setState({clasecard:'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-4',itemsporpagina:20})
		}
	}

	//Función para paginar los proyectos y cambiar de página.
	cambiarPagina = (numeroPagina:number) =>{
		this.setState({
			paginaactiva: numeroPagina,
			proyectosfiltradospaginados: [],
			proyectospaginados: []
		});
		setTimeout(() => {
			this.setState({
				paginaactiva: numeroPagina,
				proyectospaginados: paginarElementos(numeroPagina,this.state.itemsporpagina,  this.state.proyectos),
				totalpaginas:Math.ceil(this.state.proyectos.length / this.state.itemsporpagina),
				proyectosfiltradospaginados: paginarElementos(numeroPagina,this.state.itemsporpagina, this.state.proyectosfiltrados)
			});
		}, 0);
	}

	//Función para buscar proyectos.
	buscarProyecto = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ txtbuscador: e.target.value });
		if (e.target.value.trim().length > 2) {
			var data = JSON.parse(JSON.stringify(this.state.proyectos))

			const filtros = data.filter((data1:any) => data1.nombre_proyecto.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()));

			if (filtros.length < 1) {
				toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			this.filtro(1, filtros)

		}else{
			this.setState({proyectosfiltradospaginados:[]})
		}
	}

	//Función obtener resultado de la busqueda y paginarlos.
	filtro = (numeroPagina:number, array:any) => {
		this.setState({
			paginaactiva: numeroPagina,
			proyectosfiltradospaginados: [],
			totalpaginasfiltradas:0
		});
		setTimeout(() => {
			this.setState({
				proyectosfiltrados: array,
				paginaactiva: numeroPagina,
				proyectosfiltradospaginados: paginarElementos(numeroPagina,this.state.itemsporpagina, array),
				totalpaginasfiltradas:Math.ceil(this.state.proyectosfiltrados.length / this.state.itemsporpagina)
			});
		}, 0);
	}

	//Función para redireccionar al formulario para crear un proyecto
	crearProyecto = () => {
		localStorage.setItem("accion", "1");
		this.setState({ redireccionando:1 })
	}

	itemsPorPagina = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const items = Number(e.target.value);
		this.setState({itemsporpagina:items});
		this.cambiarPagina(1);
	}

	//Función para redireccionar a un proyecto seleccionado.
	redireccionando = (id_proyecto:number, id_rol:number) => {
		localStorage.setItem("id_proyecto", id_proyecto.toString());
		localStorage.setItem("id_rol", id_rol.toString());
		this.setState({ redireccionando:2 })
	}

	
	render() {
		return ( 	
			<>
				{/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/ }
				{(this.state.salir == true) ? 
					<>
						{Acciones("salir")}
					</>
				: null}
				{this.state.redireccionando == 1 ?
					<>
						{Acciones("crearproyecto")}	
					</>
				: this.state.redireccionando == 2 ?
					<>
						{Acciones("detallesproyecto")}
					</>
				: this.state.redireccionando == 3 ?
					<>
						{Acciones("salir")}
					</>
				:null}

				{/*////////// Sección de activación de modales y spinner //////////*/}
				{(this.state.cargando == true) ?
					<SpinnerModal tipo="full" show={this.state.cargando}/>
				:null}
				<ToastContainer />
				<MenuNavbar/>
				<main>
					{/*////////// Sección de breadcrumb //////////*/ }
					<Navegacion
						seccion="proyectos"
					/>
					<div className="row">
						{/*////////// Sección de buscador de proyectos //////////*/}
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
							<InputGroup>
								<Input 
									type="text" 
									className="inputBuscador"
									placeholder="Buscar por nombre"
									onChange={this.buscarProyecto} 
									value={this.state.txtbuscador} 
								/>
								<InputGroupText className="logoBuscador">
									<HiMagnifyingGlass />
								</InputGroupText>
							</InputGroup>
						</div>
						{/*////////// Sección de botón para crear proyecto, el cual se oculta dependiendo del rol //////////*/}
						{this.state.perfil.role == 1 &&
							<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 divContenedorBotones ">
								<div className="divBotonCrear">
									<Button 
										className=" botonIconoCrear"
										color="success"
										onClick={this.crearProyecto} >
										<IoMdAdd size={20} color="white"/>&nbsp;Agregar proyecto
									</Button>
								</div>
							</div>
						}
					</div>
					<div className="row mt-2">
						<FormGroup className='formSelectFiltro'>
							<Label className='labelFiltro'>
								Mostrar&nbsp;
							</Label>
							<select className="selectItemsPorPagina" value={this.state.itemsporpagina} onChange={this.itemsPorPagina}>
								<option value={5}>5</option>
								<option value={10}>10</option>
								<option value={20}>20</option>
								<option value={50}>50</option>
								<option value={100}>100</option>
							</select>
						</FormGroup>
					</div>
					{/*////////// Sección de catalogo de proyectos //////////*/}
					{this.state.visualizarproyectos === false ? 
						<div className="row mt-4">
							<div className="d-flex justify-content-center">
								<em>No tienes proyectos asignados.</em>
							</div>
						</div>
					:
						<>
							<div className="row">
								{this.state.txtbuscador.length < 3 ?
									this.state.proyectospaginados.map((proyecto, index) => (
										<Proyecto 
											key={index}
											clase={this.state.clasecard}
											proyecto={proyecto}
											url={this.state.url}
											tipo={true} verproyecto={() => this.redireccionando(proyecto.id_proyecto, proyecto.id_rol)}											
										/>
									))
									:this.state.proyectosfiltradospaginados.map((proyecto, index) => (
										<Proyecto 
											key={index}
											clase={this.state.clasecard}
											proyecto={proyecto}
											url={this.state.url}
											tipo={true} verproyecto={() => this.redireccionando(proyecto.id_proyecto, proyecto.id_rol)}											
										/>
									)
								)}
							</div>
							{/*////////// Sección de paginación //////////*/}
							<div className="row mt-3">
								{this.state.txtbuscador.length < 3 ?
									<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaactiva}
										itemsPorPagina={this.state.itemsporpagina} totalPaginas={this.state.totalpaginas} tamañoPaginacion="md" />
									:
									<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaactiva}
										itemsPorPagina={this.state.itemsporpagina} totalPaginas={this.state.totalpaginasfiltradas} tamañoPaginacion="md" />}
							</div>
						</>
					}
				</main>
			</>
		);
	};
}
//Función para redireccionar a diferentes páginas mediante condiciones del valor recibida
function Acciones(bandera:string) {
	if(bandera=='crearproyecto'){
		return <Navigate to="/GuardarProyecto" />;
	}else if (bandera == 'detallesproyecto') {
		return <Navigate to="/proyecto" />;
    } else if (bandera == 'salir') {
        localStorage.clear();
		return <Navigate to="/" />;
    }
}