import React from "react";
import { Badge } from "reactstrap";

interface props{
    importancia:string
}

const Importancia: React.FC<props> = ({ importancia }) => {
    return (
        <>
            {importancia == 'Urgente' ?
                <Badge className="badgeUrgente" pill>
                    {importancia}
                </Badge>
            :importancia == 'Muy alta' ?
                <Badge className="badgeMuyAlta" pill>
                    {importancia}
                </Badge>
            :importancia == 'Alta' ?
                <Badge className="badgeAlta" pill>
                    {importancia}
                </Badge>
            :importancia == 'Media' ?
                <Badge className="badgeMedia" pill>
                    {importancia}
                </Badge>
            :importancia == 'Baja' ?
                <Badge className="badgeBaja" pill>
                    {importancia}
                </Badge>
            :null}
        </>
    )
}
export default Importancia