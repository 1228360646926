import React, { ChangeEvent } from "react";
import { ejemplosalida } from "../Interfaces";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";
import { IoMdClose } from "react-icons/io";
import { BiPlus } from "react-icons/bi";

interface props{
    ejemplossalidas:ejemplosalida[]
    ejemplos: (ejemplos:ejemplosalida[]) => void;
}

interface states{
	ejemplossalidas: ejemplosalida[];

}

export class FormularioEjemplosSalidas extends React.Component<props, states> {
    constructor(props: props) {
		super(props);
		this.state = {
            ejemplossalidas:this.props.ejemplossalidas
        }
    } 

    componentDidUpdate(prevProps: props) {
        if (prevProps.ejemplossalidas !== this.props.ejemplossalidas) {
            this.setState({ ejemplossalidas: this.props.ejemplossalidas });
        }
    }

/////////////////////////////// Sección de placeholders
//Placeholder de input de modelo Json.
palceholderModeloJson = `{
    'name': 'string',
    'age': 'number',
    'interests': 'array',
    'active': 'boolean',
    'address': 'object',
    'scores': 'array of numbers',
    'description': 'string',
    'preferences': 'object'
}`;
//Placeholder de input de ejemplo Json.
placeholderEjemploJson = `{
    'name': 'John Doe',
    'age': 30,
    'interests': ['React', 'JavaScript'],
    'active': true,
    'address': {
        'street': '123 Main St',
        'city': 'Exampleville'
    },
    'scores': [95, 87, 92],
    'description': 'This is a sample description.',
    'preferences': {
        'theme': 'light',
        'notifications': true
    }
}`;

    /////////////////////////////// Sección de salidas
	//Función para agregar ejemplo de salida.
	agregarEjemploSalida = () => {
		const  ejemplossalidas  = this.state.ejemplossalidas;
		const nuevoEjemploSalida: ejemplosalida = {
            id_ejemplos_salidas:0,
			nombre_ejemplo_salida: '',
			nombre_ejemplo_salidalength:0,
			txtnombreejemplosalida:'',
			vermsgerrornombreejemplosalida:false,
			msgerrornombreejemplosalida:'',
			ejemplo_salida: '',
			ejemplo_salidalength:0,
			txtejemplosalida:'',
			vermsgerrorejemplosalida:false,
			msgerrorejemplosalida:''
			
		};
		if (ejemplossalidas.length < 10) {
            const ejemplosSalidasActualizado = [...ejemplossalidas, nuevoEjemploSalida];
			this.setState({ ejemplossalidas: ejemplosSalidasActualizado });
            this.props.ejemplos(ejemplosSalidasActualizado)
        } else {
            toast.warning("No puedes agregar más de 10 ejemplos.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
	};

	//Función para actualizar el estado de los inputs de nombre de cada ejemplo de salida.
	actualizarNombreEjemploSalida =(event: ChangeEvent<HTMLInputElement>, ejemploIndex: number) => {
		const  ejemplossalidas  = this.state.ejemplossalidas;
		const ejemplosActualizados = ejemplossalidas.map((ejemplo, index) => {
		  if (index === ejemploIndex) {
			return {
			  ...ejemplo,
			  nombre_ejemplo_salida: event.target.value,
			  nombre_ejemplo_salidalength:event.target.value.length
			};
		  }
		  return ejemplo;
		});
		this.setState({ ejemplossalidas: ejemplosActualizados });
        this.props.ejemplos(ejemplosActualizados)
	};

	//Función para actualizar el estado de los inputs de ejemplo de cada ejemplo de salida.
	actualizarEjemploSalida =(event: ChangeEvent<HTMLInputElement>, ejemploIndex: number) => {
		const  ejemplossalidas  = this.state.ejemplossalidas;
		const ejemplosActualizados = ejemplossalidas.map((ejemplo, index) => {
		  if (index === ejemploIndex) {
			return {
			  ...ejemplo,
			  ejemplo_salida: event.target.value,
			  ejemplo_salidalength:event.target.value.length
			};
		  }
		  return ejemplo;
		});
		this.setState({ ejemplossalidas: ejemplosActualizados });
        this.props.ejemplos(ejemplosActualizados)
	};

	//Función para eliminar un ejemplo de salida.
	eliminarEjemploSalida = (ejemploIndex: number) => {
		const ejemplossalidas = this.state.ejemplossalidas;
		if (ejemploIndex !== 0) {
			const ejemplosActualizados = ejemplossalidas.filter((ejemplo, index) => index !== ejemploIndex);
			this.setState({ ejemplossalidas: ejemplosActualizados });
            this.props.ejemplos(ejemplosActualizados)
		}
	};

    //Función para insertar tabulación dentro de los textarea
	teclaTab = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Tab") {
			const textarea = event.target as HTMLTextAreaElement;

			//Se obtiene la posición del cursor dentro del textarea
			const cursor = textarea.selectionStart;
		
			//Inserta la tabulación en la posición del cursor
			textarea.value = textarea.value.slice(0, cursor) + "\t" + textarea.value.slice(cursor);
		
			//Mueve el cursor a su posición
			textarea.setSelectionRange(cursor + 1, cursor + 1);
		
			//Evita que al presionar tab se cambie de elemento como al botón u otro input.
			event.preventDefault();
		}
	};

    render() {
		return(
            <>
                {this.state.ejemplossalidas.map((ejemplo, index) => (
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-1" key={index}>
                        <div className="row">
                            <div className="col-11">
                                <Label className='labelInputModal'>
                                    Nombre de ejemplo {index + 1} de salida <span className="spanObligatorio">*</span>
                                </Label>
                            </div>
                            <div className="col-1 divContenedorBotonEliminarEjemplo">
                                {(index !== 0 && ejemplo.id_ejemplos_salidas === 0) && (
                                    <button className="botonEliminarEjemplo" onClick={() => this.eliminarEjemploSalida(index)}>
                                        <IoMdClose size={25} color="white"/>
                                    </button>
                                )}
                            </div>
                            <div className="col-12">
                                <Input
                                    className="inputModal"
                                    type="text" 
                                    placeholder="Ej. Ejemplo 1"
                                    maxLength={30}
                                    onChange={(event) => this.actualizarNombreEjemploSalida(event, index)}
                                    value={ejemplo.nombre_ejemplo_salida}/>
                                {ejemplo.vermsgerrornombreejemplosalida
                                    ? 
                                        <div className="row">
                                            <div className="col-9 contenedorMensajeError">
                                                <span className="mensajeError">{ejemplo.msgerrornombreejemplosalida}</span>
                                            </div>
                                            <div className="col-3 contenedorContadorCaracteres">
                                                <span className='contadorCaracteres'>{ejemplo.nombre_ejemplo_salidalength} / 30</span>
                                            </div>
                                        </div>
                                    : 
                                        <div className="row">
                                            <div className="col-9 contenedorMensajeError">
                                                <span className="mensajeError">{ejemplo.msgerrornombreejemplosalida}</span>
                                            </div>
                                            <div className="col-3 contenedorContadorCaracteres">
                                                <span className='contadorCaracteres'>{ejemplo.nombre_ejemplo_salidalength} / 30</span>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <Label className='labelInputModal'>
                            Ejemplo {index + 1} de salida <span className="spanObligatorio">*</span>
                        </Label>
                        <Input
                            className="textAreaEjemplo"
                            type="textarea"
                            maxLength={3000}
                            placeholder={this.placeholderEjemploJson}
                            onChange={(event) => this.actualizarEjemploSalida(event, index)}
                            value={ejemplo.ejemplo_salida}
                            onKeyDown={this.teclaTab}/>
                            {ejemplo.vermsgerrorejemplosalida
                                ? 
                                    <div className="row">
                                        <div className="col-9 contenedorMensajeError">
                                            <span className="mensajeError">{ejemplo.msgerrorejemplosalida}</span>
                                        </div>
                                        <div className="col-3 contenedorContadorCaracteres">
                                            <span className='contadorCaracteres'>{ejemplo.ejemplo_salidalength} / 3000</span>
                                        </div>
                                    </div>
                                : 
                                    <div className="row">
                                        <div className="col contenedorContadorCaracteres">
                                            <span className='contadorCaracteres'>{ejemplo.ejemplo_salidalength} / 3000</span>
                                        </div>
                                    </div>
                            }
                        <div>
                        </div>
                    </div>
                ))}
                {this.state.ejemplossalidas.length < 10 ? 
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 divAgregarEjemplo">
                        <button className="divContendorAgregarEjemplo" type="button" onClick={this.agregarEjemploSalida}>
                            <BiPlus size={30} color="gray"/>
                            <span>Agregar ejemplo</span>  
                        </button>
                    </div>
                :null}
            </>
        )
    }
}