import React from "react";
import { FaExclamationCircle, FaFileAlt, FaCopy, FaArrowRight, FaArrowLeft, FaQuestion, FaExclamation, FaHistory, FaEye } from "react-icons/fa";
import { Card, CardBody, Badge, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MenuNavbar from "../../Complements/Navbar/Navbar";
import { getReporteApi, getSolicitudApi, getUltimaModifiacionApi, postDetallesApi } from "../../Services/Api";
import ModalFormularioReporte from "../../Complements/Modales/ModalFormularioReporte/ModalFormularioReporte";
import './DocumentacionApi.css'
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { HiPencil } from "react-icons/hi";
import { RiArrowUpDownLine, RiDeleteBinFill } from "react-icons/ri";
import { SlOptions } from "react-icons/sl";
import { IoIosWarning } from "react-icons/io";
import ModalEliminarEjemplo from "../../Complements/Modales/ModalEliminarEjemplo/ModalEliminarEjemplo";
import { format } from "date-fns";
import { Tooltip } from "react-tooltip";
import { es } from "date-fns/locale";
import ModalEliminarApi from "../../Complements/Modales/ModalEliminarApi/ModalEliminarApi";
import ModalFormularioMigrarApi from "../../Complements/Modales/ModalFormularioMigrarApi/ModalFormularioMigrarApi";
import { api, apiestado } from "./Interfaces";
import EjemplosEntradas from "./Componentes/EjemplosEntradas";
import EjemplosSalidas from "./Componentes/EjemplosSalidas";
import EjemplosExcepciones from "./Componentes/EjemplosExcepciones";
import Headers from "../../Complements/Headers/Headers";
import Navegacion from "../../Complements/Navegacion/Navegacion";
import Colaboradores from "./Componentes/Colaboradores";
import { reporte } from "../CatalogoReportes/Interfaces";

//Interfaz general del componente.
interface states{
    id_rol:number,
    salir:boolean,
    cargando:boolean,
    perfil:{
		token:number,
        name:string,
		email:string,
        role:number
  	},
    api:api,
    idreporte:number,
    ultimamodificacion:React.ReactNode,
    opcionesinformacion:boolean,
    opcionesentradas:boolean,
    opcionessalidas:boolean,
    opcionesexcepciones:boolean,

    idejemplo:number,

    //Reportes
    showmodalgenerarreporte:boolean,
    showmodaleliminarapi:boolean,
    showmodalmigrarcopiarapi:boolean,
	titulomodal:string,
	accionmodal:number,

    showmodaleliminarejemplo:boolean,
    redireccionando:number,

    showmodalformularionota:boolean,
    cambios:boolean,
    visualizarreportes:boolean
    reportes:reporte[],

    solicitud:boolean

}
export default class DocumentacionApi extends React.Component<{}, states> {
    constructor(props: {}) {
		super(props);
		this.state = {
          id_rol:0,
		  salir:false,
		  cargando:true,
          perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "", "role":""}'),
          api:apiestado,
          idreporte:0,
          ultimamodificacion:'',
          opcionesinformacion:false,
          opcionesentradas:false,
          opcionessalidas:false,
          opcionesexcepciones:false,

          idejemplo:0,

          //Reportes
          showmodalgenerarreporte:false,
          showmodaleliminarapi:false,
          showmodalmigrarcopiarapi:false,
          titulomodal:'',
          accionmodal:0,

          showmodaleliminarejemplo:false,
          redireccionando:0,

          showmodalformularionota:false,
          cambios:false,
          visualizarreportes:false,
          reportes:[],

          solicitud:false
        };
	}

	async componentDidMount() {
		if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
			//this.setState({ redireccionando: true });
        } 

        //Se obtiene el calor del item "id_rol" y se almacena en su correspondiente estado.
		const id_rol = localStorage.getItem("id_rol")
		this.setState({id_rol:Number(id_rol)})

		if ( !this.state.cargando ) {
			this.setState({ cargando: true });
		}

        const accion = localStorage.getItem("accion");
        if(accion !== null){
			this.mensajesToast(accion);
            localStorage.removeItem("accion");
		}
        
		var id_proyecto = await localStorage.getItem('id_proyecto');
		var id_api = await localStorage.getItem('id_api');

        const apiapi = await postDetallesApi(this.state.perfil.token,Number(id_proyecto),Number(id_api));
        if(this.respuestaApi (apiapi)){
			return;
		}
        this.apiFechaModificada(apiapi)

        const reporte = await getReporteApi(this.state.perfil.token, Number(id_proyecto),Number(id_api));
        if(this.respuestaApi (reporte)){
			return;
		}
        if(reporte.data.id_reporte !== null){
            this.setState({idreporte:reporte.data.id_reporte})
        }

        const solicitud = await getSolicitudApi(this.state.perfil.token, Number(id_proyecto),Number(id_api));
        if(this.respuestaApi (reporte)){
			return;
		}
        this.visualizarBotonSolicitud(solicitud);
        const ultimamodificacion = await getUltimaModifiacionApi(this.state.perfil.token, Number(id_api));
        if(this.respuestaApi (ultimamodificacion)){
			return;
		}
        this.ultimaModificacion(ultimamodificacion.data)

		setTimeout(() => {
			this.setState({cargando: false});
		}, 1000);
    }

    //Función para mostrar mensajes toast al iniciar el componente.
	mensajesToast =  (accion:string) => {
        if (accion === "Documentacion creada") {
			toast.success("Documentación creada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		if (accion === "1") {
			toast.success("Se modificó la información general.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			
		}
		if (accion === "2") {
            this.setState({cambios:!this.state.cambios})
			toast.success("Se modificaron las entradas.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
        if (accion === "3") {
			toast.success("Se eliminó el ejemplo de entrada.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
        if (accion === "4") {
			setTimeout(() => {
				toast.success("Se modificaron las salidas.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
        if (accion === "5") {
			setTimeout(() => {
				toast.success("Se eliminó el ejemplo de salida.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
        if (accion === "6") {
			setTimeout(() => {
				toast.warning("No tienes acceso a esta sección.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
        if (accion === "7") {
			setTimeout(() => {
				toast.info("No se realizaron cambios.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
        if (accion === "8") {
			setTimeout(() => {
				toast.success("Se eliminó el ejemplo de excepcion.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
        if (accion === "9") {
			setTimeout(() => {
				toast.success("Se modificaron las excepciones.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}, 1000);
		}
	}

    //Función para gestionar las respuestas negativas de las apis.
    respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:9})
			return 
		}
		return false
	}

     //Función para desplegar la lista de opciones de información general, entradas y salidas.
    desplegarOpciones = (accion:number) => {
        if(accion==1){
            this.setState({opcionesinformacion: !this.state.opcionesinformacion})
        }
        else if(accion==2){
            this.setState({opcionesentradas: !this.state.opcionesentradas})
        }
        else if(accion==3){
            this.setState({opcionessalidas: !this.state.opcionessalidas})
        } 
        else if(accion==4){
            this.setState({opcionesexcepciones: !this.state.opcionesexcepciones})
        }
	};

    //Función de regresar
	regresar = () => {
		window.history.back();
	};
    
    /////////////////////////////// Sección de la api
    //Función para modificar la fecha, trauducirla y aisgnarla.
	apiFechaModificada = (api: any) =>{
		//Se modifica la fecha.
		const fecha_creacion = api.data.fecha_creacion + " 00:00:00"; 
		const fecha = new Date(fecha_creacion);
		const fecha_formateada = format(fecha, "d 'de' MMMM 'del' yyyy", { locale: es });

		//Se asigna la nueva fecha.
		api.data.fecha_creacion = fecha_formateada;
		this.setState({ api: api.data});
	}

    //Función para mostrar tooltip de última modificación.
    ultimaModificacion = (ultimamodificacion: any) => {
        //Se asignan los formatos a la fecha y hora.
       
        const fecha = ultimamodificacion.fecha_mod + " 00:00:00"
        const fechaCompleta = new Date(fecha);
        
        const fechaFormateada = format(fechaCompleta, "d 'de' MMMM 'de' yyyy",{locale:es});
        
        const hora = new Date(`2000-01-01T${ultimamodificacion.hora_mod}`);
        const horaFormateada = format(hora, "hh:mm aaaa");
        //Se crea el mensaje a mostrar y se asigna al estado.
        const mensaje = <>
                            <div className="tooltipContenido">
                                El usuario {ultimamodificacion.nom_usuario} {ultimamodificacion.apellido_p_usuario} {ultimamodificacion.apellido_m_usuario} {ultimamodificacion.detalles_api} el
                            </div>
                            <div className="tooltipContenido">
                                {fechaFormateada} a las {horaFormateada}
                            </div>
                        </>
        this.setState({ultimamodificacion:mensaje})
	}

    //Función para ver el reporte de la api. En caso de que esté reportada.
    verReporte = () => {
        const idreporte = this.state.idreporte
        localStorage.setItem("id_reporte", idreporte.toString());
		this.setState({ redireccionando: 4})
	}

     //Función para ver los reportes de la api
    verReportes = () => {
        const proyecto = this.state.api.id_proyecto;
        const modulo = this.state.api.id_modulo;
        const submodulo = this.state.api.id_submodulo;
        const idapi = this.state.api.id_api;

        localStorage.setItem("proyecto", proyecto.toString());
        localStorage.setItem("modulo", modulo.toString());
        localStorage.setItem("submodulo", submodulo.toString());
        localStorage.setItem("id_api", idapi.toString());
		this.setState({ redireccionando: 10})
	}

    visualizarBotonSolicitud = (solicitud:any) =>{
        if(solicitud.code === 0){
            localStorage.setItem("id_solicitud", solicitud.data.id_solicitud.toString());
            localStorage.setItem("id_proyecto", solicitud.data.id_proyecto.toString());
            this.setState({solicitud:true});
        }else if(solicitud.code === 100){
            this.setState({solicitud:false});
        }
    }

    verSolicitud = () => {
		this.setState({ redireccionando: 11})
	}

    redireccionando = (accion:number) =>{
        this.setState({ redireccionando: accion})
    }

    /////////////////////////////// Sección de reportar api.
    //Función para mostrar modal para reportar la api.
    verModalFormularioReporte = (accion:number, reporte: any) => {
		if(accion==1){
			this.setState({ titulomodal:" Generar reporte", accionmodal:accion})
		}
		this.setState({ showmodalgenerarreporte: true})
	}

    //Función para cerrar modal para reportar la api.
    cerrarModalFormularioReporte = (accion:number) =>{
        this.setState({showmodalgenerarreporte: !this.state.showmodalgenerarreporte });
		if(accion==1){
			this.componentDidMount();
			setTimeout(() => {
				this.setState({cargando: false});
			}, 900);
		}
    }

    /////////////////////////////// Sección de eliminar api.
    //Función para mostrar modal para reportar la api.
    verModalEliminarApi = () => {
		this.setState({ showmodaleliminarapi: true})
	}

    //Función para cerrar modal para reportar la api.
    cerrarModalEliminarApi = (accion:number) =>{
        this.setState({showmodaleliminarapi: !this.state.showmodaleliminarapi });
		if(accion==1){
            if(this.state.api.submodulo_general === 1 ){
                localStorage.setItem("accion", "Documentacion eliminada");
                this.setState({redireccionando:5})
            }
            else if(this.state.api.submodulo_general === 0){
                localStorage.setItem("accion", "Documentacion eliminada");
                this.setState({redireccionando:6})
            }
		}
    }

    /////////////////////////////// Sección de migrar api.
    //Función para mostrar modal para reportar la api.
    verModalMigrarCopiarApi = (accion:number) => {
		this.setState({ showmodalmigrarcopiarapi: true, accionmodal:accion})
	}

    //Función para cerrar modal para reportar la api.
    cerrarModalMigrarApi = (accion:number) =>{
        this.setState({showmodalmigrarcopiarapi: !this.state.showmodalmigrarcopiarapi });
		if(accion === 1){
           localStorage.setItem("accion", "migracion");
           this.setState({redireccionando:5})
		}
        else if(accion === 2){
            localStorage.setItem("accion", "migracion");
            this.setState({redireccionando:6})
        }
        if(accion === 3){
            localStorage.setItem("accion", "copia");
            this.setState({redireccionando:5})
         }
         else if(accion === 4){
             localStorage.setItem("accion", "copia");
             this.setState({redireccionando:6})
         }
    }

    /////////////////////////////// Sección de ejemplos de entradas,salidas y excepciones.
    //Función para mostrar el modal para eliminar un ejemplo de entrada o de salida.
    verModalEliminarEjemplo = (accion:number, id_ejemplo:number) => {
        console.log(accion)
		if(accion==1){
			this.setState({ titulomodal:"Eliminar ejemplo de entrada", accionmodal:accion, idejemplo:id_ejemplo})
		}
        else if(accion==2){
			this.setState({ titulomodal:"Eliminar ejemplo de salida", accionmodal:accion, idejemplo:id_ejemplo})
		}
        else if(accion==3){
			this.setState({ titulomodal:"Eliminar ejemplo de excepción", accionmodal:accion, idejemplo:id_ejemplo})
		}
		this.setState({ showmodaleliminarejemplo: true})
	}

     //Función para cerrar el modal para eliminar un ejemplo de entrada o de salida.
    cerrarModalEliminarEjemplo = (accion:number) =>{
        this.setState({showmodaleliminarejemplo: !this.state.showmodaleliminarejemplo });
		if(accion==1){
            localStorage.setItem("accion", "3");
            this.setState({idejemplo:0, cambios:!this.state.cambios})
			this.componentDidMount();
			setTimeout(() => {
				this.setState({cargando: true});
			}, 900);
		}
        else if(accion==2){
            localStorage.setItem("accion", "5");
            this.setState({idejemplo:0,cambios:!this.state.cambios})
			this.componentDidMount();
			setTimeout(() => {
				this.setState({cargando: true});
			}, 900);
		}
        else if(accion==3){
            localStorage.setItem("accion", "8");
            this.setState({idejemplo:0,cambios:!this.state.cambios})
			this.componentDidMount();
			setTimeout(() => {
				this.setState({cargando: true});
			}, 900);
		}
    }

    copiar = (link:string) =>{
        navigator.clipboard.writeText(link);
        toast.success("Copiado en portapapeles!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

	render() {
		return ( 	
		<div className="divContenedorPrincipal">
            {/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/}
            {this.state.redireccionando == 1 ?
					<>
						{Acciones("infogeneral")}
					</>
				: this.state.redireccionando == 2 ?
					<>
						{Acciones("entradas")}
					</>
                : this.state.redireccionando == 3 ?
					<>
						{Acciones("salidas")}
					</>
                :this.state.redireccionando == 4 ?
                    <>
                        {Acciones("reporte")}
                    </>
                :this.state.redireccionando == 5 ?
                    <>
                        {Acciones("modulo")}
                    </>
                :this.state.redireccionando == 6 ?
                    <>
                        {Acciones("submodulo")}
                    </>
                :this.state.redireccionando == 7 ?
                    <>
                        {Acciones("excepciones")}
                    </>
                :this.state.redireccionando == 8 ?
                    <>
                        {Acciones("registroactividades")}
                    </>
                :this.state.redireccionando == 9 ?
                    <>
                        {Acciones("accesorestringido")}
                    </>
                :this.state.redireccionando == 10 ?
                    <>
                        {Acciones("reportes")}
                    </>
                :this.state.redireccionando == 11 ?
                    <>
                        {Acciones("solicitud")}
                    </>
			:null}
            {/*////////// Sección de activación de modales y spinner //////////*/}
            {(this.state.cargando == true) ? (
           		<SpinnerModal tipo="full" show={this.state.cargando}/>
        	) : null}

            {(this.state.showmodalgenerarreporte == true) ? (
				<ModalFormularioReporte isOpen={true} titulomodal={this.state.titulomodal} accion={this.state.accionmodal} closeModal={this.cerrarModalFormularioReporte} idapi={this.state.api.id_api} 
				token={this.state.perfil.token} idproyecto={this.state.api.id_proyecto}/>
			) : null}

            {(this.state.showmodaleliminarapi == true) ? (
				<ModalEliminarApi isOpen={true} closeModal={this.cerrarModalEliminarApi} idapi={this.state.api.id_api} 
				token={this.state.perfil.token} idproyecto={this.state.api.id_proyecto}/>
			) : null}

            {(this.state.showmodalmigrarcopiarapi == true) ? (
				<ModalFormularioMigrarApi isOpen={true} closeModal={this.cerrarModalMigrarApi} idapi={this.state.api.id_api}
                    token={this.state.perfil.token} idproyecto={this.state.api.id_proyecto} idmodulo={this.state.api.id_modulo}
                    idsubmodulo={this.state.api.id_submodulo} submodulo_general={this.state.api.submodulo_general} 
                    accion={this.state.accionmodal} nombreapi={this.state.api.nombre_api} />
			) : null}

            {(this.state.showmodaleliminarejemplo == true) ? (
				<ModalEliminarEjemplo isOpen={true} closeModal={this.cerrarModalEliminarEjemplo} idproyecto={this.state.api.id_proyecto}
                    titulomodal={this.state.titulomodal} accion={this.state.accionmodal} token={this.state.perfil.token} idejemplo={this.state.idejemplo} 
                />
			) : null}
            <ToastContainer />
            <MenuNavbar/>
		    <main>
                {/*////////// Sección de breadscrum //////////*/}
                <Navegacion
                    seccion={"documentacion-api"}
                    proyecto={this.state.api.nombre_proyecto}
                    modulo={this.state.api.nombre_modulo}
                    submodulo={this.state.api.nombre_submodulo}
                    api={this.state.api.nombre_api}
                    accion={this.state.api.submodulo_general}
                />
                {/*////////// Sección de documentación del la api //////////*/}
                <Card className="cardSeccion">
                    <CardHeader className="cardSeccionHeader">
                        <div className="contenedorTituloSeccionDetalles">
                            <div className="divTituloSeccion">
                                <FaFileAlt size={23} color="black"/>
                                <span className="tituloSeccion">Información general </span>
                            </div>
                            {/*////////// Sección de botón para editar la información general o reportar la api, el cual se oculta dependiendo del rol //////////*/}
                            <Dropdown className="dropdownOpcionesDetalles" isOpen={this.state.opcionesinformacion} onClick={() => this.desplegarOpciones(1)}>
                                <DropdownToggle data-toggle="dropdown" tag="span">
                                    <SlOptions size={20} color="black"/>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {this.state.id_rol === 1 ?
                                        <>
                                            <DropdownItem onClick={() => this.redireccionando(1)}>
                                                <HiPencil size={15} color="white" /> Editar información
                                            </DropdownItem>
                                            <DropdownItem onClick={() => this.verReportes()}>
                                                <FaEye size={15} color="white"/> Ver Reportes
                                            </DropdownItem>
                                            {this.state.api.estado === "Completada" ? 
                                                <>
                                                    <DropdownItem onClick={() => this.verModalEliminarApi()}>
                                                        <RiDeleteBinFill size={15} color="white" /> Eliminar
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => this.verModalMigrarCopiarApi(1)}>
                                                        <RiArrowUpDownLine size={15} color="white" /> Migrar api
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => this.verModalMigrarCopiarApi(2)}>
                                                        <FaCopy size={15} color="white" /> Copiar api
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => this.verModalFormularioReporte(1, null)}>
                                                        <FaExclamationCircle size={15} color="white" /> Reportar
                                                    </DropdownItem>
                                                </>
                                            :null}
                                        </>
                                    :this.state.id_rol === 2 ?
                                        <>
                                            <DropdownItem onClick={() => this.redireccionando(1)}>
                                                <HiPencil size={15} color="white" /> Editar información
                                            </DropdownItem>
                                            <DropdownItem onClick={() => this.verReportes()}>
                                                <FaEye size={15} color="white"/> Ver Reportes
                                            </DropdownItem>
                                            {this.state.api.estado === "Completada" ?
                                                <>
                                                    <DropdownItem onClick={() => this.verModalEliminarApi()}>
                                                        <RiDeleteBinFill size={15} color="white" /> Eliminar
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => this.verModalMigrarCopiarApi(1)}>
                                                        <RiArrowUpDownLine size={15} color="white" /> Migrar api
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => this.verModalMigrarCopiarApi(2)}>
                                                        <FaCopy size={15} color="white" /> Copiar api
                                                    </DropdownItem>
                                                </>
                                            :null}
                                        </>
                                    :this.state.id_rol === 3 ?
                                        <>
                                            <DropdownItem onClick={() => this.verReportes()}>
                                                <FaEye size={15} color="white"/> Ver Reportes
                                            </DropdownItem>
                                            {this.state.api.estado === "Completada" ?
                                                <DropdownItem onClick={() => this.verModalFormularioReporte(1, null)}>
                                                    <FaExclamationCircle size={15} color="white" /> Reportar
                                                </DropdownItem>
                                            :null}
                                        </>
                                    :this.state.id_rol === 4 ?
                                        <>
                                            <DropdownItem onClick={() => this.redireccionando(1)}>
                                                <HiPencil size={15} color="white" /> Editar información
                                            </DropdownItem>
                                            <DropdownItem onClick={() => this.verReportes()}>
                                                <FaEye size={15} color="white"/> Ver Reportes
                                            </DropdownItem>
                                            {this.state.api.estado === "Completada" ? 
                                                <>
                                                    <DropdownItem onClick={() => this.verModalEliminarApi()}>
                                                        <RiDeleteBinFill size={15} color="white" /> Eliminar
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => this.verModalMigrarCopiarApi(1)}>
                                                        <RiArrowUpDownLine size={15} color="white" /> Migrar api
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => this.verModalMigrarCopiarApi(2)}>
                                                        <FaCopy size={15} color="white" /> Copiar api
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => this.verModalFormularioReporte(1, null)}>
                                                        <FaExclamationCircle size={15} color="white" /> Reportar
                                                    </DropdownItem>
                                                </>
                                            :null}
                                        </>
                                    :null}
                                    <DropdownItem onClick={() => this.redireccionando(8)}>
                                        <FaHistory size={15} color="white" /> Registro de actividades
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </CardHeader>
                    <CardBody className="divContenidoCardBody">
                        <div className="row">
                          
                            <div className="divContenedorNombreApi">
                                <span className="tituloNombreApi">{this.state.api.nombre_api}</span>
                                {this.state.api.estado == 'En desarrollo' ?
                                    <Badge color="warning" pill>
                                        {this.state.api.estado}
                                    </Badge>
                                :this.state.api.estado == 'Completada' ?
                                    <Badge color="success" pill>
                                        {this.state.api.estado}
                                    </Badge>
                                :this.state.api.estado == 'Reportada' ?
                                    <>
                                        <Badge color="danger" className="badgeReportada" style={{ marginRight: "8px"}} onClick={this.verReporte} title="Ver reporte" pill>
                                            {this.state.api.estado}
                                        </Badge>
                                        <div className="divContenedorBotonReporteInfo">
                                            <a className="botonReporteInfo">
                                                <FaExclamation color="white" size={12} className="infoReporte" />
                                            </a>
                                            <Tooltip anchorSelect=".infoReporte" className="toolTipDiseño" place="top">
                                                <div>
                                                    Esta api está reportada. La información puede estar errónea.
                                                </div>
                                                <div>
                                                    Para más información, ingresa al reporte.
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </>
                                :null}
                                {this.state.solicitud &&
                                    <Badge color="primary" className="badgeSolicitud" style={{ marginLeft: "8px"}} onClick={this.verSolicitud} title="Ver solicitud" pill>
                                        Ver solicitud
                                    </Badge>
                                }
                            </div>
                        </div>
                        <div className="row d-flex">
                            <div className="col">
                                <div className="divTablaDetalles col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="row">
                                        <div className="divContenedorUltimaModificacion">
                                            <span className="tituloEncabezado">
                                                Última modificación
                                            </span>
                                            <a className="infoUltimaModificacion">
                                                <FaQuestion color="white" size={12}/>
                                            </a>
                                            <Tooltip anchorSelect=".infoUltimaModificacion" className="toolTipDiseño" place="top">
                                                {this.state.ultimamodificacion}
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <span className="tituloEncabezado colaboradores">
                                            Desarrollado por:
                                            <Colaboradores token={this.state.perfil.token}/>
                                        </span>
                                    </div>
                                    <div className="row">
                                        <span className="tituloEncabezado">Fecha de creación: {this.state.api.fecha_creacion}</span>
                                    </div>
                                    <div className="row">
                                        <div className="divContenedorUrlApi">
                                            <Card className="cardUrlApi">
                                                <CardBody>
                                                    {this.state.api.tipo_solicitud == 'POST' ?
                                                        <Badge className="badgePostApi" pill> 
                                                            {this.state.api.tipo_solicitud}
                                                        </Badge>  
                                                    : this.state.api.tipo_solicitud == 'GET' ?
                                                        <Badge className="badgeGetApi" pill> 
                                                            {this.state.api.tipo_solicitud} 
                                                        </Badge>  
                                                    : this.state.api.tipo_solicitud == 'PUT' ?
                                                        <Badge className="badgePutApi" pill> 
                                                            {this.state.api.tipo_solicitud}
                                                        </Badge> 
                                                    : this.state.api.tipo_solicitud == 'DELETE' ?
                                                        <Badge className="badgeDeleteApi" pill> 
                                                            {this.state.api.tipo_solicitud}
                                                        </Badge>
                                                    :null}
                                                    <span className="tituloEncabezado"> {this.state.api.url}</span>
                                                </CardBody>
                                            </Card>
                                            <button className="botonIconoCopiar" onClick={() => this.copiar(this.state.api.url)}>
                                                <FaCopy size={20} color="black"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="divHeaders col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <Headers
                                        headers_proyecto={this.state.api.headers_proyecto}
                                        headers_modulo={this.state.api.headers_modulo}
                                        headers_submodulo={this.state.api.headers_submodulo}
                                        headers_api={this.state.api.headers_api}
                                    />
                                </div>
                                <div>
                                    <span className="tituloEncabezado">Descripción técnica:</span>
                                    {this.state.api.descripcion_tecnica !== null && this.state.api.descripcion_tecnica.trim() !== '<p><br></p>' && this.state.api.descripcion_tecnica.trim() !== ''? 
                                        <div className="divDescripcion" dangerouslySetInnerHTML={{ __html: this.state.api.descripcion_tecnica }} />
									:
                                        <div>
                                            <em style={{fontSize: '15px'}}>La api no cuenta con descripción técnica</em>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                {/*////////// Sección de entradas de la api //////////*/}
                <Card className="cardSeccion mt-3">
                    <CardHeader className="cardSeccionHeader">
                        <div className="contenedorTituloSeccionDetalles">
                            <div className="divTituloSeccion">
                                <FaArrowRight size={23} color="black"/>
                                <span className="tituloSeccion">Entradas</span>
                            </div>
                            {/*////////// Sección de botón para editar las entradas, el cual se oculta dependiendo del rol //////////*/}
                            {this.state.id_rol == 1 || this.state.id_rol == 2 || this.state.id_rol == 4 ? 
                                <Dropdown className="dropdownOpcionesDetalles" isOpen={this.state.opcionesentradas} onClick={() => this.desplegarOpciones(2)}>
                                    <DropdownToggle data-toggle="dropdown" tag="span">
                                        <SlOptions size={20} color="black"/>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.redireccionando(2)}> 
                                            <HiPencil size={15} color="white"/> Editar entradas
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            :null}
                        </div>
                    </CardHeader>
                    <CardBody className="divContenidoCardBody">
                        <EjemplosEntradas
                            token={this.state.perfil.token}
                            idrol={this.state.id_rol}
                            modelo={this.state.api.modelo_entradas}
                            eliminarEjemplo={this.verModalEliminarEjemplo}
                            cambios={this.state.cambios}
                            formatocuerpo={this.state.api.formato_cuerpo}
                        />
                    </CardBody>
                </Card>
                {/*////////// Sección de salidas de la api //////////*/}
                <Card className="cardSeccion mt-3">
                    <CardHeader className="cardSeccionHeader">
                        <div className="contenedorTituloSeccionDetalles">
                            <div className="divTituloSeccion">
                                <FaArrowLeft size={23} color="black"/>
                                <span className="tituloSeccion">Salidas</span>
                            </div>
                            {/*////////// Sección de botón para editar las salidas, el cual se oculta dependiendo del rol //////////*/}
                            {this.state.id_rol == 1 || this.state.id_rol == 2 || this.state.id_rol == 4 ? 
                                <Dropdown className="dropdownOpcionesDetalles" isOpen={this.state.opcionessalidas} onClick={() => this.desplegarOpciones(3)}>
                                    <DropdownToggle data-toggle="dropdown" tag="span">
                                        <SlOptions size={20} color="black"/>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.redireccionando(3)}> 
                                            <HiPencil size={15} color="white"/> Editar salidas
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            :null}
                        </div>
                    </CardHeader>
                    <CardBody className="divContenidoCardBody">
                        <EjemplosSalidas 
                            token={this.state.perfil.token}
                            idrol={this.state.id_rol}
                            modelo={this.state.api.modelo_salidas}
                            eliminarEjemplo={this.verModalEliminarEjemplo}
                            cambios={this.state.cambios}                                    
                        />
                    </CardBody>
                </Card>
                {/*////////// Sección de excepciones de la api //////////*/}
                <Card className="cardSeccion mt-3">
                    <CardHeader className="cardSeccionHeader">
                        <div className="contenedorTituloSeccionDetalles">
                            <div className="divTituloSeccion">
                                <IoIosWarning size={25} color="black"/>
                                <span className="tituloSeccion">Excepciones</span>
                            </div>
                            {/*////////// Sección de botón para editar las salidas, el cual se oculta dependiendo del rol //////////*/}
                            {this.state.id_rol == 1 || this.state.id_rol == 2 || this.state.id_rol == 4 ? 
                                <Dropdown className="dropdownOpcionesDetalles" isOpen={this.state.opcionesexcepciones} onClick={() => this.desplegarOpciones(4)}>
                                    <DropdownToggle data-toggle="dropdown" tag="span">
                                        <SlOptions size={20} color="black"/>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.redireccionando(7)}> 
                                            <HiPencil size={15} color="white"/> Editar excepciones
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            :null}
                        </div>
                    </CardHeader>
                    <CardBody className="divContenidoCardBody">
                        <EjemplosExcepciones 
                            token={this.state.perfil.token}
                            idrol={this.state.id_rol}
                            modelo={this.state.api.modelo_excepciones}
                            eliminarEjemplo={this.verModalEliminarEjemplo}
                            cambios={this.state.cambios} 
                            excepciones={this.state.api.excepciones}                        
                        />
                    </CardBody>
                </Card>
			</main>
		</div>
        
    );
};
}
//Función para redireccionar a diferentes páginas mediante condiciones del valor recibida
function Acciones(bandera:string) {
    if(bandera=="reporte"){
		return <Navigate to="/reporte" />;
	}else if(bandera=="reportes"){
		return <Navigate to="/catalogoreportes" />;
	}else if(bandera=="solicitud"){
		return <Navigate to="/solicitud" />;
	}else if(bandera=="infogeneral"){
		return <Navigate to="/documentarapiinfogeneral" />;
	}else if(bandera=="entradas"){
		return <Navigate to="/documentarapientradas" />;
	}else if (bandera == 'salidas') {
		return <Navigate to="/documentarapisalidas" />;
    }else if (bandera == 'modulo') {
		return <Navigate to="/modulo" />;
    }else if (bandera == 'submodulo') {
		return <Navigate to="/submodulo" />;
    }else if (bandera == 'excepciones') {
		return <Navigate to="/documentarapiexcepciones" />;
    }else if (bandera == 'registroactividades') {
		return <Navigate to="/documentacionregistroactividad" />;
    }else if (bandera == 'accesorestringido') {
		return <Navigate to="/catalogoproyectos" />;
    }
}
