import React from "react";
import { FaEye } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { Badge } from "reactstrap";
import { solicitud } from "../Interfaces";

interface props {
  solicitud: solicitud
  dimensiones: string[]
  versolicitud: (id_reporte:number, id_proyecto:number,id_rol:number) => void;
}

const Solicitud: React.FC<props> = ({ solicitud, dimensiones,versolicitud }) => {
  return (
        <tr>
            <td style={{width: dimensiones[0] }}>
                #{solicitud.id_solicitud}
            </td>
            {solicitud.notificacion == 1 ? 
                <td style={{width: dimensiones[1] }}>
                    {solicitud.titulo_solicitud.length > 20 ? 
                        <>
                            <span>
                                {solicitud.titulo_solicitud.substring(0, 20)}...
                            </span>
                        </>   
                    : solicitud.titulo_solicitud}
                    <Badge color="danger" pill>Nuevo</Badge>
                </td>
            : solicitud.notificacion ==2 ? 
                <td style={{width: dimensiones[1] }}>
                    {solicitud.titulo_solicitud.length > 15 ? 
                        <>
                            <span>
                                {solicitud.titulo_solicitud.substring(0, 15)}...
                            </span>
                        </>   
                    : solicitud.titulo_solicitud}
                    <Badge color="primary" pill>Actualización</Badge>
                </td>
            : solicitud.notificacion ==3 ? 
                <td style={{width: dimensiones[1] }}>
                    {solicitud.titulo_solicitud.length > 20 ? 
                        <>
                            <span>
                                {solicitud.titulo_solicitud.substring(0, 20)}...
                            </span>
                        </>   
                    : solicitud.titulo_solicitud} 
                    <Badge color="warning" pill>Editado</Badge>
                </td>
            :	
                <td style={{width: dimensiones[1] }}>
                    {solicitud.titulo_solicitud.length > 30 ? 
                        <>
                            <span>
                                {solicitud.titulo_solicitud.substring(0, 30)}...
                            </span>
                        </>   
                    : solicitud.titulo_solicitud}
                </td>
            }
            <td style={{width: dimensiones[2] }}>
                {solicitud.prioridad == "Urgente" ?
                    <Badge className="badgeUrgente" pill> 
                        {solicitud.prioridad}
                    </Badge>
                :solicitud.prioridad == "Muy alta" ?
                    <Badge className="badgeMuyAlta" pill> 
                        {solicitud.prioridad}
                    </Badge>
                :solicitud.prioridad == "Alta" ?
                    <Badge className="badgeAlta" pill> 
                        {solicitud.prioridad}
                    </Badge>  
                :solicitud.prioridad == "Media" ?
                    <Badge className="badgeMedia" pill> 
                        {solicitud.prioridad}
                    </Badge> 
                :solicitud.prioridad == "Baja" ?
                    <Badge className="badgeBaja" pill> 
                        {solicitud.prioridad}
                    </Badge> 
                :null}
            </td>
            <td style={{width: dimensiones[3] }}>
                {solicitud.estado}
            </td>
            <td style={{width: dimensiones[4] }} className={`${solicitud.idsolicitudclass}`}>
                {solicitud.fecha_entrega}
                <Tooltip anchorSelect={solicitud.idsolicitudtooltip} className="toolTipDiseño" place="top">
                    Faltan {solicitud.tiempo_faltante}
                </Tooltip> 
            </td>
            <td style={{width: dimensiones[5] }}>
                {solicitud.ubicacion}
            </td>
            <td style={{width: dimensiones[6] }}>
                <div className="botonMasInformacion">
                    <a  onClick={() => versolicitud(solicitud.id_solicitud, solicitud.id_rol, solicitud.id_proyecto)}>
                        Mas información 
                    </a>
                    <FaEye size={20}/>
                </div>
            </td>
        </tr>
  );
};

export default Solicitud;
