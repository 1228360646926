import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioSubmodulo.css";
import { postGuardarSubmodulo } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import { validarLetrasNumerosEspacios, validarLongitud, validarLongitudMáxima } from '../../../Services/Validaciones';
import InputText from '../../Formularios/InputText';
import InputQuill from '../../Formularios/InputQuill';
import InputHeaders from '../../Formularios/InputHeaders';
import InputComentario from '../../Formularios/InputComentario';
import { submodulo } from '../../../Pages/DetallesSubmodulo/Interfaces';

interface props {
  isOpen: boolean,
  closeModal:(accion:number) => void,
  idproyecto:number,
  idmodulo:number,
  idsolicitud?:number,
  token:number
  titulomodal:string,
  accion:number,
  submoduloseleccionado?:submodulo
}

interface states {
  isOpen: boolean,

  txtnombresubmodulo:string,
  vermsgerrornombre:boolean,
  msgerrornombre:string,

  txtdescripcionsubmodulo:string,
  vermsgerrordescripcion:boolean,
  msgerrordescripcion:string,

  headerscheckbox:boolean,
  txtheaders:string,
  vermsgerrorheaders:boolean,
  msgerrorheaders:string,

  confirmar:boolean,
  txtcomentario:string,
  txtcomentariolength:number,
  vermsgerrorcomentario:boolean,
  msgerrorcomentario:string
}

export default class ModalFormularioSubmodulo extends React.Component<props,states>{
  constructor(props:props) {
    super(props);
    this.state = {
        isOpen:this.props.isOpen,

        txtnombresubmodulo:(props.accion == 2) ? (props.submoduloseleccionado?.nombre_submodulo !== undefined ? props.submoduloseleccionado.nombre_submodulo : ''): '',
        vermsgerrornombre:false,
        msgerrornombre:'',

        txtdescripcionsubmodulo:(props.accion == 2) ? (props.submoduloseleccionado?.descripcion_submodulo !== undefined ? props.submoduloseleccionado.descripcion_submodulo : ''): '',
        vermsgerrordescripcion:false,
        msgerrordescripcion:'',

        headerscheckbox:false,
        txtheaders:(props.accion == 2) ? (props.submoduloseleccionado?.headers_submodulo !== undefined ? props.submoduloseleccionado.headers_submodulo : ''): '',
        vermsgerrorheaders:false,
        msgerrorheaders:"",

        confirmar:false,
        txtcomentario:'',
        txtcomentariolength:0,
        vermsgerrorcomentario:false,
        msgerrorcomentario:''
    }
  }

  async componentDidMount() {
    if (this.props.accion == 2) {
      this.habilitarHeaders(this.state.txtheaders)
    }
  }

  //Función para habilitar los headers al editar el submódulo 
  habilitarHeaders=(headers:any)=> {
    if(headers!== null){
      this.setState({headerscheckbox:true})
    }
  }

  //Funciones para cambiar el estado de todos los estados de los inputs del formulario..
  nombre = (prop: string) => {
    this.setState({ txtnombresubmodulo: prop});
  };

  descripcion = (prop: string) => {
    this.setState({ txtdescripcionsubmodulo: prop});
  };

  headers = (prop: string) => {
    this.setState({ txtheaders: prop});
  };

  habilitar = (prop:boolean) => {
    this.setState({ headerscheckbox: prop});
  }

  onChangeHeaders = (value:string) => {
    this.setState({ txtheaders: value});
  };

  comentario = (prop:string) => {
    this.setState({txtcomentario:prop})
  }

  habilitarComentario = (prop:boolean) => {
      this.setState({ confirmar:prop });
  }

  showModal=(accion:number)=> {
    this.setState({isOpen:false})
    setTimeout(() => {
      this.props.closeModal(accion);
    }, 200);
  }
   
  CrearSubmodulo = async () => {
    this.setState({ vermsgerrornombre: false });
    this.setState({ vermsgerrordescripcion: false });
    this.setState({ vermsgerrorheaders: false });
    this.setState({ vermsgerrorcomentario: false});

    let longitudvalidanombre = {longitudminima: 3, longitudmaxima: 45};
    let longitudvalidadescripcion = 3000;
    let longitudvalidaheaders = {longitudminima: 30, longitudmaxima: 500};
    let longitudvalidacomentario = {longitudminima: 8, longitudmaxima: 300};

    let txtnombre = validarLetrasNumerosEspacios(this.state.txtnombresubmodulo, longitudvalidanombre);
    let txtdescripcion = validarLongitudMáxima(this.state.txtdescripcionsubmodulo, longitudvalidadescripcion);

    //Si headers es null, se asigna una cadena vacía
    let txtheadersnull = this.state.txtheaders || "";
    let txtheaders = validarLongitud(txtheadersnull, longitudvalidaheaders);
    
    let txtcomentario = validarLetrasNumerosEspacios(this.state.txtcomentario, longitudvalidacomentario);
    
    let validacionescorrectas = true;

    if (txtnombre.codigo == "invalid") {
      this.setState({ msgerrornombre: txtnombre.mensaje });
      this.setState({ vermsgerrornombre: true });
      validacionescorrectas = false;
    }

    if (txtdescripcion.codigo == "invalid") {
      this.setState({ msgerrordescripcion: txtdescripcion.mensaje });
      this.setState({ vermsgerrordescripcion: true });
      validacionescorrectas = false;
    }

    if (txtheaders.codigo == "invalid" && this.state.headerscheckbox==true) {
      this.setState({ msgerrorheaders: txtheaders.mensaje });
      this.setState({ vermsgerrorheaders: true });
      validacionescorrectas = false;
    }

    if (txtcomentario.codigo == "invalid" && this.state.confirmar === true) {
      this.setState({ msgerrorcomentario: txtcomentario.mensaje });
      this.setState({ vermsgerrorcomentario: true });
      validacionescorrectas = false;
    }

    if (validacionescorrectas) {
      let json = {
        "token":this.props.token,
        "id_proyecto":this.props.idproyecto,
        "id_submodulo":this.props.accion == 1 ? ("0") : (this.props.submoduloseleccionado?.id_submodulo !== undefined ? this.props.submoduloseleccionado.id_submodulo : 0),
        "nombre_submodulo":this.state.txtnombresubmodulo,
        "descripcion_submodulo":this.state.txtdescripcionsubmodulo,
        "headers_checkbox":this.state.headerscheckbox,
        "headers_submodulo":this.state.txtheaders,
        "id_modulo":this.props.idmodulo,
        "id_solicitud":(this.props.idsolicitud !== undefined ? this.props.idsolicitud:null),
        "comentario_checkbox":this.state.confirmar,
        "comentario":this.state.txtcomentario
      }

      const resp = await postGuardarSubmodulo(json);
      if (resp.code == 0) {
        const id_submodulo = resp.data.id_submodulo;
        localStorage.setItem("id_submodulo", id_submodulo.toString());
        this.showModal(1)
      }
      else if(resp.code==100){
        this.showModal(2)
        toast.success(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      else if (resp.code == 200) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(2)
      }
      else if (resp.code == 250) {
        this.setState({ msgerrornombre: resp.message });
        this.setState({ vermsgerrornombre: true });
      }
      else if (resp.code == 300) {
        toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(2)
      }
      else if (resp.code == 400) {
        toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(2)
      }
      else if (resp.code == 1000 || resp == -1) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
    } 
  }
    
  render() {
    return (
      <>
        <ToastContainer />
        <Modal isOpen={this.state.isOpen} size="lg">
          <ModalHeader className="modalHeader">
            <label className='labelTituloModal'> {this.props.titulomodal} </label>
            <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
          </ModalHeader>
          <ModalBody>
            <div className="divContenedorModal">
              <div className="row">
                <InputText 
                  label={'Nombre del submódulo'} 
                  placeholder='Ej. Login'
                  limitecaracteres={45} 
                  txtvalor={this.state.txtnombresubmodulo}
                  vermsgerror={this.state.vermsgerrornombre}
                  msgerror={this.state.msgerrornombre}
                  escribir={this.nombre}                  
                />
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <InputQuill 
                    label={"Descripcion del submódulo"} 
                    isOpcional={true} 
                    isModal={true} 
                    placeholder={"Ingresa la descripción del submódulo"} 
                    limitecaracteres={3000} 
                    txtvalor={this.state.txtdescripcionsubmodulo} 
                    txtvalorlength={0} 
                    vermsgerror={this.state.vermsgerrordescripcion} 
                    msgerror={this.state.msgerrordescripcion} 
                    escribir={this.descripcion}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <InputHeaders 
                    isOpcional={true}
                    isModal={true}
                    checkbox={this.state.headerscheckbox}
                    label={"Headers adicionales"}
                    limitecaracteres={500}
                    txtvalor={this.state.txtheaders}
                    vermsgerror={this.state.vermsgerrorheaders}
                    msgerror={this.state.msgerrorheaders}
                    escribir={this.headers} 
                    habilitar={this.habilitar}                      
                  />
                </div>
              </div>
              {this.props.titulomodal === "Nuevo submódulo para solicitud" ? 
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <InputComentario 
                      checkbox={this.state.confirmar}
                      label={'Añadir un comentario acerca de la creación del submódulo.'}
                      txtvalor={this.state.txtcomentario}
                      vermsgerror={this.state.vermsgerrorcomentario} 
                      msgerror={this.state.msgerrorcomentario}
                      escribir={this.comentario} 
                      habilitar={this.habilitarComentario}
                    />
                  </div>
                </div>
              :null}
            <div className="divContenedorBotonesModal">
              <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={this.CrearSubmodulo}>Guardar</Button>           
            </div>
          </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
    