import React from "react";
import { ejemploExcepcion } from "../Interfaces";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import { postEjemplosExcepciones } from "../../../Services/Api";

interface props{
    token:number
    idrol:number
    excepciones:number,
    modelo:string
    eliminarEjemplo:(accion:number,id_ejemplo:number) => void
    cambios:boolean
}

interface states {
    ejemplos:ejemploExcepcion[]
    selectejemplo:number
    cambios:boolean
}

export default class EjemplosExcepciones extends React.Component<props, states> {
    constructor(props: props) {
		super(props);
		this.state = {
          ejemplos:[],
          selectejemplo:0,
          cambios:this.props.cambios
        };
	}

    async componentDidMount() {
		var id_proyecto = await localStorage.getItem('id_proyecto');
		var id_api = await localStorage.getItem('id_api');
       
        const ejemplos = await postEjemplosExcepciones(this.props.token,Number(id_proyecto),Number(id_api));
		if(this.respuestaApi (ejemplos)){
			return;
		}
       this.setState({ejemplos:ejemplos.data, selectejemplo:0,})
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.cambios !== this.props.cambios) {
          this.setState({ cambios: this.props.cambios });
          this.componentDidMount()
        }
    }

    //Función para gestionar las respuestas negativas de las apis.
    respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			return 
		}
		return false
	}

    //Función para mostrar el ejemplo de entrada que se seleccione.
    ejemploSeleccionado = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const idejemploentrada = Number(event.target.value);
		this.setState({ selectejemplo: idejemploentrada});
	};

    verModalEliminarEjemplo = (accion:number, id_ejemplo:number) => {
		this.props.eliminarEjemplo(accion, id_ejemplo)
	}

    render() {
		return ( 	
           <>
                {this.props.excepciones !== 0 ? 
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <span className="tituloEncabezado">Modelo</span>
                            <div className="divContenedorPrincipalCodigo mt-2">
                                {(this.props.modelo == null) ? (
                                    <>
                                        <div className="divContenedorLineasCodigo">
                                            <div>	
                                                <span>1</span>
                                            </div>
                                            <div>	
                                                <span>2</span>
                                            </div>
                                            <div>	
                                                <span>3</span>
                                            </div>
                                            <div>	
                                                <span>4</span>
                                            </div>
                                            <div>	
                                                <span>5</span>
                                            </div>
                                        </div>
                                        <div className="divContenedorCodigo">
                                            /* No tiene excepciones */
                                        </div>
                                        
                                    </>
                                ) : (
                                    <>
                                        <div className="divContenedorLineasCodigo">
                                            <div>
                                                {this.props.modelo.split("\n").map((line, lineIndex) => {
                                                const lineNumber = lineIndex + 1;
                                                return (
                                                    <div key={lineIndex}>
                                                        <span>{lineNumber}</span>
                                                    </div>
                                                );
                                                })}
                                            </div>
                                        </div>
                                        <div className="divContenedorCodigo">
                                            <pre className="ql-syntax">
                                                {this.props.modelo}
                                            </pre>
                                        </div>
                                    </>
                                )}    
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="contenedorTituloSeccionDetalles">
                                <select value={this.state.selectejemplo} onChange={this.ejemploSeleccionado}>
                                    <option disabled value="0">Seleccione un ejemplo</option>
                                    {this.state.ejemplos.map((ejemplo, index) => (
                                        <option key={index} value={ejemplo.id_ejemplo_excepcion}>
                                            {ejemplo.nombre_ejemplo_excepcion}
                                        </option>
                                    ))}
                                </select>
                                {this.state.selectejemplo !== 0 && this.state.ejemplos.length > 1  && this.props.idrol !== 3?  
                                    <div className="col-1 divContenedorBotonEliminarEjemplo">
                                        <button className="botonEliminarEjemplo" onClick={() => this.verModalEliminarEjemplo(3, this.state.selectejemplo)}>
                                            <IoMdClose size={25} color="white"/>
                                        </button>
                                    </div>
                                :null}
                            </div>
                            {this.state.ejemplos.map((ejemplo, index) => (
                                ejemplo.id_ejemplo_excepcion == this.state.selectejemplo ?
                                    <>
                                        <div key={index} className="divContenedorPrincipalCodigo divEJemplo">
                                            <div className="divContenedorLineasCodigo">
                                                <div>
                                                    {ejemplo.ejemplo_excepcion.split("\n").map((line, index) => {
                                                    const lineNumber = index + 1;
                                                    return (
                                                        <div key={index}>
                                                            <span>{lineNumber}</span>
                                                        </div>
                                                    );
                                                    })}
                                                </div>
                                            </div>
                                            <div className="divContenedorCodigo">
                                                <pre className="ql-syntax">
                                                    {ejemplo.ejemplo_excepcion}
                                                </pre>
                                            </div>
                                        </div>
                                    </>
                                :null
                            ))}
                        </div>
                    </div> 
                : 
                    <div className="d-flex justify-content-center justify-content-center">
                        <em style={{fontSize: '16px'}}>La api no cuenta con excepciones</em>
                    </div>
                } 
           </>
        )
    }
}