import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioSolicitud.css";
import { getListaProyectos, getListaSubmodulosDelModuloSinFiltro, getModulosDelProyecto, postGuardarSolicitud } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import { validarFechaInput, validarInputSelect, validarLetrasNumerosEspacios, validarLongitud } from '../../../Services/Validaciones';
import InputText from '../../Formularios/InputText';
import InputQuill from '../../Formularios/InputQuill';
import InputSelectPrioridad from '../../Formularios/InputSelect';
import InputFechaEntrega from '../../Formularios/InputFechaEntrega';
import InputCategorias from './Componentes/InputCategorias';
import InputProyectos from './Componentes/InputProyectos';
import InputModulos from './Componentes/InputModulos';
import InputSubmodulos from './Componentes/InputSubmodulos';
import FormularioApis from './Componentes/FormularioApis';
import InputComentario from '../../Formularios/InputComentario';
import { api, apiestado, modulo, proyecto, submodulo } from './Interfaces';

interface props {
    isOpen: boolean,
    closeModal:(accion:number) => void,
    token:number,
    titulomodal:string,
    accion:number,
    solicitudseleccionada:any,
    apis?:api[]
}

interface states {
    isOpen: boolean,

    txttitulosolicitud:string,
    vermsgerrortitulosolicitud:boolean,
    msgerrortitulosolicitud:string,

    txtdescripcionsolicitud:string,
    vermsgerrordescripcionsolicitud:boolean,
    msgerrordescripcionsolicitud:string,

    selectprioridad:string,
    vermsgerrorselectprioridad:boolean,
    msgerrorselectprioridad:string,

    fechaentrega:string,
    vermsgerrorfechaentrega:boolean,
    msgerrorfechaentrega:string,

    selectcategoria:string,
    vermsgerrorselectcategoria:boolean,
    msgerrorselectcategoria:string,

    apis: api[];

    proyectoslista:proyecto[],
    selectproyecto:number,
    vermsgerrorselectproyecto:boolean,
    msgerrorselectproyecto:string,

    moduloslista:modulo[],
    selectmodulo:number,
    vermsgerrorselectmodulo:boolean,
    msgerrorselectmodulo:string,

    agregarsubmodulo:boolean,
    submoduloslista:submodulo[],
    selectsubmodulo:number,
    vermsgerrorselectsubmodulo:boolean,
    msgerrorselectsubmodulo:string,

    confirmar:boolean,
    txtcomentario:string,
    txtcomentariolength:number,
    vermsgerrorcomentario:boolean,
    msgerrorcomentario:string
}
export default class ModalFormularioModulo extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            isOpen:this.props.isOpen,

            txttitulosolicitud: (props.accion == 2) ? (props.solicitudseleccionada.titulo_solicitud): '',
            vermsgerrortitulosolicitud:false,
            msgerrortitulosolicitud:'',

            txtdescripcionsolicitud: (props.accion == 2) ? (props.solicitudseleccionada.descripcion_solicitud): '',
            vermsgerrordescripcionsolicitud:false,
            msgerrordescripcionsolicitud:'',

            selectprioridad:(props.accion == 2) ? (props.solicitudseleccionada.prioridad): '',
            vermsgerrorselectprioridad:false,
            msgerrorselectprioridad:'',

            fechaentrega:(props.accion == 2) ? (props.solicitudseleccionada.fecha_entrega): '',
            vermsgerrorfechaentrega:false,
            msgerrorfechaentrega:'',

            selectcategoria:(props.accion == 2) ? (props.solicitudseleccionada.solicitud_categoria): '',
            vermsgerrorselectcategoria:false,
            msgerrorselectcategoria:'',
            
            apis: props.apis && props.accion == 2 ? props.apis : [apiestado],

            proyectoslista:[],
            selectproyecto:(props.accion == 2) ? (props.solicitudseleccionada.id_proyecto): 0,
            vermsgerrorselectproyecto:false,
            msgerrorselectproyecto:'',
        
            moduloslista:[],
            selectmodulo:(props.accion == 2) ? (props.solicitudseleccionada.id_modulo): 0,
            vermsgerrorselectmodulo:false,
            msgerrorselectmodulo:'',

            agregarsubmodulo:false,
            submoduloslista:[],
            selectsubmodulo:(props.accion == 2) ? (props.solicitudseleccionada.id_submodulo): 0,
            vermsgerrorselectsubmodulo:false,
            msgerrorselectsubmodulo:'',
            
            confirmar:false,
            txtcomentario:'',
            txtcomentariolength:0,
            vermsgerrorcomentario:false,
            msgerrorcomentario:''
        } 
    }

    async componentDidMount() {
      const apiproyectos = await getListaProyectos(this.props.token);
      this.setState({proyectoslista: apiproyectos.data});

      const apimodulos = await getModulosDelProyecto(this.props.token, this.state.selectproyecto);
      this.setState({moduloslista: apimodulos.data});

      const apisubmodulos = await getListaSubmodulosDelModuloSinFiltro(this.props.token, this.state.selectproyecto,this.state.selectmodulo);
      this.setState({submoduloslista: apisubmodulos.data});
      if(this.props.accion === 2){
        this.habilitarSeccionSubmodulo(apisubmodulos.data)
      }
    }

    habilitarSeccionSubmodulo = (submodulos: any) => {
      const botonAgregarSubmodulo = submodulos.map((submodulo: any) => {
        if (submodulo.id_submodulo === this.state.selectsubmodulo) {
          if(submodulo.submodulo_general === 0){
            this.setState({agregarsubmodulo:true})
          }
        }
      });
    }
    
    //Funciones para cambiar el estado de todos los estados.
    titulo = (prop: string) => {
      this.setState({ txttitulosolicitud: prop});
    };

    descripcion = (prop: string) => {
      this.setState({ txtdescripcionsolicitud: prop});
    };

    prioridad = (prop:string) => {
      this.setState({selectprioridad:prop})
    }

    fechaEntrega = (prop:string) => {
      this.setState({ fechaentrega: prop });
    }

    categoria = (prop:string) => {
      this.setState({selectcategoria:prop})
      if(prop === "modulo/api"){
        this.setState({selectsubmodulo:0})
      }
    }

    proyecto = (prop:number) => {
      this.setState({ selectproyecto:prop }, async () => {
        const modulos = await getModulosDelProyecto(this.props.token, Number(this.state.selectproyecto));
        this.setState({ moduloslista: modulos.data });
      });
      this.setState({selectmodulo:0,selectsubmodulo:0})
    }

    modulo = (prop:number) => {
      this.setState({ selectmodulo:prop }, async () => {
        const submodulos = await getListaSubmodulosDelModuloSinFiltro(this.props.token, Number(this.state.selectproyecto),prop);
        this.setState({ submoduloslista: submodulos.data,
                        selectsubmodulo:submodulos.data[0].id_submodulo,
                        agregarsubmodulo:false 
        });
      });
    }

    agregarSubmodulo = () => {
      this.setState({agregarsubmodulo:true})
    }

    submodulo = (prop:number) => {
      this.setState({ selectsubmodulo:prop });
    }
    
    apis = (prop:api[]) => {
      this.setState({ apis: prop });
    }
  
    comentario = (prop:string) => {
      this.setState({txtcomentario:prop})
    }

    habilitarComentario = (prop:boolean) => {
        this.setState({ confirmar:prop });
    }

    showModal=(accion:number)=> {
      this.setState({isOpen:false})
      setTimeout(() => {
        this.props.closeModal(accion);
      }, 200);
    }

    guardarSolicitud = async () => {
      this.setState({ vermsgerrortitulosolicitud: false });
      this.setState({ vermsgerrordescripcionsolicitud: false });
      this.setState({ vermsgerrorselectprioridad: false });
      this.setState({vermsgerrorfechaentrega:false});
      this.setState({ vermsgerrorselectcategoria: false });
      this.setState({ vermsgerrorselectproyecto: false });
      this.setState({ vermsgerrorselectmodulo: false });
      this.setState({ vermsgerrorcomentario: false});
      
      //Actualiza estado de la visualización de los mensajes de error de cada api.
      const vermsgerrorapi = this.state.apis.map((api) => ({
        ...api,
        vermsgerrortapi: false
      }));
      this.setState({ apis: vermsgerrorapi });

      let longitudvalidatitulo = {longitudminima: 8, longitudmaxima: 45};
      let longitudvalidadescripcion = {longitudminima: 15, longitudmaxima: 3000};
      let cantidad = {cantidadminima: 1, cantidadmaxima: 10};
      let longitudvalidacomentario = {longitudminima: 8, longitudmaxima: 300};

      let txttitulo = validarLetrasNumerosEspacios(this.state.txttitulosolicitud, longitudvalidatitulo);
      let txtdescripcion = validarLongitud(this.state.txtdescripcionsolicitud, longitudvalidadescripcion);
      let selectprioridad = validarInputSelect(String(this.state.selectprioridad));
      let selectcategoria = validarInputSelect(String(this.state.selectcategoria));
      let txtfechaentrega = validarFechaInput(this.state.fechaentrega);
      let selectproyecto = validarInputSelect(String(this.state.selectproyecto));
      let selectmodulo = validarInputSelect(String(this.state.selectmodulo));
      let txtcomentario = validarLetrasNumerosEspacios(this.state.txtcomentario, longitudvalidacomentario);
      
      //Nombres de ejemplos de salida.
      const validarApi = this.state.apis.map((api) => {
        const resultadoValidacionApi = validarInputSelect(String(api.id_tipo_solicitud));
        return {
            ...api,
            txtapi: resultadoValidacionApi.codigo,
            msgerrortapi: resultadoValidacionApi.mensaje
        };
      });
      await this.setState({ apis: validarApi });
 
      let validacionescorrectas = true;

      const validarApiRespuesta = this.state.apis.map((api) => {
        if (api.txtapi === "invalid") {
            return {
                ...api,
                vermsgerrortapi: true
            };
        }
        return api;
      });
      await this.setState({ apis: validarApiRespuesta });
      const apisCorrectas =  !validarApiRespuesta.some((api) => api.txtapi === "invalid");  

      if (txttitulo.codigo == "invalid") {
        this.setState({ msgerrortitulosolicitud: txttitulo.mensaje });
        this.setState({ vermsgerrortitulosolicitud: true });
        validacionescorrectas = false;
      }

      if (txtdescripcion.codigo == "invalid") {
        this.setState({ msgerrordescripcionsolicitud: txtdescripcion.mensaje });
        this.setState({ vermsgerrordescripcionsolicitud: true });
        validacionescorrectas = false;
      }

      if (selectprioridad.codigo == "invalid") {
        this.setState({ msgerrorselectprioridad: selectprioridad.mensaje });
        this.setState({ vermsgerrorselectprioridad: true });
        validacionescorrectas = false;
      }

      if (txtfechaentrega.codigo == "invalid") {
        this.setState({ msgerrorfechaentrega: txtfechaentrega.mensaje });
        this.setState({ vermsgerrorfechaentrega: true });
        validacionescorrectas = false;
      }

      if (selectcategoria.codigo == "invalid") {
        this.setState({ msgerrorselectcategoria: selectcategoria.mensaje });
        this.setState({ vermsgerrorselectcategoria: true });
        validacionescorrectas = false;
      }

      if (selectproyecto.codigo == "invalid") {
        this.setState({ msgerrorselectproyecto: selectproyecto.mensaje });
        this.setState({ vermsgerrorselectproyecto: true });
        validacionescorrectas = false;
      }

      if (selectmodulo.codigo == "invalid" && (this.state.selectcategoria == "api" || this.state.selectcategoria == "submodulo/api")) {
        this.setState({ msgerrorselectmodulo: selectmodulo.mensaje });
        this.setState({ vermsgerrorselectmodulo: true });
        validacionescorrectas = false;
      }

      if (txtcomentario.codigo == "invalid" && this.state.confirmar === true) {
        this.setState({ msgerrorcomentario: txtcomentario.mensaje });
        this.setState({ vermsgerrorcomentario: true });
        validacionescorrectas = false;
      }

      if(validacionescorrectas){
        validacionescorrectas = apisCorrectas;
    }

      if (validacionescorrectas) {
        let json = {
          "token":this.props.token,
          "id_solicitud":this.props.accion == 1 ? ("0") : (this.props.solicitudseleccionada.id_solicitud),
          "titulo_solicitud":this.state.txttitulosolicitud,
          "descripcion_solicitud":this.state.txtdescripcionsolicitud,
          "solicitud_categoria":this.state.selectcategoria,
          "num_apis":this.state.apis.length,
          "apis":this.state.apis,
          "prioridad":this.state.selectprioridad,
          "fecha_entrega":this.state.fechaentrega,
          //llaves foraneas
          "id_proyecto":this.state.selectproyecto,
          "id_submodulo":this.state.selectsubmodulo,
          "comentario_checkbox":this.state.confirmar,
          "comentario":this.state.txtcomentario
        }

        const resp = await postGuardarSolicitud(json);

        if (resp.code == 0) {
          const id_solicitud = resp.data.id_solicitud;
          const id_rol = resp.data.id_rol;
          localStorage.setItem("id_solicitud", id_solicitud.toString());
          localStorage.setItem("id_proyecto", this.state.selectproyecto.toString());
          localStorage.setItem("accion", "1");
          localStorage.setItem("id_rol", id_rol.toString());
          this.showModal(1)
        }else if(resp.code==100){
          this.showModal(2)
          toast.success(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else if (resp.code == 200) {
          toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
        else if (resp.code == 250) {
          toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
        else if (resp.code == 300) {
          toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
        else if (resp.code == 1000 || resp == -1) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
      }
    }

    render() {
        return (
        <>
          <ToastContainer />
          <Modal isOpen={this.state.isOpen} size="lg">
           <ModalHeader className="modalHeader">
              <label className='labelTituloModal'> {this.props.titulomodal} </label>
                <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
           </ModalHeader>
            <ModalBody>
              <div className="divContenedorModal">
                <div className="row">
                  <InputText 
                    label={'Título de la solicitud'} 
                    placeholder='Ej. Apis para productos'
                    limitecaracteres={45} 
                    txtvalor={this.state.txttitulosolicitud}
                    vermsgerror={this.state.vermsgerrortitulosolicitud}
                    msgerror={this.state.msgerrortitulosolicitud}
                    escribir={this.titulo}                  
                  />
                </div>
                <div className="row">
                  <InputQuill 
                    label={"Descripcion del solicitud"} 
                    isOpcional={false} 
                    isModal={true} 
                    placeholder={"Ingresa la descripción de la solicitud"} 
                    limitecaracteres={3000} 
                    txtvalor={this.state.txtdescripcionsolicitud} 
                    txtvalorlength={0} 
                    vermsgerror={this.state.vermsgerrordescripcionsolicitud} 
                    msgerror={this.state.msgerrordescripcionsolicitud} 
                    escribir={this.descripcion}
                  />
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <InputSelectPrioridad 
                      prioridad={this.state.selectprioridad} 
                      vermsgerror={this.state.vermsgerrorselectprioridad}
                      msgerror={this.state.msgerrorselectprioridad}
                      seleccionar={this.prioridad}                    
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <InputFechaEntrega 
                      fechaentrega={this.state.fechaentrega} 
                      vermsgerror={this.state.vermsgerrorfechaentrega} 
                      msgerror={this.state.msgerrorfechaentrega} 
                      seleccionar={this.fechaEntrega}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <InputCategorias 
                      selectcategoria={this.state.selectcategoria} 
                      vermsgerror={this.state.vermsgerrorselectcategoria} 
                      msgerror={this.state.msgerrorselectcategoria} 
                      categoria={this.categoria}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <InputProyectos 
                      proyectos={this.state.proyectoslista} 
                      selectproyecto={this.state.selectproyecto}
                      vermsgerror={this.state.vermsgerrorselectproyecto} 
                      msgerror={this.state.msgerrorselectproyecto} 
                      proyecto={this.proyecto}
                    />
                  </div>
                  {this.state.selectcategoria == "api" ?
                    <>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <InputModulos 
                          modulos={this.state.moduloslista} 
                          selectmodulo={this.state.selectmodulo} 
                          vermsgerror={this.state.vermsgerrorselectmodulo} 
                          msgerror={this.state.msgerrorselectmodulo} 
                          modulo={this.modulo}
                        />
                      </div>
                      {this.state.selectmodulo != 0 ? 
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                          <InputSubmodulos 
                            agregarsubmodulo={this.state.agregarsubmodulo}
                            submodulos={this.state.submoduloslista}
                            selectsubmodulo={this.state.selectsubmodulo}
                            vermsgerror={this.state.vermsgerrorselectsubmodulo}
                            msgerror={this.state.msgerrorselectsubmodulo}
                            submodulo={this.submodulo} 
                            agregar={this.agregarSubmodulo}                          
                          />
                        </div>
                      :null}
                    </>
                  :this.state.selectcategoria == "submodulo/api" ?
                    <>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <InputModulos 
                          modulos={this.state.moduloslista} 
                          selectmodulo={this.state.selectmodulo} 
                          vermsgerror={this.state.vermsgerrorselectmodulo} 
                          msgerror={this.state.msgerrorselectmodulo} 
                          modulo={this.modulo}
                        />
                      </div>
                    </>
                  :null }
                </div>
                <div className="row">
                  <FormularioApis 
                    token={this.props.token}
                    listaapis={this.state.apis}
                    apis={this.apis}                 
                  />
                </div>
                {this.props.accion === 2 ? 
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <InputComentario 
                        checkbox={this.state.confirmar}
                        label={'Añadir un comentario acerca de la edición de la solicitud.'}
                        txtvalor={this.state.txtcomentario}
                        vermsgerror={this.state.vermsgerrorcomentario} 
                        msgerror={this.state.msgerrorcomentario}
                        escribir={this.comentario} 
                        habilitar={this.habilitarComentario}
                      />
                    </div>
                  </div>
                :null}
              </div>
              <div className="divContenedorBotonesModal">
                <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                <Button className="btnAceptarModal"onClick={this.guardarSolicitud}>Guardar</Button>           
              </div>
            </ModalBody>
          </Modal>
        </>
        );
      }
    }
    