import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalEditarComentario.css";
import { ToastContainer, toast } from 'react-toastify';
import { validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import { postEditarComentarioReporte, postEditarComentarioSolicitud } from '../../../Services/Api';
import InputComentario from '../../Formularios/InputComentario';

interface props {
  isOpen: boolean,
  closeModal:(accion:number) => void,
  idcomentario:number,
  comentario:string,
  accion:number,
  token:number
}

interface states {
  isOpen: boolean,

  confirmar:boolean,
  txtcomentario:string,
  vermsgerrorcomentario:boolean,
  msgerrorcomentario:string
}

export default class ModalEditarComentario extends React.Component<props,states>{
  constructor(props:props) {
    super(props);
    this.state = {
      isOpen:this.props.isOpen,
      
      confirmar:this.props.comentario === null ? false : true,
      txtcomentario:this.props.comentario === null ? '' : this.props.comentario,
      vermsgerrorcomentario:false,
      msgerrorcomentario:''
    }
  }

  showModal=(accion:number)=> {
    this.setState({isOpen:false})
    setTimeout(() => {
      this.props.closeModal(accion);
    }, 200);
  }

  comentario = (prop:string) => {
    this.setState({txtcomentario:prop})
  }

  habilitarComentario = (prop:boolean) => {
      this.setState({ confirmar:prop });
  }

  editarComentario = async () => {
    this.setState({ vermsgerrorcomentario: false});

    let longitudvalida = {longitudminima: 8, longitudmaxima: 300};

    let txtcomentario = validarLetrasNumerosEspacios(this.state.txtcomentario, longitudvalida);

    let validacionescorrectas = true;

    if (txtcomentario.codigo == "invalid" && this.state.confirmar === true) {
      this.setState({ msgerrorcomentario: txtcomentario.mensaje });
      this.setState({ vermsgerrorcomentario: true });
      validacionescorrectas = false;
    }

    if (validacionescorrectas) {
      let json = {
        "token":this.props.token,
        "id_comentario":this.props.idcomentario,
        "comentario_checkbox":this.state.confirmar,
        "comentario":this.state.txtcomentario
      }

      if(this.props.accion === 1){
        const resp = await postEditarComentarioReporte(json);
        if (resp.code == 0) {
          toast.success(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(1)
        }
        else if (resp.code == 200) {
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
        else if (resp.code == 250) {
          toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(1)
        }
        else if (resp.code == 1000 || resp == -1) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
      }
      else if(this.props.accion === 2){
        const resp = await postEditarComentarioSolicitud(json);
        if (resp.code == 0) {
          toast.success(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(1)
        }
        else if (resp.code == 200) {
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
        else if (resp.code == 250) {
          toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(1)
        }
        else if (resp.code == 1000 || resp == -1) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
      }
    }
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader className="modalHeader">
            <label className='labelTituloModal'>
              Añadif o editar comentario
            </label>
            <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
          </ModalHeader>
          <ModalBody>
            <div className="divContenedorModal">
              <InputComentario
                checkbox={this.state.confirmar}
                label={'Añadir o editar comentario'}
                txtvalor={this.state.txtcomentario}
                vermsgerror={this.state.vermsgerrorcomentario}
                msgerror={this.state.msgerrorcomentario}
                escribir={this.comentario}
                habilitar={this.habilitarComentario}
              />
            </div>
            <div className="divContenedorBotonesModal">
              <Button className="btnCancelModal" onClick={() => this.showModal(0)} >Cancelar</Button>
              <Button className="btnAceptarModal"  onClick={this.editarComentario} >Guardar</Button>           
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
    