import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

interface props {
    excepciones:string
    vermsgerror:boolean,
    msgerror:string,
    habilitarExcepciones: (prop:string) => void;
}

interface states {
    excepciones:string
}

export default class SelectExcepciones extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            excepciones:this.props.excepciones
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.excepciones !== this.props.excepciones) {
          this.setState({ excepciones: this.props.excepciones });
        }
    }
    
   //Función para cambiar el estado del select de habilitar excepciones.
   excepcionesSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const excepcion = event.target.value;
        this.setState({ excepciones:excepcion});
        this.props.habilitarExcepciones(excepcion)
    };

    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Habilitar excepciones <span className="spanObligatorio">*</span>
                </Label>
                <Input type="select" value={this.state.excepciones} onChange={this.excepcionesSeleccionado}>
                    <option disabled value="">Seleccione una opción</option>
                    <option value="1">Si hay excepciones </option>
                    <option value="0">Sin excepciones </option>
                </Input>
                {this.props.vermsgerror
                    ? 
                        <div className="row">
                            <div className="col-10 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                            </div>
                        </div>
                    :null
                }
            </FormGroup>
        );
    }
}