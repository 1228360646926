import React from 'react';
import { IoMdAdd } from 'react-icons/io';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { FaCheck, FaEye } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';
import ModalFormularioModulo from '../../../Complements/Modales/ModalFormularioModulo/ModalFormularioModulo';
import ModalFormularioSubmodulo from '../../../Complements/Modales/ModalFormularioSubmodulo/ModalFormularioSubmodulo';
import { solicitud } from '../Interfaces';

interface props{
    idrol:number
    token:number
    solicitud:solicitud
    cambios:boolean
    actualizarComentarios:() => void;
}

interface states {
    redireccionando:number
    cambios:boolean,

    accionmodal:number,
    titulomodal:string,
    showmodalformulariomodulo:boolean,
    showmodalformulariosubmodulo:boolean
}

export default class SeccionUbicacion extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            redireccionando:0,
            cambios:this.props.cambios,

            accionmodal:0,
            titulomodal:'',
            showmodalformulariomodulo:false,
            showmodalformulariosubmodulo:false
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.cambios !== this.props.cambios) {
          this.setState({ cambios: this.props.cambios });
        }
    }

    //Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}
		return false
	}

    //Función para abrir modal para crear módulo.
	verModalFormularioModulo = (accion: number) => {
		if (accion == 1) {
			this.setState({ titulomodal: "Nuevo módulo para solicitud", accionmodal: accion})
		}
		this.setState({ showmodalformulariomodulo: true })
	}

	//Función para cerrar modal para crear módulo.
	cerrarModalCrearModulo = (accion: number) => {
		this.setState({ showmodalformulariomodulo: !this.state.showmodalformulariomodulo });
		if (accion == 1) {
			toast.success("Módulo creado exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.actualizarComentarios();
		}
	}

	//Función para abrir modal para crear un submódulo.
	verModalFormularioSubmodulo = (accion: number) => {
		if (accion == 1) {
			this.setState({ titulomodal: "Nuevo submódulo para solicitud", accionmodal: accion })
		}
		this.setState({ showmodalformulariosubmodulo: true })
	}

	//Función para cerar el modal para crear un submódulo.
	cerrarModalFormularioSubmodulo = (accion: number) => {
		this.setState({ showmodalformulariosubmodulo: !this.state.showmodalformulariosubmodulo });
		if (accion == 1) {
			toast.success("Submódulo creado exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            this.props.actualizarComentarios()
		}
	}
    
    //Función para ir al módulo o submódulo
	redireccionarUbicacion = (ubicacion:string) => {
		if(ubicacion === "modulo"){
			localStorage.setItem("id_proyecto", String(this.props.solicitud.id_proyecto));
			localStorage.setItem("id_modulo", String(this.props.solicitud.id_modulo));
			this.setState({redireccionando:1})
		}
		else if(ubicacion === "submodulo"){
			localStorage.setItem("id_proyecto", String(this.props.solicitud.id_proyecto));
			localStorage.setItem("id_modulo", String(this.props.solicitud.id_modulo));
			localStorage.setItem("id_submodulo", String(this.props.solicitud.id_submodulo));
			this.setState({redireccionando:2})
		}
	}
    
    render() {
        return (
            <>
                {/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/}
                {this.state.redireccionando == 1 ?
					<>
						{Acciones("modulo")}
					</>
				:this.state.redireccionando == 2 ?
					<>
						{Acciones("submodulo")}
					</>
                :null}
                {/*////////// Sección de activación de modales y spinner //////////*/}
                {(this.state.showmodalformulariomodulo == true) ? (
					<ModalFormularioModulo isOpen={true} titulomodal={this.state.titulomodal} accion={this.state.accionmodal}
					closeModal={this.cerrarModalCrearModulo} idproyecto={this.props.solicitud.id_proyecto}
					token={this.props.token} idsolicitud={this.props.solicitud.id_solicitud} />
				) : null}

				{(this.state.showmodalformulariosubmodulo == true) ? (
					<ModalFormularioSubmodulo isOpen={true} closeModal={this.cerrarModalFormularioSubmodulo} idproyecto={this.props.solicitud.id_proyecto}
					idmodulo={this.props.solicitud.id_modulo} token={this.props.token} titulomodal={this.state.titulomodal} accion={this.state.accionmodal}
					idsolicitud={this.props.solicitud.id_solicitud}/>
				) : null}

                <div className="row">
                    {this.props.solicitud.solicitud_categoria == "api" ?
                        <>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <span className="tituloEncabezado">Proyecto: </span>
                                <span className="tituloProyecto">{this.props.solicitud.nombre_proyecto} </span>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <span className="tituloEncabezado">Modulo: </span>
                                <span className="tituloProyecto">{this.props.solicitud.nombre_modulo} </span>
                            </div>
                            {this.props.solicitud.submodulo_general !== 1 ? 
                                <>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                        <span className="tituloEncabezado">Submódulo: </span>
                                        <span className="tituloProyecto">{this.props.solicitud.nombre_submodulo} </span>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-2">
                                        <Button className="botonAccionSolicitud" color="primary" onClick={() => this.redireccionarUbicacion("submodulo")}>
                                            <FaEye size={20} color="white" />&nbsp;ir a ubicación
                                        </Button>
                                    </div>
                                </>
                            :
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <Button className="botonAccionSolicitud" color="primary" onClick={() => this.redireccionarUbicacion("modulo")}>
                                        <FaEye size={15} color="white" />&nbsp;Ir a ubicación
                                    </Button>
                                </div>
                            }
                        </>
                    :this.props.solicitud.solicitud_categoria == "modulo/api" ?
                        <>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <span className="tituloEncabezado">Proyecto: </span>
                                <span className="tituloProyecto">{this.props.solicitud.nombre_proyecto} </span>
                            </div>
                            {this.props.solicitud.id_modulo == 1 && (this.props.idrol === 1 || this.props.idrol === 2 || this.props.idrol === 4) ? 
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <span className="tituloEncabezado">Módulo: </span>
                                    <Button className="botonAccionSolicitud" color="success" onClick={() => this.verModalFormularioModulo(1)}>
                                        <IoMdAdd size={15} color="white" />&nbsp;Crear módulo
                                    </Button>
                                </div>
                            :this.props.solicitud.id_modulo == 1 && this.props.idrol === 3 ? 
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <span className="tituloEncabezado">Módulo: </span>
                                    <em>Aún no crean el módulo</em>
                                </div>
                            :
                                <>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                        <span className="tituloEncabezado">Módulo: </span>
                                        <span className="tituloProyecto">{this.props.solicitud.nombre_modulo}</span>
                                        <FaCheck size={15} color="green" />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">											
                                        <Button className="botonAccionSolicitud" color="primary" onClick={() => this.redireccionarUbicacion("modulo")}>
                                            <FaEye size={20} color="white" />&nbsp;Ir a ubicación
                                        </Button>
                                    </div>
                                </>
                            }
                        </>
                    :this.props.solicitud.solicitud_categoria == "submodulo/api" ?
                        <>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <span className="tituloEncabezado">Proyecto: </span>
                                <span className="tituloProyecto">{this.props.solicitud.nombre_proyecto} </span>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <span className="tituloEncabezado">Modulo: </span>
                                <span className="tituloProyecto">{this.props.solicitud.nombre_modulo} </span>
                            </div>
                            {this.props.solicitud.submodulo_general !== 0 && (this.props.idrol === 1 || this.props.idrol === 2 || this.props.idrol === 4)? 
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <span className="tituloEncabezado">Submódulo: </span>
                                    <Button className="botonAccionSolicitud" color="success" onClick={() => this.verModalFormularioSubmodulo(1)}>
                                        <IoMdAdd size={15} color="white" />&nbsp;Crear submódulo
                                    </Button>
                                </div>
                            :this.props.solicitud.submodulo_general !== 0 && this.props.idrol === 3 ? 
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <span className="tituloEncabezado">Submódulo: </span>
                                    <em>Aún no crean el submódulo</em>
                                </div>
                            :
                                <>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                        <span className="tituloEncabezado">Submódulo: </span>
                                        <span className="tituloProyecto">{this.props.solicitud.nombre_submodulo} </span>
                                        <FaCheck size={15} color="green" />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                        <Button className="botonAccionSolicitud" color="primary" onClick={() => this.redireccionarUbicacion("submodulo")}>
                                            <FaEye size={20} color="white" />&nbsp;Ir a ubicación
                                        </Button>

                                    </div>
                                </>
                            }
                        </>
                    :null}
                </div>
            </>
        );
    }
}
function Acciones(bandera:string) {
    if(bandera=="modulo"){
		return <Navigate to="/modulo" />;
	}
	else if(bandera=="submodulo"){
		return <Navigate to="/submodulo" />;
	}
}
