import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import "./EstilosFormularios.css";

interface props {
    prioridad:string,
    vermsgerror:boolean,
    msgerror:string,
    seleccionar: (prop: string) => void;
}

interface states {
    prioridad:string
}

export default class InputSelectPrioridad extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            prioridad:this.props.prioridad
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.prioridad !== this.props.prioridad) {
          this.setState({ prioridad: this.props.prioridad });
        }
    }

    prioridadSeleccionada = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const prioridad = event.target.value;
        this.setState({ prioridad:prioridad });
        this.props.seleccionar(prioridad)
    };
   
    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Prioridad <span className="spanObligatorio">*</span>
                </Label>
                <Input type="select" value={this.state.prioridad} onChange={this.prioridadSeleccionada}>
                    <option disabled value="" selected>Seleccione una opción</option>
                    <option value="Baja">Baja</option>
                    <option value="Media">Media</option>
                    <option value="Alta">Alta</option>
                    <option value="Muy alta">Muy Alta</option>
                    <option value="Urgente">Urgente</option>
                </Input>
                {this.props.vermsgerror? 
                    <div className="row">
                        <div className="col-10 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    </div>
                :null}
            </FormGroup>
        );
    }
}