import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import { evidencia } from '../Interfaces';
import { getListaImagenesReporte } from '../../../Services/Api';
import ModalEliminarImagenEvidenciaReporte from '../../../Complements/Modales/ModalEliminarImagenEvidenciaReporte/ModalEliminarImagenEvidenciaReporte';

interface props{
    cambios:boolean,
    idrol:number
    token:number
    estado:string,
    actualizarComentarios:()=> void;
}

interface states {
    idproyecto:number
    idreporte:number
    cambios:boolean,
    visualizarevidencias:boolean,
    evidencias:evidencia[],
    url:string,

    showmodaleliminarimagen:boolean,
    idevidencia:number,
    nombreevidencia:string
}

export default class Evidencias extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            idproyecto:0,
            idreporte:0,
            cambios:this.props.cambios,
            visualizarevidencias:true,
            url:'https://www.apibox.izttra.com/apibox-api/',
			//url:'http://localhost/apibox-api/',
            evidencias:[],

            showmodaleliminarimagen:false,
            idevidencia:0,
            nombreevidencia:''
        }
    }

    async componentDidMount() {
        var id_reporte = await localStorage.getItem('id_reporte');
		var id_proyecto = await localStorage.getItem('id_proyecto')

		const apireporteevidencias = await getListaImagenesReporte(this.props.token,Number(id_reporte),Number(id_proyecto));
		if(this.respuestaApi (apireporteevidencias)){
			return;
		}
		this.setState({evidencias: apireporteevidencias.data, idproyecto:Number(id_proyecto), idreporte:Number(id_reporte)});
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.cambios !== this.props.cambios) {
          this.setState({ cambios: this.props.cambios, visualizarevidencias:true });
          this.componentDidMount()
        }
    }

    
	//Función para abrir modal de la imagen.
	verModalEliminarEvidencia = (id_evidencia: number, imagen_evidencia:string) => {
		this.setState({ idevidencia: id_evidencia,nombreevidencia:imagen_evidencia  })
		this.setState({ showmodaleliminarimagen: true })
	}

	//Función para cerrar modal de la imagen.
	cerrarModalEliminarEvidencia = (accion: number) => {
		this.setState({ showmodaleliminarimagen: !this.state.showmodaleliminarimagen });
		if (accion == 1) {
			this.setState({visualizarevidencias:true})
			this.props.actualizarComentarios()
			localStorage.setItem("accion", "1");
			this.componentDidMount();
		}
	}

    //Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			return 
		}
        else if (resp.code == 100) {
			this.setState({visualizarevidencias:false})
			return 
		}
		return false
	}

    render() {
        return (
            <>
                {(this.state.showmodaleliminarimagen == true) ? (
					<ModalEliminarImagenEvidenciaReporte isOpen={true} idevidencia={this.state.idevidencia} token={this.props.token} 
					idproyecto={this.state.idproyecto} closeModal={this.cerrarModalEliminarEvidencia} 
					idreporte={this.state.idreporte} />
				) : null}
                
                <span className="tituloEncabezado">Evidencias:</span>
                {this.state.visualizarevidencias === false ? 
                    <div className="row">
                        <em style={{fontSize:'15px'}}>El reporte no cuenta con imagenes como evidencias.</em>
                    </div>
                :
                    <div className="row">
                        {this.state.evidencias.map((evidencia, index)=>(	
                            <div key={index} className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-1 divContenedorImagen" >
                                {(this.props.idrol === 1 || this.props.idrol === 3 || this.props.idrol === 4 ) && this.props.estado !== "Resuelto" ?
                                    <button className="botonEliminarImagen" onClick={() => this.verModalEliminarEvidencia(evidencia.id_evidencia, evidencia.imagen_evidencia)}>
                                        <IoMdClose size={25} color="white" />
                                    </button>
                                :null}
                                <a href={this.state.url + evidencia.imagen_evidencia} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={this.state.url + evidencia.imagen_evidencia}
                                        className="imagenReporteEvidencia"
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                }
            </>
        );
    }
}
