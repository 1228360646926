import React from "react";
import { FaFolderOpen } from "react-icons/fa";
import { TbArrowBackUp } from "react-icons/tb";
import { Button, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getProyectoDetalles, postCrearProyecto } from "../../Services/Api";
import { validarArchivoImagen, validarLetrasNumerosEspacios, validarLongitud } from "../../Services/Validaciones";
import { ToastContainer, toast } from 'react-toastify';
import { NavLink, Navigate } from "react-router-dom";
import './GuardarProyecto.css';
import MenuNavbar from "../../Complements/Navbar/Navbar";
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import InputText from "../../Complements/Formularios/InputText";
import InputQuill from "../../Complements/Formularios/InputQuill";
import InputHeaders from "../../Complements/Formularios/InputHeaders";
import InputLogo from "./Componentes/InputLogo";
import Navegacion from "../../Complements/Navegacion/Navegacion";

//Interfaz general del componente.
interface states{
	id_rol:number,
	accion:number;
	estado:boolean,
	cargando:boolean,

	idproyecto:string,
	nombreproyecto:string

	txtnombreproyecto: string,
	vermsgerrornombre:boolean,
	msgerrornombre:string,

	txtdescripcionproyecto:string,
	vermsgerrordescripcion:boolean,
	msgerrordescripcion:string,

	txtheadersproyecto:string,
	vermsgerrorheaders:boolean,
	msgerrorheaders:string,

	url:string,
	logoproyecto:string,
	logo:any,
	vermsgerrorlogo:boolean,
	msgerrorlogo:string,
	perfil:{
		token:number,
        name:string,
		email:string,
		role:number
  	},
	redireccionando:number
}
export default class GuardarProyecto extends React.Component<{},states> {
	constructor(props:{}) {
		super(props);
		this.state = {
			id_rol:0,
			accion:0,
			estado:false,
			cargando:false,

			idproyecto:'',
			nombreproyecto:'',
			txtnombreproyecto: '',
			vermsgerrornombre:false,
			msgerrornombre:'',

			txtdescripcionproyecto:'',
			vermsgerrordescripcion:false,
			msgerrordescripcion:'',

			txtheadersproyecto:'',
			vermsgerrorheaders:false,
			msgerrorheaders:"",

			url:'https://www.apibox.izttra.com/apibox-api/',
			//url:'http://localhost/apibox-api/',
			logoproyecto:'',
			logo:null,
			vermsgerrorlogo:false,
			msgerrorlogo:"",
            perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "", role:""}'),
			redireccionando:0,
		};
	}

	async componentDidMount() {
		/*
			Si no existen datos del usuario almacenados en localStorage, se interpretará que no ha iniciado sesión
			y se redirigirá automáticamente al login.
		*/
        if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
        }

		/*Si el rol del usuario es 3, que es un usuario de tipo frontend, se redirecciona al catalogo de proyectos. 
		Ya que no cuenta con el rol para crear o editar un proyecto.*/
		const id_rol = localStorage.getItem("id_rol")
		const accion = localStorage.getItem("accion");

		if(accion === "1" && this.state.perfil.role !== 1){
			localStorage.setItem("accion", "Sin permisos");
			this.setState({redireccionando:2})
		}
		else if(accion === "2" && id_rol !== "1"){
			localStorage.setItem("accion", "Sin permisos");
			this.setState({redireccionando:1})
		}

		if(accion !== null){
			this.prepararFormulario(accion);
		}else{
			localStorage.setItem("accion", "100");
			this.setState({redireccionando:1})
		}

		if ( !this.state.cargando ) {
			this.setState({ cargando: true });
		}

		setTimeout(() => {
			this.setState({cargando:false})
		}, 1000);
    }

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return
		}
		return false
	}

	//Función de regresar
	regresar = () => {
		window.history.back();
	};

	//Función preparar formulario para guardar proyecto o editar proyecto
	prepararFormulario = async (accion:string) => {
		//1 = Crear proyecto
		if (accion === "1") {
			this.setState({accion:1});
		}
		//2 = Editar proyecto
		else if (accion === "2") {
			this.setState({accion:2});
			var id_proyecto = await localStorage.getItem('id_proyecto');
			const apiproyecto = await getProyectoDetalles(this.state.perfil.token,Number(id_proyecto));
			if(this.respuestaApi (apiproyecto)){
				return;
			}
			this.setState({
				idproyecto: apiproyecto.data.id_proyecto,
				txtnombreproyecto: apiproyecto.data.nombre_proyecto,
				nombreproyecto:apiproyecto.data.nombre_proyecto,
				txtdescripcionproyecto: apiproyecto.data.descripcion_proyecto,
				txtheadersproyecto: apiproyecto.data.headers_proyecto,
				logoproyecto: apiproyecto.data.logo_img
			})
		}
	}

	/* Funciones para actualizar el estado de cada valor a guardar en el proyecto con 
       el valor capturado de su correspondiente input. 
	*/
	nombre = (prop: string) => {
		this.setState({ txtnombreproyecto: prop});
	};

	logo = (prop:File) => {
		this.setState({ logo: prop});
	}

	descripcion = (prop: string) => {
		this.setState({ txtdescripcionproyecto: prop});
	};

	headers = (prop: string) => {
		this.setState({ txtheadersproyecto: prop});
	};
	
	/////////////////////////////// Sección del guardar proyecto.
	//Función para guardar el proyecto.
	guardarProyecto = async () => {
		this.setState({ vermsgerrornombre: false });
		this.setState({ vermsgerrorlogo: false });
		this.setState({ vermsgerrordescripcion: false });
		this.setState({ vermsgerrorheaders: false });

		let longitudvalidanombre = {longitudminima: 3, longitudmaxima: 45};
		let longitudvalidadescripcion = {longitudminima: 20, longitudmaxima: 3000};
		let longitudvalidaheaders = {longitudminima: 50, longitudmaxima: 500};

		let txtnombre = validarLetrasNumerosEspacios(this.state.txtnombreproyecto, longitudvalidanombre);
		let logo = validarArchivoImagen(this.state.logo)
		let txtdescripcion = validarLongitud(this.state.txtdescripcionproyecto, longitudvalidadescripcion);
		let txtheaders = validarLongitud(this.state.txtheadersproyecto, longitudvalidaheaders);
		
		let validacionescorrectas = true;

        if (txtnombre.codigo == "invalid") {
			this.setState({ msgerrornombre: txtnombre.mensaje });
            this.setState({ vermsgerrornombre: true });
            validacionescorrectas = false;
        }

		if (logo.codigo == "invalid") {
			this.setState({ msgerrorlogo: logo.mensaje });
            this.setState({ vermsgerrorlogo: true });
            validacionescorrectas = false;
        }

		if (txtdescripcion.codigo == "invalid") {
			this.setState({ msgerrordescripcion: txtdescripcion.mensaje });
            this.setState({ vermsgerrordescripcion: true });
            validacionescorrectas = false;
        }

		if (txtheaders.codigo == "invalid") {
			this.setState({ msgerrorheaders: txtheaders.mensaje });
            this.setState({ vermsgerrorheaders: true });
            validacionescorrectas = false;
        }
	
		if (validacionescorrectas) {
			const formData = new FormData();
			formData.append('id_proyecto',this.state.accion == 1 ? ("0") : (this.state.idproyecto));
			formData.append('token', String(this.state.perfil.token));
			formData.append('nombre_proyecto',this.state.txtnombreproyecto);
			formData.append('descripcion_proyecto', this.state.txtdescripcionproyecto);
			formData.append('headers_proyecto', this.state.txtheadersproyecto);
			formData.append('logo_img', this.state.logo)
			
			const resp = await postCrearProyecto(formData);
			if (resp.code == 250) {
				this.setState({ msgerrornombre: resp.message });
            	this.setState({ vermsgerrornombre: true });
			}
			else if (resp.code == 0) {
				const idproyecto = resp.data.id_proyecto;
				const id_rol = resp.data.id_rol
				localStorage.setItem("id_proyecto", idproyecto.toString());
				localStorage.setItem("id_rol", id_rol.toString());
				localStorage.setItem("accion", "Proyectocreado");
				this.setState({ redireccionando: 1 })
			}
			else if (resp.code == 100) {
				const idproyecto = resp.data.id_proyecto;
				localStorage.setItem("id_proyecto", idproyecto);
				localStorage.setItem("accion", "Proyectoeditado");
				this.setState({ redireccionando: 1 })
			}
			else if (resp.code == 150) {
				const idproyecto = resp.data.id_proyecto;
				localStorage.setItem("id_proyecto", idproyecto);
				localStorage.setItem("accion", "LogoActualizado");
				this.setState({ redireccionando: 1 })
			}
			else if (resp.code == 200) {
				toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			else if (resp.code == 300) {
				toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			else if (resp.code == 500) {
				localStorage.setItem("accion", "SinCambios");
				this.setState({ redireccionando: 1 })
			}
			else if (resp.code == 1000 || resp == -1) {
				toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
		}
		
	}
	render() {
		return ( 
			<div className="divContenedorPrincipal">
				{/*////////// Sección de redirigir a paginas mediante condicionales //////////*/}
				{this.state.redireccionando == 1 ?
					<>
						{Acciones("detallesproyecto")}
					</>
				: this.state.redireccionando == 2 ?
					<>
						{Acciones("cancelar")}
					</>
				:this.state.redireccionando == 3 ?
					<>
						{Acciones("cancelar1")}
					</>
				:null}

				{/*////////// Sección de activación spinner //////////*/}
				{(this.state.cargando == true) ?
						<SpinnerModal tipo="full" show={this.state.cargando}/>
				: null}
				<ToastContainer />
				<MenuNavbar/>
				<main>
					{/*////////// Sección de breadscrum //////////*/}
					<Navegacion
						seccion={"guardar-proyecto"}
						proyecto={this.state.nombreproyecto}
						accion={this.state.accion}
					/>
					{/*////////// Sección de formulario para guardar proyecto. //////////*/}
					<div className="row">
						{/*////////// Sección de input de nombre del proyecto //////////*/}
						<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-6">
							<InputText
								label={'Nombre del proyecto'}
								placeholder={"Ej. Apibox"}
								limitecaracteres={45}
								txtvalor={this.state.txtnombreproyecto}
								vermsgerror={this.state.vermsgerrornombre}
								msgerror={this.state.msgerrornombre}
								escribir={this.nombre}
							/>
						</div>
						{/*////////// Sección de input para cargar logo del proyecto //////////*/}
						<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-6">
							<InputLogo 
								accion={this.state.accion} 
								logourl={this.state.logoproyecto} 
								vermsgerror={this.state.vermsgerrorlogo} 
								msgerror={this.state.msgerrorlogo} 
								logo={this.logo}/>
						</div>
					</div>
					<div className="row">
						{/*////////// Sección de input de descripción del proyecto//////////*/}
						<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2 divContenedorTextArea">
							<InputQuill 
								label={"Descripcion del proyecto"} 
								isOpcional={false} 
								isModal={false} 
								placeholder={"Ingresa la descripción del proyecto"} 
								limitecaracteres={3000} 
								txtvalor={this.state.txtdescripcionproyecto} 
								txtvalorlength={0} 
								vermsgerror={this.state.vermsgerrordescripcion} 
								msgerror={this.state.msgerrordescripcion} 
								escribir={this.descripcion}
							/>
						</div>
						{/*////////// Sección de input de headers del proyecto//////////*/}
						<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2 divContenedorTextAreaHeaders">
							<InputHeaders 
								isOpcional={false}
								isModal={false}
								label={"Headers del proyecto"}
								limitecaracteres={500}
								txtvalor={this.state.txtheadersproyecto}
								vermsgerror={this.state.vermsgerrorheaders}
								msgerror={this.state.msgerrorheaders}
								escribir={this.headers}								
							/>
						</div>
					</div>
					{/*////////// Sección de botón para cancelar y guardar el proyecto //////////*/}
					<div className="row mt-3">
						<div className="col divContenedorBotonesGuardar">
							{this.state.accion === 1 ? (
								<Button className="botonGuardarProyecto" color="success" onClick={this.guardarProyecto}>Guardar proyecto</Button>
							) : this.state.accion === 2 ? (
								<Button className="botonGuardarProyecto" color="success" onClick={this.guardarProyecto}>Guardar cambios</Button>
							) : null}
						</div>
					</div>
				</main>
			</div>
		);
	};
}
//Función para redireccionar a diferentes páginas mediante condiciones del valor recibida
function Acciones(bandera:string) {
    if (bandera == 'detallesproyecto') {
		return <Navigate to="/proyecto" />;
    } else if(bandera == 'cancelar'){
		return <Navigate to="/catalogoproyectos" />;
	}else if(bandera == 'cancelar1'){
		return <Navigate to="/proyecto" />;
	}
}