import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { formatocuerpo } from '../Interfaces';
import { getListaFormatosCuerpo } from '../../../Services/Api';
import { toast } from 'react-toastify';

interface props {
    token:number
    formatocuerpo:number
    vermsgerror:boolean,
    msgerror:string,
    formatoCuerpoSeleccionado: (formatocuerpo:number) => void;
}

interface states {
    formatocuerpo:number
    formatocuerpolista:formatocuerpo[]
}

export default class SelectFormatoCuerpo extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            formatocuerpo:this.props.formatocuerpo,
            formatocuerpolista:[]
        }
    }

    async componentDidMount() {
		const apiformatossolicitud = await getListaFormatosCuerpo(this.props.token);
		if(this.respuestaApi (apiformatossolicitud)){
			return;
		}
        console.log(apiformatossolicitud)
		this.setState({formatocuerpolista: apiformatossolicitud.data});	
    }

    //Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return 
		}
		return false
	}

    componentDidUpdate(prevProps: props) {
        if (prevProps.formatocuerpo !== this.props.formatocuerpo) {
          this.setState({ formatocuerpo: this.props.formatocuerpo });
        }
    }
    
    //Función para cambiar el estado del select de tipo solicitud.
	formatoCuerpoSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const formatocuerpo = Number(event.target.value);
		this.setState( {formatocuerpo:formatocuerpo});
        this.props.formatoCuerpoSeleccionado(formatocuerpo)
	};

    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Formato de cuerpo <span className="spanObligatorio">*</span> 
                </Label>
                <Input type="select" value={this.state.formatocuerpo} onChange={this.formatoCuerpoSeleccionado}>
                    <option disabled value={0}>Seleccione una opción</option>
                    {this.state.formatocuerpolista.map((formatocuerpo, index)=>(	
                        <option key={index} value= {formatocuerpo.id_formatos_cuerpo}>
                            {formatocuerpo.formato_cuerpo}
                        </option>
                    ))}
                </Input>
                {this.props.vermsgerror
                    ? 
                        <div className="row">
                            <div className="col-10 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                            </div>
                        </div>
                    :null
                }
            </FormGroup>
        );
    }
}