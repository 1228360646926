export interface solicitud {
	id_solicitud: number,
	titulo_solicitud: string,
	fecha_generacion: string,
	id_tipo_solicitud: number,
	prioridad:string,
	estado: string,
	descripcion_solicitud:string,
	fecha_entrega: string,
	fecha_ent_formateada:string,
	tiempo_faltante:string,
	solicitud_categoria: string,
	num_apis: number,
	id_proyecto: number,
	nombre_proyecto: string,
	id_modulo: number,
	nombre_modulo:string,
	id_submodulo: number,
	nombre_submodulo: string,
	submodulo_general: number,
	nombre_usuario: string,
	apellido_paterno:string,
	apellido_materno: string
}

//Interfaz de la estructura de datos de cada api.
export interface api{
	//Tipado de la api.
	idsolicitud_tiene_api: number,
	id_api: number,
	nombre_api: string,
	estado: string,
    tipo_solicitud: string,
    tipo_solicitud_api: string
	//Tipado del formulario
	index:number,
	id_tipo_solicitud: number,
	txtapi:string,
	vermsgerrortapi:boolean,
	msgerrortapi:string
}

export const solicitudestado: solicitud = {
    id_solicitud: 0,
    titulo_solicitud: "",
    fecha_generacion: "",
    id_tipo_solicitud: 0,
    prioridad:"",
    estado: "",
    descripcion_solicitud:"",
    fecha_entrega: "",
    fecha_ent_formateada:"",
    tiempo_faltante:"",
    solicitud_categoria: "",
    num_apis: 0,
    id_proyecto: 0,
    nombre_proyecto: "",
    id_modulo: 0,
    nombre_modulo:"",
    id_submodulo: 0,
    nombre_submodulo: "",
    submodulo_general: 0,
    nombre_usuario: "",
    apellido_paterno:"",
    apellido_materno: ""
};