import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Input, Label } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioMigrarApi.css";
import { getListaProyectos, getListaSubmodulosDelModuloSinFiltro, getModulosDelProyecto, postCopiarDocumentacionApi, postMigrarDocumentacionApi } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import { validarInputSelect, validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import { proyecto } from '../../../Pages/DetallesProyecto/Interfaces';
import { modulo } from '../../../Pages/DetallesModulo/Interfaces';
import { submodulo } from '../../../Pages/DetallesSubmodulo/Interfaces';
import InputText from '../../Formularios/InputText';

interface props {
  isOpen: boolean,
  closeModal:(accion:number) => void,
  idproyecto:number,
  idmodulo:number, 
  idsubmodulo:number,
  submodulo_general:number,
  idapi:number,
  token:number,
  accion:number //1 migrar 2 copiar
  nombreapi?:string
}

interface states {
  isOpen: boolean,
  confirmarenvio: boolean,

  proyectoslista:proyecto[],
  selectproyecto:number,
  vermsgerrorselectproyecto:boolean,
  msgerrorselectproyecto:string,

  moduloslista:modulo[],
  selectmodulo:number,
  vermsgerrorselectmodulo:boolean,
  msgerrorselectmodulo:string,

  submodulo_general:number,
  agregarsubmodulo:boolean,
  submoduloslista:submodulo[],
  selectsubmodulo:number,
  vermsgerrorselectsubmodulo:boolean,
  msgerrorselectsubmodulo:string

  txtnombre:string,
  vermsgerror:boolean,
  msgerror:string
}

export default class ModalFormularioMigrarApi extends React.Component<props,states>{
  constructor(props:props) {
    super(props);
    this.state = {
      isOpen:this.props.isOpen,
      confirmarenvio:false,

      proyectoslista:[],
      selectproyecto: this.props.idproyecto,
      vermsgerrorselectproyecto:false,
      msgerrorselectproyecto:'',
  
      moduloslista:[],
      selectmodulo: this.props.idmodulo,
      vermsgerrorselectmodulo:false,
      msgerrorselectmodulo:'',

      submodulo_general:this.props.submodulo_general,
      agregarsubmodulo:false,
      submoduloslista:[],
      selectsubmodulo:this.props.idsubmodulo,
      vermsgerrorselectsubmodulo:false,
      msgerrorselectsubmodulo:'',

      txtnombre:this.props.nombreapi ?this.props.nombreapi: '',
      vermsgerror:false,
      msgerror:''
    }
  }

  async componentDidMount() {
    if(this.state.submodulo_general === 0){
      this.agregarSubmodulo();
    }

    const apiproyectos = await getListaProyectos(this.props.token);
    this.setState({proyectoslista: apiproyectos.data});

    const apimodulos = await getModulosDelProyecto(this.props.token, this.state.selectproyecto);
    this.setState({moduloslista: apimodulos.data});

    const apisubmodulos = await getListaSubmodulosDelModuloSinFiltro(this.props.token, this.state.selectproyecto, this.state.selectmodulo);
    this.setState({submoduloslista: apisubmodulos.data});
  }
  
  showModal=(accion:number)=> {
    this.setState({isOpen:false})
    setTimeout(() => {
      this.props.closeModal(accion);
    }, 200);
  }

  proyectoSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const proyecto = Number(event.target.value);
    this.setState({ selectproyecto:proyecto }, async () => {
      const modulos = await getModulosDelProyecto(this.props.token, Number(this.state.selectproyecto));
      this.setState({ moduloslista: modulos.data });
    });
    this.setState({selectmodulo:0,selectsubmodulo:0})
  };

  moduloSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const modulo = Number(event.target.value);
    this.setState({ selectmodulo:modulo }, async () => {
      const submodulos = await getListaSubmodulosDelModuloSinFiltro(this.props.token, Number(this.state.selectproyecto), Number(modulo));                       
      this.setState({ submoduloslista: submodulos.data,
                      selectsubmodulo:submodulos.data[0].id_submodulo,
                      agregarsubmodulo:false 
      });
    });
  };

  agregarSubmodulo = () => {
    this.setState({agregarsubmodulo:true})
  }

  submoduloSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const submodulo = Number(event.target.value);
    this.setState({ selectsubmodulo:submodulo });
  };

  nombre = (prop: string) => {
    this.setState({ txtnombre: prop});
  };

  migrarApi = async () => {
    if(this.props.accion === 1) {
      this.setState({ vermsgerrorselectproyecto: false});
      this.setState({ vermsgerrorselectmodulo: false});
      this.setState({ vermsgerrorselectsubmodulo: false});
      
      let selecproyecto = validarInputSelect(String(this.state.selectproyecto));
      let selectmodulo = validarInputSelect(String(this.state.selectmodulo));
    
      let validacionescorrectas = true;
  
      if (selecproyecto.codigo == "invalid") {
        this.setState({ msgerrorselectproyecto: selecproyecto.mensaje });
        this.setState({ vermsgerrorselectproyecto: true });
        validacionescorrectas = false;
      }
  
      if (selectmodulo.codigo == "invalid") {
        this.setState({ msgerrorselectmodulo: selectmodulo.mensaje });
        this.setState({ vermsgerrorselectmodulo: true });
        validacionescorrectas = false;
      }
      
      if (validacionescorrectas) {
  
        let json = {
          "token":this.props.token,
          "id_proyecto":this.props.idproyecto,
          "id_submodulo":this.state.selectsubmodulo,
          "id_api":this.props.idapi
        }
  
        const resp = await postMigrarDocumentacionApi(json);
  
        if (resp.code == 0) {
          const id_proyecto = resp.data.id_proyecto;
          const id_modulo = resp.data.id_modulo;
          const id_submodulo = resp.data.id_submodulo;
          const submodulo_general = resp.data.submodulo_general
          localStorage.setItem("id_proyecto", id_proyecto.toString());
          localStorage.setItem("id_modulo", id_modulo.toString());
          localStorage.setItem("id_submodulo", id_submodulo.toString());
          
          if(submodulo_general === 1){
            this.showModal(1)
          }
          else if(submodulo_general === 0){
            localStorage.setItem("submodulo_general", submodulo_general.toString());
            this.showModal(2)
          }
        }
        else if(resp.code==100){
          this.showModal(0)
          toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else if(resp.code==150){
          this.showModal(0)
          toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else if (resp.code == 200) {
          this.showModal(0)
          toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else if (resp.code == 300) {
          this.showModal(0)
          toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else if (resp.code == 1000 || resp == -1) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
      }
    }
    else if(this.props.accion === 2){
      this.setState({ vermsgerrorselectproyecto: false});
      this.setState({ vermsgerrorselectmodulo: false});
      this.setState({ vermsgerrorselectsubmodulo: false});
      this.setState({ vermsgerror: false});
      
      let longitudvalidanombre = {longitudminima: 3, longitudmaxima: 70};

      let selecproyecto = validarInputSelect(String(this.state.selectproyecto));
      let selectmodulo = validarInputSelect(String(this.state.selectmodulo));
      let selectsubmodulo = validarInputSelect(String(this.state.selectsubmodulo));
      let txtnombre = validarLetrasNumerosEspacios(String(this.state.txtnombre),longitudvalidanombre);
      let validacionescorrectas = true;
  
      if (selecproyecto.codigo == "invalid") {
        this.setState({ msgerrorselectproyecto: selecproyecto.mensaje });
        this.setState({ vermsgerrorselectproyecto: true });
        validacionescorrectas = false;
      }
  
      if (selectmodulo.codigo == "invalid") {
        this.setState({ msgerrorselectmodulo: selectmodulo.mensaje });
        this.setState({ vermsgerrorselectmodulo: true });
        validacionescorrectas = false;
      }
      if (txtnombre.codigo == "invalid") {
        this.setState({ msgerror: txtnombre.mensaje });
        this.setState({ vermsgerror: true });
        validacionescorrectas = false;
      }
      
      if (validacionescorrectas) {
  
        let json = {
          "token":this.props.token,
          "id_proyecto":this.props.idproyecto,
          "id_submodulo":this.state.selectsubmodulo,
          "id_api":this.props.idapi,
          "nombre_api":this.state.txtnombre
        }
      
        const resp = await postCopiarDocumentacionApi(json);

        if (resp.code == 0) {
          const id_proyecto = resp.data.id_proyecto;
          const id_modulo = resp.data.id_modulo;
          const id_submodulo = resp.data.id_submodulo;
          const submodulo_general = resp.data.submodulo_general
          localStorage.setItem("id_proyecto", id_proyecto.toString());
          localStorage.setItem("id_modulo", id_modulo.toString());
          localStorage.setItem("id_submodulo", id_submodulo.toString());
          
          if(submodulo_general === 1){
            this.showModal(3)
          }
          else if(submodulo_general === 0){
            localStorage.setItem("submodulo_general", submodulo_general.toString());
            this.showModal(4)
          }
        }
        else if(resp.code==100){
          this.showModal(0)
          toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else if(resp.code==150){
          this.showModal(0)
          toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else if (resp.code == 200) {
          this.showModal(0)
          toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else if (resp.code == 250) {
          this.setState({ msgerror: resp.message });
          this.setState({ vermsgerror: true });
        }
        else if (resp.code == 300) {
          this.showModal(0)
          toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        else if (resp.code == 1000 || resp == -1) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
        
      }
    }
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal isOpen={this.state.isOpen} size="md">
          <ModalHeader className="modalHeader">
              {this.props.accion === 1 ?
                <label className='labelTituloModal'> Migrar api </label>
              :this.props.accion === 2 ?
                <label className='labelTituloModal'> Copiar api </label>
              :null}
              <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
          </ModalHeader>
          <ModalBody>
            <div className="divContenedorModal">
              {this.props.accion === 2 &&
                <div className="row">
                  <div className="col-12">
                    <InputText
                      label={'Nombre de la api'}
                      placeholder='Ej. Login'
                      limitecaracteres={45}
                      txtvalor={this.state.txtnombre}
                      vermsgerror={this.state.vermsgerror}
                      msgerror={this.state.msgerror}
                      escribir={this.nombre}
                    />
                  </div>
                </div>
              }
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <FormGroup className='formContenedor'>
                    <Label className='labelInputModal'>
                      Proyecto
                    </Label>
                    <Input type="select" value={this.state.selectproyecto} onChange={this.proyectoSeleccionado}>
                      <option disabled value="0" selected>Seleccione una opción</option>
                        {this.state.proyectoslista.map((Proyecto, key)=>(	
                            <option key={key} value= {Proyecto.id_proyecto}>
                              {Proyecto.nombre_proyecto} - {this.state.selectproyecto}
                            </option>
                        ))}
                    </Input>
                    {this.state.vermsgerrorselectproyecto ?
                      <div className="row">
                        <div className="col-10 contenedorMensajeError">
                        <span className="mensajeError">{this.state.msgerrorselectproyecto}</span>
                        </div>
                      </div>
                    :null}
                  </FormGroup>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <FormGroup className='formContenedor'>
                    <Label className='labelInputModal'>
                      Módulo
                    </Label>
                    <Input type="select" value={this.state.selectmodulo} onChange={this.moduloSeleccionado}>
                        <option disabled value="0">Seleccione una opción</option>
                          {this.state.moduloslista.map((Modulo, index) => (	
                            <option value={Modulo.id_modulo} key={index}>
                              {Modulo.nombre_modulo}
                            </option>
                          ))}
                      </Input>
                    {this.state.vermsgerrorselectmodulo ? 
                      <div className="row">
                        <div className="col-10 contenedorMensajeError">
                        <span className="mensajeError">{this.state.msgerrorselectmodulo}</span>
                        </div>
                      </div>
                    :null}
                  </FormGroup>
                </div>
                {this.state.selectmodulo != 0 ? 
                  <div className="col-12">
                    <FormGroup className='formContenedor'>
                      <Label className='labelInputModal'>
                        Submódulo
                      </Label>
                      <br />
                      {this.state.agregarsubmodulo == false ? 
                        <button className='botonSubmodulo' onClick={this.agregarSubmodulo}>
                          Agregar submódulo
                        </button>
                      :
                      <Input type="select" value={this.state.selectsubmodulo} onChange={this.submoduloSeleccionado}>
                          {this.state.submoduloslista.map((Submodulo)=>(	
                              (Submodulo.submodulo_general == 1) ? 
                                (
                                  <option value= {Submodulo.id_submodulo}>
                                    Sin submódulo
                                  </option> 
                                ) 
                                : 
                                <option value= {Submodulo.id_submodulo}>
                                  {Submodulo.nombre_submodulo}
                                </option>
                          ))}
                      </Input>}
                    </FormGroup>
                  </div>
                :null}
              </div>
            </div>
            <div className="divContenedorBotonesModal">
              <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={this.migrarApi}>Migrar</Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}