import React from 'react';
import { HiPencil } from 'react-icons/hi';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { PostActualizarEstadoReporte, PostActualizarEstadoSolicitud } from '../../../Services/Api';
import { validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import InputComentario from '../../../Complements/Formularios/InputComentario';

interface props {
    idrol:number
    tipo:string
    id:number
    token:number
    idproyecto:number
    estado:string
    generarComentario: (codigo:number, mensaje:string) => void;
}

interface states {
    confirmar:boolean,
    txtcomentario:string,
	txtcomentariolength:number,
	vermsgerrorcomentario:boolean,
    msgerrorcomentario:string,

    estado:string
}

export default class FormularioComentario extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            confirmar:false,
            txtcomentario:'',
            txtcomentariolength:0,
            vermsgerrorcomentario:false,
            msgerrorcomentario:'',

            estado:this.props.estado
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.estado !== this.props.estado) {
          this.setState({ estado: this.props.estado });
        }
    }

    comentario = (prop:string) => {
        this.setState({txtcomentario:prop})
    }

    habilitarComentario = (prop:boolean) => {
        this.setState({ confirmar:prop });
    }

    //Funcion para cambiar el estado del input de estado.
	estadoSeleccionada = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const estado = event.target.value;
		this.setState({ estado:estado });
	};

    //Función para publicar el comentario.
	generarComentario = async () => {
		this.setState({ vermsgerrorcomentario: false});

		let longitudvalida = {longitudminima: 8, longitudmaxima: 300};

		let txtcomentario = validarLetrasNumerosEspacios(this.state.txtcomentario, longitudvalida);

		let validacionescorrectas = true;

		if (txtcomentario.codigo == "invalid" && this.state.confirmar === true) {
			this.setState({ msgerrorcomentario: txtcomentario.mensaje });
			this.setState({ vermsgerrorcomentario: true });
			validacionescorrectas = false;
		}

		if (validacionescorrectas) {
            if(this.props.tipo === 'solicitud'){
                let json = {
                    "token":this.props.token,
                    "id_solicitud": this.props.id,
                    "estado":this.state.estado,
                    "id_proyecto":this.props.idproyecto,
                    "comentario_checkbox":this.state.confirmar,
                    "comentario":this.state.txtcomentario
                }
                const resp = await PostActualizarEstadoSolicitud(json);

                if(resp.code === 0){
                    this.setState({confirmar:false,txtcomentario:''})
                }

                this.props.generarComentario(resp.code, resp.message)
            }
            else if(this.props.tipo === 'reporte'){
                let json = {
                    "token":this.props.token,
                    "id_reporte": this.props.id,
                    "estado":this.state.estado,
                    "id_proyecto":this.props.idproyecto,
                    "comentario_checkbox":this.state.confirmar,
                    "comentario":this.state.txtcomentario
                }

                const resp = await PostActualizarEstadoReporte(json);

                if(resp.code === 0){
                    this.setState({confirmar:false,txtcomentario:''})
                }

                this.props.generarComentario(resp.code, resp.message)
            }
		}
	}

    render() {
        return (
            <>
                <div className="row mt-3">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                        <InputComentario 
                            checkbox={this.state.confirmar}
                            label={'Añadir un comentario'}
                            txtvalor={this.state.txtcomentario}
                            vermsgerror={this.state.vermsgerrorcomentario} 
                            msgerror={this.state.msgerrorcomentario}
                            escribir={this.comentario} 
                            habilitar={this.habilitarComentario}
                        />
                    </div>
                </div>
                {this.props.idrol !== 3 ? 
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <FormGroup className='formContenedor'>
                                <Label className='labelInputModal'>
                                    Estado
                                </Label>
                                <Input type="select" value={this.state.estado} onChange={this.estadoSeleccionada}>
                                    <option disabled value="Nuevo" >Seleccione una opción</option>
                                    <option value="En revisión">En revisión</option>
                                    <option value="En desarrollo">En desarrollo</option>
                                    <option value="En pruebas">En pruebas</option>
                                    <option value="Completado">Completado</option>
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                :null}
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <Button
                            className="botonConIcono"
                            color="success"
                            onClick={this.generarComentario}>
                        {this.props.idrol === 3 ?
                            <>
                                <HiPencil size={20} color="white" />&nbsp;Comentar
                            </>
                        :
                            <>
                                <HiPencil size={20} color="white" />&nbsp;Actualizar estado
                            </>
                        }
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}