import React from 'react';
import { IoIosWarning, IoMdAdd } from 'react-icons/io';
import { toast } from 'react-toastify';
import { Badge, Button, Table } from 'reactstrap';
import { getListaSolicitudTieneApi } from '../../../Services/Api';
import { FaCheckCircle, FaClock, FaExclamationCircle, FaEye, FaFolderOpen } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { RiDeleteBinFill } from 'react-icons/ri';
import { TbExchange } from 'react-icons/tb';
import { Navigate } from 'react-router-dom';
import SpinnerModal from '../../../Complements/Modales/SpinnerModal/SpinnerModal';
import ModalFormularioAgregarCambiarApi from '../../../Complements/Modales/ModalFormularioAgregarCambiarApi/ModalFormularioAgregarCambiarApi';
import ModalEliminarApiSolicitud from '../../../Complements/Modales/ModalEliminarApiSolicitud/ModalEliminarApiSolicitud';
import { api, solicitud } from '../Interfaces';

interface props{
    idrol:number
    token:number
    estado:string
    solicitud:solicitud
    cambios:boolean
    actualizarComentarios:() => void;
}

interface states {
    tabla: {
		encabezados: string[],
		dimensiones: string[],
		apis:api[]
	},
    cargando:boolean
    redireccionando:number
    cambios:boolean,

    accionmodal:number,
    showmodalagregarapi:boolean,
    titulomodal:string,
    idsolicitudtieneapi:number,
	tiposolicitud:string,
	idapi:number,

    showmodaleliminarapi:boolean
}

export default class TablaApis extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            tabla: {
				encabezados: [
				  "No. Api",
				  "Tipo de solicitud requerida",
				  "Nombre de la api",
				  "Tipo de solicitud de la api",
				  "Estado",
				  "Acciones"
				],
				dimensiones: [
					"70px",//No. Api
					"205px",//Tipo de solicitud requerida
					"250px",//Api
					"205px",//Tipo de solicitud de la api
					"100px",//Estado
					"400px",//Acciones
				],
				apis:[]
			},
            cargando:false,
            redireccionando:0,
            cambios:this.props.cambios,

            accionmodal:0,
            showmodalagregarapi:false,
            titulomodal:'',
            idsolicitudtieneapi:0,
            tiposolicitud:'',
            idapi:0,

            showmodaleliminarapi:false
        }
    }

    async componentDidMount() {
        const apisolicitudtieneapi = await getListaSolicitudTieneApi(this.props.token,this.props.solicitud.id_solicitud);
		if(this.respuestaApi (apisolicitudtieneapi)){
			return;
		}
		var tabla = this.state.tabla;
		tabla.apis = JSON.parse(JSON.stringify(apisolicitudtieneapi.data));
        this.setState({cargando:false})
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.cambios !== this.props.cambios) {
          this.setState({ cambios: this.props.cambios });
          this.componentDidMount()
        }
    }

    //Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}
		return false
	}

    //Función para abrir modal para agregar api existente.
	verModalFormularioAgregarApi = (accion: number,idsolicitud_tiene_api:number, tipo_solicitud:string, id_api:number) => {
		if (accion == 1) {
			this.setState({ titulomodal: "Añadir api desde tabla", accionmodal: accion, idsolicitudtieneapi: Number(idsolicitud_tiene_api), 
						    tiposolicitud:tipo_solicitud, idapi:id_api})
		}
		else if (accion == 2) {
			this.setState({ titulomodal: "Cambiar api desde tabla", accionmodal: accion, idsolicitudtieneapi: Number(idsolicitud_tiene_api), 
						    tiposolicitud:tipo_solicitud, idapi:id_api})
		}
		this.setState({ showmodalagregarapi: true })
	}

	//Función para cerrar modal para agregar api existente.
	cerrarModalAgregarApi = (accion: number) => {
		this.setState({ showmodalagregarapi: !this.state.showmodalagregarapi });
		if (accion == 1) {
			this.setState({cargando:true});
            this.props.actualizarComentarios()
			this.componentDidMount();
		}
	}

    //Función para mostrar modal para eliminar api.
	verModalEliminarApi = (idsolicitud_tiene_api: number) => {
		this.setState({ idsolicitudtieneapi: Number(idsolicitud_tiene_api), showmodaleliminarapi: true }) 
	}

	//Función para cerrar modal para eliminar api.
	cerrarModalEliminarApi = (accion: number) => {
		this.setState({ showmodaleliminarapi: !this.state.showmodaleliminarapi });
		if (accion == 1) {
			this.setState({cargando:true})
            localStorage.setItem("accion", "3");
			this.props.actualizarComentarios()
			this.componentDidMount();
		}
	}

    //Función para redireccionar al formulario para documentar una api.
	redireccionandoDocumentarApi(idsolicitud_tiene_api:number){
        localStorage.setItem("idsolicitud_tiene_api", idsolicitud_tiene_api.toString());
		localStorage.setItem("id_proyecto", this.props.solicitud.id_proyecto.toString());
		localStorage.setItem("id_modulo", this.props.solicitud.id_modulo.toString());
		localStorage.setItem("id_submodulo", this.props.solicitud.id_submodulo.toString());
		localStorage.setItem("submodulo_general", this.props.solicitud.submodulo_general.toString());
        this.setState({redireccionando:1}) 
	}

	//Función para redireccionar a la documentación de la api.
	redireccionandoApi(id_api:number){
        localStorage.setItem("id_proyecto", this.props.solicitud.id_proyecto.toString());
		localStorage.setItem("id_modulo", this.props.solicitud.id_modulo.toString());
		localStorage.setItem("id_submodulo", this.props.solicitud.id_submodulo.toString());
		localStorage.setItem("submodulo_general", this.props.solicitud.submodulo_general.toString()); 
		localStorage.setItem("id_api",id_api.toString());
		this.setState({redireccionando:2}) 
	}
    
    render() {
        return (
            <>
                {/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/}
                {this.state.redireccionando == 1 ?
                    <>
                        {Acciones("documentarapi")}
                    </>
                :this.state.redireccionando == 2 ?
                    <>
                        {Acciones("documentacionapi")}
                    </>
                :null}
                {/*////////// Sección de activación de modales y spinner //////////*/}
                {(this.state.cargando == true) ? (
           			<SpinnerModal tipo="full" show={this.state.cargando}/>
        		) : null}

                {(this.state.showmodalagregarapi == true) ? (
					<ModalFormularioAgregarCambiarApi isOpen={true} closeModal={this.cerrarModalAgregarApi} idsolicitudtieneapi={this.state.idsolicitudtieneapi}
					token={this.props.token} idproyecto={this.props.solicitud.id_proyecto} titulomodal={this.state.titulomodal} accion={this.state.accionmodal}
					tiposolicitud={this.state.tiposolicitud} idapi={this.state.idapi} idsubmodulo={this.props.solicitud.id_submodulo}/>
				) : null}

				{(this.state.showmodaleliminarapi == true) ? (
					<ModalEliminarApiSolicitud isOpen={true} closeModal={this.cerrarModalEliminarApi} idsolicitudtieneapi={this.state.idsolicitudtieneapi}
					token={this.props.token}/>
				) : null}
                <Table className="tablaListaElementos" hover responsive>
                    <thead className="titulosTablaApi">
                        <tr>
                            {this.state.tabla.encabezados.map((encabezado, index) => (
                                <th key={"tabla" + index}
                                style={{width: this.state.tabla.dimensiones[index] , minWidth:this.state.tabla.dimensiones[index]}}>
                                    {encabezado}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.tabla.apis.map((api, index) => (
                            <tr key={index}>
                                <td style={{width: this.state.tabla.dimensiones[0]}}>
                                    {index + 1}
                                </td>
                                <td className="text-center" style={{width: this.state.tabla.dimensiones[1] }}>
                                    {api.tipo_solicitud == "POST" ?
                                        <Badge className="badgePost" pill> 
                                            {api.tipo_solicitud}
                                        </Badge>
                                    :api.tipo_solicitud == "GET" ?
                                        <Badge className="badgeGet" pill> 
                                            {api.tipo_solicitud}
                                        </Badge>  
                                    :api.tipo_solicitud == "PUT" ? 
                                        <Badge className="badgePut" pill> 
                                            {api.tipo_solicitud}
                                        </Badge>
                                    :api.tipo_solicitud == "DELETE" ?
                                        <Badge className="badgeDelete" pill> 
                                            {api.tipo_solicitud}
                                        </Badge>  
                                    :null }
                                </td>
                                {api.id_api == 1 ? 
                                    <td colSpan={3} style={{ textAlign: "center" }}>
                                        <em style={{fontSize: '14px'}}>No tiene api</em>
                                    </td>
                                :
                                    <>
                                        <td style={{width: this.state.tabla.dimensiones[2] }}>
                                            {api.nombre_api}
                                        </td>
                                        <td className="text-center" style={{width: this.state.tabla.dimensiones[3] }}>
                                            {api.tipo_solicitud_api == "POST" ?
                                                <Badge className="badgePost" pill>
                                                    {api.tipo_solicitud_api}
                                                </Badge>
                                            :api.tipo_solicitud_api == "GET" ?
                                                <Badge className="badgeGet" pill>
                                                    {api.tipo_solicitud_api}
                                                </Badge>
                                            :api.tipo_solicitud_api == "PUT" ?
                                                <Badge className="badgePut" pill>
                                                    {api.tipo_solicitud_api}
                                                </Badge>
                                            :api.tipo_solicitud_api == "DELETE" ?
                                                <Badge className="badgeDelete" pill>
                                                    {api.tipo_solicitud_api}
                                                </Badge>
                                            :null}
                                        </td>
                                        <td style={{width: this.state.tabla.dimensiones[4] }}>
                                            {api.estado == "Completada" ? 
                                                <>
                                                    <span className="apisInformacion">
                                                        <FaCheckCircle color="green" size={16} className="apisCompletadas" data-tooltip-content='Api completada' />
                                                    </span>
                                                    <Tooltip anchorSelect=".apisCompletadas" className="toolTipDiseño" place="top" />
                                                </>
                                            :api.estado == "En desarrollo" ? 
                                                <>
                                                    <span className="apisInformacion">  
                                                        <FaClock color="orange" size={16} className="apisDesarrollo" data-tooltip-content='Api en desarrollo'/> 
                                                    </span>
                                                    <Tooltip anchorSelect=".apisDesarrollo" className="toolTipDiseño" place="top" />	
                                                </> 
                                            :api.estado == "Reportada" ? 
                                                <>
                                                    <span className="apisInformacion">
                                                        <FaExclamationCircle color="red" size={16} className="apisConErrores" data-tooltip-content='Api con errores'/>	
                                                    </span>
                                                    <Tooltip anchorSelect=".apisConErrores" className="toolTipDiseño" place="top" />	
                                                </> 
                                            :null}
                                            {api.tipo_solicitud != api.tipo_solicitud_api ?
                                                <>
                                                    <span className="apisInformacion" style={{marginLeft:'5px'}}>
                                                        <IoIosWarning color="orange" size={19} className="diferentesTipos" data-tooltip-content='Los tipos de solicitud no coinciden.' />
                                                    </span>
                                                    <Tooltip anchorSelect=".diferentesTipos" className="toolTipDiseño" place="top"/>
                                                </>
                                            :null}
                                        </td>
                                    </>
                                } 
                                <td className="tdBotonesAcciones" style={{width: this.state.tabla.dimensiones[5] }}>
                                    {api.id_api === 1 ? 
                                        this.props.idrol === 1  ?
                                            <>
                                                <Button className="botonAccionSolicitud" color="success" onClick={() => this.redireccionandoDocumentarApi(api.idsolicitud_tiene_api)}>
                                                    <IoMdAdd size={15} color="white" />&nbsp;Crear api
                                                </Button>
                                                <Button className="botonAccionSolicitud" color="warning" onClick={() => this.verModalFormularioAgregarApi(1,api.idsolicitud_tiene_api, api.tipo_solicitud, api.id_api)}>
                                                    <FaFolderOpen size={15} color="black" />&nbsp;Añadir api
                                                </Button>
                                                {this.state.tabla.apis.length !== 1 ? 
                                                    <Button className="botonAccionSolicitud" color="danger" onClick={() => this.verModalEliminarApi(api.idsolicitud_tiene_api)}>
                                                        <RiDeleteBinFill size={15} color="white"/>&nbsp;Eliminar
                                                    </Button>
                                                :null}
                                            </>
                                        :this.props.idrol === 2 ?
                                            <>
                                                <Button className="botonAccionSolicitud" color="success" onClick={() => this.redireccionandoDocumentarApi(api.idsolicitud_tiene_api)}>
                                                    <IoMdAdd size={15} color="white" />&nbsp;Crear api
                                                </Button>
                                                <Button className="botonAccionSolicitud" color="warning" onClick={() => this.verModalFormularioAgregarApi(1,api.idsolicitud_tiene_api, api.tipo_solicitud, api.id_api)}>
                                                    <FaFolderOpen size={15} color="black" />&nbsp;Añadir api
                                                </Button>
                                            </>
                                        :this.props.idrol === 3 ?
                                            <>
                                                {this.state.tabla.apis.length !== 1 ? 
                                                    <Button className="botonAccionSolicitud" color="danger" onClick={() => this.verModalEliminarApi(api.idsolicitud_tiene_api)}>
                                                        <RiDeleteBinFill size={15} color="white"/>&nbsp;Eliminar
                                                    </Button>
                                                :
                                                    <em style={{fontSize: '14px'}}>No hay acciones disponibles</em>
                                                }
                                            </>
                                        :this.props.idrol === 4 ?
                                            <>
                                                <Button className="botonAccionSolicitud" color="success" onClick={() => this.redireccionandoDocumentarApi(api.idsolicitud_tiene_api)}>
                                                    <IoMdAdd size={15} color="white" />&nbsp;Crear api
                                                </Button>
                                                <Button className="botonAccionSolicitud" color="warning" onClick={() => this.verModalFormularioAgregarApi(1,api.idsolicitud_tiene_api, api.tipo_solicitud, api.id_api)}>
                                                    <FaFolderOpen size={15} color="black" />&nbsp;Añadir api
                                                </Button>
                                                {this.state.tabla.apis.length !== 1 ? 
                                                    <Button className="botonAccionSolicitud" color="danger" onClick={() => this.verModalEliminarApi(api.idsolicitud_tiene_api)}>
                                                        <RiDeleteBinFill size={15} color="white"/>&nbsp;Eliminar
                                                    </Button>
                                                :null}
                                            </>
                                        :null
                                    :
                                        this.props.idrol === 1 && this.props.estado !== "Resuelto" ?
                                            <>
                                                <Button className="botonAccionSolicitud" color="primary" onClick={() => this.redireccionandoApi(api.id_api)}>
                                                    <FaEye size={15} color="white" />&nbsp;Ver api
                                                </Button>
                                                <Button className="botonAccionSolicitud" color="warning" onClick={() => this.verModalFormularioAgregarApi(2,api.idsolicitud_tiene_api,api.tipo_solicitud, api.id_api)}>
                                                    <TbExchange size={15} color="black" />&nbsp;Cambiar api
                                                </Button>
                                                {this.state.tabla.apis.length !== 1 ? 
                                                    <Button className="botonAccionSolicitud" color="danger" onClick={() => this.verModalEliminarApi(api.idsolicitud_tiene_api)}>
                                                        <RiDeleteBinFill size={15} color="white"/>&nbsp;Eliminar
                                                    </Button>
                                                :null}
                                            </>
                                        :this.props.idrol === 2 && this.props.estado !== "Resuelto" ?
                                            <>
                                                <Button className="botonAccionSolicitud" color="primary" onClick={() => this.redireccionandoApi(api.id_api)}>
                                                    <FaEye size={15} color="white" />&nbsp;Ver api
                                                </Button>
                                                <Button className="botonAccionSolicitud" color="warning" onClick={() => this.verModalFormularioAgregarApi(2,api.idsolicitud_tiene_api,api.tipo_solicitud, api.id_api)}>
                                                    <TbExchange size={15} color="black" />&nbsp;Cambiar api
                                                </Button>
                                            </>
                                        :this.props.idrol === 3 && this.props.estado !== "Resuelto" ?
                                            <>
                                                <Button className="botonAccionSolicitud" color="primary" onClick={() => this.redireccionandoApi(api.id_api)}>
                                                    <FaEye size={15} color="white" />&nbsp;Ver api
                                                </Button>
                                                {this.state.tabla.apis.length !== 1 ? 
                                                    <Button className="botonAccionSolicitud" color="danger" onClick={() => this.verModalEliminarApi(api.idsolicitud_tiene_api)}>
                                                        <RiDeleteBinFill size={15} color="white"/>&nbsp;Eliminar
                                                    </Button>
                                                :null}
                                            </>
                                        :this.props.idrol === 4 && this.props.estado !== "Resuelto" ?
                                            <>
                                                <Button className="botonAccionSolicitud" color="primary" onClick={() => this.redireccionandoApi(api.id_api)}>
                                                    <FaEye size={15} color="white" />&nbsp;Ver api
                                                </Button>
                                                <Button className="botonAccionSolicitud" color="warning" onClick={() => this.verModalFormularioAgregarApi(2,api.idsolicitud_tiene_api,api.tipo_solicitud, api.id_api)}>
                                                    <TbExchange size={15} color="black" />&nbsp;Cambiar api
                                                </Button>
                                                {this.state.tabla.apis.length !== 1 ? 
                                                    <Button className="botonAccionSolicitud" color="danger" onClick={() => this.verModalEliminarApi(api.idsolicitud_tiene_api)}>
                                                        <RiDeleteBinFill size={15} color="white"/>&nbsp;Eliminar
                                                    </Button>
                                                :null}
                                            </>
                                        :
                                            <Button className="botonAccionSolicitud" color="primary" onClick={() => this.redireccionandoApi(api.id_api)}>
                                                <FaEye size={15} color="white" />&nbsp;Ver api
                                            </Button>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </>
        );
    }
}
function Acciones(bandera:string) {
    if(bandera=="documentarapi"){
		return <Navigate to="/documentarapi" />;
	}
    else if(bandera=="documentacionapi"){
		return <Navigate to="/documentacionapi" />;
	}
}
