import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

interface props {
    rol:number,
    vermsgerror:boolean,
    msgerror:string,
    seleccionar: (prop: number) => void;
}

interface states {
    rol:number
}

export default class SelectRol extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            rol:this.props.rol
        }
    }

    rolSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const rol = Number(event.target.value);
        this.setState({ rol:rol });
        this.props.seleccionar(rol)
    };
   
    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                Rol
                </Label>
                <Input type="select" value={this.state.rol} onChange={this.rolSeleccionado}>
                    <option disabled value="0" selected>Seleccione una opción</option>
                    <option value= "2">Backend</option>
                    <option value= "3">Frontend</option>
                    <option value= "4">Fullstack</option>
                </Input>
                {this.props.vermsgerror
                ? 
                    <div className="row">
                    <div className="col-10 contenedorMensajeError">
                    <span className="mensajeError">{this.props.msgerror}</span>
                    </div>
                    </div>
                :null
                }
            </FormGroup>
        );
    }
}