import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import "./EstilosFormularios.css";
import { FaQuestion } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

interface props {
    fechaentrega:string,
    vermsgerror:boolean,
    msgerror:string,
    seleccionar: (prop: string) => void;
}

interface states {
    fechaentrega:string
    fechaminima:string
}

export default class InputFechaEntrega extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            fechaentrega:this.props.fechaentrega,
            fechaminima:''
        }
    }

    componentDidMount() {
        const today = new Date().toISOString().slice(0, 16);
        this.setState({fechaminima:today})
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.fechaentrega !== this.props.fechaentrega) {
          this.setState({ fechaentrega: this.props.fechaentrega });
        }
    }

    fechaEntregaSeleccionada = (event: React.ChangeEvent<HTMLInputElement>) => {
      const fechaentrega = event.target.value;
      this.setState({ fechaentrega });
      this.props.seleccionar(fechaentrega)
    };
   
    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal' style={{ display: 'flex' }}>
                    Fecha de entrega
                    <span className="spanObligatorio">*</span>
                    <a className="infoFecha">
                        <FaQuestion color="white" size={12}/>
                    </a>
                </Label>
                <Tooltip anchorSelect=".infoFecha" className="toolTipDiseño" place="top">
                    <div>
                        El formato es de 24 hrs.
                    </div>
                </Tooltip>
                <Input
                    type="datetime-local"
                    value={this.state.fechaentrega}
                    onChange={this.fechaEntregaSeleccionada}
                    min={this.state.fechaminima}
                />
                {this.props.vermsgerror ?
                    <div className="row">
                        <div className="col-12 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    </div>
                :null}
            </FormGroup>
        );
    }
}