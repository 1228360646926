import React  from "react";
import { CSSProperties } from "react";
import { ClipLoader } from "react-spinners";
import "./SpinnerModal.css"

interface states{
	tipo:string,
    show:boolean
}

const override: CSSProperties = {
    display: "block",
    margin:"auto"
};

export default class SpinnerModal extends React.Component<states>{
    render() {
        return (
            <>
                <div className={(this.props.tipo == 'full') ? ("progresoSpinnerFull") : "progresoSpinner"}>
                    <div className="animacionSpinner">
                        <ClipLoader
                            color={"#ffffff"}
                            cssOverride={override}
                            size={60}
                            loading={this.props.show}
                        />
                        <label className="spinnerMessage"> </label>
                    </div>
                </div>
            </>
        )
    }
}