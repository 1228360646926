import React from "react";
import { Button } from 'reactstrap';
import { postDetallesApi, postEditarSalidas, postEjemplosSalidas } from "../../Services/Api";
import { validarLongitud } from "../../Services/Validaciones";
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from "react-router-dom";
import './DocumentarApi.css';
import MenuNavbar from "../../Complements/Navbar/Navbar";
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { FormularioEjemplosSalidas } from "./Componentes.tsx/FormularioEjemplosSalidas";
import { ejemplosalida, ejemplosalidaestado } from "./Interfaces";
import { InputModelo } from "./Componentes.tsx/InputModelo";
import { api, apiestado } from "../DocumentacionApi/Interfaces";
import Navegacion from "../../Complements/Navegacion/Navegacion";

//Interfaz general del componente.
interface states{
    api:api,
	idproyecto:number,
    idapi:number,
    submodulo_general:number,

	txtmodelosalidas:string,
	txtmodelosalidaslength:number,
	vermsgerrormodelosalida:boolean,
    msgerrormodelosalidas:string,

	ejemplosalidas: ejemplosalida[];
	
	perfil:{
		token:number,
        name:string,
		email:string
  	},
	redireccionando:number,
	cargando:boolean

}

export default class EditarSalidas extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
            api:apiestado,
			idproyecto:0,
			idapi: 0,
            submodulo_general:0,

			txtmodelosalidas:'',
			txtmodelosalidaslength:0,
			vermsgerrormodelosalida:false,
			msgerrormodelosalidas:'',

			ejemplosalidas: [ejemplosalidaestado],

            perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": ""}'),
			redireccionando:0,
			cargando:true
	  	}
    }
    async componentDidMount() {
        if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
            //this.setState({ redireccionando: true });
        }

        const id_rol = Number(localStorage.getItem("id_rol"))
		this.restriccionRol(id_rol)

        const submodulo_general = await localStorage.getItem('submodulo_general');
        const id_proyecto = await localStorage.getItem('id_proyecto');
        const id_api = await localStorage.getItem('id_api');

        this.setState({idproyecto:Number(id_proyecto),idapi:Number(id_api), submodulo_general:Number(submodulo_general)});

		const modelo = await postDetallesApi(this.state.perfil.token,Number(id_proyecto),Number(id_api));
        if(this.respuestaApi (modelo)){
			return;
		}
        this.mostrarModelo(modelo)

        const ejemplosSalidas = await postEjemplosSalidas(this.state.perfil.token,Number(id_proyecto),Number(id_api));
        if(this.respuestaApi (ejemplosSalidas)){
			return;
		}
        this.ejemplosSalidasFaltantes(ejemplosSalidas)
        
        setTimeout(() => {
           this.setState({cargando:false})
        }, 1000);
    }

    //Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}
		return false
	}

	//Función para restringir el acceso al componente de acuerdo a su rol.
	restriccionRol = (id_rol:number) => {
		if(id_rol === 3){
            localStorage.setItem("accion","6");
			this.setState({redireccionando:2})
		}
	}

    //Función de regresar
	regresar = () => {
		window.history.back();
	};

    //Función para capturar la información del modelo.
    mostrarModelo = (modelo:any) =>{
        this.setState({txtmodelosalidas:modelo.data.modelo_salidas, 
            txtmodelosalidaslength:modelo.data.modelo_salidas ? modelo.data.modelo_salidas.length :0,
            api:modelo.data})
    }

    //Función para agregar los datos faltantes a los ejemplos de entradas.
    ejemplosSalidasFaltantes = (ejemplossalidas:any) => {
       const ejemplosSalidasFaltantes = ejemplossalidas.data.map((ejemplo:any) => ({
            ...ejemplo,
            nombre_ejemplo_salidalength: ejemplo.nombre_ejemplo_salida.length,
            txtnombreejemplosalida: '',
            vermsgerrornombreejemplosalida:false,
            msgerrornombreejemplosalida:'',
            ejemplo_salidalength: ejemplo.ejemplo_salida.length,
            txtejemplosalida:'',
            vermsgerrorejemplosalida:false,
            msgerrorejemplosalida:''
        }));
        this.setState({ ejemplosalidas: ejemplosSalidasFaltantes });
    }

    //Funcion para cambiar el estado del valor y longitud del modelo.
    escribirModelo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const modelo = event.target.value;
        this.setState({ txtmodelosalidas: modelo, txtmodelosalidaslength: modelo.length });
    };

    //Prueba
	modelosalidas = (prop:string) => {
		this.setState({ txtmodelosalidas: prop });
	}

	ejemplosSalidas = (prop:ejemplosalida[]) => {
		console.log(prop)
		this.setState({ ejemplosalidas: prop });
	}
   
    /////////////////////////////// Sección de función para guardar los cambios realizados.
    guardarCambios = async () => {
        this.setState({ vermsgerrormodelosalida: false });

        //Actualiza estado de la visualización de los mensajes de error de cada nombre de ejemplo de salida.
        const vermsgerrornombreejemplosalida = this.state.ejemplosalidas.map((ejemplo) => ({
            ...ejemplo,
            vermsgerrornombreejemplosalida: false
        }));
        this.setState({ ejemplosalidas: vermsgerrornombreejemplosalida });

        //Actualiza estado de la visualización de los mensajes de error de cada ejemplo de salida.
        const vermsgerrorejemplosalida = this.state.ejemplosalidas.map((ejemplo) => ({
            ...ejemplo,
            vermsgerrorejemplosalida: false
        }));
        this.setState({ ejemplosalidas: vermsgerrorejemplosalida });

        let longitudvalidamodelojson = { longitudminima: 30, longitudmaxima: 3000 };
        let longitudvalidanombreejemplo = {longitudminima: 3, longitudmaxima: 30};
        let longitudvalidaejemplojson = { longitudminima: 30, longitudmaxima: 3000 };

        let txtmodelosalidas = validarLongitud(this.state.txtmodelosalidas, longitudvalidamodelojson);

        //Nombres de ejemplos de salida.
        const validarNombreEjemploSalidas = this.state.ejemplosalidas.map((ejemplo) => {
            const resultadoValidacionNombre = validarLongitud(ejemplo.nombre_ejemplo_salida, longitudvalidanombreejemplo);
            return {
                ...ejemplo,
                txtnombreejemplosalida: resultadoValidacionNombre.codigo,
                msgerrornombreejemplosalida: resultadoValidacionNombre.mensaje
            };
        });
        await this.setState({ ejemplosalidas: validarNombreEjemploSalidas });

        //Ejemplos de salida.
        const validarEjemploSalidas = this.state.ejemplosalidas.map((ejemplo) => {
            const resultadoValidacion = validarLongitud(ejemplo.ejemplo_salida, longitudvalidaejemplojson);
            return {
                ...ejemplo,
                txtejemplosalida: resultadoValidacion.codigo,
                msgerrorejemplosalida: resultadoValidacion.mensaje
            };
        });
        await this.setState({ ejemplosalidas: validarEjemploSalidas });

        let validacionescorrectas = true;
        let salidasCorrectas = true;

        if (txtmodelosalidas.codigo == "invalid") {
            this.setState({ msgerrormodelosalidas: txtmodelosalidas.mensaje });
            this.setState({ vermsgerrormodelosalida: true });
            validacionescorrectas = false;
        }

        //Nombres de ejemplos de salida.
        const validarNombreEjemploSalidasRespuesta = this.state.ejemplosalidas.map((ejemplo) => {
            if (ejemplo.txtnombreejemplosalida === "invalid") {
                return {
                    ...ejemplo,
                    vermsgerrornombreejemplosalida: true
                };
            }
            return ejemplo;
        });
        await this.setState({ ejemplosalidas: validarNombreEjemploSalidasRespuesta });
        //Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemploNombresSalidasCorrectas su valor booleano inverso.
		//En pocas la palabras se si se cumple la condición se guardará como falso.
        const ejemploNombresSalidasCorrectas =  !validarNombreEjemploSalidasRespuesta.some((ejemplo) => ejemplo.txtnombreejemplosalida === "invalid");  
        
        //Ejemplos de salida.
        const validarEjemploSalidasRespuesta = this.state.ejemplosalidas.map((ejemplo) => {
            if (ejemplo.txtejemplosalida === "invalid") {
            return {
                ...ejemplo,
                vermsgerrorejemplosalida: true
            };

            }
            return ejemplo; // Devuelve el ejemplo sin modificar
        });

        await this.setState({ ejemplosalidas: validarEjemploSalidasRespuesta });
        //Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemploSalidasCorrectas su valor booleano inverso.
		//En pocas la palabras se si se cumple la condición se guardará como falso.
        const ejemploSalidasCorrectas = !validarEjemploSalidasRespuesta.some((ejemplo) => ejemplo.txtejemplosalida === "invalid");
    
        //Se suman ambos valores. Si es true y false. Se guarda como false. Si ambos son true, se guarda como true y se actualiza el valor en salidasCorrectas.
		//Si el valor de salidasCorrectas es true significa que todos los nombres y sus correspondientes ejemplos son aptos para guardar.     
        salidasCorrectas= ejemploNombresSalidasCorrectas && ejemploSalidasCorrectas;

        //Se evalua el valor de validacionescorrectas para tomar en cuenta los demás campos.
		//Si es true, significa que el campo de modelo es apto para guardar. Entonces se le asigna el valor de salidaCorrectas para
        //poder evaluar los campos por completo.
        if(validacionescorrectas){
            validacionescorrectas = salidasCorrectas;
        }
        
        //Si validacionescorrectas es true se crea el json y se manda a la api.
        if (validacionescorrectas) {
            let json = {
                "token": this.state.perfil.token,
                "id_proyecto": this.state.idproyecto,
                "id_api": this.state.idapi,
                "modelo_salidas":this.state.txtmodelosalidas,
                "ejemplos_salidas":this.state.ejemplosalidas,
                
            } 
            const resp = await postEditarSalidas(json);
        
            if (resp.code == 0) {
                localStorage.setItem("accion", "4");
                this.setState({ redireccionando: 1 })
            }else if (resp.code == 100) {
                this.setState({ redireccionando: 1 })
            }else if (resp.code == 200) {
                toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }else if (resp.code == 300) {
                toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }else if (resp.code == 400) {
                localStorage.setItem("accion", '7');
                this.setState({ redireccionando: 1 })
            }else if (resp.code == 1000 || resp == -1) {
				toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
        }
    
    }

    //Función para cancelar y redirigir a la documentación de la api.
    cancelar = () => {
        this.setState({ redireccionando: 2})
    }

    render() {
        return ( 
            <div className="divContenedorPrincipal">
                {/*////////// Sección de redirigir a paginas mediante condicionales //////////*/}
                {this.state.redireccionando == 1 ?
                    <>
                        {Acciones("documentacionapi")}
                    </>
                :this.state.redireccionando == 2 ?
                    <>
                        {Acciones("cancelar")}
                    </>
                :null}

                {/*////////// Sección de activación de spinner //////////*/}
                {(this.state.cargando == true) ? (
                        <SpinnerModal tipo="full" show={this.state.cargando}/>
                ) : null}
                <ToastContainer />
                <MenuNavbar/>
                <main>
                    {/*////////// Sección de breadscrum //////////*/}
                    <Navegacion
						seccion={"edit-salidas"}
						proyecto={this.state.api.nombre_proyecto}
						modulo={this.state.api.nombre_modulo}
						submodulo={this.state.api.nombre_submodulo}
						accion={this.state.api.submodulo_general}
						api={this.state.api.nombre_api}
					/>
                    {/*////////// Sección de salidas //////////*/}
                    <div className="row">
                        <InputModelo 
                            titulo={"Modelo de salidas"} 
                            txtmodelo={this.state.txtmodelosalidas} 
                            vermsgerrormodelo={this.state.vermsgerrormodelosalida} 
                            msgerrormodelo={this.state.msgerrormodelosalidas} 
                            modelo={this.modelosalidas}
                        />
                        <FormularioEjemplosSalidas 
							ejemplossalidas={this.state.ejemplosalidas}	
							ejemplos={this.ejemplosSalidas} 						
						/>
                    </div>
                    <div className="row mt-4">
                        <div className="col divContenedorBotonesGuardar">
                            <Button className="botonCancelarDocApi" color="danger" onClick={this.cancelar} >Cancelar</Button>
                            <Button color="success" onClick={this.guardarCambios} >Guardar cambios</Button>
                        </div>
                    </div>
                </main>
            </div>
        );
    };
}
function Acciones(bandera:string) {
    if (bandera=='documentacionapi'){
        return <Navigate to="/documentacionapi" />;
    }else if(bandera=='cancelar'){
        return <Navigate to="/documentacionapi" />;
    }
}
    
    
