import React from "react";
import { Button } from 'reactstrap';
import { postDetallesApi, postEditarInformacionTecnica } from "../../Services/Api";
import { validarInputSelect, validarLetrasNumerosEspacios, validarLongitud, validarLongitudMáxima } from "../../Services/Validaciones";
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from "react-router-dom";
import './DocumentarApi.css';
import MenuNavbar from "../../Complements/Navbar/Navbar";
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import InputText from "../../Complements/Formularios/InputText";
import SelectTipoSolicitud from "./Componentes.tsx/SelectTipoSolicitud";
import SelectEstado from "./Componentes.tsx/SelectEstado";
import InputQuill from "../../Complements/Formularios/InputQuill";
import InputHeaders from "../../Complements/Formularios/InputHeaders";
import Navegacion from "../../Complements/Navegacion/Navegacion";
import { api, apiestado } from "../DocumentacionApi/Interfaces";
import SelectHeaders from "./Componentes.tsx/SelectHeaders";

//Interfaz general del componente.
interface states{
	api:api,
	id_proyecto:number,
	id_submodulo:number,
	submodulo_general:number,
	idapi:number,

	txtnombreapi: string,
    vermsgerrornombre:boolean,
    msgerrornombre:string,

	txturl:string,
    vermsgerrorurl:boolean,
    msgerrorurl:string,
	
	txtdescripcionapi:string,
    vermsgerrordescripcionapi:boolean,
    msgerrordescripcionapi:string,

    tiposolicitud:string,
	vermsgerrortiposolicitud:boolean,
    msgerrortiposolicitud:string,

	estadoapi:string,
	vermsgerrorestadoapi:boolean,
    msgerrorestadoapi:string,

	headerscheckbox:boolean,
	txtheadersapi:string,
	vermsgerrorheaders:boolean,
    msgerrorheaders:string,

	perfil:{
		token:number,
        name:string,
		email:string
  	},
	redireccionando:number,
	estado:boolean,
	cargando:boolean

}
export default class EditarInformacionGeneral extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
			api:apiestado,
			id_proyecto:0,
			id_submodulo:0,
			submodulo_general:0,
			idapi:0,

			txtnombreapi: '',
			vermsgerrornombre:false,
			msgerrornombre:'',

			txturl:'',
			vermsgerrorurl:false,
			msgerrorurl:'',

			txtdescripcionapi:'',
			vermsgerrordescripcionapi:false,
			msgerrordescripcionapi:'',

			txtheadersapi:'',
			vermsgerrorheaders:false,
    		msgerrorheaders:'',

			tiposolicitud: '',
			vermsgerrortiposolicitud:false,
    		msgerrortiposolicitud:'',

			estadoapi:'',
			vermsgerrorestadoapi:false,
			msgerrorestadoapi:'',

			headerscheckbox:false,

            perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": ""}'),
			redireccionando:0,
			estado:false,
			cargando:true
	  	};
	}

	async componentDidMount() {
        if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
			//this.setState({ redireccionando: true });
        }

		const id_rol = Number(localStorage.getItem("id_rol"))
		this.restriccionRol(id_rol);
		
		const id_proyecto = await localStorage.getItem('id_proyecto');
		const id_api = await localStorage.getItem('id_api');
		
		const apiapi = await postDetallesApi(this.state.perfil.token,Number(id_proyecto),Number(id_api));
		if(this.respuestaApi (apiapi)){
			return;
		}
		this.informacionGeneral(apiapi);
		
		setTimeout(() => {
			this.setState({cargando: false});
		}, 1000);
    }

	//Función para restringir el acceso al componente de acuerdo a su rol.
	restriccionRol = (id_rol:number) => {
		if(id_rol === 3){
			localStorage.setItem("accion","6");
			this.setState({redireccionando:2})
		}
	}

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}
		return false
	}

	//Función de regresar
	regresar = () => {
		window.history.back();
	};
	
	//Función para capturar la información general de la api para editar.
    informacionGeneral = (apiapi:any) =>{
        this.setState({idapi:apiapi.data.id_api,
			txtnombreapi:apiapi.data.nombre_api,
			txturl:apiapi.data.url,
			txtdescripcionapi:apiapi.data.descripcion_tecnica,
			estadoapi:apiapi.data.estado,
			txtheadersapi:apiapi.data.headers_api,
			tiposolicitud:apiapi.data.tipo_solicitud,
			submodulo_general:apiapi.data.submodulo_general,
			id_submodulo: apiapi.data.id_submodulo,
			id_proyecto:apiapi.data.id_proyecto});
		this.setState({api:apiapi.data})
		this.habilitarHeaders(apiapi.data.headers_api);
    }

	//Función para habilitar los headers en caso de que si tenga.
	habilitarHeaders = (prop:boolean) => {
		this.setState({ headerscheckbox: prop});
	}
	
	nombre = (prop: string) => {
		this.setState({ txtnombreapi: prop});
	};

	url = (prop: string) => {
		this.setState({ txturl: prop});
	};

	tipoSolicitudSeleccionada = (prop:string) => {
		this.setState({tiposolicitud:prop})
	}

	estadoSeleccionado = (prop: string) => {
		this.setState({ estadoapi: prop});
	};

	descripcion = (prop: string) => {
		this.setState({ txtdescripcionapi: prop});
	};

	habilitarHeaders2 = (prop:boolean) => {
		this.setState({ headerscheckbox: prop});
	}

	headers = (prop: string) => {
		this.setState({ txtheadersapi: prop});
	};

	/////////////////////////////// Sección de función para guardar los cambios realizados.
	guardarCambios = async () => {
		this.setState({ vermsgerrornombre: false });
		this.setState({ vermsgerrorurl: false });
		this.setState({ vermsgerrortiposolicitud: false });
		this.setState({ vermsgerrordescripcionapi: false });
		this.setState({ vermsgerrorheaders: false });
		this.setState({ vermsgerrorestadoapi: false });

		let longitudvalidanombre = {longitudminima: 3, longitudmaxima: 70};
		let longitudvalidaurl = {longitudminima: 5, longitudmaxima: 70};
		let longitudvalidadescripcion = 3000;
		let longitudvalidaheaders = {longitudminima: 30, longitudmaxima: 500};

		let txtnombre = validarLetrasNumerosEspacios(this.state.txtnombreapi, longitudvalidanombre);
		let txturl = validarLongitud(this.state.txturl, longitudvalidaurl);
		let selecttiposolicitud = validarInputSelect(this.state.tiposolicitud);
		let selectestado = validarInputSelect(String(this.state.estadoapi));
		let txtdescripcion = validarLongitudMáxima(this.state.txtdescripcionapi, longitudvalidadescripcion);
		let txtheadersnull = this.state.txtheadersapi || "";
		let txtheaders = validarLongitud(txtheadersnull, longitudvalidaheaders);

		let validacionescorrectas = true;

		if (txtnombre.codigo == "invalid") {
			this.setState({ msgerrornombre: txtnombre.mensaje });
			this.setState({ vermsgerrornombre: true });
			validacionescorrectas = false;
		}
		if (txturl.codigo == "invalid") {
			this.setState({ msgerrorurl: txturl.mensaje });
			this.setState({ vermsgerrorurl: true });
			validacionescorrectas = false;
		}
		if (selecttiposolicitud.codigo == "invalid") {
			this.setState({ msgerrortiposolicitud: selecttiposolicitud.mensaje });
			this.setState({ vermsgerrortiposolicitud: true });
			validacionescorrectas = false;
		}
		if (selectestado.codigo == "invalid") {
			this.setState({ msgerrorestadoapi: selectestado.mensaje });
			this.setState({ vermsgerrorestadoapi: true });
			validacionescorrectas = false;
		}
		if (txtdescripcion.codigo == "invalid") {
			this.setState({ msgerrordescripcionapi: txtdescripcion.mensaje });
			this.setState({ vermsgerrordescripcionapi: true });
			validacionescorrectas = false;
		}

		if (txtheaders.codigo == "invalid" && this.state.headerscheckbox==true) {
			this.setState({ msgerrorheaders: txtheaders.mensaje });
			this.setState({ vermsgerrorheaders: true });
			validacionescorrectas = false;
		}

		if (validacionescorrectas) {
			let json = {
				"token": this.state.perfil.token,
				"id_proyecto": this.state.id_proyecto,
				"id_api":this.state.idapi,
				"nombre_api": this.state.txtnombreapi,
				"estado":this.state.estadoapi,
				"url":this.state.txturl,
				"descripcion_tecnica":this.state.txtdescripcionapi,
				//Headers
				"headers_checkbox":this.state.headerscheckbox,
				"headers_api":this.state.txtheadersapi,
				"id_submodulo":this.state.id_submodulo,
				"tipo_solicitud":this.state.tiposolicitud
			}
			const resp = await postEditarInformacionTecnica(json);

			if (resp.code == 0) {
				localStorage.setItem("accion", '1');
				this.setState({ redireccionando: 1 })
			}
			else if (resp.code == 100) {
				localStorage.setItem("accion", '7');
				this.setState({ redireccionando: 1 })
			}
			else if (resp.code == 200) {
				toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			else if (resp.code == 250) {
				this.setState({ msgerrornombre: resp.message });
				this.setState({ vermsgerrornombre: true });
			}
			else if (resp.code == 300) {
				toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			else if (resp.code == 1000 || resp == -1) {
				toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
		}
	}

	//Función para cancelar y redirigir a la documentación de la api.
	cancelar = () => {
		this.setState({ redireccionando: 2})
	}

	render() {
		return ( 
			<div className="divContenedorPrincipal" >
				{/*////////// Sección de redirigir a paginas mediante condicionales //////////*/}
				{this.state.redireccionando == 1 ?
					<>
						{Acciones("documentacionapi")}
					</>
				: this.state.redireccionando == 2 ?
					<>
						{Acciones("cancelar")}
					</>
				:null}

				{/*////////// Sección de activación de spinner //////////*/}
				{(this.state.cargando == true) ? (
					<SpinnerModal tipo="full" show={this.state.cargando}/>
				) : null}
				<ToastContainer />
				<MenuNavbar/>
				<main>
					{/*////////// Sección de breadscrum //////////*/}
					<Navegacion
						seccion={"edit-info-general"}
						proyecto={this.state.api.nombre_proyecto}
						modulo={this.state.api.nombre_modulo}
						submodulo={this.state.api.nombre_submodulo}
						accion={this.state.api.submodulo_general}
						api={this.state.api.nombre_api}
					/>
					{/*////////// Sección de información general //////////*/}
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">	
							<InputText 
								label={'Nombre de la API'} 
								placeholder='Ej. Lista clientes'
								limitecaracteres={70} 
								txtvalor={this.state.txtnombreapi}
								vermsgerror={this.state.vermsgerrornombre}
								msgerror={this.state.msgerrornombre}
								escribir={this.nombre}                  
							/>
						</div>
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">	
							<InputText 
								label={'URL'} 
								placeholder='Ej. /clientes/lista'
								limitecaracteres={70} 
								txtvalor={this.state.txturl}
								vermsgerror={this.state.vermsgerrorurl}
								msgerror={this.state.msgerrorurl}
								escribir={this.url}                  
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">	
							<SelectTipoSolicitud 
								token={this.state.perfil.token} 
								tiposolicitud={this.state.tiposolicitud} 
								vermsgerror={this.state.vermsgerrortiposolicitud} 
								msgerror={this.state.msgerrortiposolicitud} 
								tipoSolicitudSeleccionada={this.tipoSolicitudSeleccionada}
							/>
						</div>
						{this.state.estadoapi != "Reportada" ? 
							<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">	
								<SelectEstado 
									estado={this.state.estadoapi} 
									vermsgerror={this.state.vermsgerrorestadoapi} 
									msgerror={this.state.msgerrorestadoapi} 
									estadoSeleccionado={this.estadoSeleccionado}
								/>
							</div>
						:null}
					</div>
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<SelectHeaders
								estado={this.state.headerscheckbox}
								headersHabilitados={this.habilitarHeaders}
							/>
						</div>
					</div>
					{this.state.headerscheckbox ?
						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3 divContenedorTextArea">
								<InputQuill
									label={"Descripción técnica"}
									isOpcional={true}
									isModal={false}
									placeholder={"Ingresa la descripción técnica de la api."}
									limitecaracteres={3000} 
									txtvalor={this.state.txtdescripcionapi} 
									txtvalorlength={0} 
									vermsgerror={this.state.vermsgerrordescripcionapi} 
									msgerror={this.state.msgerrordescripcionapi} 
									escribir={this.descripcion}
								/>
							</div>
							<div className="col-12 scale-up-top-left col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3 divContenedorTextArea">
								<InputHeaders
									isOpcional={false}
									isModal={false}
									checkbox={this.state.headerscheckbox}
									label={"Headers"}
									limitecaracteres={500}
									txtvalor={this.state.txtheadersapi}
									vermsgerror={this.state.vermsgerrorheaders}
									msgerror={this.state.msgerrorheaders}
									escribir={this.headers}
									habilitar={this.habilitarHeaders}
								/>
							</div>
						</div>
					:
						<div className="row">
							<div className="col-12 divContenedorTextArea">
								<InputQuill
									label={"Descripción técnica"}
									isOpcional={true}
									isModal={false}
									placeholder={"Ingresa la descripción técnica de la api."}
									limitecaracteres={3000} 
									txtvalor={this.state.txtdescripcionapi} 
									txtvalorlength={0} 
									vermsgerror={this.state.vermsgerrordescripcionapi} 
									msgerror={this.state.msgerrordescripcionapi} 
									escribir={this.descripcion}
								/>
							</div>
						</div>
					}
					<div className="row mt-5">
						<div className="col divContenedorBotonesGuardar">
							<Button className="botonCancelarDocApi" color="danger" onClick={this.cancelar} >Cancelar</Button>
							<Button color="success" onClick={this.guardarCambios} >Guardar cambios</Button>
						</div>
					</div>
				</main>
			</div>
		);
	};
}
function Acciones(bandera:string) {
    if (bandera=='documentacionapi'){
		return <Navigate to="/documentacionapi" />;
    }else if(bandera=='cancelar'){
		return <Navigate to="/documentacionapi" />;
	}
}


