//Interface de la estructura de datos del reporte
export interface reporte {
	id_reporte: number,
	titulo_reporte: string,
	descripcion_reporte:string,
	fecha_entrega: any,
	tiempo_faltante:any,
	fecha_entrega_formateada: string,
	fecha_generacion: any,
	importancia: string,
	estado:string,
	id_api:number,
	id_proyecto:number,
	nombre_proyecto:string,
	id_modulo:number,
	nombre_modulo:string,
	id_submodulo:number,
	nombre_submodulo:string,
	submodulo_general:number,
	nombre_usuario:string,
	apellido_paterno:string, 
	apellido_materno:string
}

//Interfaz de la estructura de datos de cada comentario.
export interface comentario{
	id_comentario:number,
	idcomentarioclass:string,
	idcomentatiotooltip:string,
	comentario:string, 
	accion:string,
	fecha_generacion:string, 
	tiempotranscurrido:string,
	id_usuario:number,
	token:number,
	nombre_usuario:string,
	apellido_paterno:string,
	apellido_materno:string,
	rol:number,
	opciones:boolean
}

//Interfaz de la estructura de datos de cada imagen.
export interface evidencia{
	id_evidencia: number,
	imagen_evidencia: string,
	fecha_creacion: string,
	id_reporte: number
}

export const reporteestado:reporte = {
	id_reporte: 0,
	titulo_reporte: '',
	descripcion_reporte:'',
	fecha_entrega: 0,
	tiempo_faltante:0,
	fecha_entrega_formateada: '',
	fecha_generacion: 0,
	importancia: '',
	estado:'',
	id_api:0,
	id_proyecto:0,
	nombre_proyecto:'',
	id_modulo:0,
	nombre_modulo:'',
	id_submodulo:0,
	nombre_submodulo:'',
	submodulo_general:0,
	nombre_usuario:'',
	apellido_paterno:'', 
	apellido_materno:''
}