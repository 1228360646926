import React from "react";
import { FaEye } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { Badge } from "reactstrap";
import { reporte } from "../Interfaces";

interface props {
  reporte: reporte;
  dimensiones: string[]
  verreporte: (id_reporte:number, id_proyecto:number,id_rol:number) => void;
}

const Reporte: React.FC<props> = ({ reporte, dimensiones,verreporte }) => {
  return (
    <tr>
        <td style={{width:dimensiones[0] }}>
            #{reporte.id_reporte}
        </td>
        {reporte.notificaciones == 1 ? 
            <td style={{width: dimensiones[1] }}>
                {reporte.titulo_reporte.length > 20 ? 
                    <>
                        <span title={reporte.ubicacion}>
                            {reporte.titulo_reporte.substring(0, 20)}...
                        </span>
                    </>   
                : reporte.titulo_reporte}
                <Badge color="danger" pill>Nuevo</Badge>
            </td>
        : reporte.notificaciones==2 ? 
            <td style={{width: dimensiones[1] }}>
                {reporte.titulo_reporte.length > 15 ? 
                    <>
                        <span title={reporte.ubicacion}>
                            {reporte.titulo_reporte.substring(0, 15)}...
                        </span>
                    </>   
                : reporte.titulo_reporte}
                <Badge color="primary" pill>Actualización</Badge>
            </td>
        : reporte.notificaciones==3 ? 
            <td style={{width: dimensiones[1] }}>
                {reporte.titulo_reporte.length > 20 ? 
                    <>
                        <span title={reporte.ubicacion}>
                            {reporte.titulo_reporte.substring(0, 20)}...
                        </span>
                    </>   
                : reporte.titulo_reporte} 
                <Badge color="warning" pill>Editado</Badge>
            </td>
        :	
            <td style={{width: dimensiones[1] }}>
                {reporte.titulo_reporte.length > 30 ? 
                    <>
                        <span title={reporte.ubicacion}>
                            {reporte.titulo_reporte.substring(0, 30)}...
                        </span>
                    </>   
                : reporte.titulo_reporte}
            </td>
        }
        <td style={{width: dimensiones[2] }}>
            {reporte.ubicacion.length > 45 ? 
                <>
                    <span title={reporte.ubicacion}>
                        {reporte.ubicacion.substring(0, 45)}...
                    </span>
                </>   
            : reporte.ubicacion}
        </td>
        <td style={{width: dimensiones[3] }} className={`${reporte.idreporteclass}`}>
            {reporte.fecha_entrega}
            <Tooltip anchorSelect={reporte.idreportetooltip} className="toolTipDiseño" place="top">
                Faltan {reporte.tiempo_faltante}
            </Tooltip> 
        </td>
        <td style={{width: dimensiones[4] }}>
            {reporte.importancia == 'Urgente' ? 
                <Badge className="badgeUrgente" pill> 
                    {reporte.importancia}
                </Badge> 
            :reporte.importancia == 'Muy alta' ? 
                <Badge className="badgeMuyAlta" pill> 
                    {reporte.importancia}
                </Badge> 
            :reporte.importancia == 'Alta' ? 
                <Badge className="badgeAlta" pill> 
                    {reporte.importancia}
                </Badge> 
            :reporte.importancia == 'Media' ? 
                <Badge className="badgeMedia" pill> 
                    {reporte.importancia}
                </Badge>  
            :reporte.importancia == 'Baja' ?
                <Badge className="badgeBaja" pill> 
                    {reporte.importancia}
                </Badge>  
            :null}
        </td>
        <td style={{width: dimensiones[5] }}>
            {reporte.estado}
        </td>
        <td style={{width: dimensiones[6] }}>
            <div className="botonMasInformacion">
                <a  onClick={() => verreporte(reporte.id_reporte, reporte.id_proyecto, reporte.id_rol)}>
                    Mas información
                </a>
                <FaEye size={20}/>
            </div>
        </td>
    </tr>
  );
};

export default Reporte;
