//Interface de la estructura de datos del módulo
export interface modulo {
    id_modulo: number,
    nombre_modulo: string,
    descripcion_modulo: string,
    headers_modulo: string,
    fecha_creacion: string,
    id_proyecto: number,
    nombre_proyecto:string,
    headers_proyecto:string,
    id_submodulo:number,
    submodulo_general:number,
    apis_completadas: number,
    apis_en_desarrollo: number,
    apis_reportadas: number
}
	
//Interfaz de la estructura de datos de cada submodulo.
export interface submodulo {
	id_submodulo: number,
	nombre_submodulo: string,
	apis_completadas: number,
	apis_en_desarrollo: number,
	apis_reportadas: number
}

//Interfaz de la estructura de datos de cada api.
export interface api {
	id_api: number,
	nombre_api: string,
	tipo_solicitud: string,
	formato_cuerpo: string,
	estado:string
}

export const moduloestado: modulo = {
    id_modulo: 0,
    nombre_modulo: '',
    descripcion_modulo: '',
    headers_modulo: '',
    fecha_creacion: '',
    id_proyecto: 0,
    nombre_proyecto:'',
    headers_proyecto:'',
    id_submodulo:0,
    submodulo_general:0,
    apis_completadas: 0,
    apis_en_desarrollo: 0,
    apis_reportadas: 0
};