import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Input, Label} from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioReporte.css";
import { postGuardarReporte } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import { validarArchivosImagenes, validarFechaInput, validarInputSelect, validarLetrasNumerosEspacios, validarLongitud } from '../../../Services/Validaciones';
import InputText from '../../Formularios/InputText';
import InputQuill from '../../Formularios/InputQuill';
import InputSelectPrioridad from '../../Formularios/InputSelect';
import InputEvidencias from './Componentes/InputEvidencias';
import InputFechaEntrega from '../../Formularios/InputFechaEntrega';
import { reporte } from '../../../Pages/DetallesReporte/Interfaces';

interface props {
  isOpen: boolean,
  closeModal:(accion:number) => void,
  idapi:number,
  idproyecto:number,
  token:number,
  titulomodal:string,
  accion:number,
  reporteseleccionado?:reporte
}

interface states {
  isOpen: boolean,
  
  txttituloreporte:string,
  vermsgerrortituloreporte:boolean,
  msgerrortituloreporte:string,

  selectimportanciareporte:string,
  vermsgerrorselectimportancia:boolean,
  msgerrorselectimportancia:string,

  txtdescripcionreporte:string,
  vermsgerrordescripcionreporte:boolean,
  msgerrordescripcionreporte:string,

  imagenes:File[],
  numimagenes:number;
  vermsgerrorimagenes:boolean,
  msgerrorimagenes:string,

  fechaentrega:string,
  vermsgerrorfechaentrega:boolean,
  msgerrorfechaentrega:string,
  fechaminima:string,

  confirmar:boolean,
  txtcomentario:string,
  txtcomentariolength:number,
  vermsgerrorcomentario:boolean,
  msgerrorcomentario:string  
}

export default class ModalFormularioReporte extends React.Component<props,states>{
  constructor(props:props) {
    super(props);
    this.state = {
      isOpen:this.props.isOpen,

      txttituloreporte: (props.accion == 2) ?  (props.reporteseleccionado?.titulo_reporte !== undefined ? props.reporteseleccionado.titulo_reporte : ''): '',
      vermsgerrortituloreporte:false,
      msgerrortituloreporte:'',

      selectimportanciareporte:(props.accion == 2) ? (props.reporteseleccionado?.importancia !== undefined ? props.reporteseleccionado.importancia : ''): '',
      vermsgerrorselectimportancia:false,
      msgerrorselectimportancia:'',

      txtdescripcionreporte:(props.accion == 2) ? (props.reporteseleccionado?.descripcion_reporte !== undefined ? props.reporteseleccionado.descripcion_reporte : ''): '',
      vermsgerrordescripcionreporte:false,
      msgerrordescripcionreporte:'',

      imagenes:[],
      numimagenes:0,
      vermsgerrorimagenes:false,
      msgerrorimagenes:'', 

      fechaentrega:(props.accion == 2) ? (props.reporteseleccionado?.fecha_entrega !== undefined ? props.reporteseleccionado.fecha_entrega : ''): '',
      vermsgerrorfechaentrega:false,
      msgerrorfechaentrega:'',
      fechaminima:'',

      confirmar:false,
      txtcomentario:'',
      txtcomentariolength:0,
      vermsgerrorcomentario:false,
      msgerrorcomentario:''
    }
  }

  async componentDidMount() {
    const today = new Date().toISOString().slice(0, 16);
    this.setState({fechaminima:today})
  }
  
  //Funciones para cambiar el estado de todos los estados.
  titulo = (prop: string) => {
    this.setState({ txttituloreporte: prop});
  };

  importancia = (prop:string) => {
    this.setState({ selectimportanciareporte:prop });
  }

  descripcion = (prop: string) => {
    this.setState({ txtdescripcionreporte: prop});
  };

  evidencias = (prop: File[]) => {
    this.setState({ imagenes: prop, numimagenes:prop.length });
  }

  fechaEntrega = (prop:string) => {
    this.setState({ fechaentrega: prop });
  }

  //Funcion para cambiar el estado del valor y longitud del comentario.
  escribirComentario = (event: React.ChangeEvent<HTMLInputElement>) => {
    const modelo = event.target.value;
    this.setState({ txtcomentario: modelo, txtcomentariolength: modelo.length });
  };

  showModal=(accion:number)=> {
    this.setState({isOpen:false})
    setTimeout(() => {
      this.props.closeModal(accion);
    }, 200);
  }
  
  generarReporte = async () => {
    this.setState({ vermsgerrortituloreporte: false });
    this.setState({ vermsgerrordescripcionreporte: false });
    this.setState({ vermsgerrorselectimportancia: false});
    this.setState({ vermsgerrorfechaentrega: false});
    this.setState({ vermsgerrorimagenes: false});
    this.setState({ vermsgerrorcomentario: false});

    let longitudvalidanombre = {longitudminima: 8, longitudmaxima: 45};
    let longitudvalidadescripcion = {longitudminima: 15, longitudmaxima: 3000};
    let longitudvalidacomentario = {longitudminima: 8, longitudmaxima: 300};

    let txttitulo = validarLetrasNumerosEspacios(this.state.txttituloreporte, longitudvalidanombre);
    let txtdescripcion = validarLongitud(this.state.txtdescripcionreporte, longitudvalidadescripcion);
    let selectimportancia = validarInputSelect(String(this.state.selectimportanciareporte));
    let txtfechaentrega = validarFechaInput(this.state.fechaentrega);
    let txtcomentario = validarLetrasNumerosEspacios(this.state.txtcomentario, longitudvalidacomentario);
    
    let validacionescorrectas = true;

    const validarApiRespuesta = this.state.imagenes.map((imagen) => {
      const inputimagenes = validarArchivosImagenes(imagen);
      if (inputimagenes.codigo === "invalid") {
        this.setState({ msgerrorimagenes: inputimagenes.mensaje });
        this.setState({ vermsgerrorimagenes: true });
        validacionescorrectas = false;
      }
    });

    if (txttitulo.codigo == "invalid") {
      this.setState({ msgerrortituloreporte: txttitulo.mensaje });
      this.setState({ vermsgerrortituloreporte: true });
      validacionescorrectas = false;
    }

    if (selectimportancia.codigo == "invalid") {
      this.setState({ msgerrorselectimportancia: selectimportancia.mensaje });
        this.setState({ vermsgerrorselectimportancia: true });
        validacionescorrectas = false;
    }

    if (txtdescripcion.codigo == "invalid") {
      this.setState({ msgerrordescripcionreporte: txtdescripcion.mensaje });
      this.setState({ vermsgerrordescripcionreporte: true });
      validacionescorrectas = false;
    }

    if (txtfechaentrega.codigo == "invalid") {
      this.setState({ msgerrorfechaentrega: txtfechaentrega.mensaje });
      this.setState({ vermsgerrorfechaentrega: true });
      validacionescorrectas = false;
    }
    
    if (txtcomentario.codigo == "invalid" && this.state.confirmar === true) {
      this.setState({ msgerrorcomentario: txtcomentario.mensaje });
      this.setState({ vermsgerrorcomentario: true });
      validacionescorrectas = false;
    }

    if (validacionescorrectas) {
      const formData = new FormData();
      formData.append('token', String(this.props.token));
      formData.append('id_reporte',String(this.props.accion == 1 ? ("0") : (this.props.reporteseleccionado?.id_reporte !== undefined ? this.props.reporteseleccionado.id_reporte:0)) )
      formData.append('titulo_reporte', this.state.txttituloreporte);
      formData.append('descripcion_reporte', this.state.txtdescripcionreporte);
      formData.append('fecha_entrega', this.state.fechaentrega);
      formData.append('importancia', this.state.selectimportanciareporte);
      formData.append('id_proyecto', String(this.props.idproyecto));
      formData.append('id_api',String(this.props.accion == 1 ? this.props.idapi : 0));
      this.state.imagenes.forEach((imagen, index) => {
        formData.append("evidencias_img_"+(index+1), imagen);
      });
      formData.append('num_imagenes', String(this.state.numimagenes));
      formData.append('comentario_checkbox', String(this.state.confirmar));
      formData.append('comentario', String(this.state.txtcomentario));

      const entries = formData.entries();
      let entry = entries.next();

      while (!entry.done) {
        console.log(entry.value[0], entry.value[1]);
        entry = entries.next(); 
      }
      var resp = await postGuardarReporte(formData);
      console.log(resp)
      if (resp.code == 0) {
        this.showModal(1)
        toast.success(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      else if(resp.code==100){
        this.showModal(2)
        toast.success(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      else if(resp.code==101){
        this.showModal(2)
        toast.success(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      else if(resp.code==150){
        this.showModal(0)
        toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      else if (resp.code == 200) { 
        console.log(resp)
        toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 250) {
        toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 300) {
        toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 1000 || resp == -1) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
    }
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal isOpen={this.state.isOpen} size="lg">
          <ModalHeader className="modalHeader">
            <label className='labelTituloModal'> {this.props.titulomodal} </label>
            <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
          </ModalHeader>
          <ModalBody>
            <div className="divContenedorModal">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                  <InputText 
                    label={'Título del reporte'} 
                    placeholder='Ej. Error en headers'
                    limitecaracteres={45} 
                    txtvalor={this.state.txttituloreporte}
                    vermsgerror={this.state.vermsgerrortituloreporte}
                    msgerror={this.state.msgerrortituloreporte}
                    escribir={this.titulo}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <InputSelectPrioridad 
                    prioridad={this.state.selectimportanciareporte} 
                    vermsgerror={this.state.vermsgerrorselectimportancia}
                    msgerror={this.state.msgerrorselectimportancia}
                    seleccionar={this.importancia}
                  />
                </div>
              </div>
              <div className="row">
                <InputQuill 
                  label={"Descripción del reporte"} 
                  isOpcional={false} 
                  isModal={true} 
                  placeholder={"Ingresa la descripción del reporte"} 
                  limitecaracteres={3000} 
                  txtvalor={this.state.txtdescripcionreporte} 
                  txtvalorlength={0} 
                  vermsgerror={this.state.vermsgerrordescripcionreporte} 
                  msgerror={this.state.msgerrordescripcionreporte} 
                  escribir={this.descripcion}
                />
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <InputEvidencias 
                    vermsgerror={this.state.vermsgerrorimagenes} 
                    msgerror={this.state.msgerrorimagenes} 
                    evicencias={this.evidencias}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <InputFechaEntrega 
                    fechaentrega={this.state.fechaentrega} 
                    vermsgerror={this.state.vermsgerrorfechaentrega} 
                    msgerror={this.state.msgerrorfechaentrega} 
                    seleccionar={this.fechaEntrega}
                  />
                </div>
              </div>
              {this.props.accion === 2 ? 
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <FormGroup className='formContenedor'>
                      <Label className='labelInputModal'>
                        <input type="checkbox" onClick={() => this.setState({ confirmar: !this.state.confirmar })} checked={this.state.confirmar} /> Añadir un comentario acerca de la edición del reporte <span className="spanOpcional">(opcional)</span>
                      </Label>
                      <Input
                          type="textarea"
                          className="inputModal"
                          maxLength={300}
                          value={this.state.txtcomentario}
                          onChange={this.escribirComentario}
                          disabled={!this.state.confirmar} />
                      {this.state.vermsgerrorcomentario
                        ? 
                          <div className="row">
                            <div className="col-10 contenedorMensajeError">
                            <span className="mensajeError">{this.state.msgerrorcomentario}</span>
                            </div>
                            <div className="col-2 contenedorContadorCaracteres">
                            <span className='contadorCaracteres'>{this.state.txtcomentariolength} / 300</span>
                            </div>
                          </div>
                        : 
                          <div className='contenedorContadorCaracteres'>
                            <span className='contadorCaracteres'>{this.state.txtcomentariolength} / 300</span>
                          </div>
                      }
                    </FormGroup>
                  </div>
                </div>
              :null}
            </div>
            <div className="divContenedorBotonesModal">
              <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={this.generarReporte}>Guardar</Button>           
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
 }
    