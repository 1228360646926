export interface submodulo{
	id_proyecto:number,
	id_submodulo: number,
	nombre_submodulo: string,
	nombre_modulo:string, 
	nombre_proyecto:string,
	submodulo_general:number
}

//Interfaz de la estructura de tipos de solicitud.
export interface tiposolicitud{
	id_tipo_solicitud:number,
	tipo_solicitud:string
}

//Interfaz de la estructura de formatos de cuerpo.
export interface formatocuerpo{
	id_formatos_cuerpo:number,
	formato_cuerpo:string
}

//Interfaz de la estructura de los ejemplo de entrada.
export interface ejemploentrada {
	id_ejemplos_entradas:number,
	nombre_ejemplo_entrada: string,
	nombre_ejemplo_entradalength:number,
	txtnombreejemploentrada:string,
	vermsgerrornombreejemploentrada:boolean,
    msgerrornombreejemploentrada:string,
	ejemplo_entrada: string,
	ejemplo_entradalength:number,
	txtejemploentrada:string,
	vermsgerrorejemploentrada:boolean,
    msgerrorejemploentrada:string,
}

//Interfaz de la estructura de los ejemplo de salida.
export interface ejemplosalida {
	id_ejemplos_salidas:number,
	nombre_ejemplo_salida: string,
	nombre_ejemplo_salidalength:number,
	txtnombreejemplosalida:string,
	vermsgerrornombreejemplosalida:boolean,
    msgerrornombreejemplosalida:string
	ejemplo_salida: string,
	ejemplo_salidalength:number,
	txtejemplosalida:string,
	vermsgerrorejemplosalida:boolean,
    msgerrorejemplosalida:string
}

//Interfaz de la estructura de los ejemplo de excepciones.
export interface ejemploexcepciones {
    id_ejemplo_excepcion:number,
	nombre_ejemplo_excepcion: string,
	nombre_ejemplo_excepcionlength:number,
	txtnombreejemploexcepcion:string,
	vermsgerrornombreejemploexcepcion:boolean,
    msgerrornombreejemploexcepcion:string
	ejemplo_excepcion: string,
	ejemplo_excepcionlength:number,
	txtejemploexcepcion:string,
	vermsgerrorejemploexcepcion:boolean,
    msgerrorejemploexcepcion:string
}

export const submoduloestado:submodulo = {
	id_proyecto:0,
	id_submodulo: 0,
	nombre_submodulo: '',
	nombre_modulo:'', 
	nombre_proyecto:'',
	submodulo_general:0
}

export const ejemploentradaestado:ejemploentrada = {
	id_ejemplos_entradas:0,
	nombre_ejemplo_entrada: '',
	nombre_ejemplo_entradalength:0,
	txtnombreejemploentrada:'',
	vermsgerrornombreejemploentrada:false,
	msgerrornombreejemploentrada:'',
	//////////////////////////////
	ejemplo_entrada: '',
	ejemplo_entradalength:0,
	txtejemploentrada:'',
	vermsgerrorejemploentrada:true,
	msgerrorejemploentrada:'',
}

export const ejemplosalidaestado:ejemplosalida = {
	id_ejemplos_salidas:0,
	nombre_ejemplo_salida: '',
	nombre_ejemplo_salidalength:0,
	txtnombreejemplosalida:'',
	vermsgerrornombreejemplosalida:false,
	msgerrornombreejemplosalida:'',
	ejemplo_salida: '',
	ejemplo_salidalength:0,
	txtejemplosalida:'',
	vermsgerrorejemplosalida:false,
	msgerrorejemplosalida:'',
}

export const ejemploexcepcionesestado:ejemploexcepciones = {
	id_ejemplo_excepcion:0,
	nombre_ejemplo_excepcion: '',
	nombre_ejemplo_excepcionlength:0,
	txtnombreejemploexcepcion:'',
	vermsgerrornombreejemploexcepcion:false,
	msgerrornombreejemploexcepcion:'',
	ejemplo_excepcion: '',
	ejemplo_excepcionlength:0,
	txtejemploexcepcion:'',
	vermsgerrorejemploexcepcion:false,
	msgerrorejemploexcepcion:''
}