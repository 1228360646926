import React from "react";
import { toast } from "react-toastify";
import { usuario } from "../Interfaces";
import { getListaColaboradoresApi } from "../../../Services/Api";
import { Badge } from "reactstrap";

interface props {
    token:number
}

//Interfaz general del componente.
interface states{
    usuarios:usuario[],
    token:number
}

export default class Colaboradores extends React.Component<props, states> {
    constructor(props: props) {
		super(props);
		this.state = {
            usuarios:[],
            token:this.props.token
        };
	}

    async componentDidMount() {
        var id_api = await localStorage.getItem('id_api');
		const usuarios = await getListaColaboradoresApi(this.state.token,Number(id_api));
        if(this.respuestaApi (usuarios)){
			return;
		}
        this.setState({usuarios:usuarios.data})
    }

    //Función para gestionar las respuestas negativas de las apis.
    respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			return 
		}
		return false
	}

    render() {
		return ( 	
            <>
                {this.state.usuarios.map((usuario, index) => (
                   <Badge key={index} color="primary" className="usuarioContenedorColaborador">
                        <span className="nombre">
                            {usuario.nombre_completo}
                        </span>
                    </Badge>
                ))}
            </>
        )
    }
}


