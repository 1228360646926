import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalEliminarApi.css";
import { deleteEliminarDocumentacionApi } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';

interface ModalProps {
  isOpen: boolean,
  closeModal:(accion:number) => void,
  idproyecto:number,
  idapi:number,
  token:number
}

interface ModalState {
  isOpen: boolean,
  confirmar:boolean
}

export default class ModalEliminarApi extends React.Component<ModalProps,ModalState>{
  constructor(props:ModalProps) {
    super(props);
    this.state = {
      isOpen:this.props.isOpen,
      confirmar:false
    }
  }

  showModal=(accion:number)=> {
    this.setState({isOpen:false})
    setTimeout(() => {
      this.props.closeModal(accion);
    }, 200);
  }

  eliminarApi = async () => {
    let json = {
      "token":this.props.token,
      "id_proyecto":this.props.idproyecto,
      "id_api":this.props.idapi
    }

    const resp = await deleteEliminarDocumentacionApi(json);
    
    if (resp.code == 0) {
      this.showModal(1)
    }
    else if (resp.code == 100) {
      toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
    else if (resp.code == 200) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
    else if (resp.code == 300) {
      toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
    else if (resp.code == 1000 || resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
  }
    
  render() {
    return (
      <>
        <ToastContainer />
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader className="modalHeader">
            <label className='labelTituloModal'> Eliminar api </label>
              <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
          </ModalHeader>
          <ModalBody>
            <div className="divContenedorModal">
              <div className="contenidoTextoModal">
                <span className='textoPreguntaModal'>¿Está seguro de eliminar la documentación de la api?</span>
              </div>
              <div className="contenedorCentro mb-1 mt-1" onClick={() => this.setState({ confirmar: !this.state.confirmar })}>
                <input type="checkbox" checked={this.state.confirmar} /> Confirmar eliminación
              </div>
              <div className="contenidoTextoModal mb-2">
                <span className='spanNota'>Nota: </span>
                <span className='textoAviso'>
                  Si la documentación de esta API no corresponde a este proyecto, módulo o submódulo, te recomendamos migrarla 
                  a su ubicación adecuada. También puedes reutilizar esta API para ahorrar tiempo al documentar.
                </span>
              </div>
            </div>
            <div className="divContenedorBotonesModal">
              <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={this.eliminarApi} disabled={!this.state.confirmar}>Eliminar</Button>           
            </div>
          </ModalBody>
        </Modal>
      </>
      
    );
  }
}
    