import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalEliminarEjemplo.css";
import { deleteEliminarEjemploEntrada, deleteEliminarEjemploExcepcion, deleteEliminarEjemploSalida } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';

interface ModalProps {
  isOpen: boolean,
  closeModal:(accion:number) => void,
  idejemplo:number,
  idproyecto:number,
  titulomodal:string,
  accion:number,
  token:number
}

interface ModalState {
  isOpen: boolean,
  confirmar:boolean
}

export default class ModalEliminarEjemplo extends React.Component<ModalProps,ModalState>{
  constructor(props:ModalProps) {
    super(props);
    this.state = {
      isOpen:this.props.isOpen,
      confirmar:false
    }
  }

  showModal=(accion:number)=> {
    this.setState({isOpen:false})
    setTimeout(() => {
      this.props.closeModal(accion);
    }, 200);
  }

  eliminarEjemplo = async () => {
    if(this.props.accion==1){
      let json = {
        "token":this.props.token,
        "id_proyecto":this.props.idproyecto,
        "id_ejemplo_entrada":this.props.idejemplo
      }
      const resp = await deleteEliminarEjemploEntrada(json);
      if (resp.code == 0) {
        this.showModal(1)
      }
      else if (resp.code == 200) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 300) {
        toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 1000 || resp == -1) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
    }
    else if(this.props.accion==2){
      let json = {
        "token":this.props.token,
        "id_proyecto":this.props.idproyecto,
        "id_ejemplos_salidas":this.props.idejemplo
      }
      const resp = await deleteEliminarEjemploSalida(json);
      if (resp.code == 0) {
        this.showModal(2)
      }
      else if (resp.code == 200) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 300) {
        toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 1000 || resp == -1) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
    }
    else if(this.props.accion==3){
      let json = {
        "token":this.props.token,
        "id_proyecto":this.props.idproyecto,
        "id_ejemplo_excepcion":this.props.idejemplo
      }
      const resp = await deleteEliminarEjemploExcepcion(json);
      if (resp.code == 0) {
        console.log(resp)
        this.showModal(3)
      }
      else if (resp.code == 200) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 300) {
        toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
      else if (resp.code == 1000 || resp == -1) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal(0)
      }
    }
  }
    
  render() {
    return (
      <>
        <ToastContainer />
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader className="modalHeader">
            <label className='labelTituloModal'> 
                {this.props.titulomodal}
            </label>
            <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
          </ModalHeader>
          <ModalBody>
            <div className="divContenedorModal">
              <div className="contenidoTextoModal">
                {this.props.accion ==1 ?
                    <span>
                      ¿Está seguro de eliminar este ejemplo de entrada? 
                    </span>
                  :this.props.accion ==2 ?
                    <span>
                      ¿Está seguro de eliminar este ejemplo de salida?
                    </span>
                  :this.props.accion == 3 ?
                    <span>
                      ¿Está seguro de eliminar este ejemplo de excepción? 
                    </span>
                  :null}
              </div>
              <div className="contenedorCentro mb-2 mt-1" onClick={() => this.setState({ confirmar: !this.state.confirmar })}>
                <input type="checkbox" checked={this.state.confirmar} /> Confirmar eliminación
              </div>
            </div>
            <div className="divContenedorBotonesModal">
              <Button className="btnCancelModal" onClick={() => this.showModal(0)} >Cancelar</Button>
              <Button className="btnAceptarModal"  onClick={this.eliminarEjemplo} disabled={!this.state.confirmar}>Eliminar</Button>           
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
    