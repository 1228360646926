import React from 'react';
import { Label } from 'reactstrap';
import { usuario } from '../Interfaces';

interface props {
    usuario:usuario
}

const InfoUsuario: React.FC<props> = ({ usuario}) => {
    return (
        <div className="row">
            <div className="col-12 col-sm-4 col-md-4 divContenedorImagenPerfilUsuario">
                <img src="https://cdn-icons-png.flaticon.com/512/3985/3985429.png" className="imagenPerfilUsuarioModal"/>
            </div>
            <div className="col-12 col-sm-8 col-md-8">
                <div className="divContenedorInformacionUsuario">
                    <Label className='labelInputModal'>
                        Nombre completo:
                    </Label>
                    <span className="informacionUsuario">
                        {usuario.nombre_usuario} {usuario.apellido_paterno} {usuario.apellido_materno}
                    </span>
                </div>
                <div className="divContenedorInformacionUsuario">
                    <Label className='labelInputModal'>
                        Correo electrónico: 
                    </Label>
                    <span className="informacionUsuario">
                        {usuario.email}
                    </span>
                </div>
                <div className="divContenedorInformacionUsuario">
                    <Label className='labelInputModal'>
                        Rol: 
                    </Label>
                    <span className="informacionUsuario">
                        {usuario.rol}
                    </span>
                </div>
            </div>
        </div>
    )
}
export default InfoUsuario;
