export interface usuario{
    id_usuario:number, 
    nombre_usuario:string,
    apellido_paterno:string, 
    apellido_materno:string,
    email: string,
    rol: string
}

export const usuarioestado:usuario = {
    id_usuario:0, 
    nombre_usuario:'',
    apellido_paterno:'', 
    apellido_materno:'',
    email: '',
    rol: ''
}