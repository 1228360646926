import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalEliminarApiSolicitud.css";
import { deleteEliminarApi } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import { validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import InputComentario from '../../Formularios/InputComentario';

interface props {
    isOpen: boolean,
    closeModal:(accion:number) => void,
    idsolicitudtieneapi:number,
    token:number
}

interface states {
    isOpen: boolean,
    confirmar:boolean,

    confirmarcomentario:boolean,
    txtcomentario:string,
    vermsgerrorcomentario:boolean,
    msgerrorcomentario:string
   
}
export default class ModalEliminarApiSolicitud extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
          isOpen:this.props.isOpen,
          confirmar:false,

          confirmarcomentario:false,
          txtcomentario:'',
          vermsgerrorcomentario:false,
          msgerrorcomentario:''
        }
    }

    showModal=(accion:number)=> {
      this.setState({isOpen:false})
      setTimeout(() => {
        this.props.closeModal(accion);
      }, 200);
    }

    comentario = (prop:string) => {
      this.setState({txtcomentario:prop})
    }

    habilitarComentario = (prop:boolean) => {
        this.setState({ confirmarcomentario:prop });
    }

    eliminarApi = async () => {
      this.setState({ vermsgerrorcomentario: false});

      let longitudvalidacomentario = {longitudminima: 8, longitudmaxima: 300};

      let txtcomentario = validarLetrasNumerosEspacios(this.state.txtcomentario, longitudvalidacomentario);
      
      let validacionescorrectas = true;

      if (txtcomentario.codigo == "invalid" && this.state.confirmarcomentario === true) {
        this.setState({ msgerrorcomentario: txtcomentario.mensaje });
        this.setState({ vermsgerrorcomentario: true });
        validacionescorrectas = false;
      }

      if (validacionescorrectas) {
        let json = {
          "token":this.props.token,
          "idsolicitud_tiene_api":this.props.idsolicitudtieneapi,
          "comentario_checkbox":this.state.confirmarcomentario,
          "comentario":this.state.txtcomentario
        }
        const resp = await deleteEliminarApi(json);
  
        if (resp.code == 0) {
          this.showModal(1)
        }
        else if (resp.code == 200) {
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
        else if (resp.code == 300) {
          toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
        else if (resp.code == 1000 || resp == -1) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.showModal(0)
        }
      }
    }
    
    render() {
        return (
          <>
            <ToastContainer />
            <Modal isOpen={this.state.isOpen}>
              <ModalHeader className="modalHeader">
                <label className='labelTituloModal'> 
                  Eliminar api
                </label>
                <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
              </ModalHeader>
              <ModalBody>
                <div className="divContenedorModal">
                  <div className="contenidoTextoModal">
                    <span className='textoPreguntaModal'>¿Está seguro de eliminar esta api?</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <InputComentario 
                      checkbox={this.state.confirmarcomentario}
                      label={'Añadir un comentario acerca de eliminación de la api.'}
                      txtvalor={this.state.txtcomentario}
                      vermsgerror={this.state.vermsgerrorcomentario} 
                      msgerror={this.state.msgerrorcomentario}
                      escribir={this.comentario} 
                      habilitar={this.habilitarComentario}
                    />
                  </div>
                </div>
                <div className="contenedorCentro" onClick={() => this.setState({ confirmar: !this.state.confirmar })}>
                  <input type="checkbox" checked={this.state.confirmar} /> Confirmar eliminación
                </div>
                <div className="divContenedorBotonesModal mt-2">
                  <Button className="btnCancelModal" onClick={() => this.showModal(0)} >Cancelar</Button>
                  <Button className="btnAceptarModal"  onClick={this.eliminarApi} disabled={!this.state.confirmar}>Eliminar</Button>           
                </div>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
    