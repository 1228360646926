//Interfaz de la estructura de datos de cada modulo.
export interface modulo{
	id_modulo:number,
	nombre_modulo:string,
	apis_completadas: number,
	apis_en_desarrollo: number,
	apis_reportadas: number
}

//Interfaz de la estructura de datos de cada usuario.
export interface usuario{
	id_usuario:number, 
	nombre_usuario:string,
	apellido_paterno:string, 
	apellido_materno:string
}

export interface proyecto{
    id_proyecto:number,
    nombre_proyecto:string,
    descripcion_proyecto:string,
    headers_proyecto:string,
    logo_img:string,
    id_usuario:number,
    nombre_usuario:string,
    apellido_paterno:string,
    apellido_materno:string,
    fecha_creacion: string,
    apis_completadas: number,
    apis_en_desarrollo: number,
    apis_reportadas: number
}

export const proyectoestado: proyecto = {
    id_proyecto:0,
    nombre_proyecto:'',
    descripcion_proyecto:'',
    headers_proyecto:'',
    logo_img:'',
    id_usuario:0,
    nombre_usuario:'',
    apellido_paterno:'',
    apellido_materno:'',
    fecha_creacion: '',
    apis_completadas: 0,
    apis_en_desarrollo: 0,
    apis_reportadas: 0
};