import React from "react";
import { NavLink,Navigate} from "react-router-dom";
import "./Navbar.css";
import SpinnerModal from "../Modales/SpinnerModal/SpinnerModal";
import { Badge, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown } from "reactstrap";
import { getNotificacionesReportes, getNotificacionesSolicitudes } from "../../Services/Api";

interface states{
  isOpen: boolean,
  salir: boolean,
  cargando:boolean,
  perfil:{
		token:number,
        name:string,
		email:string,
        role:number
  }
  notificacionesreportes:number,
  notificacionessolicitudes:number
}

export default class MenuNavbar extends React.Component<{}, states> {
    constructor(props: {}) {
      super(props);
      this.state = {
        isOpen: false,
		salir:false,
		cargando:false,
		perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "","role":""}'),
        notificacionesreportes:0,
        notificacionessolicitudes:0
    };
    }
	async componentDidMount() {
        if (await localStorage.getItem('acceso') == null || await localStorage.getItem('acceso') == undefined || await localStorage.getItem('acceso') == '') {
            this.setState({ salir: true });
        }

        const apinotificacionesreporte= await getNotificacionesReportes(this.state.perfil.token);
        const { notificaciones_reportes } = apinotificacionesreporte.data;
        this.setState({notificacionesreportes:notificaciones_reportes})

        const apinotificacionessolicitudes= await getNotificacionesSolicitudes(this.state.perfil.token);
        const { notificaciones_solicitudes } = apinotificacionessolicitudes.data;
        this.setState({notificacionessolicitudes:notificaciones_solicitudes})
    }

	toggleNavbar = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    salir = () => {
        this.setState({cargando: true });
        setTimeout(() => {
            this.setState({cargando: false });
        }, 500);
        setTimeout(() => {
            this.setState({ salir: true });
        }, 500);
	}

    render() {
        return (
            <>
                {this.state.salir ?
                        <> {Acciones("salir")} </>
                    : null}
                {(this.state.cargando == true) ? (
                    <SpinnerModal tipo="full" show={this.state.cargando}/>
                ) : null}
                <Navbar className="navbarPrincipal" expand="sm">
                    <NavbarBrand className="navbarBrand">
                        Apibox
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar}/>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="navContenidoMenu" navbar>
                            <NavItem>
                                <NavLink className="navlinkNavbar" to="/catalogoproyectos">
                                    <span>Proyectos</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="navlinkNavbar" to="/catalogoapis">
                                    <span>APIs</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="navlinkNavbar" to="/catalogoreportes">
                                    {(this.state.notificacionesreportes == 0) ? (
                                        (this.state.perfil.role == 2 || this.state.perfil.role == 3) ? (
                                            <span>Mis reportes</span>
                                        ) : (
                                            <span>Reportes</span>
                                        )
                                    ) : (
                                        (this.state.perfil.role == 2 || this.state.perfil.role == 3) ? (
                                            <>
                                                <span>Mis reportes</span><Badge className="badgeNotificacion"  color="danger" pill>{this.state.notificacionesreportes}</Badge>
                                            </>
                                        ) : (
                                            <>
                                                <span>Reportes</span><Badge className="badgeNotificacion" color="danger" pill>{this.state.notificacionesreportes}</Badge>
                                            </>
                                        )
                                    )}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="navlinkNavbar" to="/catalogosolicitudes">
                                    {(this.state.notificacionessolicitudes == 0) ? (
                                        (this.state.perfil.role == 2 || this.state.perfil.role == 3) ? (
                                            <span>Mis solicitudes</span>
                                        ) : (
                                            <span>Solicitudes</span>
                                        )
                                    ) : (
                                        (this.state.perfil.role == 2 || this.state.perfil.role == 3) ? (
                                            <>
                                                <span>Mis solicitudes</span><Badge className="badgeNotificacion" color="danger" pill>{this.state.notificacionessolicitudes}</Badge>
                                            </>
                                        ) : (
                                            <>
                                                <span>Solicitudes</span><Badge className="badgeNotificacion" color="danger" pill>{this.state.notificacionessolicitudes}</Badge>
                                            </>
                                        )
                                    )}
                                </NavLink>
                            </NavItem>
                            {this.state.isOpen ? (
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle className="navlinkNavbar" nav caret>
                                        {this.state.perfil.name}
                                    </DropdownToggle>
                                    <DropdownMenu className="droptownNavbar">
                                    <DropdownItem> 
                                            <NavLink className="navlinkDropdownitemNavbar" to="/">
                                                <a>Mi perfil</a>
                                            </NavLink>
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={this.salir} >Cerrar sesión </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            ) : (
                                <UncontrolledDropdown nav>
                                    <DropdownToggle className="navlinkNavbar" nav caret>
                                        {this.state.perfil.name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={this.salir}>
                                            Cerrar sesión 
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            )}
                        </Nav>
                    </Collapse>
                </Navbar>
            </>
	    );
    }
}
function Acciones(bandera:string) {
    if (bandera == 'salir') {
        localStorage.clear();
		return <Navigate to="/" />;
    }
}

  