import React, { ChangeEvent } from "react";
import { ejemploentrada, ejemploexcepciones } from "../Interfaces";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";
import { IoMdClose } from "react-icons/io";
import { BiPlus } from "react-icons/bi";

interface props{
    ejemplosexcepciones:ejemploexcepciones[]
    ejemplos: (ejemplos:ejemploexcepciones[]) => void;
}

interface states{
    ejemplosexcepciones: ejemploexcepciones[];

}

export class FormularioEjemplosExcepciones extends React.Component<props, states> {
    constructor(props: props) {
		super(props);
		this.state = {
            ejemplosexcepciones:this.props.ejemplosexcepciones
        }
    } 

    componentDidUpdate(prevProps: props) {
        if (prevProps.ejemplosexcepciones !== this.props.ejemplosexcepciones) {
            this.setState({ ejemplosexcepciones: this.props.ejemplosexcepciones });
        }
    }

    /////////////////////////////// Sección de excepciones
    //Función para agregar ejemplo de salida.
    agregarEjemploExcepcion = () => {
        const  ejemploexcepciones = this.state.ejemplosexcepciones;
        const nuevoEjemploExcepcion: ejemploexcepciones = {
            id_ejemplo_excepcion:0,
            nombre_ejemplo_excepcion: '',
            nombre_ejemplo_excepcionlength:0,
            txtnombreejemploexcepcion:'',
            vermsgerrornombreejemploexcepcion:false,
            msgerrornombreejemploexcepcion:'',
            ejemplo_excepcion: '',
            ejemplo_excepcionlength:0,
            txtejemploexcepcion:'',
            vermsgerrorejemploexcepcion:false,
            msgerrorejemploexcepcion:''
            
        };
		if (ejemploexcepciones.length < 10) {
			const ejemplosExcepcionesActualizado = [...ejemploexcepciones, nuevoEjemploExcepcion];
			this.setState({ ejemplosexcepciones: ejemplosExcepcionesActualizado });
            this.props.ejemplos(ejemplosExcepcionesActualizado)
        } else {
            toast.warning("No puedes agregar más de 10 ejemplos.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
    };
    
    //Función para actualizar el estado de los inputs de nombre de cada ejemplo de salida.
    actualizarNombreEjemploExcepcion =(event: ChangeEvent<HTMLInputElement>, ejemploIndex: number) => {
        const  ejemplosexcepciones  = this.state.ejemplosexcepciones;
        const ejemplosActualizados = ejemplosexcepciones.map((ejemplo, index) => {
            if (index === ejemploIndex) {
            return {
                ...ejemplo,
                nombre_ejemplo_excepcion: event.target.value,
                nombre_ejemplo_excepcionlength:event.target.value.length
            };
            }
            return ejemplo;
        });
        this.setState({ ejemplosexcepciones: ejemplosActualizados });
        this.props.ejemplos(ejemplosActualizados)
    };
    
    //Función para actualizar el estado de los inputs del ejemplo  de cada ejemplo de salida.
    actualizarEjemploExcepcion =(event: ChangeEvent<HTMLInputElement>, ejemploIndex: number) => {
        const  ejemplosexcepciones  = this.state.ejemplosexcepciones;
        const ejemplosActualizados = ejemplosexcepciones.map((ejemplo, index) => {
            if (index === ejemploIndex) {
            return {
                ...ejemplo,
                ejemplo_excepcion: event.target.value,
                ejemplo_excepcionlength:event.target.value.length
            };
            }
            return ejemplo;
        });
        this.setState({ ejemplosexcepciones: ejemplosActualizados });
        this.props.ejemplos(ejemplosActualizados)
    };
    
    //Función para eliminar un ejemplo de entrada.
    eliminarEjemploExcepcion = (ejemploIndex: number) => {
        const ejemploexcepciones = this.state.ejemplosexcepciones;
        if (ejemploIndex !== 0) {
            const ejemplosActualizados = ejemploexcepciones.filter((ejemplo, index) => index !== ejemploIndex);
            this.setState({ ejemplosexcepciones: ejemplosActualizados });
            this.props.ejemplos(ejemplosActualizados)
        }
    };

    //Función para insertar tabulación dentro de los textarea
	teclaTab = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Tab") {
			const textarea = event.target as HTMLTextAreaElement;

			//Se obtiene la posición del cursor dentro del textarea
			const cursor = textarea.selectionStart;
		
			//Inserta la tabulación en la posición del cursor
			textarea.value = textarea.value.slice(0, cursor) + "\t" + textarea.value.slice(cursor);
		
			//Mueve el cursor a su posición
			textarea.setSelectionRange(cursor + 1, cursor + 1);
		
			//Evita que al presionar tab se cambie de elemento como al botón u otro input.
			event.preventDefault();
		}
	};

    render() {
		return(
            <>
                {this.state.ejemplosexcepciones.map((ejemplo, index) => (
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-1" key={index}>
                        <div className="row">
                            <div className="col-11">
                                <Label className='labelInputModal'>
                                    Nombre de ejemplo {index + 1} de excepcion <span className="spanObligatorio">*</span>
                                </Label>
                            </div>
                            <div className="col-1 divContenedorBotonEliminarEjemplo">
                                {index !== 0 && ejemplo.id_ejemplo_excepcion === 0?
                                    <button className="botonEliminarEjemplo" onClick={() => this.eliminarEjemploExcepcion(index)}>
                                        <IoMdClose size={25} color="white"/>
                                    </button>
                                :null}
                            </div>
                            <div className="col-12">
                                <Input
                                    className="inputModal"
                                    type="text" 
                                    maxLength={30}
                                    onChange={(event) => this.actualizarNombreEjemploExcepcion(event, index)}
                                    value={ejemplo.nombre_ejemplo_excepcion}/>
                                    {ejemplo.vermsgerrornombreejemploexcepcion
                                        ? 
                                            <div className="row">
                                                <div className="col-10 contenedorMensajeError">
                                                    <span className="mensajeError">{ejemplo.msgerrornombreejemploexcepcion}</span>
                                                </div>
                                                <div className="col-2 contenedorContadorCaracteres">
                                                    <span className='contadorCaracteres'>{ejemplo.nombre_ejemplo_excepcionlength} / 30</span>
                                                </div>
                                            </div>
                                        : 
                                            <div className="contenedorContadorCaracteres">
                                                <span className='contadorCaracteres'>{ejemplo.nombre_ejemplo_excepcionlength} / 30</span>
                                            </div>
                                    }
                            </div>
                        </div>
                        <Label className='labelInputModal'>
                            Ejemplo {index + 1} de excepciones <span className="spanObligatorio">*</span>
                        </Label>
                        <Input
                            className="textAreaEjemplo"
                            type="textarea"
                            maxLength={3000}
                            onChange={(event) => this.actualizarEjemploExcepcion(event, index)}
                            value={ejemplo.ejemplo_excepcion} 
                            onKeyDown={this.teclaTab}/>
                        {ejemplo.vermsgerrorejemploexcepcion
                            ? 
                                <div className="row">
                                    <div className="col-10 contenedorMensajeError">
                                        <span className="mensajeError">{ejemplo.msgerrorejemploexcepcion}</span>
                                    </div>
                                    <div className="col-2 contenedorContadorCaracteres">
                                        <span className='contadorCaracteres'>{ejemplo.ejemplo_excepcionlength} / 3000</span>
                                    </div>
                                </div>
                            : 
                                <div className="row">
                                    <div className="col contenedorContadorCaracteres">
                                        <span className='contadorCaracteres'>{ejemplo.ejemplo_excepcionlength} / 3000</span>
                                    </div>
                                </div>
                        }
                    </div>
                ))}
                {this.state.ejemplosexcepciones.length < 10 ? 
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 divAgregarEjemplo">
                        <button className="divContendorAgregarEjemplo" type="button" onClick={this.agregarEjemploExcepcion}>
                            <BiPlus size={30} color="gray"/>
                            <span>Agregar ejemplo</span>  
                        </button>
                    </div>
                :null}
            </>
        )
    }
}