import React from "react";
import { Table, Input } from 'reactstrap';
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MenuNavbar from "../../Complements/Navbar/Navbar";
import { getListaRegistroActividad, postDetallesApi } from "../../Services/Api";
import './DocumentacionRegistroActividad.css'
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import Paginacion, { paginarElementos } from "../../Complements/Paginacion/Paginacion";
import { api, apiestado, registro } from "./Interfaces";
import Navegacion from "../../Complements/Navegacion/Navegacion";

//Interfaz general del componente.
interface states{
    id_rol:number,
    salir:boolean,
    cargando:boolean,
    perfil:{
		token:number,
        name:string,
		email:string,
        role:number
  	},
    api:api,
    tabla: {
        encabezados: string[],
        dimensiones: string[],
        registros:registro[]
    },
    registrosfiltrados:registro[],
    registrospaginados:registro[],
    registrosfiltradospaginados:registro[],
    txtbuscador:string,
    paginaActiva: number,
	itemsPorPagina: number,
	totalPaginas:number,
    totalPaginasFiltradas:number,
    redireccionando:number
}

export default class DocumentacionRegistroActividad extends React.Component<{}, states> {
    constructor(props: {}) {
		super(props);
		this.state = {
          id_rol:0,
		  salir:false,
		  cargando:true,
          perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "", "role":""}'),
          api:apiestado,
            tabla: {
				encabezados: [
				  "Actividad",
				  "Fecha",
				  "Usuario"
				],
				dimensiones: [
					"150px",//Actividad
					"270px",//Fecha
					"150px",//Usuario
				],
				registros:[]
			},
            registrosfiltrados:[],
            registrospaginados:[],
            registrosfiltradospaginados:[],
            txtbuscador:'',
            paginaActiva: 1,
			itemsPorPagina: 8,
			totalPaginas:0,
            totalPaginasFiltradas:0,
            redireccionando:0
        }
	}

	async componentDidMount() {
		if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
			//this.setState({ redireccionando: true });
        } 
       
        var id_proyecto = await localStorage.getItem('id_proyecto');
		var id_api = await localStorage.getItem('id_api');

        const apiapi = await postDetallesApi(this.state.perfil.token,Number(id_proyecto),Number(id_api));
        if(this.respuestaApi (apiapi)){
			return;
		}
        this.setState({api:apiapi.data})
        
        const listaregistroactividad = await getListaRegistroActividad(this.state.perfil.token,Number(id_api));
        if(this.respuestaApi (listaregistroactividad)){
			return;
		}
        this.registrosModificados(listaregistroactividad)
        this.cambiarPagina(1)
		setTimeout(() => {
			this.setState({cargando: false});
		}, 1000);
    }

    
    //Función para gestionar las respuestas negativas de las apis.
    respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		return false
	}

    //Función de regresar
	regresar = () => {
		window.history.back();
	};

    //Función para modificar la fecha y concatenar nombre del usuario.
	registrosModificados = (registros: any) =>{
		const registrosmodificados = registros.data.map((registro:any, index:number) => {
			//Se agrega un nuevo formato a la fecha de generación del reporte.
			const fecha = new Date(registro.fecha + " " + registro.hora);
			const fecha_formateada = format(fecha, "d 'de' MMMM 'del' yyyy 'a las' hh:mm a", {locale: es});

            const nombre_completo = registro.nombre_usuario + " " + registro.apellido_paterno + " " + registro.apellido_materno

			return { ...registro, fecha_formateada, nombre_completo};
		});

        var tabla = this.state.tabla;
		tabla.registros = JSON.parse(JSON.stringify(registrosmodificados));
	}

    //Función para buscar una solicitud por su título.
	buscarRegistro = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ txtbuscador: e.target.value });

		if (e.target.value.trim().length > 2) {
			var data1 = JSON.parse(JSON.stringify(this.state.tabla.registros))

			const filtros = data1.filter((data1:any) => (data1.detalles + " " + data1.fecha_formateada + " " + data1.nombre_completo).toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

			if (filtros.length < 1) {
				toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			this.filtro(1, filtros)
		}
		else{
			this.setState({registrosfiltradospaginados:[]})
		}

	}

    //Función para cambiar de página
	cambiarPagina = (numeroPagina:number) =>{
		this.setState({
			paginaActiva: numeroPagina,
			registrospaginados:[]
		  });
	  
		setTimeout(() => {
			this.setState({
				paginaActiva: numeroPagina,
				registrospaginados: paginarElementos(numeroPagina,this.state.itemsPorPagina,  this.state.tabla.registros),
				totalPaginas:Math.ceil(this.state.tabla.registros.length / this.state.itemsPorPagina),
			});
		}, 0);
		
	}

    ///Función obtener resultado de la busqueda y paginarlos.
	filtro = (numeroPagina:number, array:any) => {
		this.setState({
		  paginaActiva: numeroPagina,
		  registrosfiltradospaginados: [],
		  totalPaginasFiltradas:0
		});
		setTimeout(() => {
		  this.setState({
			registrosfiltrados: array,
			paginaActiva: numeroPagina,
			registrosfiltradospaginados: paginarElementos(numeroPagina,this.state.itemsPorPagina, array),
			totalPaginasFiltradas:Math.ceil(this.state.registrosfiltrados.length / this.state.itemsPorPagina)
		  });
		}, 0);
	}

	render() {
		return ( 	
		<div className="divContenedorPrincipal">
            {/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/}
            {this.state.redireccionando == 1 ?
					<>
						{Acciones("infogeneral")}
					</>
			:null}

            {/*////////// Sección de activación de modales y spinner //////////*/}
            {(this.state.cargando == true) ? (
           		<SpinnerModal tipo="full" show={this.state.cargando}/>
        	) : null}

            <ToastContainer />
            <MenuNavbar/>
		    <main>
				{/*////////// Sección de breadscrum //////////*/}
				<Navegacion
					seccion={"registro-apis"}
					proyecto={this.state.api.nombre_proyecto}
					modulo={this.state.api.nombre_modulo}
					submodulo={this.state.api.nombre_submodulo}
					accion={this.state.api.submodulo_general}
					api={this.state.api.nombre_api}
				/>
                {/*////////// Sección de buscador de registros //////////*/}
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Input
                            type="text"
                            className="inputBuscador"
                            placeholder="Buscar solicitud por actividad, fecha o usuario..."
                            onChange={this.buscarRegistro} 
                            value={this.state.txtbuscador}
                        />
                    </div>
                </div>
                {/*////////// Sección de lista de registros //////////*/}
                <div className="row mt-3">
                    <Table className="tablaListaElementos" hover responsive >
                        <thead className="titulosTabla">
                            <tr>
                                {this.state.tabla.encabezados.map((encabezado, index) => (
                                    <th key={"tabla" + index}
                                    style={{width: this.state.tabla.dimensiones[index] , minWidth:this.state.tabla.dimensiones[index]}}>
                                        {encabezado}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody >
                            {this.state.txtbuscador.length < 3 ?
								this.state.registrospaginados.map((registro, index)=>(	
                                    <tr key={index}>
                                        <td style={{width: this.state.tabla.dimensiones[0] }}>
                                            {registro.detalles}
                                        </td>
                                        <td style={{width: this.state.tabla.dimensiones[1] }}>
                                            {registro.fecha_formateada}
                                        </td>
                                        <td style={{width: this.state.tabla.dimensiones[2] }}>
                                            {registro.nombre_completo}
                                        </td>
                                    </tr>
                                ))
								:this.state.registrosfiltradospaginados.map((registro, index)=>(	
									<tr key={index}>
                                        <td style={{width: this.state.tabla.dimensiones[0] }}>
                                            {registro.detalles}
                                        </td>
                                        <td style={{width: this.state.tabla.dimensiones[1] }}>
                                            {registro.fecha_formateada}
                                        </td>
                                        <td style={{width: this.state.tabla.dimensiones[2] }}>
                                            {registro.nombre_completo}
                                        </td>
                                    </tr>
								))
							}
                        </tbody>
                    </Table>
                </div>
                <div className="row mt-3">
                    {this.state.txtbuscador.length < 3 ?
                        <Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaActiva}
                        itemsPorPagina={this.state.itemsPorPagina} totalPaginas={this.state.totalPaginas} tamañoPaginacion="md"/>
                    : 
                        <Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaActiva}
                        itemsPorPagina={this.state.itemsPorPagina} totalPaginas={this.state.totalPaginasFiltradas} tamañoPaginacion="md"/>
                    }
                </div>
			</main>
		</div>
    );
};
}
//Función para redireccionar a diferentes páginas mediante condiciones del valor recibida
function Acciones(bandera:string) {
    if(bandera=="reporte"){
		return <Navigate to="/reporte" />;
	}else if(bandera=="infogeneral"){
		return <Navigate to="/documentarapiinfogeneral" />;
	}else if(bandera=="entradas"){
		return <Navigate to="/documentarapientradas" />;
	}else if (bandera == 'salidas') {
		return <Navigate to="/documentarapisalidas" />;
    }else if (bandera == 'modulo') {
		return <Navigate to="/modulo" />;
    }else if (bandera == 'submodulo') {
		return <Navigate to="/submodulo" />;
    }else if (bandera == 'excepciones') {
		return <Navigate to="/documentarapiexcepciones" />;
    }
}
