import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { modulo } from '../Interfaces';

interface props {
    modulos:modulo[]
    selectmodulo:number
    vermsgerror:boolean,
    msgerror:string,
    modulo: (modulo:number) => void;
}

interface states {
    selectmodulo:number
}

export default class InputModulos extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            selectmodulo:this.props.selectmodulo
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.selectmodulo !== this.props.selectmodulo) {
          this.setState({ selectmodulo: this.props.selectmodulo });
        }
    }

    moduloSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const modulo = Number(event.target.value);
        this.setState({ selectmodulo:modulo });
        this.props.modulo(modulo)
    };
  
    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Módulo <span className="spanObligatorio">*</span>
                </Label>
                <Input type="select" value={this.state.selectmodulo} onChange={this.moduloSeleccionado}>
                    <option disabled value="0">Seleccione una opción</option>
                    {this.props.modulos.map((modulo, index) => (	
                    <option key={index} value={modulo.id_modulo}>
                        {modulo.nombre_modulo}
                    </option>
                    ))}
                </Input>
                {this.props.vermsgerror ? 
                    <div className="row">
                        <div className="col-10 contenedorMensajeError">
                        <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    </div>
                :null}
            </FormGroup>
        );
    }
}