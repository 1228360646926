import React from "react";
import { FaCloud } from "react-icons/fa";
import { Input, Button, Card, CardBody, Breadcrumb, BreadcrumbItem, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, InputGroupText } from 'reactstrap';
import { getSubmoduloDetalles, getListaApisDelSubmodulo } from "../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { Navigate, NavLink } from "react-router-dom";
import ModalCrearSubmodulo from "../../Complements/Modales/ModalFormularioSubmodulo/ModalFormularioSubmodulo";
import { RiDeleteBinFill } from "react-icons/ri";
import ModalEliminarSubmodulo from "../../Complements/Modales/ModalEliminarSubmodulo/ModalEliminarSubmodulo";
import { CgDetailsMore } from "react-icons/cg";
import MenuNavbar from "../../Complements/Navbar/Navbar";
import { HiPencil, HiPuzzle } from "react-icons/hi";
import Paginacion, { paginarElementos } from '../../Complements/Paginacion/Paginacion';
import { SlOptions } from "react-icons/sl";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { TbArrowBackUp } from "react-icons/tb";
import { IoMdAdd } from "react-icons/io";
import Api from "../DetallesModulo/Componentes/Api";
import { api, submodulo, submoduloestado } from "./Interfaces";
import Headers from "../../Complements/Headers/Headers";
import Navegacion from "../../Complements/Navegacion/Navegacion";
import { HiMagnifyingGlass } from "react-icons/hi2";

//Interfaz general del componente.
interface SubmoduloInt{
	id_rol:number,
	isExpanded: boolean,
	salir: boolean,
	cargando:boolean,
	perfil:{
		token:number,
        name:string,
		email:string,
		role:number
	},
	opcionesdetalles:boolean,
	submodulo:submodulo,

	txtbuscadorapis: string,
	visualizarapis:boolean,
	apis: api[],
	apisfiltradas: api[],
	apispaginadas: api[],
	apisfiltradaspaginadas: api[],

	//Estados de modales.
	titulomodal:string
	accionmodalmodulo:number,
	submoduloseleccionado:submodulo,
	showmodalcrearsubmodulo:boolean,
	showmodaleliminarsubmodulo:boolean,

	//Estados de paginación de apis
	paginaactiva: number,
	itemsporpagina: number,
    totalpaginas:number
    totalpaginasfiltradas:number,

	redireccionando:number
}

export default class DetallesSubmodulo extends React.Component<{}, SubmoduloInt> {
	constructor(props: {}) {
		super(props);
		this.state = {
		  id_rol:0,
		  isExpanded: false,
		  salir:false,
		  cargando:true,
		  perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "", role:""}'),
		  opcionesdetalles:false,
		  submodulo:submoduloestado,

		  txtbuscadorapis: "",
		  visualizarapis:true,
		  apis: [],
		  apisfiltradas:[],
		  apispaginadas:[],
	      apisfiltradaspaginadas:[],

		  //Estados de modales.
		  titulomodal:"",
		  accionmodalmodulo:0,
		  submoduloseleccionado:submoduloestado,
		  showmodalcrearsubmodulo:false,
		  showmodaleliminarsubmodulo:false,
		
		  //Estados de paginación.
		  paginaactiva: 1,
      	  itemsporpagina: 4,
		  totalpaginas:0,
		  totalpaginasfiltradas:0,

		  redireccionando:0
		  };
	}

	async componentDidMount() {
		/*
		Si no existen datos del usuario almacenados en localStorage, se interpretará que no ha iniciado sesión
		y se redirigirá automáticamente al login. 
		*/
		if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
			//this.setState({ redireccionando: true });
        } 

		//Se obtiene el calor del item "id_rol" y se almacena en su correspondiente estado.
		const id_rol = localStorage.getItem("id_rol")
		this.setState({id_rol:Number(id_rol)})

		//Se obtiene el valor de el item "accion", se hacen validaciones y se muestra el mensaje correspondiente,
		const accion = localStorage.getItem("accion");
		if(accion !== null){
			this.mensajesToast(accion);
			localStorage.removeItem("accion");
		}

		const id_submodulo = await localStorage.getItem('id_submodulo');
		const id_modulo = await localStorage.getItem('id_modulo');
		const id_proyecto = await localStorage.getItem('id_proyecto');

        const apisubmodulo = await getSubmoduloDetalles(this.state.perfil.token,Number(id_submodulo),Number(id_proyecto),Number(id_modulo));
		console.log(apisubmodulo)
		if(this.respuestaApi (apisubmodulo,0)){
			return;
		}
		this.submoduloFechaModificada(apisubmodulo)
	
		const apiapis = await getListaApisDelSubmodulo(this.state.perfil.token,Number(id_submodulo),Number(id_proyecto));
		if(this.respuestaApi (apiapis,1)){
			return;
		}
		this.setState({apis: apiapis.data});
		this.cambiarPagina(1)

		setTimeout(() => {
			this.setState({cargando: false});
		}, 1000);
		
    }

	//Función para mostrar mensajes toast al iniciar el componente.
	mensajesToast =  (accion:string) => {
		if (accion === "1") {
			toast.success("Submódulo creado exitosamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		if (accion === "Documentacion eliminada") {
			toast.success("Documentación eliminada!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		if (accion === "accesorestringido") {
			toast.warning("No tienes acceso a esta sección.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		if (accion === "migracion") {
			toast.success("Migración exitosa!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		if (accion === "copia") {
			toast.success("Api copiada exitosamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
	}

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any, tipo:number) => {
		if (resp === -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code === 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code === 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}
		else if (resp.code == 100) {
			if(tipo === 1){
				this.setState({visualizarapis:false})
			}
			this.setState({ cargando: false })
			return 
		}
		return false
	}

	//Función de regresar
	regresar = () => {
		window.history.back();
	};

	/////////////////////////////// Sección del submódulo
	//Función para modificar la fecha, trauducirla y aisgnarla.
	submoduloFechaModificada = (submodulo: any) =>{
		//Se modifica la fecha.
		const fecha_creacion = submodulo.data.fecha_creacion + " 00:00:00"; 
		const fecha = new Date(fecha_creacion);
		const fecha_formateada = format(fecha, "d 'de' MMMM 'del' yyyy", { locale: es });

		//Se asigna la nueva fecha.
		submodulo.data.fecha_creacion = fecha_formateada;
		
		this.setState({ submodulo: submodulo.data });
	}

	//Función para desplegar opciones
	desplegarOpciones = () => {
		this.setState({opcionesdetalles: !this.state.opcionesdetalles})
	};

	//Función para abrir modal para editar submódulo
	verModalFormularioSubmodulo = (accion:number, submodulo:any) => {
		if(accion==2){
			this.setState({ titulomodal:"Editar submódulo", accionmodalmodulo:accion, submoduloseleccionado:JSON.parse(JSON.stringify(submodulo)) }) 
		}
		this.setState({ showmodalcrearsubmodulo: true})
	}
	
	//Función para cerrar modal para editar submódulo.
	cerrarModalCrearSubmodulo = (accion:number) =>{
        this.setState({showmodalcrearsubmodulo:!this.state.showmodalcrearsubmodulo });	
		if (accion == 2) {
			this.setState({cargando:true});
			this.componentDidMount();
		}
    }

	//Función para abrir modal para eliminar submódulo.
	verModalEliminarSubmodulo = () => {
		this.setState({ showmodaleliminarsubmodulo: true})
	}

	//Función para cerrar modal para eliminar submódulo.
	cerrarModalEliminarSubmodulo = (accion:number) =>{
        this.setState({showmodaleliminarsubmodulo: !this.state.showmodaleliminarsubmodulo });
		if(accion==1){
			localStorage.setItem("accion", "SubmoduloEliminado");
			this.setState({ redireccionando: 2})
		}
    }

	/////////////////////////////// Sección de apis del submódulo
	//Función para paginar apis y cambiar de página.
	cambiarPagina = (numeroPagina:number) =>{
		this.setState({
			paginaactiva: numeroPagina,
			apisfiltradaspaginadas: [],
			apispaginadas: []
		  });
		setTimeout(() => {
			this.setState({
				paginaactiva: numeroPagina,
				apispaginadas: paginarElementos(numeroPagina,this.state.itemsporpagina,  this.state.apis),
				totalpaginas:Math.ceil(this.state.apis.length / this.state.itemsporpagina),
				apisfiltradaspaginadas: paginarElementos(numeroPagina,this.state.itemsporpagina, this.state.apisfiltradas)
			});
		}, 0);
	}

	//Función para buscar apis.
	buscarApi = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ txtbuscadorapis: e.target.value });

		if (e.target.value.trim().length > 2) {
			var data1 = JSON.parse(JSON.stringify(this.state.apis))
			
			const filtros = data1.filter((data1:any) => data1.nombre_api.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
			
			if (filtros.length < 1) {
				toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			this.filtro(1, filtros)
		}else{
			this.setState({apisfiltradaspaginadas:[]});
		}	
	}
	
	//Función para obtener el resultado de la busqueda y paginarlos.
	filtro = (numeroPagina:number, array:any) => {
		this.setState({
		  paginaactiva: numeroPagina,
		  apisfiltradaspaginadas: [],
		  totalpaginasfiltradas:0
		});
		setTimeout(() => {
		  this.setState({
			apisfiltradas: array,
			paginaactiva: numeroPagina,
			apisfiltradaspaginadas: paginarElementos(numeroPagina,this.state.itemsporpagina, array),
			totalpaginasfiltradas:Math.ceil(this.state.apisfiltradas.length / this.state.itemsporpagina)
		  });
		}, 0);
	}

	//Función para redireccionar al formulario para documentar una api.
	redireccionandoDocumentarApi = (submodulo_general: number) => {
		localStorage.setItem("submodulo_general", submodulo_general.toString());
		this.setState({ redireccionando: 3 })
	}

	//Función para redirigir a la documentación de una api.
	redireccionandoDocumentacionApi = (id_api:number) => {
		localStorage.setItem("id_proyecto", this.state.submodulo.id_proyecto.toString());
		localStorage.setItem("submodulo_general", this.state.submodulo.submodulo_general.toString());
		localStorage.setItem("id_api", id_api.toString());
		this.setState({ redireccionando: 4 })
	}

	render() {
		return (
			<div className="divContenedorPrincipal">
				{/*////////// Sección de redirigir a paginas mediante condicionales //////////*/}
				{this.state.redireccionando == 1 ?
					<>
						{Acciones("accesorestringido")}
					</>
				: this.state.redireccionando == 2 ?
					<>
						{Acciones("submoduloeliminadoexitosamente")}
					</>
				: this.state.redireccionando == 3 ?
					<>
						{Acciones("documentarapi")}
					</>
				: this.state.redireccionando == 4 ?
					<>
						{Acciones("documentacionapi")}
					</>
				:null}

				{/*////////// Sección de activación de modales y spinner //////////*/}
				{(this.state.cargando == true) ? (
					<SpinnerModal tipo="full" show={this.state.cargando}/>
				) : null}
				{(this.state.showmodaleliminarsubmodulo == true) ? (
					<ModalEliminarSubmodulo isOpen={true} closeModal={this.cerrarModalEliminarSubmodulo} idsubmodulo={this.state.submodulo.id_submodulo}
					token={this.state.perfil.token} idproyecto={this.state.submodulo.id_proyecto}/>
				) : null}
				{(this.state.showmodalcrearsubmodulo == true) ? (
					<ModalCrearSubmodulo isOpen={true} closeModal={this.cerrarModalCrearSubmodulo} idproyecto={this.state.submodulo.id_proyecto}
					idmodulo={this.state.submodulo.id_modulo} token={this.state.perfil.token} titulomodal={this.state.titulomodal} accion={this.state.accionmodalmodulo}
					submoduloseleccionado={this.state.submoduloseleccionado} idsolicitud={0}/>
				) : null}
				<ToastContainer />
				<MenuNavbar/>
				<main>
					{/*////////// Sección de breadscrum //////////*/}
					<Navegacion
						seccion="submodulo"
						proyecto={this.state.submodulo.nombre_proyecto}
						modulo={this.state.submodulo.nombre_modulo}
						submodulo={this.state.submodulo.nombre_submodulo}
					/>
					{/*////////// Sección de información del submódulo //////////*/}
					<Card className="cardSeccion">
						<CardHeader className="cardSeccionHeader">
							<div className="contenedorTituloSeccionDetalles">
								<div className="divTituloSeccion">
									<CgDetailsMore size={23} color="black" />
									<span className="tituloSeccion">Detalles</span>
								</div>
								{/*////////// Sección de botón para editar submódulo, el cual se oculta dependiendo del rol //////////*/}
								{this.state.id_rol == 1 || this.state.id_rol == 2 || this.state.id_rol == 4 ? 
									<Dropdown className="dropdownOpcionesDetalles" isOpen={this.state.opcionesdetalles} onClick={() => this.desplegarOpciones()}>
										<DropdownToggle data-toggle="dropdown" tag="span">
											<SlOptions size={20} color="black"/>
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem onClick={() => this.verModalFormularioSubmodulo(2, this.state.submodulo)}>
												<HiPencil size={15} color="white"/> Editar información
											</DropdownItem>
											<DropdownItem onClick={() => this.verModalEliminarSubmodulo()}>
												<RiDeleteBinFill size={15} color="white"/> Eliminar
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								:null}
							</div>
						</CardHeader>
						<CardBody className="divContenidoCardBody">
							<div className="row d-flex">
								<div className="col">
									<div className="divTablaDetalles col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<table className="tableContenidoDetalles ">
											<tbody>
												<tr className="trTabla">
													<td className="col-4">
														<span className="tituloEncabezado">Nombre:</span>
													</td>
													<td className="col-8">
														<span className="tituloProyecto">{this.state.submodulo.nombre_submodulo}</span>
													</td>
												</tr>
												<tr className="trTabla">
													<td className="col-4">
														<span className="tituloEncabezado">Fecha de creación:</span>
													</td>
													<td className="col-8">
														<span className="tituloProyecto">{this.state.submodulo.fecha_creacion}</span>
													</td>
												</tr>
												<tr className="trTabla">
													<td className="col-4">
														<span className="tituloEncabezado">Apis completadas:</span>
													</td>
													<td className="col-8">
														<span className="tituloProyecto">{this.state.submodulo.apis_completadas}</span>
													</td>
												</tr>
												<tr className="trTabla">
													<td className="col-4">
														<span className="tituloEncabezado">Apis con errores:</span>
													</td>
													<td className="col-8">
														<span className="tituloProyecto">{this.state.submodulo.apis_reportadas}</span>
													</td>
												</tr>
												<tr className="trTabla">
													<td className="col-4">
														<span className="tituloEncabezado">Apis en desarrollo:</span>
													</td>
													<td className="col-8">
														<span className="tituloProyecto">{this.state.submodulo.apis_en_desarrollo}</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									{/*////////// Sección de headers del submódulo //////////*/}
									<div className="divHeaders col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
										<Headers
											headers_proyecto={this.state.submodulo.headers_proyecto}
											headers_modulo={this.state.submodulo.headers_modulo}
											headers_submodulo={this.state.submodulo.headers_submodulo}
											headers_api={null}
										/>
									</div>
									{this.state.submodulo.descripcion_submodulo !== null && this.state.submodulo.descripcion_submodulo.trim() !== '<p><br></p>' && this.state.submodulo.descripcion_submodulo.trim() !== ''  ? 
										<div className="div">
											<span className="tituloEncabezado">Descripción del submódulo:</span><br />
											<div className="divDescripcion" dangerouslySetInnerHTML={{ __html: this.state.submodulo.descripcion_submodulo }} />		
										</div>
									:
										<div className="div">
											<span className="tituloEncabezado">Descripción del submódulo:</span><br />
											<em style={{fontSize:'15px'}}>Agrega una breve descripción para entender el funcionamiento del submódulo.</em>	
										</div>
									}
								</div>
							</div>
						</CardBody>
					</Card>
					{/*////////// Sección de apis del submódulo //////////*/}
					<div className="row mt-3">
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<Card className="cardSeccion">
								<CardHeader className="cardSeccionHeader">
									<div className="contenidoTituloSeccion">
										<FaCloud size={25} color="black" />
										<span className="tituloSeccion">Apis</span>
									</div>
								</CardHeader>
								<CardBody>
									<div className="row">
										{/*////////// Sección de buscador de apis //////////*/}
										<div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 divContenedorBuscador">
											<InputGroup>
												<Input 
													type="text" 
													className="inputBuscador"
													placeholder="Buscar por nombre"
													onChange={this.buscarApi} 
													value={this.state.txtbuscadorapis} 
												/>
												<InputGroupText className="logoBuscador">
													<HiMagnifyingGlass />
												</InputGroupText>
											</InputGroup>
										</div>
										{/*////////// Sección de botón para documentar una api , el cual se oculta dependiendo del rol //////////*/}
										{this.state.id_rol == 1 || this.state.id_rol == 2 || this.state.id_rol == 4 ? 
											<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 divContenedorBotones">
												<Button
													className=" botonCrearModSubApi"
													color="success"
													onClick={() => this.redireccionandoDocumentarApi(this.state.submodulo.submodulo_general)}>
													<IoMdAdd size={20} color="white"/>&nbsp;Api
												</Button>
											</div>
										:null}
									</div>
									{/*////////// Sección de lista de apis del submódulo //////////*/}
									{this.state.visualizarapis === false ? 
											<div className="row mt-4">
												<div className="d-flex justify-content-center">
													<em style={{fontSize:'14px'}}>El submódulo aún no cuenta con apis.</em>
												</div>
											</div>
										:
											<>
												<div className="row">
													{this.state.txtbuscadorapis.length < 3 ?
														this.state.apispaginadas.map((api, index) => (
															<Api 
																key={index}
																clase="col-12 mt-3"
																api={api} 
																verapi={() => this.redireccionandoDocumentacionApi(api.id_api)} 
															/>
														))
														:
														this.state.apisfiltradaspaginadas.map((api, index) => (
															<Api 
																key={index}
																clase="col-12 mt-3"
																api={api} 
																verapi={() => this.redireccionandoDocumentacionApi(api.id_api)} 
															/>
														))}
												</div>
												{/*////////// Sección de paginación de apis//////////*/}
												<div className="row mt-3">
													{this.state.txtbuscadorapis.length < 3 ?
														<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaactiva}
														itemsPorPagina={this.state.itemsporpagina} totalPaginas={this.state.totalpaginas} tamañoPaginacion="sm"/>
													: 
														<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaactiva}
														itemsPorPagina={this.state.itemsporpagina} totalPaginas={this.state.totalpaginasfiltradas} tamañoPaginacion="sm"/>
													}
												</div>
											</>
										}
								</CardBody>
							</Card>
						</div>
					</div>
				</main>
			</div>
		);
	};
}
//Función para redireccionar a diferentes páginas mediante condiciones del valor recibida
function Acciones(bandera:string) {
	if(bandera=="accesorestringido"){
		return <Navigate to="/catalogoproyectos" />;
	} else if (bandera == 'submoduloeliminadoexitosamente') {
		return <Navigate to="/modulo" />;
    } else if(bandera=='documentarapi'){
		return <Navigate to="/documentarapi" />;
	} else if(bandera=='documentacionapi'){
		return <Navigate to="/documentacionapi" />;
	}
}


