import React, { ChangeEvent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import "./EstilosFormularios.css";

interface props {
    label:string,
    isOpcional:boolean
    isModal:boolean
    checkbox?:boolean
    limitecaracteres:number,
    txtvalor:string,
    vermsgerror:boolean,
    msgerror:string,
    escribir: (prop: string) => void;
    habilitar?: (prop:boolean) => void
}

interface states {
    txtvalor:string,
    txtvalorlength:number,
    checkbox?:boolean
}

export default class InputHeaders extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            txtvalor:this.props.txtvalor,
            txtvalorlength:this.props.txtvalor ? this.props.txtvalor.length : 0,
            checkbox:this.props.checkbox !== undefined ? this.props.checkbox : true
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.txtvalor !== this.props.txtvalor && this.props.txtvalor) {
            this.setState({ txtvalor: this.props.txtvalor, txtvalorlength:this.props.txtvalor.length });
        }
        if (prevProps.checkbox !== this.props.checkbox) {
            this.setState({ checkbox: this.props.checkbox});
        }
    }

    palceholderModeloJson = `Ejemplo de encabezado {
        Authorization: Bearer tu_token,
        Content-Type: application/json,
        Otro-Encabezado: valor
}`;

    //Función para insertar tabulación
    teclaTab = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Tab") {
          const textarea = event.target as HTMLTextAreaElement;
  
          //Se obtiene la posición del cursor dentro del textarea
          const cursor = textarea.selectionStart;
        
          //Inserta la tabulación en la posición del cursor
          textarea.value = textarea.value.slice(0, cursor) + "\t" + textarea.value.slice(cursor);
        
          //Mueve el cursor a su posición
          textarea.setSelectionRange(cursor + 1, cursor + 1);
        
          //Evita que al presionar tab se cambie de elemento como al botón u otro input.
          event.preventDefault();
        }
    };

    escribir = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({txtvalor:event.target.value, txtvalorlength:event.target.value.length})
        this.props.escribir(event.target.value)
    };

    checked = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			checkbox: event.target.checked
		});
        this.props.habilitar?.(event.target.checked)
	};
   
    render() {
        return (
            <FormGroup className='formContenedor'>
                {this.props.isOpcional ? 
                    <FormGroup check inline>
                        <Input
                            type="checkbox" 
                            checked={this.state.checkbox}
                            onChange={this.checked}/>
                        <Label className='labelInputModal' check>
                            {this.props.label} <span className="spanOpcional">(opcional)</span>
                        </Label>
                    </FormGroup>
                :
                    <Label className='labelInputModal'>
                        {this.props.label} <span className="spanObligatorio">*</span>
                    </Label>
                }
                <Input
                    className={this.props.isModal ? 'textAreaHeadersModal mt-1':'textAreaHeaders' + (this.props.isOpcional ? ' mt-1' : '')}
                    type="textarea"
                    placeholder={this.palceholderModeloJson}
                    maxLength={this.props.limitecaracteres}
                    onChange={this.escribir} 
                    value={this.state.txtvalor}
                    onKeyDown={this.teclaTab}
                    disabled={!this.state.checkbox}
                />
                    {this.props.vermsgerror
                        ? 
                            <div className="row">
                                <div className="col-10 contenedorMensajeError">
                                    <span className="mensajeError">{this.props.msgerror}</span>
                                </div>
                                <div className="col-2 contenedorContadorCaracteres">
                                    <span className='contadorCaracteres'>{this.state.txtvalorlength} / {this.props.limitecaracteres} </span>
                                </div>
                            </div>
                        : 
                            <div className='contenedorContadorCaracteres'>
                                <span className='contadorCaracteres'>{this.state.txtvalorlength} / {this.props.limitecaracteres} </span>
                            </div>
                        }
            </FormGroup>
        );
    }
}