//Interfaz de la estructura de datos de los ejemplos de entradas.
export interface ejemploEntrada {
	id_ejemplos_entradas: number;
	nombre_ejemplo_entrada: string;
	ejemplo_entrada:string;
}

//Interfaz de la estructura de datos de los ejemplos de salidas.
export interface ejemploSalida {
	id_ejemplos_salidas: number;
	nombre_ejemplo_salida: string;
	ejemplo_salida:string;
}

//Interfaz de la estructura de datos de los ejemplos de entradas.
export interface ejemploExcepcion {
	id_ejemplo_excepcion: number;
	nombre_ejemplo_excepcion: string;
	ejemplo_excepcion:string;
}

export interface api {
    id_api: number,
    nombre_api: string,
    url: string,
    descripcion_tecnica:string,
    nombre_usuario:string,
    apellido_paterno:string,
    apellido_materno:string,
    headers_api:string,
    fecha_creacion: string,
    estado: string,
    tipo_solicitud: string,
    formato_cuerpo: string,
    modelo_entradas:string,
    modelo_salidas: string,
    excepciones:number,
    modelo_excepciones:string,
    headers_proyecto:string,
    id_proyecto:number,
    nombre_proyecto:string,
    id_modulo:number,
    nombre_modulo: string,
    headers_modulo:string,
    id_submodulo:number,
    nombre_submodulo: string,
    headers_submodulo:string,
    submodulo_general:number
}

export const apiestado:api = {
    id_api: 0,
    nombre_api: '',
    url: '',
    descripcion_tecnica:'',
    nombre_usuario:'',
    apellido_paterno:'',
    apellido_materno:'',
    headers_api:'',
    fecha_creacion: '',
    estado: '',
    tipo_solicitud: '',
    formato_cuerpo: '',
    modelo_entradas:'',
    modelo_salidas: '',
    excepciones:0,
    modelo_excepciones: '',
    nombre_proyecto:'',
    headers_proyecto:'',
    id_proyecto:0,
    id_modulo:0,
    id_submodulo:0,
    nombre_modulo: '',
    headers_modulo:'',
    nombre_submodulo: '',
    headers_submodulo:'',
    submodulo_general:0
}

export interface usuario{
    id_usuario:number,
    nombre_completo:string
}