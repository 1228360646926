import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

interface props {
    selectcategoria:string
    vermsgerror:boolean,
    msgerror:string,
    categoria: (cateogoria:string) => void;
}

interface states {
    selectcategoria:string
}

export default class InputCategorias extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            selectcategoria:this.props.selectcategoria
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.selectcategoria !== this.props.selectcategoria) {
          this.setState({ selectcategoria: this.props.selectcategoria });
        }
    }

    //Función para guardar los archivos cargados en su estado correspondiente.
    categoriaSeleccionada = (event: React.ChangeEvent<HTMLInputElement>) => {
        const categoria = String(event.target.value);
        this.setState({ selectcategoria:categoria });
        this.props.categoria(categoria)
    };
  

    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Categoría <span className="spanObligatorio">*</span>
                </Label>
                <Input type="select" value={this.state.selectcategoria} onChange={this.categoriaSeleccionada}>
                    <option disabled value="" selected>Seleccione una opción</option>
                    <option value="api" selected>api</option>
                    <option value="modulo/api" selected>modulo/api</option>
                    <option value="submodulo/api" selected>submodulo/api</option>
                </Input>
                {this.props.vermsgerror ? 
                    <div className="row">
                        <div className="col-10 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    </div>
                :null}
            </FormGroup>
        );
    }
}