import React, {  } from "react";
import { FaArrowRight, FaCheckCircle, FaClock, FaExclamationCircle } from "react-icons/fa";
import { Button, Card, CardBody, Label } from "reactstrap";
import { Tooltip } from 'react-tooltip'
import { proyecto } from "../Interfaces";

interface props{
    clase:string
    proyecto:proyecto
    url:string
    tipo: boolean
    verproyecto:(idproyecto:number,accion:number) => void,
}

const Proyecto: React.FC<props> = ({ clase, proyecto, url, tipo, verproyecto }) => {
    return(
        <div className={clase}>
            <Card className={tipo ? 'cardProyecto animacionCardProyecto' : 'cardProyecto'}  >
                <CardBody>
                    <div className="row">
                        <div className="col-7 col-sm-8">
                            <div className="divDescripcionProyecto">
                                <Label className="nombreProyecto">
                                    {proyecto.nombre_proyecto.length > 11
                                        ? `${proyecto.nombre_proyecto.substring(0, 11)}...`
                                        : proyecto.nombre_proyecto}
                                </Label>
                                <span className="fechaCreacion">{proyecto.fecha_creacion}</span>
                            </div>
                            <div className="divContenedorApis">
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
                                    <span className="apisInformacion">
                                        <FaCheckCircle color="green" size={20} className="apisCompletadas" data-tooltip-content='Apis completadas' />
                                        <span>{proyecto.apis_completadas} </span>
                                    </span>
                                    <Tooltip anchorSelect=".apisCompletadas" className="toolTipDiseño" place="top" />
                                </div>
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
                                    <span className="apisInformacion">
                                        <FaClock color="orange" size={20} className="apisDesarrollo" data-tooltip-content='Apis en desarrollo' />
                                        <span>{proyecto.apis_en_desarrollo} </span>
                                    </span>
                                    <Tooltip anchorSelect=".apisDesarrollo" className="toolTipDiseño" place="top" />
                                </div>
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4">
                                    <span className="apisInformacion">
                                        <FaExclamationCircle color="red" size={20} className="apisConErrores" data-tooltip-content='Apis con reportes' />
                                        <span> {proyecto.apis_reportadas} </span>
                                    </span>
                                    <Tooltip anchorSelect=".apisConErrores" className="toolTipDiseño" place="top" />
                                </div>
                            </div>
                        </div>
                        <div className="col-5 col-sm-4 text-center">
                            <img src={url + proyecto.logo_img} className="logoProyecto" alt="Logo del proyecto"/>
                        </div>
                    </div>
                </CardBody>
                <div>
                    <Button color="primary" className="botonVerProyecto"
                        onClick={() => verproyecto(proyecto.id_proyecto, proyecto.id_rol)}>
                        Ver proyeto&nbsp;<FaArrowRight size={16} />
                    </Button>
                </div>
            </Card>
        </div>
    )
}
export default Proyecto;
