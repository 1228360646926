import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./Paginacion.css"

interface props {
    cambiarPagina: (numeroPagina: number) => void,
    paginaActiva: number,
    itemsPorPagina: number,
    totalPaginas: number,
    tamañoPaginacion:string
}

interface states{
    paginas:JSX.Element[]
}

export default class Paginacion extends React.Component<props,states> {
    constructor(props: props) {
        super(props);
        this.state = {
          paginas: []
        };
    }

    componentDidUpdate(prevProps: props) {
      if (prevProps.totalPaginas !== this.props.totalPaginas) {
        this.renderizarPaginas(this.props.totalPaginas, this.props.paginaActiva);
      }
    }

    renderizarPaginas =(totalPaginas:number, paginaactiva:number)=> {
        const paginasRenderizadas = [];
        if (totalPaginas >= 5) {
            for (let i = 1; i <= 5; i++) {
              paginasRenderizadas.push(
                <PaginationItem key={i}>
                  <PaginationLink
                    className={i === paginaactiva ? "paginaActiva" : ""}
                    onClick={() => this.paginaElegida(i)}
                  >
                    {i}
                  </PaginationLink>
                </PaginationItem>
              );
            }
            // Puntos suspensivos si es necesario
            if (totalPaginas > 5 && paginaactiva < totalPaginas - 2) {
              paginasRenderizadas.push(
                <PaginationItem key="puntos">
                  <PaginationLink disabled>...</PaginationLink>
                </PaginationItem>
              );
            }

            if (paginaactiva >= 6 && paginaactiva < this.props.totalPaginas) {
              paginasRenderizadas.push(
                <PaginationItem key="nkdflsndf">
                  <PaginationLink
                    className="paginaActiva"
                    onClick={() => this.paginaElegida(paginaactiva)}
                  >
                    {paginaactiva}
                  </PaginationLink>
                </PaginationItem>
              );
            }

            // Agregar la última página
            if(totalPaginas > 5){
              paginasRenderizadas.push(
                <PaginationItem key={totalPaginas}>
                  <PaginationLink
                    className={paginaactiva === totalPaginas ? "paginaActiva" : ""}
                    onClick={() => this.paginaElegida(totalPaginas)}
                  >
                    {totalPaginas}
                  </PaginationLink>
                </PaginationItem>
              );
            }
            this.setState({ paginas: paginasRenderizadas });
          }else{
            for (let i = 1; i <= totalPaginas; i++) {
              paginasRenderizadas.push(
                <PaginationItem key={i}>
                    <PaginationLink
                    className={i === paginaactiva ? "paginaActiva" : ""}
                    onClick={() => this.paginaElegida(i)}
                    >
                  {i}
                    </PaginationLink>
                </PaginationItem>
              );
            }
            this.setState({ paginas: paginasRenderizadas });
        }
    }
    paginaAnterior = () => {
        let paginaAnterior = this.props.paginaActiva - 1;
        if (paginaAnterior >= 1) {
            this.props.cambiarPagina(paginaAnterior);
            this.renderizarPaginas(this.props.totalPaginas, paginaAnterior)
        }
    }

    paginaSiguiente = () => {
        let paginaSiguiente = this.props.paginaActiva + 1;
        if(paginaSiguiente <= this.props.totalPaginas){
            this.props.cambiarPagina(paginaSiguiente);
            this.renderizarPaginas(this.props.totalPaginas, paginaSiguiente)
        }
    }

    paginaElegida = (pagina:number)=> {
        if(pagina <= this.props.totalPaginas){
            this.props.cambiarPagina(pagina);
            this.renderizarPaginas(this.props.totalPaginas, pagina)
        }
    }  

   
    render() {
        return (
            <div className="divContenedorPaginacion">
                <Pagination size={this.props.tamañoPaginacion}>
                    {this.props.paginaActiva != 1 ? 
                        <PaginationItem>
                            <PaginationLink
                            onClick={this.paginaAnterior}
                            previous
                            />
                        </PaginationItem>
                    :null}
                    {this.state.paginas}
                    {this.props.paginaActiva != this.props.totalPaginas ? 
                        <PaginationItem>
                            <PaginationLink
                            onClick={this.paginaSiguiente}
                            next
                            />
                        </PaginationItem>
                    :null}
                </Pagination>
            </div>
        );
    }
}


export function paginarElementos(paginaActiva:number, itemsPorPagina:number, array:any) {
    // Definición de variables
    let elementos = array; // Lista de elementos que se paginarán
    let content = []; // Array para almacenar los elementos de la página actual
    let itemfinal = ((itemsPorPagina * paginaActiva) - 1); // Índice del último elemento en la página

    // Si el índice final supera el tamaño de la lista, ajustar al último índice válido
    if (itemfinal > elementos.length)
        itemfinal = elementos.length - 1;
    
    let iteminicial = (itemsPorPagina * (paginaActiva - 1)); // Índice del primer elemento en la página

    // Iterar a través de la lista para obtener los elementos de la página actual
    for (let i = iteminicial; i <= itemfinal; i++) {
        if (i < elementos.length) { // Asegurarse de no exceder el tamaño de la lista
            content.push(elementos[i]); // Agregar el elemento a 'content'
        }
    } 
    return content; // Devolver los elementos de la página actual
}