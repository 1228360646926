import React from "react";
import { Badge } from "reactstrap";
import { IoMdClose } from "react-icons/io";
import { usuario } from "../Interfaces";

interface props {
    index: number;
    usuario: usuario;
    accion: number;
    verModalFormularioUsuarioColaboraProyecto?: (idsuario: number, accion: number) => void;
}

const Usuario: React.FC<props> = ({ index, usuario, accion, verModalFormularioUsuarioColaboraProyecto }) => {
    const eliminarUsuario = () => {
        if (verModalFormularioUsuarioColaboraProyecto) {
            verModalFormularioUsuarioColaboraProyecto(usuario.id_usuario, accion);
        }
    };

    return (
        <Badge key={index} color="primary" className="usuarioContenedor">
            <span className="nombre">
                {usuario.nombre_usuario + ' ' + usuario.apellido_paterno + ' ' + usuario.apellido_materno}
            </span>
            {verModalFormularioUsuarioColaboraProyecto ?
				<IoMdClose
                    className="eliminarUsuario"
                    size={17} color="black"
                    title="Eliminar usuario"
                    onClick={eliminarUsuario}
                />
            :null}
        </Badge>
    )
}
export default Usuario;