//respuestas rápidas 
const campovacio = "Este campo es obligatorio"

/////////////////////  validaciones del login  /////////////////////
export function validarUsuario(data: string): { codigo: string, mensaje: string } {
   let validator: RegExp = /^[A-Za-z0-9]+$/;
   let x: boolean = validator.test(data);
   let aviso: string = "Escribe solo letras y números";

   if (data == "") {
      aviso = campovacio;
   }
   return { codigo: (x) ? ("valid") : "invalid", mensaje: aviso };
   
}

export function validarClave(data:string) {
   let validator = /^[A-Za-z0-9]+$/
   let x = validator.test(data);
   var aviso = "Escribe solo letras y números";

   if (data == "") {
      aviso = campovacio
   }
   return { codigo: (x) ? ("valid") : "invalid", mensaje: aviso };
}

/////////////////////   validaciones de inputs de formularios   /////////////////////
export function validarLetrasNumerosEspacios(data: string, longitud: { longitudminima: number, longitudmaxima: number }) {
   let { longitudminima, longitudmaxima } = longitud;
   let validator = new RegExp(`^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ,.;_-]{${longitudminima},${longitudmaxima}}$`);

   var aviso: string = "Solo se permiten letras, números y espacios";

   let x: boolean = validator.test(data);
   let longitudminimavalida: boolean = data.length < longitudminima;
   let longitudmaximavalida: boolean = data.length > longitudmaxima;


   if (data == "") {
      aviso = campovacio;
   } else if (longitudminimavalida) {
      aviso = `Este campo no puede tener menos de ${longitudminima} caracteres`;
   } else if(longitudmaximavalida){
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (x) ? ("valid") : "invalid",
      mensaje: aviso
   };
}


export function validarLongitud(data: string, longitud: { longitudminima: number, longitudmaxima: number }) {
   let { longitudminima, longitudmaxima } = longitud;
   var aviso: string = "";
   
   let longitudminimavalida: boolean = data.length < longitudminima;
   let longitudmaximavalida: boolean = data.length > longitudmaxima;

   if (data == "") {
      aviso = campovacio;
   } else if (longitudminimavalida) {
      aviso = `Este campo no puede tener menos de ${longitudminima} caracteres`;
   } else if(longitudmaximavalida){
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (aviso) ? "invalid" : "valid",
      mensaje: aviso
   };
}

export function validarLongitudMáxima(data: string, longitudmaxima: number) {
   var aviso: string = "";
   let longitudmaximavalida: boolean = data.length > longitudmaxima;

  if(longitudmaximavalida){
      aviso = `Este campo no puede superar más de ${longitudmaxima} caracteres`;
   }

   return {
      codigo: (aviso) ? "invalid" : "valid",
      mensaje: aviso
   };
}

export function validarInputSelect(data: string) {
   var aviso: string = "";
   var selectvalido: boolean = false;

   if (data == "" || data=="0" ) {
      selectvalido=true;
      aviso = 'Selecciona una opción';
   } 
   return {
      codigo: (selectvalido) ? "invalid" : "valid",
      mensaje: aviso
   };
}

export function validarInputSelectExepciones(data: string) {
   var aviso: string = "";
   var selectvalido: boolean = false;

   if (data == "") {
      selectvalido=true;
      aviso = 'Selecciona una opción';
   } 
   return {
      codigo: (selectvalido) ? "invalid" : "valid",
      mensaje: aviso
   };
}

export function validarInputNumerico(data: number, cantidad: { cantidadminima: number, cantidadmaxima: number }  ) {
   let { cantidadminima, cantidadmaxima } = cantidad;
   var aviso: string = "";
   
   let cantidadminimavalida: boolean = data < cantidadminima;
   let cantidadmaximavalida: boolean = data > cantidadmaxima;

   if (data == null) {
      aviso = campovacio;
   } else if (cantidadminimavalida) {
      aviso = `La cantidad mínima es ${cantidadminima}`;
   } else if(cantidadmaximavalida){
      aviso = `La cantidad máxima es ${cantidadmaxima}`;
   }

   return {
      codigo: (aviso) ? "invalid" : "valid",
      mensaje: aviso
   };
}

export function validarFechaInput(fecha: string) {
   var aviso: string = "";
   const fechaValida: boolean = true;
 
   if (fecha) {
     const fechaIngresada = new Date(fecha);
     const fechaHoy = new Date();
 
     if (fechaIngresada < fechaHoy) {
       aviso = "La fecha y hora no pueden ser anterior a la actual";
     }
   } else {
     aviso = "La fecha es obligatoria";
   }
 
   return {
      codigo: (aviso) ? "invalid" : "valid",
     mensaje: aviso,
   };
}

export function validarArchivoImagen(archivo: File) {
   var aviso: string = "";
 
   if (archivo) {
     const extensionesValidas = ["jpg", "jpeg", "png"];
 
     const nombreArchivo = archivo.name;
     const extension = nombreArchivo.split(".").pop();
 
     if (extension && extensionesValidas.indexOf(extension.toLowerCase()) === -1) {
      aviso = "El archivo no es una imagen válida. Las extensiones permitidas son: " + extensionesValidas.join(", ") + ".";
    }
   } 
 
   return {
     codigo: aviso ? "invalid" : "valid",
     mensaje: aviso,
   };
}

export function validarArchivosImagenes(archivo: File) {
   var aviso: string = "";
 
   if (archivo) {
     const extensionesValidas = ["jpg", "jpeg", "png"];
 
     const nombreArchivo = archivo.name;
     const extension = nombreArchivo.split(".").pop();
 
     if (extension && extensionesValidas.indexOf(extension.toLowerCase()) === -1) {
      aviso = "Uno de los archivos no es una imagen válida. Las extensiones permitidas son: " + extensionesValidas.join(", ") + ".";
    }
   } 
 
   return {
     codigo: aviso ? "invalid" : "valid",
     mensaje: aviso,
   };
}
 
 