import React from "react";
import { Button, Label } from 'reactstrap';
import { getSubmoduloDetalles, postGuardarApi } from "../../Services/Api";
import { validarInputSelect, validarInputSelectExepciones, validarLetrasNumerosEspacios, validarLongitud, validarLongitudMáxima } from "../../Services/Validaciones";
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from "react-router-dom";
import './DocumentarApi.css';
import MenuNavbar from "../../Complements/Navbar/Navbar";
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import InputComentario from "../../Complements/Formularios/InputComentario";
import { ejemploentrada, ejemploentradaestado, ejemploexcepciones, ejemploexcepcionesestado, ejemplosalida, ejemplosalidaestado, submodulo, submoduloestado } from "./Interfaces";
import { FormularioEjemplosSalidas } from "./Componentes.tsx/FormularioEjemplosSalidas";
import { FormularioEjemplosEntradas } from "./Componentes.tsx/FormularioEjemplosEntradas";
import { FormularioEjemplosExcepciones } from "./Componentes.tsx/FormularioEjemplosExcepciones";
import { InputModelo } from "./Componentes.tsx/InputModelo";
import InputText from "../../Complements/Formularios/InputText";
import InputQuill from "../../Complements/Formularios/InputQuill";
import InputHeaders from "../../Complements/Formularios/InputHeaders";
import SelectTipoSolicitud from "./Componentes.tsx/SelectTipoSolicitud";
import SelectEstado from "./Componentes.tsx/SelectEstado";
import SelectFormatoCuerpo from "./Componentes.tsx/SelectFormatoCuerpo";
import SelectExcepciones from "./Componentes.tsx/SelectExcepciones";
import Navegacion from "../../Complements/Navegacion/Navegacion";
import SelectHeaders from "./Componentes.tsx/SelectHeaders";

//Interfaz general del componente.
interface states{
	id_rol:number,
	submodulo:submodulo

	txtnombreapi: string,
    vermsgerrornombre:boolean,
    msgerrornombre:string,

	txturl:string,
    vermsgerrorurl:boolean,
    msgerrorurl:string,

	txtdescripcionapi:string,
    vermsgerrordescripcionapi:boolean,
    msgerrordescripcionapi:string,

    tiposolicitud:string,
	vermsgerrortiposolicitud:boolean,
    msgerrortiposolicitud:string,

    formatocuerpo:number,
	vermsgerrorformatocuerpo:boolean,
    msgerrorformatocuerpo:string,

	headerscheckbox:boolean,
	txtheadersapi:string,
	vermsgerrorheaders:boolean,
    msgerrorheaders:string,

	placeholdermodeloentradas:string,
	txtmodeloentradas:string,
	vermsgerrormodeloentradas:boolean,
    msgerrormodeloentradas:string,

	placeholderejemplosentradas:string,
	ejemplosentradas: ejemploentrada[];

	txtmodelosalidas:string,
	vermsgerrormodelosalida:boolean,
    msgerrormodelosalidas:string,

	ejemplossalidas: ejemplosalida[];

	estado:string,
	vermsgerrorestado:boolean,
    msgerrorestado:string,

	selectexcepciones:string,
    vermsgerrorexcepciones:boolean,
    msgerrorexcepciones:string,

	txtmodeloexcepciones:string,
	vermsgerrormodeloexcepciones:boolean,
    msgerrormodeloexcepciones:string,

	ejemplosexcepciones: ejemploexcepciones[];

	confirmar:boolean,
    txtcomentario:string,
    vermsgerrorcomentario:boolean,
    msgerrorcomentario:string,

	perfil:{
		token:number,
        name:string,
		email:string
	},
	redireccionando:number,
	cargando:boolean,
	idsolicitud_tiene_api:number

}
export default class DocumentarApi extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
			id_rol:0,
			submodulo:submoduloestado,
			txtnombreapi: '',
			vermsgerrornombre:false,
			msgerrornombre:'',

			txturl:'',
			vermsgerrorurl:false,
			msgerrorurl:'',

			txtdescripcionapi:'',
			vermsgerrordescripcionapi:false,
			msgerrordescripcionapi:'',

			txtheadersapi:'',
			vermsgerrorheaders:false,
			msgerrorheaders:'',

			tiposolicitud: '',
			vermsgerrortiposolicitud:false,
    		msgerrortiposolicitud:'',

    		formatocuerpo:0,
			vermsgerrorformatocuerpo:false,
    		msgerrorformatocuerpo:'',
			headerscheckbox:false,

			placeholdermodeloentradas:'',
			txtmodeloentradas:'',
			vermsgerrormodeloentradas:false,
			msgerrormodeloentradas:'',

			estado:'',
			vermsgerrorestado:false,
			msgerrorestado:'',

			placeholderejemplosentradas:'',
			ejemplosentradas: [ejemploentradaestado],

			txtmodelosalidas:'',
			vermsgerrormodelosalida:false,
			msgerrormodelosalidas:'',

			ejemplossalidas: [ejemplosalidaestado],

			selectexcepciones:'',
            vermsgerrorexcepciones:false,
            msgerrorexcepciones:'',

			txtmodeloexcepciones:'',
			vermsgerrormodeloexcepciones:false,
			msgerrormodeloexcepciones:'',

			ejemplosexcepciones: [ejemploexcepcionesestado],

			confirmar:false,
            txtcomentario:'',
            vermsgerrorcomentario:false,
            msgerrorcomentario:'',

            perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": ""}'),
			redireccionando:0,
			cargando:true,
			idsolicitud_tiene_api:0
	  	};
	}

	async componentDidMount() {
        if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
            const datos = localStorage.getItem('acceso');
			//this.setState({ redireccionando: true });
        }

		const idsolicitud_tiene_api = await localStorage.getItem('idsolicitud_tiene_api');
		this.setState({idsolicitud_tiene_api: Number(idsolicitud_tiene_api)})

		const id_submodulo = await localStorage.getItem('id_submodulo');
		const id_modulo = await localStorage.getItem('id_modulo');
		const id_proyecto = await localStorage.getItem('id_proyecto');
		const id_rol = Number(localStorage.getItem("id_rol"))

		const apisubmodulo = await getSubmoduloDetalles(this.state.perfil.token,Number(id_submodulo),Number(id_proyecto),Number(id_modulo));
		if(this.respuestaApi (apisubmodulo)){
			return;
		}

		this.setState({submodulo: apisubmodulo.data});
		this.restriccionRol(apisubmodulo, id_rol);

		setTimeout(() => {
			this.setState({cargando: false});
			localStorage.removeItem("idsolicitud_tiene_api");
		}, 1000);
    }

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return 
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return 
		}
		return false
	}

	//Función para restringir el acceso al componente de acuerdo a su rol.
	restriccionRol = (submodulo:any, id_rol:number) => {
		console.log(submodulo.data)
		if(id_rol === 3){
			if(submodulo.data.submodulo_general ==1){
				localStorage.setItem("accion", "accesorestringido");
				this.setState({redireccionando:2})
			} 
			if(submodulo.data.submodulo_general ==0){
				localStorage.setItem("accion", "accesorestringido");
				this.setState({redireccionando:3})
			}
		}
	}

	//Función de regresar
	regresar = () => {
		window.history.back();
	};

	nombre = (prop: string) => {
		this.setState({ txtnombreapi: prop});
	};

	url = (prop: string) => {
		this.setState({ txturl: prop});
	};

	tipoSolicitudSeleccionada = (prop:string) => {
		this.setState({tiposolicitud:prop})
	}

	descripcion = (prop: string) => {
		this.setState({ txtdescripcionapi: prop});
	};

	habilitarHeaders = (prop:boolean) => {
		this.setState({ headerscheckbox: prop});
	}

	headers = (prop: string) => {
		this.setState({ txtheadersapi: prop});
	};

	estadoSeleccionado = (prop: string) => {
		this.setState({ estado: prop});
	};

	formatoCuerpoSeleccionado = (prop: number) => {
		if(prop === 1){
			this.setState({placeholdermodeloentradas:this.palceholderModeloJson, placeholderejemplosentradas:this.placeholderEjemploJson})
		}
		else if(prop === 2){
			this.setState({placeholdermodeloentradas:this.palceholderModeloFormData, placeholderejemplosentradas:'Form-data2'})
		}
		else if(prop === 3){
			this.setState({placeholdermodeloentradas:'Form-Encode', placeholderejemplosentradas:'Form-Encode2'})
		}
		else if(prop === 4){
			this.setState({placeholdermodeloentradas:'Text', placeholderejemplosentradas:'Text2'})
		}
		this.setState({ formatocuerpo: prop});
	};

	/////////////////////////////// Sección de placeholders
	//Placeholder de input de modelo Json.
	palceholderModeloJson = `{
	'name': 'string',
	'age': 'number',
	'interests': 'array',
	'active': 'boolean',
	'address': 'object',
	'scores': 'array of numbers',
	'description': 'string',
	'preferences': 'object'
}`;
	//Placeholder de input de ejemplo Json.
	placeholderEjemploJson = `{
	'name': 'John Doe',
	'age': 30,
	'interests': ['React', 'JavaScript'],
	'active': true,
	'address': {
		'street': '123 Main St',
		'city': 'Exampleville'
	},
	'scores': [95, 87, 92],
	'description': 'This is a sample description.',
	'preferences': {
		'theme': 'light',
		'notifications': true
	}
}`;
//Placeholder de input de modelo Json.
	palceholderModeloFormData = `name: (string)
age: (number)
interests: (array)
active: (boolean)
address: (object)
scores: (array of numbers)
description: (string)
preferences: (object)`;
	/////////////////////////////// Sección de entradas
	modeloentradas = (prop:string) => {
		this.setState({ txtmodeloentradas: prop });
	}

	ejemplosEntradas = (prop:ejemploentrada[]) => {
		this.setState({ ejemplosentradas: prop });
	}

	/////////////////////////////// Sección de salida
	modelosalidas = (prop:string) => {
		this.setState({ txtmodelosalidas: prop });
	}

	ejemplosSalidas = (prop:ejemplosalida[]) => {
		this.setState({ ejemplossalidas: prop });
	}
	
	/////////////////////////////// Sección de excepciones
	excepcionesSeleccionado = (prop:string) => {
        this.setState({ selectexcepciones:prop});
    };

	modeloExcepciones = (prop:string) => {
		this.setState({ txtmodeloexcepciones: prop });
	}

	ejemplosExcepciones = (prop:ejemploexcepciones[]) => {
		this.setState({ ejemplosexcepciones: prop });
	}

	/////////////////////////////// Sección de comentarios
	comentario = (prop:string) => {
		this.setState({txtcomentario:prop})
	}

	habilitarComentario = (prop:boolean) => {
		this.setState({ confirmar:prop });
	}

	/////////////////////////////// Sección de función para guardar la api.
	guardarApi = async () => {
		this.setState({ vermsgerrornombre: false });
		this.setState({ vermsgerrorurl: false });
		this.setState({ vermsgerrortiposolicitud: false });
		this.setState({ vermsgerrorformatocuerpo: false });
		this.setState({ vermsgerrordescripcionapi: false });
		this.setState({ vermsgerrorheaders: false });
		this.setState({ vermsgerrormodelosalida: false });
		this.setState({ vermsgerrorestado: false });
		this.setState({ vermsgerrorexcepciones: false });
		this.setState({ vermsgerrorcomentario: false});

		//Si formato cuerpo es diferente a 5(Sin formato) se toman en cuenta los ejemplos entrada dentro de la validación de campos.
		if(this.state.formatocuerpo !== 5){
			this.setState({ vermsgerrormodeloentradas: false });
			//Actualiza el estado de la visualización de los mensajes de error de cada ejemplo de entrada.
			const vermsgerrornombreejemploentrada = this.state.ejemplosentradas.map((ejemplo) => {
				return {
					...ejemplo,
					vermsgerrornombreejemploentrada:false
					};
				});
			await this.setState({ ejemplosentradas: vermsgerrornombreejemploentrada });

			//Actualiza el estado de la visualización de los mensajes de error de cada ejemplo de entrada.
			const vermsgerrorejemploentrada = this.state.ejemplosentradas.map((ejemplo) => {
				return {
					...ejemplo,
					vermsgerrorejemploentrada:false
					};
				});
			await this.setState({ ejemplosentradas: vermsgerrorejemploentrada });
		}
		
		//Actualiza el estado de la visualización de los mensajes de error de cada nombre de ejemplo.
		const vermsgerrornombreejemplosalida = this.state.ejemplossalidas.map((ejemplo) => ({
			...ejemplo,
			vermsgerrornombreejemplosalida: false
		}));
		await this.setState({ ejemplossalidas: vermsgerrornombreejemplosalida });
		
		//Actualizar estado de la visualización de los mensajes de error de cada ejemplo de salida.
		const vermsgerrorejemplosalida = this.state.ejemplossalidas.map((ejemplo) => ({
			...ejemplo,
			vermsgerrorejemplosalida: false
		  }));
		await this.setState({ ejemplossalidas: vermsgerrorejemplosalida });

		if(this.state.selectexcepciones === "1"){
			this.setState({ vermsgerrormodeloexcepciones: false });

			//Actualiza estado de la visualización de los mensajes de error de cada nombre de ejemplo de excepcion.
			const vermsgerrornombreejemploexcepcion = this.state.ejemplosexcepciones.map((ejemplo) => ({
				...ejemplo,
				vermsgerrornombreejemploexcepcion: false
			}));
			this.setState({ ejemplosexcepciones: vermsgerrornombreejemploexcepcion });
	
			//Actualiza estado de la visualización de los mensajes de error de cada ejemplo de excepcion.
			const vermsgerrorejemploexcepcion = this.state.ejemplosexcepciones.map((ejemplo) => ({
				...ejemplo,
				vermsgerrorejemploexcepcion: false
			}));
			this.setState({ ejemplosexcepciones: vermsgerrorejemploexcepcion });
		}
		  
		let longitudvalidanombre = {longitudminima: 3, longitudmaxima: 70};
		let longitudvalidaurl = {longitudminima: 5, longitudmaxima: 70};
		let longitudvalidadescripcion = 3000;
		let longitudvalidaheaders = {longitudminima: 30, longitudmaxima: 500};
		let longitudvalidamodelo = { longitudminima: 30, longitudmaxima: 3000 };
		let longitudvalidanombreejemplo = {longitudminima: 3, longitudmaxima: 30};
		let longitudvalidaejemplo = { longitudminima: 30, longitudmaxima: 3000 };
		let longitudvalidacomentario = {longitudminima: 8, longitudmaxima: 300};
		
		let txtnombre = validarLetrasNumerosEspacios(this.state.txtnombreapi, longitudvalidanombre);
		let txturl = validarLongitud(this.state.txturl, longitudvalidaurl);
		let txtdescripcion = validarLongitudMáxima(this.state.txtdescripcionapi, longitudvalidadescripcion);
		let selecttiposolicitud = validarInputSelect(String(this.state.tiposolicitud));
		let selectformatocuerpo = validarInputSelect(String(this.state.formatocuerpo));
		let txtheaders = validarLongitud(this.state.txtheadersapi, longitudvalidaheaders);
		let txtmodeloentradasjson = validarLongitud(this.state.txtmodeloentradas, longitudvalidamodelo);
		let txtmodelosalidasjson = validarLongitud(this.state.txtmodelosalidas, longitudvalidaejemplo);
		let txtmodeloexcepciones = validarLongitud(this.state.txtmodeloexcepciones, longitudvalidamodelo);
		let selectestado = validarInputSelect(this.state.estado);
		let selectexcepciones = validarInputSelectExepciones(this.state.selectexcepciones);
		let txtcomentario = validarLetrasNumerosEspacios(this.state.txtcomentario, longitudvalidacomentario);

		//Si formato cuerpo es diferente a 5(Sin formato) se toman en cuenta los ejemplos entrada dentro de la validación de campos.
		if(this.state.formatocuerpo !== 5){
			//Nombre de ejemplos de entrada.
			const validarNombreejemplosentradas = this.state.ejemplosentradas.map((ejemplo) => {
				const resultadoValidacionNombre = validarLongitud(ejemplo.nombre_ejemplo_entrada, longitudvalidanombreejemplo);
				return {
				...ejemplo,
				txtnombreejemploentrada: resultadoValidacionNombre.codigo,
				msgerrornombreejemploentrada: resultadoValidacionNombre.mensaje
				};
			});
			await this.setState({ ejemplosentradas: validarNombreejemplosentradas });

			//Ejemplos de entrada.
			const validarejemplosentradas = this.state.ejemplosentradas.map((ejemplo) => {
				const resultadoValidacion = validarLongitud(ejemplo.ejemplo_entrada, longitudvalidaejemplo);
				return {
				...ejemplo,
				txtejemploentrada: resultadoValidacion.codigo,
				msgerrorejemploentrada: resultadoValidacion.mensaje
				};
			});
			await this.setState({ ejemplosentradas: validarejemplosentradas });
		}

		//Nombre de ejemplos de salida.
		const validarNombreejemplossalidas = this.state.ejemplossalidas.map((ejemplo) => {
			const resultadoValidacionNombre = validarLongitud(ejemplo.nombre_ejemplo_salida, longitudvalidanombreejemplo);
			return {
			  ...ejemplo,
			  txtnombreejemplosalida: resultadoValidacionNombre.codigo,
			  msgerrornombreejemplosalida: resultadoValidacionNombre.mensaje
			};
		});
		await this.setState({ ejemplossalidas: validarNombreejemplossalidas });

		//Ejemplos de salidas
		const validarejemplossalidas = this.state.ejemplossalidas.map((ejemplo) => {
			const resultadoValidacion = validarLongitud(ejemplo.ejemplo_salida, longitudvalidaejemplo);
			return {
			  ...ejemplo,
			  txtejemplosalida: resultadoValidacion.codigo,
			  msgerrorejemplosalida: resultadoValidacion.mensaje
			};
		});
		await this.setState({ ejemplossalidas: validarejemplossalidas });

		if(this.state.selectexcepciones === "1"){
			//Nombres de ejemplos de excepcion.
			const validarNombreejemplosexcepciones = this.state.ejemplosexcepciones.map((ejemplo) => {
				const resultadoValidacionNombre = validarLongitud(ejemplo.nombre_ejemplo_excepcion, longitudvalidanombreejemplo);
				return {
					...ejemplo,
					txtnombreejemploexcepcion: resultadoValidacionNombre.codigo,
					msgerrornombreejemploexcepcion: resultadoValidacionNombre.mensaje
				};
			});
			await this.setState({ ejemplosexcepciones: validarNombreejemplosexcepciones });

			//Ejemplos de excepcion.
			const validarejemplosexcepciones = this.state.ejemplosexcepciones.map((ejemplo) => {
				const resultadoValidacion = validarLongitud(ejemplo.ejemplo_excepcion, longitudvalidaejemplo);
				return {
					...ejemplo,
					txtejemploexcepcion: resultadoValidacion.codigo,
					msgerrorejemploexcepcion: resultadoValidacion.mensaje
				};
			});
			await this.setState({ ejemplosexcepciones: validarejemplosexcepciones });
		}

		let validacionescorrectas = true;

		if (txtnombre.codigo == "invalid") {
			this.setState({ msgerrornombre: txtnombre.mensaje });
			this.setState({ vermsgerrornombre: true });
			validacionescorrectas = false;
		}
		if (txturl.codigo == "invalid") {
			this.setState({ msgerrorurl: txturl.mensaje });
			this.setState({ vermsgerrorurl: true });
			validacionescorrectas = false;
		}
		if (selecttiposolicitud.codigo == "invalid") {
			this.setState({ msgerrortiposolicitud: selecttiposolicitud.mensaje });
			this.setState({ vermsgerrortiposolicitud: true });
			validacionescorrectas = false;
		}
		if (selectformatocuerpo.codigo == "invalid") {
			this.setState({ msgerrorformatocuerpo: selectformatocuerpo.mensaje });
			this.setState({ vermsgerrorformatocuerpo: true });
			validacionescorrectas = false;
		}
		if (txtdescripcion.codigo == "invalid") {
		   this.setState({ msgerrordescripcionapi: txtdescripcion.mensaje });
			this.setState({ vermsgerrordescripcionapi: true });
			validacionescorrectas = false;
		}
	
		if (txtheaders.codigo == "invalid" && this.state.headerscheckbox==true) {
		  	this.setState({ msgerrorheaders: txtheaders.mensaje });
			this.setState({ vermsgerrorheaders: true });
			validacionescorrectas = false;
		}

		if (txtmodelosalidasjson.codigo == "invalid") {
			this.setState({ msgerrormodelosalidas: txtmodelosalidasjson.mensaje });
		  	this.setState({ vermsgerrormodelosalida: true });
		  	validacionescorrectas = false;
	  	}

		if (selectestado.codigo == "invalid") {
			this.setState({ msgerrorestado: selectestado.mensaje });
			this.setState({ vermsgerrorestado: true });
			validacionescorrectas = false;
		}

		if (selectexcepciones.codigo == "invalid") {
			this.setState({ msgerrorexcepciones: selectexcepciones.mensaje });
			this.setState({ vermsgerrorexcepciones: true });
			validacionescorrectas = false;
		}

		if (txtcomentario.codigo == "invalid" && this.state.confirmar === true) {
			this.setState({ msgerrorcomentario: txtcomentario.mensaje });
			this.setState({ vermsgerrorcomentario: true });
			validacionescorrectas = false;
		}

		let entradasCorrectas = true;
		//Si formato cuerpo es diferente a 5(Sin formato) se toman en cuenta los ejemplos entrada dentro de la validación de campos.
		if(this.state.formatocuerpo !== 5){
			if (txtmodeloentradasjson.codigo == "invalid") {
				this.setState({ msgerrormodeloentradas: txtmodeloentradasjson.mensaje });
				this.setState({ vermsgerrormodeloentradas: true });
				validacionescorrectas = false;
			}
			const validarNombreejemplosentradasJsonRespuesta = this.state.ejemplosentradas.map((ejemplo) => {
				if (ejemplo.txtnombreejemploentrada === "invalid") {
					return {
						...ejemplo,
						vermsgerrornombreejemploentrada: true
					};
				}
				return ejemplo;
			});
			await this.setState({ ejemplosentradas: validarNombreejemplosentradasJsonRespuesta });
			//Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemploNombresEntradasCorrectas su valor booleano inverso.
			//En pocas la palabras se si se cumple la condición se guardará como falso.
			const ejemploNombresEntradasCorrectas =  !validarNombreejemplosentradasJsonRespuesta.some((ejemplo) => ejemplo.txtnombreejemploentrada === "invalid");
			
			const validarejemplosentradasJsonRespuesta = this.state.ejemplosentradas.map((ejemplo) => {
				if (ejemplo.txtejemploentrada === "invalid") {
					return {
						...ejemplo,
						vermsgerrorejemploentrada: true
					};
				}
				return ejemplo; // Devuelve el ejemplo sin modificar
			});
			await this.setState({ ejemplosentradas: validarejemplosentradasJsonRespuesta });
			//Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemplosentradasCorrectas su valor booleano inverso.
			//En pocas la palabras se si se cumple la condición se guardará como falso.
			const ejemplosentradasCorrectas = !validarejemplosentradasJsonRespuesta.some((ejemplo) => ejemplo.txtejemploentrada === "invalid");
			
			//Se suman ambos valores. Si es true y false. Se guarda como false. Si ambos son true, se guarda como true y se actualiza el valor en entradasCorrectas.
			//Si el valor de entradasCorrectas es true significa que todos los nombres y sus correspondientes ejemplos son aptos para guardar.
			entradasCorrectas = ejemploNombresEntradasCorrectas && ejemplosentradasCorrectas;
		}
		
		//Nombre de ejemplo de salidas 
		const validarNombreejemplossalidasRespuesta = this.state.ejemplossalidas.map((ejemplo) => {
			if (ejemplo.txtnombreejemplosalida === "invalid") {
				return {
					...ejemplo,
					vermsgerrornombreejemplosalida: true
				};
			}
			return ejemplo;
		});

		await this.setState({ ejemplossalidas: validarNombreejemplossalidasRespuesta });
		//Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemplossalidasNombresCorrectos su valor booleano inverso.
		//En pocas la palabras se si se cumple la condición se guardará como falso.
		const ejemplossalidasNombresCorrectos = !validarNombreejemplossalidasRespuesta.some((ejemplo) => ejemplo.txtnombreejemplosalida === "invalid");
			
		//Ejemplo de salidas
		const validarejemplossalidasRespuesta = this.state.ejemplossalidas.map((ejemplo) => {
			if (ejemplo.txtejemplosalida === "invalid") {
				return {
					...ejemplo,
					vermsgerrorejemplosalida: true
				};
			}
			return ejemplo; // Devuelve el ejemplo sin modificar
		});

		await this.setState({ ejemplossalidas: validarejemplossalidasRespuesta });
		//Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemplossalidasCorrectos su valor booleano inverso.
		//En pocas la palabras se si se cumple la condición se guardará como falso.
		const ejemplossalidasCorrectos = !validarejemplossalidasRespuesta.some((ejemplo) => ejemplo.txtejemplosalida === "invalid");

		//Se suman ambos valores. Si es true y false. Se guarda como false. Si ambos son true, se guarda como true y se actualiza el valor en entradasCorrectas.
		//Si el valor de salidasCorrectas es true significa que todos los nombres y sus correspondientes ejemplos son aptos para guardar.
		const salidasCorrectas = ejemplossalidasNombresCorrectos && ejemplossalidasCorrectos;

		let excepcionesCorrectas = true;
		//Si formato cuerpo es diferente a 5(Sin formato) se toman en cuenta los ejemplos entrada dentro de la validación de campos.
		if(this.state.selectexcepciones === "1"){
			if (txtmodeloexcepciones.codigo == "invalid") {
				this.setState({ msgerrormodeloexcepciones: txtmodeloexcepciones.mensaje });
				this.setState({ vermsgerrormodeloexcepciones: true });
				validacionescorrectas = false;
			}
	
			//Nombres de ejemplos de excepcion.
			const validarNombreejemplosexcepcionesRespuesta = this.state.ejemplosexcepciones.map((ejemplo) => {
				if (ejemplo.txtnombreejemploexcepcion === "invalid") {
					return {
						...ejemplo,
						vermsgerrornombreejemploexcepcion: true
					};
				}
				return ejemplo;
			});
			await this.setState({ ejemplosexcepciones: validarNombreejemplosexcepcionesRespuesta });
			//Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemploNombresExcepcionesCorrectas su valor booleano inverso.
			//En pocas la palabras se si se cumple la condición se guardará como falso.
			const ejemploNombresExcepcionesCorrectas =  !validarNombreejemplosexcepcionesRespuesta.some((ejemplo) => ejemplo.txtnombreejemploexcepcion === "invalid");  
			
			//Ejemplos de excepcion.
			const validarejemplosexcepcionesRespuesta = this.state.ejemplosexcepciones.map((ejemplo) => {
				if (ejemplo.txtejemploexcepcion === "invalid") {
				return {
					...ejemplo,
					vermsgerrorejemploexcepcion: true
				};
	
				}
				return ejemplo; // Devuelve el ejemplo sin modificar
			});
	
			await this.setState({ ejemplosexcepciones: validarejemplosexcepcionesRespuesta });
			//Se utiliza la función some para que si uno de los nombres de los ejemplos resulta invalido se guarde en ejemplosexcepcionesCorrectas su valor booleano inverso.
			//En pocas la palabras se si se cumple la condición se guardará como falso.
			const ejemplosexcepcionesCorrectas = !validarejemplosexcepcionesRespuesta.some((ejemplo) => ejemplo.txtejemploexcepcion === "invalid");
		
			//Se suman ambos valores. Si es true y false. Se guarda como false. Si ambos son true, se guarda como true y se actualiza el valor en salidasCorrectas.
			//Si el valor de excepcionesCorrectas es true significa que todos los nombres y sus correspondientes ejemplos son aptos para guardar.     
			excepcionesCorrectas= ejemploNombresExcepcionesCorrectas && ejemplosexcepcionesCorrectas;
		}
		//Se evalua el valor de validaciones correctas para tomar en cuenta los demás campos.
		//Se suman los valores de entradasCorrectas y salidasCorrectas a valicaciones correctas.
		if(validacionescorrectas){
			validacionescorrectas = entradasCorrectas  && salidasCorrectas && excepcionesCorrectas;
		}
		
		//Si validacionescorrectas es true se crea el json y se manda a la api.
		if (validacionescorrectas) {
		    let json = {
				"token": this.state.perfil.token,
				"id_proyecto": this.state.submodulo.id_proyecto,
				"nombre_api": this.state.txtnombreapi,
				"url":this.state.txturl,
				"descripcion_tecnica":this.state.txtdescripcionapi,
				"estado":this.state.estado,
				//Headers
				"headers_checkbox":this.state.headerscheckbox,
				"headers_api":this.state.txtheadersapi,
				//entradas
				"modelo_entradas":this.state.txtmodeloentradas,
				"ejemplos_entradas":this.state.ejemplosentradas,
				//salidas
				"modelo_salidas":this.state.txtmodelosalidas,
				"ejemplos_salidas":this.state.ejemplossalidas,
				"tipo_solicitud":this.state.tiposolicitud,
				"id_submodulo": this.state.submodulo.id_submodulo,
				"id_formato_cuerpo": this.state.formatocuerpo,
				
				//Excepciones
				"excepciones":this.state.selectexcepciones,
                "modelo_excepciones":this.state.txtmodeloexcepciones,
                "ejemplos_excepciones":this.state.ejemplosexcepciones,

				//Solicitud tiene api
				"idsolicitud_tiene_api": this.state.idsolicitud_tiene_api,
				"comentario_checkbox":this.state.confirmar,
          		"comentario":this.state.txtcomentario
			} 

			const resp = await postGuardarApi(json);
			
			if (resp.code == 0 && this.state.idsolicitud_tiene_api != 0) {
				localStorage.setItem("accion", "Documentacion creada");
				localStorage.removeItem("idsolicitud_tiene_api");
				this.setState({ redireccionando: 4 })
			}
			else if (resp.code == 0) {
				const id_api = resp.data.id_api;
				localStorage.setItem("id_api", id_api.toString());
				localStorage.setItem("accion", "Documentacion creada");
				this.setState({ redireccionando: 1 })
			}
			else if (resp.code == 100) {
				this.setState({ redireccionando: 1 })
			}
			else if (resp.code == 200) {
				toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			else if (resp.code == 250) {
				this.setState({ msgerrornombre: resp.message });
				this.setState({ vermsgerrornombre: true });
			}
			else if (resp.code == 300) {
				toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			else if (resp.code == 1000 || resp == -1) {
				toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
		}
	}

	//Función para cancelar y redirigir a módulo o submódulo.
	cancelar = (accion:number) => {
		this.setState({ redireccionando: accion })
	}
	 
	render() {
		return ( 
			<div className="divContenedorPrincipal" >
				{/*////////// Sección de redirigir a paginas mediante condicionales //////////*/}
				{this.state.redireccionando == 1 ?
					<>
						{Acciones("documentacionapi")}
					</>
				:this.state.redireccionando == 2 ?
					<>
						{Acciones("cancelar1")}
					</>
				:this.state.redireccionando == 3 ?
					<>
						{Acciones("cancelar2")}
					</>
				:this.state.redireccionando == 4 ?
					<>
						{Acciones("solicitud")}
					</>
				:null}
				
				{/*////////// Sección de activación de spinner //////////*/}
				{(this.state.cargando == true) ? (
					<SpinnerModal tipo="full" show={this.state.cargando}/>
				) : null}

				<ToastContainer />
				<MenuNavbar/>
				<main>
					{/*////////// Sección de breadscrum //////////*/}
					<Navegacion
						seccion={"documentar-api"}
						proyecto={this.state.submodulo.nombre_proyecto}
						modulo={this.state.submodulo.nombre_modulo}
						submodulo={this.state.submodulo.nombre_submodulo}
						accion={this.state.submodulo.submodulo_general}
					/>
					{/*////////// Sección de información general //////////*/}
					<div className="row">
						<Label className='labelInputModal'>
							<h5>Información general</h5>
						</Label>
					</div>
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<InputText
								label={'Nombre de la API'}
								placeholder='Ej. Lista clientes'
								limitecaracteres={70}
								txtvalor={this.state.txtnombreapi}
								vermsgerror={this.state.vermsgerrornombre}
								msgerror={this.state.msgerrornombre}
								escribir={this.nombre}
							/>
						</div>
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<InputText
								label={'URL'}
								placeholder='Ej. /clientes/lista'
								limitecaracteres={70}
								txtvalor={this.state.txturl}
								vermsgerror={this.state.vermsgerrorurl}
								msgerror={this.state.msgerrorurl}
								escribir={this.url}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<SelectTipoSolicitud
								token={this.state.perfil.token}
								tiposolicitud={this.state.tiposolicitud}
								vermsgerror={this.state.vermsgerrortiposolicitud}
								msgerror={this.state.msgerrortiposolicitud}
								tipoSolicitudSeleccionada={this.tipoSolicitudSeleccionada}
							/>
						</div>
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<SelectEstado
								estado={this.state.estado}
								vermsgerror={this.state.vermsgerrorestado}
								msgerror={this.state.msgerrorestado}
								estadoSeleccionado={this.estadoSeleccionado}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<SelectHeaders
								estado={this.state.headerscheckbox}
								headersHabilitados={this.habilitarHeaders}
							/>
						</div>
					</div>
					{this.state.headerscheckbox ?
						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3 divContenedorTextArea">
								<InputQuill
									label={"Descripción técnica"}
									isOpcional={true}
									isModal={false}
									placeholder={"Ingresa la descripción técnica de la api."}
									limitecaracteres={3000} 
									txtvalor={this.state.txtdescripcionapi} 
									txtvalorlength={0} 
									vermsgerror={this.state.vermsgerrordescripcionapi} 
									msgerror={this.state.msgerrordescripcionapi} 
									escribir={this.descripcion}
								/>
							</div>
							<div className="col-12 scale-up-top-left col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3 divContenedorTextArea">
								<InputHeaders
									isOpcional={false}
									isModal={false}
									checkbox={this.state.headerscheckbox}
									label={"Headers"}
									limitecaracteres={500}
									txtvalor={this.state.txtheadersapi}
									vermsgerror={this.state.vermsgerrorheaders}
									msgerror={this.state.msgerrorheaders}
									escribir={this.headers}
									habilitar={this.habilitarHeaders}
								/>
							</div>
						</div>
					:
						<div className="row">
							<div className="col-12 divContenedorTextArea">
								<InputQuill
									label={"Descripción técnica"}
									isOpcional={true}
									isModal={false}
									placeholder={"Ingresa la descripción técnica de la api."}
									limitecaracteres={3000} 
									txtvalor={this.state.txtdescripcionapi} 
									txtvalorlength={0} 
									vermsgerror={this.state.vermsgerrordescripcionapi} 
									msgerror={this.state.msgerrordescripcionapi} 
									escribir={this.descripcion}
								/>
							</div>
						</div>
					}
					{/*////////// Sección de entradas //////////*/}
					<div className="row mt-3">
						<Label className='labelInputModal'>
							<h5>Entradas</h5>
						</Label>
					</div>
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<SelectFormatoCuerpo 
								token={this.state.perfil.token} 
								formatocuerpo={this.state.formatocuerpo} 
								vermsgerror={this.state.vermsgerrorformatocuerpo} 
								msgerror={this.state.msgerrorformatocuerpo} 
								formatoCuerpoSeleccionado={this.formatoCuerpoSeleccionado}
							/>
						</div>
					</div>
					{this.state.formatocuerpo != 5 && (
						<div className="row">
							<InputModelo
								titulo={"Modelo de entradas"}
								placeholder={this.state.placeholdermodeloentradas}
								txtmodelo={this.state.txtmodeloentradas}
								vermsgerrormodelo={this.state.vermsgerrormodeloentradas}
								msgerrormodelo={this.state.msgerrormodeloentradas}
								modelo={this.modeloentradas} 
							/>
							<FormularioEjemplosEntradas
								placeholder={this.state.placeholderejemplosentradas}
								ejemplosentradas={this.state.ejemplosentradas}
								ejemplos={this.ejemplosEntradas} 
							/>
						</div>
					)}
					{/*////////// Sección de salidas //////////*/}
					<div className="row  mt-3">
						<Label className='labelInputModal'>
							<h5>Salidas</h5>
						</Label>
					</div>
					<div className="row">
                        <InputModelo 
                            titulo={"Modelo de salidas"} 
                            txtmodelo={this.state.txtmodelosalidas} 
                            vermsgerrormodelo={this.state.vermsgerrormodelosalida} 
                            msgerrormodelo={this.state.msgerrormodelosalidas} 
                            modelo={this.modelosalidas}
                        />
                        <FormularioEjemplosSalidas 
							ejemplossalidas={this.state.ejemplossalidas}	
							ejemplos={this.ejemplosSalidas} 						
						/>
                    </div>
					{/*////////// Sección de excepciones //////////*/}
					<div className="row mt-5">
						<Label className='labelInputModal'>
							<h5>Excepciones</h5>
						</Label>
					</div>
					<div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<SelectExcepciones 
								excepciones={this.state.selectexcepciones} 
								vermsgerror={this.state.vermsgerrorexcepciones} 
								msgerror={this.state.msgerrorexcepciones} 
								habilitarExcepciones={this.excepcionesSeleccionado}						
							/>
                        </div>
                    </div> 
                    {this.state.selectexcepciones === "1" ? 
                        <div className="row">
							<InputModelo
								titulo={"Modelo de excepciones "}
								txtmodelo={this.state.txtmodeloexcepciones}
								vermsgerrormodelo={this.state.vermsgerrormodeloexcepciones}
								msgerrormodelo={this.state.msgerrormodeloexcepciones}
								modelo={this.modeloExcepciones} 
							/>
							<FormularioEjemplosExcepciones
								ejemplosexcepciones={this.state.ejemplosexcepciones}
								ejemplos={this.ejemplosExcepciones} 
							/>
						</div>
                    :null}
					{/*////////// Sección de comentario //////////*/}
					{this.state.idsolicitud_tiene_api !== 0 ?
						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
								<InputComentario 
									checkbox={this.state.confirmar}
									label={'Añadir un comentario acerca de la creación de la api.'}
									txtvalor={this.state.txtcomentario}
									vermsgerror={this.state.vermsgerrorcomentario} 
									msgerror={this.state.msgerrorcomentario}
									escribir={this.comentario} 
									habilitar={this.habilitarComentario}
								/>
							</div>
						</div>
					:null}
					<div className="row mt-4">
						<div className="col divContenedorBotonesGuardar">
							{this.state.submodulo.submodulo_general === 1 ? (
								<>
									<Button className="botonCancelarDocApi" color="danger" onClick={() => this.cancelar(2)} >Cancelar</Button>
								</>
							) : this.state.submodulo.submodulo_general === 0 ? (
								<>
									<Button className="botonCancelarDocApi" color="danger" onClick={() => this.cancelar(3)} >Cancelar</Button>
								</>
							) : null}
							<Button color="success" onClick={this.guardarApi} >Crear Api</Button>
						</div>
					</div>
				</main>
			</div>
		);
	};
}
function Acciones(bandera:string) {
    if (bandera=='documentacionapi'){
		return <Navigate to="/documentacionapi" />;
    }else if(bandera=='cancelar1'){
		return <Navigate to="/modulo" />;
	}else if(bandera=='cancelar2'){
		return <Navigate to="/submodulo" />;
	}else if(bandera=='solicitud'){
		return <Navigate to='/solicitud'/>
	}
}


