import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalEliminarSubmodulo.css";
import { postEliminarSubmodulo } from "../../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';

interface props {
  isOpen: boolean,
  closeModal:(accion:number) => void,
  idsubmodulo:number,
  idproyecto:number,
  token:number
}

interface states {
  isOpen: boolean,
  confirmar:boolean,
  redireccionando:boolean
}

export default class ModalEliminarProyecto extends React.Component<props,states>{
  constructor(props:props) {
    super(props);
    this.state = {
        isOpen:this.props.isOpen,
        confirmar:false,
        redireccionando:false
    }
  }

  showModal=(accion:number)=> {
    this.setState({isOpen:false})
    setTimeout(() => {
      this.props.closeModal(accion);
    }, 200);
  }

  eliminarSubmodulo = async () => {
    let json = {
      "token":this.props.token,
      "id_submodulo":this.props.idsubmodulo,
      "id_proyecto":this.props.idproyecto
    }
    
    const resp = await postEliminarSubmodulo(json);

    if (resp.code == 0) {
      this.showModal(1)
    }
    else if (resp.code == 200) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
    else if (resp.code == 100) {
      toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
    else if (resp.code == 300) {
      toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
    else if (resp.code == 1000 || resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.showModal(0)
    }
  }
    
  render() {
    return (
      <>
        <ToastContainer />
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader className="modalHeader">
            <label className='labelTituloModal'> Eliminar submódulo</label>
              <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
          </ModalHeader>
          <ModalBody>
            <div className="divContenedorModal">
              <div className="contenidoTextoModal">
                <span className='textoPreguntaModal'>¿Está seguro de eliminar el submódulo?</span>
              </div>
              <div className="contenedorCentro mb-2 mt-1" onClick={() => this.setState({ confirmar: !this.state.confirmar })}>
                <input type="checkbox" checked={this.state.confirmar} /> Confirmar eliminación
              </div>
            </div>
            <div className="divContenedorBotonesModal">
              <Button className="btnCancelModal" onClick={() => this.showModal(0)} >Cancelar</Button>
              <Button className="btnAceptarModal"  onClick={this.eliminarSubmodulo} disabled={!this.state.confirmar}>Eliminar</Button>           
            </div>
          </ModalBody>
        </Modal>
      </>
      
    );
  }
}
    