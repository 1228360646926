import React from "react";
import { FormGroup, Label, Input, Button, Card, CardBody, InputGroup, InputGroupText } from "reactstrap";
import './Login.css'
import { Navigate } from "react-router-dom";
import { validarUsuario, validarClave } from "../../Services/Validaciones";
import { login } from "../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { estiloMensajeColorError } from "../../Services/PaletaDeColores";
import { HiEye,HiEyeOff } from "react-icons/hi";
import { FaLock, FaUser } from "react-icons/fa";
import { BiArrowFromLeft } from "react-icons/bi";
import Cookies from "universal-cookie";

//Interfaz general del componente.
interface states{
	txtusuario: string,
	vermsgerrorusuario: boolean,
	msgerrorusuario:string,

	txtclave: string,
	vermsgerrorclave: boolean,
	msgerrorclave:string,
	verclave:boolean;

	recordar:boolean,
	cargando:boolean,
	colormsgerror:string,
	redireccionando: boolean,
}

export default class Login extends React.Component<{}, states>{
    constructor(props: {}) {
		super(props);
		this.state = {
			txtusuario: "",
			vermsgerrorusuario: false,
			msgerrorusuario: "",

            txtclave: "",
            vermsgerrorclave: false,
            msgerrorclave: "",
			verclave:false,
			recordar:false,
			cargando:false,
			colormsgerror: estiloMensajeColorError().msgerror,
			redireccionando: false
	  	};
	}

	async componentDidMount() {
		//Si ya existe un usuario que haya iniciado sesión, se redirige al catalogo de proyectos.
        if (await localStorage.getItem('acceso') != null || await localStorage.getItem('acceso') != undefined) {
			this.setState({ redireccionando: true });
        }
		const cookies = new Cookies();
		const user = cookies.get('usuario')
		const pass = cookies.get('pass')
		const trimmedPass = String(pass);
		if(user && pass){
			this.setState({
				recordar:true,
				txtusuario:user,
				txtclave:trimmedPass
			});
		}else{
			this.setState({
				recordar:false
			});
		}
    }

	/*
	Función para obtener el valor de los datos ingresados por teclado en los inputs y
	cambiar el estado de sus variables correspondientes.
	*/
	escribir = (prop: keyof states) => (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ ...this.state, [prop]: event.target.value });
	};
	
	//Función de iniciar sesión
	iniciarSesion = async () => {
		this.setState({ vermsgerrorusuario: false });
        this.setState({ vermsgerrorclave: false });

        let usercorrecto = validarUsuario(this.state.txtusuario.trim());
        let passwordcorrecto = validarClave(this.state.txtclave.trim());
        let validacionescorrectas = true;
		
		if (usercorrecto.codigo === "invalid") {
            this.setState({ msgerrorusuario: usercorrecto.mensaje });
            this.setState({ vermsgerrorusuario: true });
            validacionescorrectas = false;
			
        }
		
        if (passwordcorrecto.codigo === "invalid") {
            this.setState({ msgerrorclave: passwordcorrecto.mensaje })
            this.setState({ vermsgerrorclave: true })
            validacionescorrectas = false
			
        }
		
		if (validacionescorrectas) {
			this.setState({cargando: true });
			var resp = await login(this.state.txtusuario.trim(), this.state.txtclave.trim());

			setTimeout(() => {
				this.setState({cargando: false });
			}, 500);

			if (resp.code === 200) {
                toast.warn(resp.message, { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp === -1) {
                toast.error("Error de conexión", { autoClose: 2000, position: toast.POSITION.TOP_LEFT });
                setTimeout(() => {
					this.setState({cargando: false });
				}, 500);
                return;
            }
		
			const usuario = {token:resp.data.token , name: resp.data.nombre_usuario, email:resp.data.email, role:resp.data.id_rol };
			localStorage.setItem("acceso", JSON.stringify(usuario));
			if(this.state.recordar === true){
				const cookies = new Cookies();
				cookies.set('usuario',this.state.txtusuario,{path:'/'})
				cookies.set('pass',this.state.txtclave,{path:'/'})
			}else{
				const cookies = new Cookies();
				cookies.remove('usuario');
				cookies.remove('pass');
			}

			setTimeout(() => {
				this.setState({ redireccionando: true });
			}, 500);
			
			
        }
	};

	//Función para ejecutar la función de iniciarSesion() con la tecla "Enter"
	teclaEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.iniciarSesion()
		}
	}

	//Función para ver contraseña
	verClave = () => {
		this.setState({ verclave: !this.state.verclave });
	}

	recordarContraseña = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			recordar: event.target.checked
		});
	};

    render() {
        return(
			<>
				{/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/ }
				{this.state.redireccionando
					?
						<>
							{Acciones("usuarioactivo")}
						</>
				: null}

				{/*////////// Sección de activación de modales y spinner //////////*/ }
				{(this.state.cargando === true) ? (
					<SpinnerModal tipo="full" show={this.state.cargando}/>
				) : null}
				
				<ToastContainer />

				{/*////////// Sección del login //////////*/}
				<div className="container">
					<div className="divLogin">
						<Card className="col-sm col-md-11 col-lg-8 col-xl-8 cardLogin">
							<div className="row">
								<div className="col-6 divLogoLogin">
									<div className="divContenedorApibox">
										<a>Bienvenido a</a>
										<a>Apibox</a>
									</div>
									<br />
									<div className="divContenedorTextoLogin">
										<a>"Un camino claro a la conexión con tus APIS."</a>
									</div>
									<div className="divContenedorPropiedad">
										<a>© 2023 <a href="https://www.izttra.com" target="_blank">Izttra</a> . Todos los derechos reservados.</a>
									</div>
								</div>
								<div className="col-6 divFormularioLogin">
									<div className="divIdentificate">
										Identifícate
									</div>
									<div>
										<FormGroup className='formContenedor'>
											<Label className='labelInputModal'>
												Usuario
											</Label>
											<InputGroup>
												<InputGroupText className="iconoInput">
													<FaUser size={15} color="white"/> 
												</InputGroupText>
												<Input
													className="inputLogin"
													maxLength={35}
													placeholder="Introduce tu usuario"
													type="text"
													onChange={this.escribir("txtusuario")} 
													value={this.state.txtusuario}
												/>
												{this.state.vermsgerrorusuario 
												? 
													<p style={{ color: this.state.colormsgerror }}>
														{this.state.msgerrorusuario}
													</p> 
												:null}
											</InputGroup>
										</FormGroup>
									</div>
									<div>
										<FormGroup className='formContenedor'>
											<Label className='labelInputModal'>
												Contraseña
											</Label>
											<InputGroup>
												<InputGroupText className="iconoInput">
													<FaLock size={15} color="white"/> 
												</InputGroupText>
												<Input
													className="inputClave"
													placeholder="Introduce tu contraseña"
													maxLength={25}
													type={this.state.verclave ? "text" : "password"}
													onKeyDown={(e) => this.teclaEnter(e)}
													onChange={this.escribir("txtclave")}
													value={this.state.txtclave}
													endAdornment={"aaaaaaaa"} />
												<InputGroupText className="botonVerClave">
													{this.state.verclave ?
														<HiEyeOff size={20} color="grey" onClick={this.verClave}/>
													: <HiEye size={20} color="grey" onClick={this.verClave}/>
													}
												</InputGroupText>
												{this.state.vermsgerrorclave
												?
													<p style={{ color: this.state.colormsgerror }}>
														{this.state.msgerrorclave}
													</p>
												:null}
											</InputGroup>
										</FormGroup>
									</div>
									<div>
										<FormGroup check>
										<Input
											type="checkbox" 
											checked={this.state.recordar}
											onChange={this.recordarContraseña}
										/>
											{' '}
											<Label check>
												Recordar contraseña
											</Label>
										</FormGroup>
									</div>
									<div className="divBotonIniciarSesión">	
										<Button 
											onClick={this.iniciarSesion}
											color="success">
											Iniciar sesión <BiArrowFromLeft size={25} color="white"/>  
										</Button>
									</div>
								</div>
							</div>
						</Card>
					</div>
				</div>
			</>
    	)
	}
}
//Función para redireccionar a diferentes páginas mediante condiciones del valor recibida
function Acciones(bandera:string) {
    if (bandera === 'usuarioactivo') {
		return <Navigate to="/catalogoproyectos" />;
    }
}


