import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

interface props {
    estado:boolean
    headersHabilitados: (estado:boolean) => void;
}

interface states {
    estado:boolean
}

export default class SelectHeaders extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            estado:this.props.estado
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.estado !== this.props.estado) {
            this.setState({ estado: this.props.estado });
        }
    }

    //Función para cambiar el estado del select de tipo solicitud.
	estadoSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const seleccion = event.target.value;
        const valor = seleccion === "Habilitado" ? true : false;
		this.setState( {estado:valor});
        this.props.headersHabilitados(valor)
	};

    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Headers adicionales<span className="spanObligatorio">*</span>
                </Label>
                <Input type="select" value={this.state.estado ? "Habilitado" : "Deshabilitado"} onChange={this.estadoSeleccionado}>
                    <option value="Habilitado" selected>Habilitado</option>
                    <option value="Deshabilitado">Deshabilitado</option>
                </Input>
            </FormGroup>
        );
    }
}