import ReactDOM from 'react-dom/client';
import './App.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login/Login';
import CatalogoProyectos from './Pages/CatalogoProyectos/CatalogoProyectos';
import GuardarProyecto from './Pages/GuardarProyecto/GuardarProyecto';
import DetallesProyecto from './Pages/DetallesProyecto/DetallesProyecto';
import DetallesModulo from './Pages/DetallesModulo/DetallesModulo';
import DetallesSubmodulo from './Pages/DetallesSubmodulo/DetallesSubmodulo';
import DocumentarApi from './Pages/DocumentarApi/DocumentarApi';
import DocumentacionApi from './Pages/DocumentacionApi/DocumentacionApi';
import CatalogoApis from './Pages/CatalogoApis/CatalogoApis';
import CatalogoReportes from './Pages/CatalogoReportes/CatalogoReportes';
import DetallesReporte from './Pages/DetallesReporte/DetallesReporte';
import CatalogoSolicitudes from './Pages/CatalogoSolicitudes/CatalogoSolicitudes';
import EditarInformacionGeneral from './Pages/DocumentarApi/EditarInformacionGeneral';
import EditarEntradas from './Pages/DocumentarApi/EditarEntradas';
import EditarSalidas from './Pages/DocumentarApi/EditarSalidas';
import EditarExcepciones from './Pages/DocumentarApi/EditarExcepciones';
import DetallesSolicitud from './Pages/DetallesSolicitud/DetallesSolicitud';
import DocumentacionRegistroActividad from './Pages/DocumentacionRegistroActividad/DocumentacionRegistroActividad';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login/>} />
      
      <Route path="/catalogoproyectos" element={<CatalogoProyectos/>} />
      <Route path="/guardarproyecto" element={<GuardarProyecto/>} />
      <Route path="/proyecto" element={<DetallesProyecto/>} />
      
      <Route path="/modulo" element={<DetallesModulo/>} />

      <Route path="/submodulo" element={<DetallesSubmodulo/>} />

      <Route path="/documentarapi" element={<DocumentarApi/>} />
      <Route path="/documentacionapi" element={<DocumentacionApi/>} />
      <Route path="/documentacionregistroactividad" element={<DocumentacionRegistroActividad/>} />
      <Route path="/documentarapiinfogeneral" element={<EditarInformacionGeneral/>} />
      <Route path="/documentarapientradas" element={<EditarEntradas/>} />
      <Route path="/documentarapisalidas" element={<EditarSalidas/>} />
      <Route path="/documentarapiexcepciones" element={<EditarExcepciones/>} />

      <Route path="/catalogoapis" element={<CatalogoApis/>} />

      <Route path="/catalogoreportes" element={<CatalogoReportes/>} />
      <Route path="/reporte" element={<DetallesReporte/>} />

      <Route path="/catalogosolicitudes" element={<CatalogoSolicitudes/>} />
      <Route path="/solicitud" element={<DetallesSolicitud/>} />

    </Routes>
  </BrowserRouter>
);
reportWebVitals();
