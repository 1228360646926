import React from "react";
import { Button, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import {getListaApisGeneral, getListaProyectos, getListaSubmodulosDelModuloSinFiltro, getModulosDelProyecto } from "../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CatalogoApis.css';
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { Navigate } from "react-router-dom";
import MenuNavbar from "../../Complements/Navbar/Navbar";
import Paginacion, { paginarElementos } from '../../Complements/Paginacion/Paginacion';
import Api from "./Componentes/Api";
import { api } from "./Interfaces";
import Navegacion from "../../Complements/Navegacion/Navegacion";
import { proyecto } from "../CatalogoProyectos/Interfaces";
import { modulo, submodulo } from "../../Complements/Modales/ModalFormularioSolicitud/Interfaces";
import { HiMagnifyingGlass } from "react-icons/hi2";


interface states{
	isExpanded: boolean,
  	salir: boolean,
	cargando:boolean,
	  perfil:{
		token:number,
        name:string,
		email:string,
		role:number
  	},

	proyectoslista:proyecto[],
	selectproyecto:number,

	moduloslista:modulo[],
	selectmodulo:number,

	submoduloslista:submodulo[],
 	selectsubmodulo:number,

	visualizarapis:number,
	apis:api[],
	apisfiltradas:api[],
	apispaginadas:api[],
	apisfiltradaspaginadas:api[],
	
	txtbuscadorapis: string,
	paginaActiva: number,
	itemsPorPagina: number,
    totalPaginas:number
    totalPaginasFiltradas:number,

	txtnumitems:number,
	redireccionando:number,
 
}

export default class CatalogoApis extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
		  isExpanded: false,
		  salir:false,
		  cargando:true,
		  perfil:JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "","role":""}'),
		  
		  proyectoslista:[],
		  selectproyecto: 0,

		  moduloslista:[],
		  selectmodulo: 0,

		  submoduloslista:[],
		  selectsubmodulo:0,

		  visualizarapis:0, 
		  apis:[],
		  apisfiltradas:[],
		  apispaginadas:[],
		  apisfiltradaspaginadas:[],
		  txtbuscadorapis: "",
		  paginaActiva: 1,
      	  itemsPorPagina: 10,
		  totalPaginas:0,
		  totalPaginasFiltradas:0,

		  txtnumitems:2,
		  redireccionando:0,
		  };
	}
	async componentDidMount() {
		if (await localStorage.getItem('acceso') == null || await localStorage.getItem('acceso') == undefined || await localStorage.getItem('acceso') == '') {
            this.setState({ redireccionando: 2 })
        }
		const apiapis = await getListaApisGeneral(this.state.perfil.token, "todas",0);
		this.setState({apis:apiapis.data});
		this.respuestaApi(apiapis,"todas");
		this.cambiarPagina(1);

		const apiproyectos = await getListaProyectos(this.state.perfil.token);
		this.setState({proyectoslista: apiproyectos.data});
		
		setTimeout(() => {
			this.setState({cargando: false});
			localStorage.removeItem("accion");
		}, 1000);
		
    }

	respuestaApi = (resp:any, tipo_busqueda:string) => {
		if(resp.code == 0){
			this.setState({visualizarapis:4})
			return
		}
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return
		}
		else if (resp.code == 100) {
			if(tipo_busqueda === "todas"){
				this.setState({visualizarapis:0})
			}
			else if(tipo_busqueda === "proyecto"){
				this.setState({visualizarapis:1})
			}
			else if(tipo_busqueda === "modulo"){
				this.setState({visualizarapis:2})
			}
			else if(tipo_busqueda === "submodulo"){
				this.setState({visualizarapis:3})
			}
			this.setState({ cargando: false })
		}
	}

	proyectoSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const proyecto = Number(event.target.value);
		if(proyecto === 0){
			const apiapis = await getListaApisGeneral(this.state.perfil.token, "todas",0);
			this.setState({apis:apiapis.data,selectproyecto:proyecto,selectmodulo:0, moduloslista:[],selectsubmodulo:0,submoduloslista:[],txtbuscadorapis:""});
			this.respuestaApi(apiapis,"todas");
			this.cambiarPagina(1);
		}else{
			this.setState({ selectproyecto:proyecto,selectmodulo:0, selectsubmodulo:0 }, async () => {
				const modulos = await getModulosDelProyecto(this.state.perfil.token, Number(this.state.selectproyecto));
				const apiapis = await getListaApisGeneral(this.state.perfil.token, "proyecto",proyecto);
				this.respuestaApi(apiapis,"proyecto");
				this.setState({apis:apiapis.data,moduloslista: modulos.data,submoduloslista:[],selectsubmodulo:0,txtbuscadorapis:""});
				this.cambiarPagina(1);
			});
		}
	};

	moduloSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const modulo = Number(event.target.value);
		if(modulo === 0){
			const apiapis = await getListaApisGeneral(this.state.perfil.token, "proyecto",this.state.selectproyecto);
			this.setState({apis:apiapis.data,selectmodulo:0, selectsubmodulo:0,submoduloslista:[],txtbuscadorapis:""});
			this.respuestaApi(apiapis,"todas");
			this.cambiarPagina(1);
		}else{
			this.setState({ selectmodulo:modulo,selectsubmodulo:0 }, async () => {
				const submodulos = await getListaSubmodulosDelModuloSinFiltro(this.state.perfil.token, Number(this.state.selectproyecto), Number(modulo));
				const apiapis = await getListaApisGeneral(this.state.perfil.token, "modulo",modulo);
				this.respuestaApi(apiapis,"modulo");
				this.setState({ submoduloslista: submodulos.data,
								selectsubmodulo:submodulos.data[0].id_submodulo,
								apis:apiapis.data,txtbuscadorapis:""
				});
				this.cambiarPagina(1);
			});
		}
	};

	submoduloSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const submodulo = Number(event.target.value);
		if(submodulo === 0){
			const apiapis = await getListaApisGeneral(this.state.perfil.token, "modulo",this.state.selectmodulo);
			this.respuestaApi(apiapis,"modulo");
			this.setState({selectsubmodulo:submodulo,apis:apiapis.data,txtbuscadorapis:""});
			this.cambiarPagina(1);
		}else{
			const apiapis = await getListaApisGeneral(this.state.perfil.token, "submodulo",submodulo);
			this.respuestaApi(apiapis,"submodulo");
			this.setState({selectsubmodulo:submodulo, apis:apiapis.data,txtbuscadorapis:""});
			this.cambiarPagina(1);
		}
		
	};

	buscarApi = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ txtbuscadorapis: e.target.value });

		if (e.target.value.trim().length > 2) {
			var data1 = JSON.parse(JSON.stringify(this.state.apis))
			
			const filtros = data1.filter((data1:any) => data1.nombre_api.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
			
			if (filtros.length < 1) {
				toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			this.filtro(1, filtros)
		}else{
			this.setState({apisfiltradaspaginadas:[]});
		}
	}

	//Función para cambiar de página
	cambiarPagina = (numeroPagina:number) =>{
		this.setState({
			paginaActiva: numeroPagina,
			apisfiltradaspaginadas: [],
			apispaginadas: []
		});

		setTimeout(() => {
			this.setState({
				paginaActiva: numeroPagina,
				apispaginadas: paginarElementos(numeroPagina,this.state.itemsPorPagina,  this.state.apis),
				totalPaginas:Math.ceil(this.state.apis.length / this.state.itemsPorPagina),
				apisfiltradaspaginadas: paginarElementos(numeroPagina,this.state.itemsPorPagina, this.state.apisfiltradas)
			});
		}, 0);
	}

	filtro = (numeroPagina:number, array:any) => {
		this.setState({
			paginaActiva: numeroPagina,
			apisfiltradaspaginadas: [],
			totalPaginasFiltradas:0
		});
		setTimeout(() => {
			this.setState({
				apisfiltradas: array,
				paginaActiva: numeroPagina,
				apisfiltradaspaginadas: paginarElementos(numeroPagina,this.state.itemsPorPagina, array),
				totalPaginasFiltradas:Math.ceil(this.state.apisfiltradas.length / this.state.itemsPorPagina)
			});
		},0);
	}

	limpiarBusqueda = () => {
		this.setState({selectproyecto:0, selectmodulo:0,moduloslista:[], selectsubmodulo:0,submoduloslista:[],txtbuscadorapis:""})
		this.componentDidMount();
	}

	itemsPorPagina = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const items = Number(e.target.value);
		this.setState({itemsPorPagina:items});
		this.cambiarPagina(1);
	}

	redireccionandoApi(id_proyecto:number, id_modulo:number,id_submodulo:number,submodulo_general:number,id_api:number, id_rol:number){
		localStorage.setItem("id_proyecto", id_proyecto.toString());
		localStorage.setItem("id_modulo", id_modulo.toString());
		localStorage.setItem("id_submodulo", id_submodulo.toString());
		localStorage.setItem("submodulo_general", submodulo_general.toString());
		localStorage.setItem("id_api",id_api.toString());
		localStorage.setItem("id_rol",id_rol.toString());
		this.setState({ redireccionando: 1 })
	}

	render() {
		return (  	
		<div className="divContenedorPrincipal">
			{this.state.redireccionando == 1 ?
				<>
					{Acciones("api")}	
				</>
			: this.state.redireccionando == 2 ?
				<>
					{Acciones("salir")}
				</> 
			: null}
			{this.state.cargando == true ?
           		<SpinnerModal tipo="full" show={this.state.cargando}/>
        	:null}

            <ToastContainer />
			<MenuNavbar/>
		    <main>
				<Navegacion
					seccion={"apis"}
				/>
				<div className="row divContenedorFiltros">
					<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
						<FormGroup className='formSelectFiltro'>
							<Label className='labelFiltro'>
								Proyecto
							</Label>
							<Input className="selectFiltro" type="select" value={this.state.selectproyecto} onChange={this.proyectoSeleccionado}>
								<option value="0" selected>Todos</option>
								{this.state.proyectoslista.map((Proyecto, key)=>(	
									<option key={key} value= {Proyecto.id_proyecto}>
									{Proyecto.nombre_proyecto}
									</option>
								))}
							</Input>
						</FormGroup>
					</div>
					<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
						<FormGroup className='formSelectFiltro'>
							<Label className='labelFiltro'>
								Módulo
							</Label>
							<Input className="selectFiltro" type="select" value={this.state.selectmodulo} onChange={this.moduloSeleccionado}>
								<option value="0" selected>Todos</option>
								{this.state.moduloslista.map((Modulo, index) => (	
									<option value={Modulo.id_modulo} key={index}>
									{Modulo.nombre_modulo}
									</option>
								))}
							</Input>
						</FormGroup>
					</div>
					<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
						<FormGroup className='formSelectFiltro'>
							<Label className='labelFiltro'>
								Submódulo
							</Label>
							<Input className="selectFiltro" type="select" value={this.state.selectsubmodulo} onChange={this.submoduloSeleccionado}>
								{this.state.submoduloslista.length !== 0 ?
									this.state.submoduloslista.map((Submodulo, index)=>(
										(Submodulo.submodulo_general == 1) ?
											<option key={index} value="0">
												Todos
											</option>
										:
											<option key={index} value= {Submodulo.id_submodulo}>
												{Submodulo.nombre_submodulo}
											</option>
									))
								:
									<option disabled value="0">Todos</option>
								}
							</Input>
						</FormGroup>
					</div>
					<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 ">
						<InputGroup>
							<Input 
								type="text" 
								className="inputBuscador"
								placeholder="Filtrar por nombre"
								onChange={this.buscarApi} 
								value={this.state.txtbuscadorapis} 
							/>
							<InputGroupText className="logoBuscador">
								<HiMagnifyingGlass />
							</InputGroupText>
						</InputGroup>
					</div>
					<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-1">
						<Button
							className="botonLimpiarBusqueda"
							color="primary"
							onClick={() => this.limpiarBusqueda()}
							>
							Limpiar
						</Button>
					</div>
					<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-1">
						<FormGroup className='formSelectFiltro'>
							<Label className='labelFiltro'>
								Mostrar&nbsp;
							</Label>
							<select className="selectItemsPorPagina" value={this.state.itemsPorPagina} onChange={this.itemsPorPagina}>
								<option value={5}>5</option>
								<option value={10}>10</option>
								<option value={20}>20</option>
								<option value={50}>50</option>
								<option value={100}>100</option>
							</select>
						</FormGroup>
					</div>
				</div>
				{this.state.visualizarapis === 0 ?
					<div className="row mt-4">
						<div className="d-flex justify-content-center">
							<em>No tienes acceso a ningún proyecto</em>
						</div>
					</div>
				:this.state.visualizarapis === 1 ?
					<div className="row mt-4">
						<div className="d-flex justify-content-center">
							<em>El proyecto no cuenta con apis</em>
						</div>
					</div>
				:this.state.visualizarapis === 2 ?
					<div className="row mt-4">
						<div className="d-flex justify-content-center">
							<em>El módulo no cuenta con apis</em>
						</div>
					</div>
				:this.state.visualizarapis === 3 ?
					<div className="row mt-4">
						<div className="d-flex justify-content-center">
							<em>El submódulo no cuenta con apis</em>
						</div>
					</div>
				:this.state.visualizarapis === 4 ?
					<>
						<div className="row">
						{this.state.txtbuscadorapis.length < 3 ?
							this.state.apispaginadas.map((api, index)=>(
								<Api
									clase="col-12 mt-3"
									key={index}
									api={api}
									verapi={() => this.redireccionandoApi(api.id_proyecto, api.id_modulo, api.id_submodulo, api.submodulo_general, api.id_api,  api.id_rol)} 
								/>
								))
							:this.state.apisfiltradaspaginadas.length > 0 ?
								this.state.apisfiltradaspaginadas.map((api, index)=>(
									<Api
										clase="col-12 mt-3"
										key={index}
										api={api}
										verapi={() => this.redireccionandoApi(api.id_proyecto, api.id_modulo, api.id_submodulo, api.submodulo_general, api.id_api,  api.id_rol)} 
									/>
								))
								:
								<div className="row mt-4">
									<div className="d-flex justify-content-center">
										<em>No hay apis que coincidan</em>
									</div>
								</div>
							}
						</div>
						<div className="row mt-3">
							{this.state.txtbuscadorapis.length < 3 ?
								<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaActiva}
								itemsPorPagina={this.state.itemsPorPagina} totalPaginas={this.state.totalPaginas} tamañoPaginacion="md"/>
							:
								<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaActiva}
								itemsPorPagina={this.state.itemsPorPagina} totalPaginas={this.state.totalPaginasFiltradas} tamañoPaginacion="md"/>
							}
						</div>
					</>
				:null}
		</main>
		</div>
);
};
}
//Función para redireccionar a diferentes páginas mediante condiciones del valor recibida
function Acciones(bandera:string) {
	if(bandera=='api'){
		return <Navigate to="/documentacionapi" />;
	}else if (bandera == 'salir') {
        localStorage.clear();
		return <Navigate to="/" />;
    }
}