import React from "react";
import { Input, Button, Card, CardBody, CardHeader, DropdownMenu, Dropdown, DropdownItem, DropdownToggle, InputGroup, InputGroupText } from 'reactstrap';
import { getProyectoDetalles,getModulosDelProyecto } from "../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './DetallesProyecto.css';
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import ModalEliminarProyecto from "../../Complements/Modales/ModalEliminarProyecto/ModalEliminarProyecto";
import ModalCrearModulo from "../../Complements/Modales/ModalFormularioModulo/ModalFormularioModulo";
import { Navigate } from "react-router-dom";
import { RiDeleteBinFill } from "react-icons/ri";
import { HiPencil, HiPuzzle } from "react-icons/hi";
import { CgDetailsMore } from "react-icons/cg";
import Paginacion, { paginarElementos } from '../../Complements/Paginacion/Paginacion';
import MenuNavbar from "../../Complements/Navbar/Navbar";
import { SlOptions } from "react-icons/sl";
import { format } from "date-fns";
import { es } from 'date-fns/locale';
import { IoMdAdd } from "react-icons/io";
import Modulo from "./Componentes/Modulo";
import { modulo, proyecto, proyectoestado } from "./Interfaces";
import Headers from "../../Complements/Headers/Headers";
import SeccionUsuarios from "./SeccionUsuarios";
import Navegacion from "../../Complements/Navegacion/Navegacion";
import { HiMagnifyingGlass } from "react-icons/hi2";

//Interfaz general del componente.
interface states{
	id_rol:number,
	salir: boolean,
	cargando:boolean,
	perfil:{
		token:number,
        name:string,
		email:string,
		role:number
	},
	opcionesinformacion:boolean,
	proyecto:proyecto,
	url:string,

	txtbuscador:string,
	visualizarmodulos:boolean,
	modulos:modulo[],
	modulosfiltrados:modulo[],
	modulospaginados:modulo[],
	modulosfiltradospaginados:modulo[],

	//Estados de modales
	titulomodal:string,
	accionmodal:number,
	showmodaleliminar:boolean,
	showmodalcrearmodulo:boolean,

	//Estados de paginación
	paginaactiva: number,
	itemsporpagina: number,
	totalpaginas:number
	totalpaginasfiltradas:number,

	redireccionando:number
}

export default class DetallesProyecto extends React.Component<{}, states> {
	constructor(props: {}) {
		super(props);
		this.state = {
			id_rol:0,
			salir:false,
			cargando:true,
			perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "", role:""}'),

			opcionesinformacion:false,
			proyecto:proyectoestado,
			url:'https://www.apibox.izttra.com/apibox-api/',
			//url:'http://localhost/apibox-api/',

			txtbuscador:"",
			visualizarmodulos:true,
			modulos:[],
			modulosfiltrados:[],
			modulospaginados:[],
			modulosfiltradospaginados:[],

			//Estados de modales
			titulomodal:"",
			accionmodal:0,
			showmodaleliminar:false,
			showmodalcrearmodulo:false,

			//Estados de paginación
			paginaactiva: 1,
			itemsporpagina: 4,
			totalpaginas:0,
			totalpaginasfiltradas:0,

			redireccionando:0
		};
	}

	async componentDidMount() {
		/*
			Si no existen datos del usuario almacenados en localStorage, se interpretará que no ha iniciado sesión
			y se redireccionaráá automáticamente al login.
		*/
		if (await localStorage.getItem('acceso') == null || await localStorage.getItem('acceso') == undefined || await localStorage.getItem('acceso') == '') {
            this.setState({ redireccionando: 5 })
        }

		//Se obtiene el valor de el item "accion", se hacen validaciones y se muestra el mensaje correspondiente,
		const accion = localStorage.getItem("accion");
		if(accion !== null){
			this.mensajesToast(accion);
			localStorage.removeItem("accion");
		}

		//Se obtiene el calor del item "id_rol" y se almacena en su correspondiente estado.
		const id_rol = localStorage.getItem("id_rol")
		this.setState({id_rol:Number(id_rol)})

		var id_proyecto = await localStorage.getItem('id_proyecto');

        const apiproyecto = await getProyectoDetalles(this.state.perfil.token,Number(id_proyecto));
		if(this.respuestaApi (apiproyecto, 0)){
			return;
		}
		this.proyectoFechaModificada(apiproyecto)

		const apimodulos = await getModulosDelProyecto(this.state.perfil.token,Number(id_proyecto));
		if(this.respuestaApi (apimodulos, 1)){
			return;
		}

		this.setState({modulos: apimodulos.data});
		this.cambiarPagina(1);

		setTimeout(() => {
			this.setState({cargando: false});
		}, 1000);
    }

	//Función para mostrar mensajes toast al iniciar el componente.
	mensajesToast =  (accion:string) => {
		if (accion === "Proyectocreado") {
			toast.success("Proyecto creado exitosamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		else if (accion === "Proyectoeditado") {
			toast.success("Proyecto editado exitosamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		else if(accion === "Sin permisos"){
			toast.warning("No tienes los permisos necesarios.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		else if (accion === "LogoActualizado") {
			toast.success("Logo actualizado!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		else if (accion === "SinCambios") {
			toast.info("No se hicieron cambios.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		else if (accion === "Moduloeliminado") {
			toast.success("Módulo eliminado exitosamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
	}

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any, tipo:number) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return
		}
		else if (resp.code == 300) {
			localStorage.setItem("accion", "300");
			this.setState({redireccionando:1})
			return
		}
		else if (resp.code == 100) {
			if(tipo === 1){
				this.setState({visualizarmodulos:false})
			}
			this.setState({ cargando: false })
			return
		}
		return false
	}

	//Función de regresar
	regresar = () => {
		window.history.back();
	};

	/////////////////////////////// Sección del proyecto
	//Función para modificar la fecha, trauducirla y aisgnarla.
	proyectoFechaModificada = (proyecto: any) =>{
		//Se modifica la fecha.
		const fecha_creacion = proyecto.data.fecha_creacion  + " 00:00:00";
		const fecha = new Date(fecha_creacion);
		const fecha_formateada = format(fecha, "d 'de' MMMM 'del' yyyy", { locale: es });

		//Se asigna la nueva fecha.
		proyecto.data.fecha_creacion = fecha_formateada;
		this.setState({proyecto: proyecto.data});
	}

	//Función para desplegar opciones de editar y eliminar proyecto.
	desplegarOpciones = () => {
		this.setState({opcionesinformacion: !this.state.opcionesinformacion})
	};

	//Función para redireccionar al formulario para editar la información del proyecto.
	editarProyecto = (id_proyecto:number) => {
		localStorage.setItem("accion", "2");
		this.setState({ redireccionando: 2})
	}

	//Función para abrir el modal para eliminar proyecto.
	verModalEliminarProyecto = (id_proyecto:number) => {
		this.setState({ showmodaleliminar: true})
	}

	//Función para cerrar el modal para eliminar proyecto.
	cerrarModalEliminarProyecto = (accion:number) =>{
        this.setState({showmodaleliminar: !this.state.showmodaleliminar });
		if(accion==1){
			localStorage.setItem("accion", "204");
			this.setState({ redireccionando: 3})
		}
    }

	actualizarComponente = () =>{
		this.setState({cargando:true});
		this.componentDidMount()
	}

	/////////////////////////////// Sección de modulos del proyecto.
	//Función para paginar modulos y cambiar de página.
	cambiarPagina = (numeroPagina:number) =>{
		this.setState({
			paginaactiva: numeroPagina,
			modulosfiltradospaginados: [],
			modulospaginados: []
		});

		setTimeout(() => {
			this.setState({
				paginaactiva: numeroPagina,
				modulospaginados: paginarElementos(numeroPagina,this.state.itemsporpagina,  this.state.modulos),
				totalpaginas:Math.ceil(this.state.modulos.length / this.state.itemsporpagina),
				modulosfiltradospaginados: paginarElementos(numeroPagina,this.state.itemsporpagina, this.state.modulosfiltrados)
			});
		}, 0);
	}

	//Función para buscar modulos.
	buscarModulo = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ txtbuscador: e.target.value });

		if (e.target.value.trim().length > 2) {
			var data1 = JSON.parse(JSON.stringify(this.state.modulos))

			const filtros = data1.filter((data1:any) => data1.nombre_modulo.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

			if (filtros.length < 1) {
				toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			}
			this.filtro(1, filtros)
		}
		else {
			this.setState({modulosfiltradospaginados:[]});
		}
	}

	//Función obtener resultado de la busqueda y paginarlos.
	filtro = (numeroPagina:number, array:any) => {
		this.setState({
			paginaactiva: numeroPagina,
			modulosfiltradospaginados: [],
			totalpaginasfiltradas:0
		});
		setTimeout(() => {
			this.setState({
				modulosfiltrados: array,
				paginaactiva: numeroPagina,
				modulosfiltradospaginados: paginarElementos(numeroPagina,this.state.itemsporpagina, array),
				totalpaginasfiltradas:Math.ceil(this.state.modulosfiltrados.length / this.state.itemsporpagina)
			});
		}, 0);
	}

	//Función para abrir modal del formulario para crear un módulo.
	verModalFormularioModulo = (accion:number) => {
		if(accion==1){
			this.setState({titulomodal:"Nuevo módulo", accionmodal:accion})
		}
		this.setState({ showmodalcrearmodulo: true})
	}
	
	//Función para cerrar el modal.
	cerrarModalCrearModulo = (accion:number) =>{
        this.setState({showmodalcrearmodulo:!this.state.showmodalcrearmodulo });
		if(accion==1){
			localStorage.setItem("accion", "1");
			this.setState({ redireccionando: 4 })
		}
    }

	//Función para redireccionar a un modulo seleccionado.
	redireccionando = (id_modulo:number) => {
		localStorage.setItem("id_modulo", id_modulo.toString());
		this.setState({ redireccionando: 4 })
	}

	render() {
		return ( 	
			<div className="divContenedorPrincipal" >
				{/*////////// Sección de redireccionar a paginas mediante condicionales //////////*/}
				{this.state.redireccionando == 1 ?
					<>
						{Acciones("accesorestringido")}
					</>
				: this.state.redireccionando == 2 ?
					<>
						{Acciones("editarproyecto")}
					</>
				: this.state.redireccionando == 3 ?
					<>
						{Acciones("proyectoeliminadoexitosamente")}
					</>
				: this.state.redireccionando == 4 ?
					<>
						{Acciones("detallesmodulo")}
					</>
				:this.state.redireccionando == 5 ?
					<>
						{Acciones("salir")}
					</>
				:null}
				
				{/*////////// Sección de activación de modales y spinner //////////*/}
				{(this.state.cargando == true) ? (
					<SpinnerModal tipo="full" show={this.state.cargando}/>
				) : null}
				
				{(this.state.showmodaleliminar == true) ? (
					<ModalEliminarProyecto isOpen={true} closeModal={this.cerrarModalEliminarProyecto} idproyecto={this.state.proyecto.id_proyecto} 
					token={this.state.perfil.token}/>
				) : null}

				{(this.state.showmodalcrearmodulo == true) ? (
					<ModalCrearModulo isOpen={true} titulomodal={this.state.titulomodal} accion={this.state.accionmodal} closeModal={this.cerrarModalCrearModulo} idproyecto={this.state.proyecto.id_proyecto}
					token={this.state.perfil.token} idsolicitud={0}/>
				) : null}

				<ToastContainer />
				<MenuNavbar/>
				<main>
					{/*////////// Sección de breadscrum //////////*/}
					<Navegacion
						seccion="proyecto"
						proyecto={this.state.proyecto.nombre_proyecto}
					/>
					{/*////////// Sección de información del proyecto //////////*/}
						<Card className="cardSeccion">
							<CardHeader className="cardSeccionHeader">
								<div className="contenedorTituloSeccionDetalles">
									<div className="divTituloSeccion">
										<CgDetailsMore size={23} color="black"/>
										<span className="tituloSeccion">Detalles</span>
									</div>
									{/*////////// Sección de botón para editar y eliminar proyecto, el cual se oculta dependiendo del rol //////////*/}
									{this.state.id_rol == 1 ? 
										<Dropdown className="dropdownOpcionesDetalles" isOpen={this.state.opcionesinformacion} toggle={() => this.desplegarOpciones()}>
											<DropdownToggle data-toggle="dropdown" tag="span">
												<SlOptions size={20} color="black"/>
											</DropdownToggle>
											<DropdownMenu>
												<DropdownItem onClick={() => this.editarProyecto(this.state.proyecto.id_proyecto)}>
													<HiPencil size={15} color="white"/> Editar información
												</DropdownItem>
												<DropdownItem onClick={() => this.verModalEliminarProyecto(this.state.proyecto.id_proyecto)}>
													<RiDeleteBinFill size={15} color="white"/> Eliminar
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>
									: null}
								</div>
							</CardHeader>
							<CardBody className="divContenidoCardBody">
								<div className="row d-flex">
									<div className="col">
										<div className="divTablaDetalles col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
											<table className="tableContenidoDetalles ">
												<tbody>
													<tr className="trTabla">
														<td className="col-4">
															{/* Se utiliza la consulta de la fecha para que tome la imagen como un elemento nuevo y no ocupe el elemento que tiene guardado en caché.*/}
															<img src={this.state.url + this.state.proyecto.logo_img + `?${new Date().getTime()}`} className="logoProyectoDetalles" />
														</td>
													</tr>
													<tr className="trTabla">
														<td className="col-4">
															<span className="tituloEncabezado">Nombre:</span>
														</td>
														<td className="col-8">
															<span className="tituloProyecto">{this.state.proyecto.nombre_proyecto}</span>
														</td>
													</tr>
													<tr className="trTabla">
														<td className="col-4">
															<span className="tituloEncabezado">Fecha de creación:</span>
														</td>
														<td className="col-8">
															<span className="tituloProyecto">{this.state.proyecto.fecha_creacion}</span>
														</td>
													</tr>
													<tr className="trTabla">
														<td className="col-4">
															<span className="tituloEncabezado">Apis completadas:</span>
														</td>
														<td className="col-8">
															<span className="tituloProyecto">{this.state.proyecto.apis_completadas}</span>
														</td>
													</tr>
													<tr className="trTabla">
														<td className="col-4">
															<span className="tituloEncabezado">Apis con errores:</span>
														</td>
														<td className="col-8">
															<span className="tituloProyecto">{this.state.proyecto.apis_reportadas}</span>
														</td>
													</tr>
													<tr className="trTabla">
														<td className="col-4">
															<span className="tituloEncabezado">Apis en desarrollo:</span>
														</td>
														<td className="col-8">
															<span className="tituloProyecto">{this.state.proyecto.apis_en_desarrollo}</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										{/*////////// Sección de headers del proyecto //////////*/}
										<div className="divHeaders col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
											<Headers
												headers_proyecto={this.state.proyecto.headers_proyecto}
												headers_modulo={null}
												headers_submodulo={null}
												headers_api={null}
											/>
										</div>
										<div>
											<span className="tituloEncabezado">Descripción del proyecto:</span>
											<div className="divDescripcion" dangerouslySetInnerHTML={{ __html: this.state.proyecto.descripcion_proyecto }} />
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
						{/*////////// Sección de colaboradores del proyecto //////////*/}
						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3">
								<SeccionUsuarios
									proyecto={this.state.proyecto}
									actualizarComponente={this.actualizarComponente}
								/>
							</div>
							{/*////////// Sección de módulos del proyecto //////////*/}
							<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3">
								<Card className="cardSeccion seccionDerecha">
									<CardHeader className="cardSeccionHeader">
										<div className="contenidoTituloSeccion">
											<HiPuzzle size={25} color="black"/>
											<span className="tituloSeccion">Módulos</span>
										</div>
									</CardHeader>
									<CardBody>
										{/*////////// Sección de buscador de módulos //////////*/}
										<div className="row">
											<div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 divContenedorBuscador">
												<InputGroup>
													<Input 
														type="text" 
														className="inputBuscador"
														placeholder="Buscar por nombre"
														onChange={this.buscarModulo} 
														value={this.state.txtbuscador} 
													/>
													<InputGroupText className="logoBuscador">
														<HiMagnifyingGlass />
													</InputGroupText>
												</InputGroup>
											</div>
											{/*////////// Sección de botón para abrir modal para crear módulos, se oculta dependiendo del rol //////////*/}
											{this.state.id_rol == 1 || this.state.id_rol == 2 || this.state.id_rol == 4 ? 
												<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 divContenedorBotones">
													<Button
														className="botonCrearModSubApi"
														color="success"
														onClick={() => this.verModalFormularioModulo(1)}>
														<IoMdAdd size={20} color="white"/>&nbsp;Módulo
													</Button>
												</div>
											: null}
										</div>
										{/*////////// Sección de la lista de los módulos //////////*/}
										
										{this.state.visualizarmodulos === false ? 
											<div className="row mt-4">
												<div className="d-flex justify-content-center">
													<em style={{fontSize:'15px'}}>El proyecto aún no cuenta con módulos.</em>
												</div>
											</div>
										:
											<>
												<div className="row">
													{this.state.txtbuscador.length < 3 ?
														this.state.modulospaginados.map((modulo, index) => (
															<Modulo 
																key={index}
																clase="col-12 mt-3"
																modulo={modulo} 
																vermodulo={() => this.redireccionando(modulo.id_modulo)}
															/>
														))
														:
														this.state.modulosfiltradospaginados.map((modulo, index) => (
															<Modulo 
																key={index}
																clase="col-12 mt-3"
																modulo={modulo} 
																vermodulo={() => this.redireccionando(modulo.id_modulo)}
															/>
														))}
												</div>
												{/*////////// Sección de paginación de los módulos //////////*/}
												<div className="row mt-3">
													{this.state.txtbuscador.length < 3 ?
														<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaactiva}
															itemsPorPagina={this.state.itemsporpagina} totalPaginas={this.state.totalpaginas} tamañoPaginacion="sm" />
														:
														<Paginacion cambiarPagina={this.cambiarPagina} paginaActiva={this.state.paginaactiva}
															itemsPorPagina={this.state.itemsporpagina} totalPaginas={this.state.totalpaginasfiltradas} tamañoPaginacion="sm" />}
												</div>
											</>
										}
									</CardBody>
								</Card>
							</div>
						</div>
				</main>
			</div>
		);
	};
}
//Función para redireccionar a diferentes páginas mediante condiciones del valor recibida
function Acciones(bandera:string) {
	if(bandera=="accesorestringido"){
		return <Navigate to="/catalogoproyectos" />;
	}else if(bandera=="editarproyecto"){
		return <Navigate to="/guardarproyecto" />;
	}else if (bandera == 'proyectoeliminadoexitosamente') {
		return <Navigate to="/catalogoproyectos" />;
    }else if(bandera == 'detallesmodulo'){
		return <Navigate to="/modulo" />;
	}else if (bandera == 'salir') {
        localStorage.clear();
		return <Navigate to="/" />;
    }
}
