import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import "./EstilosFormularios.css";
import Quill from "react-quill";
import { formats, modules } from '../../Services/ReactQuillServicios';

interface props {
    label:string,
    isOpcional:boolean
    isModal:boolean
    placeholder:string,
    limitecaracteres:number,
    txtvalor:string,
    txtvalorlength:number,
    vermsgerror:boolean,
    msgerror:string,
    escribir: (prop: string) => void;
}

interface states {
    txtvalor:string,
    txtvalorlength:number,
}

export default class InputQuill extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            txtvalor:this.props.txtvalor,
            txtvalorlength:this.props.txtvalor.length,
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.txtvalor !== this.props.txtvalor) {
          this.setState({ txtvalor: this.props.txtvalor,  txtvalorlength:this.props.txtvalor.length });
        }
    }

    escribir = (valor:string) => {
        this.setState({ txtvalor: valor,  txtvalorlength:valor.length });
        this.props.escribir(valor)
    };

    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    {this.props.isOpcional ? 
                        <>
                           {this.props.label} <span className="spanOpcional">(opcional)</span>
                        </>
                    : 
                        <>
                            {this.props.label} <span className="spanObligatorio">*</span>
                        </>
                    }
                </Label>
                <Quill
                    className={this.props.isModal ? 'textAreaDescripcionModal' :"textAreaDescripcion" }  
                    theme="snow"
                    placeholder={this.props.placeholder}  
                    value={this.state.txtvalor}
                    onChange={this.escribir}
                    modules={modules}
                    formats={formats}
                />
                <div className="row contenedorErroresDescripcion">
                    {this.props.vermsgerror
                        ? 
                            <>
                                <div className="col-9 contenedorMensajeError">
                                    <span className="mensajeError">{this.props.msgerror}</span>
                                </div>
                                <div className="col-3 contenedorContadorCaracteres">
                                    <span className='contadorCaracteres'>{this.state.txtvalorlength} / 3000</span>
                                </div>
                            </>
                            
                        : 
                            <div className='contenedorContadorCaracteres'>
                                <span className='contadorCaracteres'>{this.state.txtvalorlength} / 3000</span>
                            </div>
                    }
                </div>
            </FormGroup>
        );
    }
}