import React, { ChangeEvent } from "react";
import { ejemploentrada } from "../Interfaces";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";
import { IoMdClose } from "react-icons/io";
import { BiPlus } from "react-icons/bi";

interface props{
    placeholder?:string
    ejemplosentradas:ejemploentrada[]
    ejemplos: (ejemplos:ejemploentrada[]) => void;
}

interface states{
	ejemplosentradas: ejemploentrada[];

}

export class FormularioEjemplosEntradas extends React.Component<props, states> {
    constructor(props: props) {
		super(props);
		this.state = {
            ejemplosentradas:this.props.ejemplosentradas
        }
    } 

    componentDidUpdate(prevProps: props) {
        if (prevProps.ejemplosentradas !== this.props.ejemplosentradas) {
            this.setState({ ejemplosentradas: this.props.ejemplosentradas });
        }
    }

/////////////////////////////// Sección de placeholders
//Placeholder de input de modelo Json.
palceholderModeloJson = `{
    'name': 'string',
    'age': 'number',
    'interests': 'array',
    'active': 'boolean',
    'address': 'object',
    'scores': 'array of numbers',
    'description': 'string',
    'preferences': 'object'
}`;
//Placeholder de input de ejemplo Json.
placeholderEjemploJson = `{
    'name': 'John Doe',
    'age': 30,
    'interests': ['React', 'JavaScript'],
    'active': true,
    'address': {
        'street': '123 Main St',
        'city': 'Exampleville'
    },
    'scores': [95, 87, 92],
    'description': 'This is a sample description.',
    'preferences': {
        'theme': 'light',
        'notifications': true
    }
}`;
    /////////////////////////////// Sección de entradas
	//Función para agregar ejemplo de entrada.
	agregarEjemploEntradas = () => {
		const ejemploEntradas  = this.state.ejemplosentradas;
		const nuevoEjemploEntradas: ejemploentrada = {
            id_ejemplos_entradas:0,
			nombre_ejemplo_entrada: '',
			nombre_ejemplo_entradalength:0,
			txtnombreejemploentrada:'',
			vermsgerrornombreejemploentrada:false,
			msgerrornombreejemploentrada:'',
			ejemplo_entrada: '',
			ejemplo_entradalength:0,
			txtejemploentrada:'',
			vermsgerrorejemploentrada:false,
			msgerrorejemploentrada:'',
		};
		if (ejemploEntradas.length < 10) {
            const ejemplosEntradasActualizado = [...ejemploEntradas, nuevoEjemploEntradas];
			this.setState({ ejemplosentradas: ejemplosEntradasActualizado });
            this.props.ejemplos(ejemplosEntradasActualizado)
        } else {
            toast.warning("No puedes agregar más de 10 ejemplos.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
	};

	//Función para actualizar el estado de los inputs de nombre de cada ejemplo de entrada.
	actualizarNombreEjemploEntrada =(event: ChangeEvent<HTMLInputElement>, ejemploIndex: number) => {
		const  ejemplosEntradas  = this.state.ejemplosentradas;
		const ejemplosActualizados = ejemplosEntradas.map((ejemplo, index) => {
		  if (index === ejemploIndex) {
			return {
			  ...ejemplo,
			  nombre_ejemplo_entrada: event.target.value,
			  nombre_ejemplo_entradalength:event.target.value.length
			};
		  }
		  return ejemplo;
		});
		this.setState({ ejemplosentradas: ejemplosActualizados });
        this.props.ejemplos(ejemplosActualizados)
	};

	//Función para actualizar el estado de los inputs del ejemplo  de cada ejemplo de entrada.
	actualizarEjemploEntradas =(event: ChangeEvent<HTMLInputElement>, ejemploIndex: number) => {
		const ejemploEntradas  = this.state.ejemplosentradas;
		const ejemplosActualizados = ejemploEntradas.map((ejemplo, index) => {
		  if (index === ejemploIndex) {
			return {
			  ...ejemplo,
			  ejemplo_entrada: event.target.value,
			  ejemplo_entradalength: event.target.value.length
			};
		  }
		  return ejemplo;
		});
		this.setState({ ejemplosentradas: ejemplosActualizados });
        this.props.ejemplos(ejemplosActualizados)
	};

	//Función para eliminar un ejemplo de entrada.
	eliminarEjemploEntrada = (ejemploIndex: number) => {
		const ejemploEntradas = this.state.ejemplosentradas;
		if (ejemploIndex !== 0) {
			const ejemplosActualizados = ejemploEntradas.filter((ejemplo, index) => index !== ejemploIndex);
			this.setState({ ejemplosentradas: ejemplosActualizados });
            this.props.ejemplos(ejemplosActualizados)
		}
	};
    //Función para insertar tabulación dentro de los textarea
	teclaTab = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Tab") {
			const textarea = event.target as HTMLTextAreaElement;

			//Se obtiene la posición del cursor dentro del textarea
			const cursor = textarea.selectionStart;
		
			//Inserta la tabulación en la posición del cursor
			textarea.value = textarea.value.slice(0, cursor) + "\t" + textarea.value.slice(cursor);
		
			//Mueve el cursor a su posición
			textarea.setSelectionRange(cursor + 1, cursor + 1);
		
			//Evita que al presionar tab se cambie de elemento como al botón u otro input.
			event.preventDefault();
		}
	};

    render() {
		return(
            <>
                {this.state.ejemplosentradas.map((ejemplo, index) => (
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-1" key={index}>
                        <div className="row">
                            <div className="col-11">
                                <Label className='labelInputModal'>
                                    Nombre de ejemplo {index + 1} de entrada <span className="spanObligatorio">*</span>
                                </Label>
                            </div>
                            <div className="col-1 divContenedorBotonEliminarEjemplo">
                                {(index !== 0 && ejemplo.id_ejemplos_entradas === 0) && (
                                    <button className="botonEliminarEjemplo" onClick={() => this.eliminarEjemploEntrada(index)}>
                                        <IoMdClose size={25} color="white"/>
                                    </button>
                                )}
                            </div>
                            <div className="col-12">
                                <Input
                                    className="inputModal"
                                    type="text" 
                                    maxLength={30}
                                    onChange={(event) => this.actualizarNombreEjemploEntrada(event, index)}
                                    value={ejemplo.nombre_ejemplo_entrada}/>
                                    {ejemplo.vermsgerrornombreejemploentrada
                                        ? 
                                            <div className="row">
                                                <div className="col-10 contenedorMensajeError">
                                                    <span className="mensajeError">{ejemplo.msgerrornombreejemploentrada}</span>
                                                </div>
                                                <div className="col-2 contenedorContadorCaracteres">
                                                    <span className='contadorCaracteres'>{ejemplo.nombre_ejemplo_entradalength} / 30</span>
                                                </div>
                                            </div>
                                        : 
                                            <div className="contenedorContadorCaracteres">
                                                <span className='contadorCaracteres'>{ejemplo.nombre_ejemplo_entradalength} / 30</span>
                                            </div>
                                    }
                            </div>
                        </div>
                        <Label className='labelInputModal'>
                            Ejemplo {index + 1} de entradas <span className="spanObligatorio">*</span>
                        </Label>
                        <Input
                            className="textAreaEjemplo"
                            type="textarea"
                            placeholder={this.props.placeholder}
                            maxLength={3000}
                            onChange={(event) => this.actualizarEjemploEntradas(event, index)}
                            value={ejemplo.ejemplo_entrada} 
                            onKeyDown={this.teclaTab}/>
                        {ejemplo.vermsgerrorejemploentrada
                            ? 
                                <div className="row">
                                    <div className="col-9 contenedorMensajeError">
                                        <span className="mensajeError">{ejemplo.msgerrorejemploentrada}</span>
                                    </div>
                                    <div className="col-3 contenedorContadorCaracteres">
                                        <span className='contadorCaracteres'>{ejemplo.ejemplo_entradalength} / 3000</span>
                                    </div>
                                </div>
                            : 
                                <div className="row">
                                    <div className="col contenedorContadorCaracteres">
                                        <span className='contadorCaracteres'>{ejemplo.ejemplo_entradalength} / 3000</span>
                                    </div>
                                </div>
                        }
                    </div>
                ))}
                {this.state.ejemplosentradas.length < 10 ? 
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 divAgregarEjemplo">
                        <button className="divContendorAgregarEjemplo" type="button" onClick={this.agregarEjemploEntradas}>
                            <BiPlus size={30} color="gray"/>
                            <span>Agregar ejemplo</span>  
                        </button>
                    </div>
                :null}
            </>
        )
    }
}