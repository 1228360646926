import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { submodulo } from '../Interfaces';

interface props {
    agregarsubmodulo:boolean
    submodulos:submodulo[]
    selectsubmodulo:number
    vermsgerror:boolean,
    msgerror:string,
    agregar: () => void;
    submodulo: (submodulo:number) => void;
}

interface states {
    selectsubmodulo:number
}

export default class InputSubmodulos extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            selectsubmodulo:this.props.selectsubmodulo
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.selectsubmodulo !== this.props.selectsubmodulo) {
          this.setState({ selectsubmodulo: this.props.selectsubmodulo });
        }
    }

    agregarSubmodulo = () => {
       this.props.agregar()
    }

    submoduloSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const submodulo = Number(event.target.value);
        this.setState({ selectsubmodulo:submodulo });
        this.props.submodulo(submodulo)
    };
  
    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Submódulo <span className="spanObligatorio">*</span>
                </Label>
                {this.props.agregarsubmodulo === false ? 
                    <button className='botonAgregar' onClick={this.agregarSubmodulo}>
                        Agregar submódulo
                    </button>
                :
                <Input type="select" value={this.state.selectsubmodulo} onChange={this.submoduloSeleccionado}>
                    {this.props.submodulos.map((submodulo, index)=>(	
                        (submodulo.submodulo_general == 1) ? 
                        (
                            <option key={index} value={submodulo.id_submodulo}>
                                Sin submódulo
                            </option> 
                        ) 
                        : 
                        <option key={index} value={submodulo.id_submodulo}>
                            {submodulo.nombre_submodulo}
                        </option>
                    ))}
                </Input>}
            </FormGroup>
        );
    }
}