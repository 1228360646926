import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { proyecto } from '../Interfaces';

interface props {
    proyectos:proyecto[]
    selectproyecto:number
    vermsgerror:boolean,
    msgerror:string,
    proyecto: (proyecto:number) => void;
}

interface states {
    selectproyecto:number
}

export default class InputProyectos extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            selectproyecto:this.props.selectproyecto
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.selectproyecto !== this.props.selectproyecto) {
          this.setState({ selectproyecto: this.props.selectproyecto });
        }
    }

    proyectoSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const proyecto = Number(event.target.value);
        this.setState({ selectproyecto:proyecto });
        this.props.proyecto(proyecto)
    };
  
    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Proyecto <span className="spanObligatorio">*</span>
                </Label>
                <Input type="select" value={this.state.selectproyecto} onChange={this.proyectoSeleccionado}>
                    <option disabled value="0" selected>Seleccione una opción</option>
                    {this.props.proyectos.map((proyecto, index)=>(	
                        (proyecto.id_rol === 1 || proyecto.id_rol === 3 || proyecto.id_rol === 4) && (
                            <option key={index} value={proyecto.id_proyecto}>
                                {proyecto.nombre_proyecto}
                            </option>
                        )
                    ))}
                </Input>
                {this.props.vermsgerror ? 
                    <div className="row">
                        <div className="col-10 contenedorMensajeError">
                            <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    </div>
                :null}
            </FormGroup>
        );
    }
}