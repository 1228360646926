import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Input, Label, Badge } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioAgregarCambiarApi.css";
import { ToastContainer, toast } from 'react-toastify';
import { validarInputSelect, validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import { getListaApisSolicitud, postAgregarCambiarApi } from '../../../Services/Api';
import InputComentario from '../../Formularios/InputComentario';

interface ModalProps {
    isOpen: boolean,
    closeModal:(accion:number) => void,
    titulomodal:string,
    accion:number| null
    token:number,
    idproyecto:number,
    idapi:number,
    idsubmodulo:number,
    idsolicitudtieneapi:number | null,
    tiposolicitud:string,
}

interface Api{
  id_api:number, 
  nombre_api:string,
  tipo_solicitud:string
}

interface ModalState {
    isOpen: boolean,
    closeModal:(accion:number) => void,
    titulomodal:string,
    accion:number| null
    token:number,
    idproyecto:number,
    idsubmodulo:number,
    idsolicitudtieneapi:number | null,
    tiposolicitud:string,

    visualizarapis:boolean,
    apislista:Api[],
    idapi:number,
    vermsgerrorselectapi:boolean,
    msgerrorselectapi:string,
    redireccionando:boolean,

    confirmar:boolean,
    txtcomentario:string,
    txtcomentariolength:number,
    vermsgerrorcomentario:boolean,
    msgerrorcomentario:string,
    
}
export default class ModalFormularioAgregarCambiarApi extends React.Component<ModalProps,ModalState>{
    constructor(props:ModalProps) {
        super(props);
        this.state = {
            isOpen:this.props.isOpen,
            closeModal:this.props.closeModal,
            titulomodal:this.props.titulomodal,
            accion:this.props.accion,
            token:this.props.token,
            idproyecto:this.props.idproyecto,
            idsubmodulo:this.props.idsubmodulo,
            idsolicitudtieneapi:this.props.idsolicitudtieneapi,
            tiposolicitud:this.props.tiposolicitud,
            visualizarapis:true,
            apislista:[],
            idapi:(props.accion == 2) ? (this.props.idapi): 0,
            vermsgerrorselectapi:false,
            msgerrorselectapi:'',
            redireccionando:false,

            confirmar:false,
            txtcomentario:'',
            txtcomentariolength:0,
            vermsgerrorcomentario:false,
            msgerrorcomentario:'',
        }
    }
    async componentDidMount() {
      const apiapis = await getListaApisSolicitud(this.state.token,this.state.idsubmodulo,this.state.idproyecto);
		  if(this.respuestaApi (apiapis)){
        return;
      }
      this.setState({apislista: apiapis.data});
      
    }

    respuestaApi = (resp:any) => {
      if (resp == -1) {
        toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return true
      }
      else if (resp.code == 200) {
        toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return 
      }
      else if (resp.code == 100) {
        this.setState({visualizarapis:false})
        return 
      }
      return false
    }

    showModal=(accion:number)=> {
      this.setState({isOpen:false})
      setTimeout(() => {
        this.props.closeModal(accion);
      }, 200);
    }

    apiSeleccionada = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const apiseleccionada = Number(event.target.value);
      this.setState({ idapi:apiseleccionada });
    };

    comentario = (prop:string) => {
      this.setState({txtcomentario:prop})
    }

    habilitarComentario = (prop:boolean) => {
        this.setState({ confirmar:prop });
    }

    agregarCambiarApi = async () => {
      this.setState({ vermsgerrorselectapi: false});
      this.setState({ vermsgerrorcomentario: false});
     
      let longitudvalidacomentario = {longitudminima: 8, longitudmaxima: 300};

      let selectapi = validarInputSelect(String(this.state.idapi));
      let txtcomentario = validarLetrasNumerosEspacios(this.state.txtcomentario, longitudvalidacomentario);

      let validacionescorrectas = true;

      if (selectapi.codigo == "invalid") {
        this.setState({ msgerrorselectapi: selectapi.mensaje });
          this.setState({ vermsgerrorselectapi: true });
          validacionescorrectas = false;
      }

      if (txtcomentario.codigo == "invalid" && this.state.confirmar === true) {
        this.setState({ msgerrorcomentario: txtcomentario.mensaje });
        this.setState({ vermsgerrorcomentario: true });
        validacionescorrectas = false;
      }
      
      if (validacionescorrectas) {
          let json = {
            "token":this.state.token,
            "id_proyecto":this.state.idproyecto,
            "id_api":this.state.idapi,
            "idsolicitud_tiene_api":this.state.idsolicitudtieneapi,
            "comentario_checkbox":this.state.confirmar,
				    "comentario":this.state.txtcomentario
          }
          const resp = await postAgregarCambiarApi(json);

          if (resp.code == 0 && this.state.accion === 1) {
            toast.success("Api agregada exitósamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.showModal(1)
          }
          else  if (resp.code == 0 && this.state.accion === 2) {
            toast.success("Se cambió la api exitósamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.showModal(1)
          }
          else if(resp.code==100){
            this.showModal(2)
            toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
          else if (resp.code == 200) {
            toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.showModal(0)
          }
          else if (resp.code == 250) {
            toast.info(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.showModal(0)
          }
          else if (resp.code == 300) {
            toast.warning(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.showModal(0)
          }
          else if (resp.code == 1000 || resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.showModal(0)
          }
      }
    }
    
    render() {
        
        return (
          <>
            <ToastContainer />
            <Modal isOpen={this.state.isOpen} size="md">
              <ModalHeader className="modalHeader">
                  <label className='labelTituloModal'> {this.state.titulomodal} </label>
                  <CgClose className='iconoCerrarModal' onClick={() => this.showModal(0)}/>
              </ModalHeader>
              <ModalBody>
                <div className="divContenedorModal">
                  <div className="row">
                    <div className="col-12">
                      <Label className='labelInputModal'>
                        Tipo de solicitud requerida:
                      </Label>
                      <span>
                        {this.state.tiposolicitud == "POST" ?
                          <Badge className="badgePost" pill> 
                            {this.state.tiposolicitud}
                          </Badge>
                        :this.state.tiposolicitud == "GET" ?
                          <Badge className="badgeGet" pill> 
                            {this.state.tiposolicitud}
                          </Badge>  
                        :this.state.tiposolicitud == "PUT" ? 
                          <Badge className="badgePut" pill> 
                            {this.state.tiposolicitud}
                          </Badge>
                        :this.state.tiposolicitud == "DELETE" ?
                          <Badge className="badgeDelete" pill> 
                            {this.state.tiposolicitud}
                          </Badge>  
                        :null }
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <FormGroup className='formContenedor'>
                        <Label className='labelInputModal'>
                          Apis
                        </Label>
                        <Input type="select" value={this.state.idapi} onChange={this.apiSeleccionada}>
                          <option disabled value="0">Seleccione una opción</option>
                          {this.state.visualizarapis === false ? 
                            <option disabled value="0">El módulo o submódulo no cuenta con apis.</option>
                          :
                          this.state.apislista.map((api, index)=>(	
                            <option key={index} value= {api.id_api}>
                              {api.tipo_solicitud} - {api.nombre_api}
                            </option>
                          ))
                          }
                        </Input>
                        {this.state.vermsgerrorselectapi
                          ? 
                            <div className="row">
                              <div className="col-10 contenedorMensajeError">
                              <span className="mensajeError">{this.state.msgerrorselectapi}</span>
                              </div>
                            </div>
                          :null
                        }
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <InputComentario 
                        checkbox={this.state.confirmar}
                        label={this.state.accion === 1 ? 'Añadir un comentario acerca de la asignación de la api.' :this.state.accion === 2 ? 'Añadir un comentario acerca del cambio de la api.' :''}
                        txtvalor={this.state.txtcomentario}
                        vermsgerror={this.state.vermsgerrorcomentario} 
                        msgerror={this.state.msgerrorcomentario}
                        escribir={this.comentario} 
                        habilitar={this.habilitarComentario}
                      />
                    </div>
                  </div>
                </div>
                <div className="divContenedorBotonesModal">
                  <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                  {this.state.accion === 1 ? 
                     <Button className="btnAceptarModal"onClick={this.agregarCambiarApi}>Agregar</Button>  
                  :this.state.accion === 2 ? 
                    <Button className="btnAceptarModal"onClick={this.agregarCambiarApi}>Cambiar</Button>  
                  :null}  
                </div>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }