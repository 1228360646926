import React from "react";
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { getListaUsuariosProyecto } from "../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './DetallesProyecto.css';
import { HiUserGroup } from "react-icons/hi";
import { IoMdAdd } from "react-icons/io";
import { proyecto, usuario } from "./Interfaces";
import Usuario from "./Componentes/Usuario";
import ModalFormularioUsuarioColaboraProyecto from "../../Complements/Modales/ModalFormularioUsuarioColaboraProyecto/ModalFormularioUsuarioColaboraProyecto";

interface props {
    proyecto: proyecto;
	actualizarComponente:() => void;
}

//Interfaz general del componente.
interface states{
	cargando:boolean,
	perfil:{
		token:number,
        name:string,
		email:string,
		role:number
	},
	opcionesinformacion:boolean,
	proyecto:proyecto,
	url:string,

	visualizarusuariosfront:boolean,
	usuariosfront:usuario[],

	visualizarusuariosback:boolean,
	usuariosback:usuario[],

	visualizarusuariosfrontback:boolean,
	usuariosfrontback:usuario[],

	//Estados de modales
	titulomodal:string,
	accionmodal:number,
	showmodaleliminar:boolean,
	idusuario:number,
	showmodalusuariocolaboraproyecto:boolean,
}

export default class SeccionUsuarios extends React.Component<props, states> {
	constructor(props: props) {
		super(props);
		this.state = {
			cargando:true,
			perfil: JSON.parse(localStorage.getItem('acceso') || '{"token":"", "name": "", "email": "", role:""}'),

			opcionesinformacion:false,
			proyecto:props.proyecto,
			//url:'https://www.apibox.izttra.com/apibox-api/',
			url:'http://localhost/apibox-api/',
			visualizarusuariosfront:true,
			usuariosfront:[],

			visualizarusuariosback:true,
			usuariosback:[],

			visualizarusuariosfrontback:true,
			usuariosfrontback:[],

			//Estados de modales
			titulomodal:"",
			accionmodal:0,
			showmodaleliminar:false,
			idusuario:0,
			showmodalusuariocolaboraproyecto:false,
		};
	}

	async componentDidMount() {
		//Se obtiene el valor de el item "accion", se hacen validaciones y se muestra el mensaje correspondiente,
		const accion = localStorage.getItem("accion");
		if(accion !== null){
			this.mensajesToast(accion);
		}

		var id_proyecto = await localStorage.getItem('id_proyecto');

		const apiusuariosback = await getListaUsuariosProyecto(this.state.perfil.token, Number(id_proyecto), 2);
		if(this.respuestaApi (apiusuariosback, 2)){
			return;
		}

		const apiusuariosfront = await getListaUsuariosProyecto(this.state.perfil.token, Number(id_proyecto),3);
		if(this.respuestaApi (apiusuariosfront, 3)){
			return;
		}

		const usuariosfrontback = await getListaUsuariosProyecto(this.state.perfil.token, Number(id_proyecto),4);
		if(this.respuestaApi (usuariosfrontback, 4)){
			return;
		}

		this.setState({usuariosback:apiusuariosback.data, usuariosfront:apiusuariosfront.data,usuariosfrontback:usuariosfrontback.data});
    }

	componentDidUpdate(prevProps: props) {
        if (prevProps.proyecto !== this.props.proyecto) {
            this.setState({ proyecto: this.props.proyecto });
            this.componentDidMount()
        }
    }

	//Función para mostrar mensajes toast al iniciar el componente.
	mensajesToast =  (accion:string) => {
		if (accion === "SinCambios") {
			toast.info("No se hicieron cambios.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		else if (accion === "ColaboradorAsignado") {
			toast.success("Se ha asignado el colaborador!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		else if (accion === "ColaboradorEliminado") {
			toast.success("Se ha eliminado el colaborador!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
		else if (accion === "Moduloeliminado") {
			toast.success("Módulo eliminado exitosamente!", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
		}
	}

	//Función para gestionar las respuestas negativas de las apis.
	respuestaApi = (resp:any, tipo:number) => {
		if (resp == -1) {
			toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return true
		}
		else if (resp.code == 200) {
			toast.error("Algo salió mal. Revisa tu endpoint.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
			this.setState({ cargando: false })
			return
		}
		else if (resp.code == 100) {
            if(tipo === 2){
				this.setState({visualizarusuariosback:false})
			}
			else if(tipo === 3){
				this.setState({visualizarusuariosfront:false})
			}
			else if(tipo === 4){
				this.setState({visualizarusuariosfrontback:false})
			}
			this.setState({ cargando: false })
			return
		}
		return false
	}

	//Función para abrir el modal para añadir colaboradores al proyecto.
	verModalFormularioUsuarioColaboraProyecto = (id_usuario:number, accion:number) => {
		if(accion==1){
			this.setState({titulomodal:"Añadir colaborador", accionmodal:accion})
		}
		else if(accion==2){
			this.setState({titulomodal:"Información del usuario",idusuario:id_usuario, accionmodal:accion})
		}
		this.setState({ showmodalusuariocolaboraproyecto: true})
	}

	//Función para cerrar el modal para añadir colaboradores.
	cerrarFormularioUsuarioColaboraProyecto = (accion:number) =>{
        this.setState({showmodalusuariocolaboraproyecto:!this.state.showmodalusuariocolaboraproyecto });
		if(accion==1){
			localStorage.setItem("accion", "ColaboradorAsignado");
			this.setState({visualizarusuariosfront:true, visualizarusuariosback:true, visualizarusuariosfrontback:true, cargando:true})
			this.props.actualizarComponente();
			this.componentDidMount()
		}
		if(accion==3){
			localStorage.setItem("accion", "ColaboradorEliminado");
			this.setState({visualizarusuariosfront:true, visualizarusuariosback:true, visualizarusuariosfrontback:true,cargando:true})
			this.props.actualizarComponente();
			this.componentDidMount()
		}
    }

	render() {
		return (
			<>
				{(this.state.showmodalusuariocolaboraproyecto == true) ? (
					<ModalFormularioUsuarioColaboraProyecto
						isOpen={true}
						idusuario={this.state.idusuario}
						titulomodal={this.state.titulomodal}
						accion={this.state.accionmodal}
						closeModal={this.cerrarFormularioUsuarioColaboraProyecto}
						idproyecto={this.state.proyecto.id_proyecto}
						token={this.state.perfil.token}
						rol={this.state.perfil.role}
					/>
				) : null}
				<ToastContainer/>
				<Card className="cardSeccion ">
					<CardHeader className="cardSeccionHeader">
						<div className="contenidoTituloSeccion">
							<HiUserGroup size={23} color="black" />
							<span className="tituloSeccion">Colaboradores</span>
						</div>
					</CardHeader>
					<CardBody className="divContenidoCardBody">
						{/*////////// Sección de botón para añadir un colaborador, el cual solo se muestra al admin //////////*/}
						{this.state.perfil.role == 1 ?
							<div className="row mb-4">
								<div className="col-12 divContenedorBotones">
									<Button
										className="botonCrearModSubApi"
										color="success"
										onClick={() => this.verModalFormularioUsuarioColaboraProyecto(0, 1)}>
										<IoMdAdd size={20} color="white" />&nbsp;Colaborador
									</Button>
								</div>
							</div>
							: null}
						<div className="row">
							{/*////////// Sección de colaboradores frontend //////////*/}
							<div className="col-12">
								<table className="tableContenidoDetalles">
									<tr className="trTabla">
										<td className="col-4">
											<span className="tituloEncabezado">Frontend</span>
										</td>
									</tr>
									{this.state.visualizarusuariosfront === false ?
										<div>
											<em style={{ fontSize: '13px' }}>El proyecto aún no cuenta con usuarios frontend.</em>
										</div>
										:
										<tr className="trTabla">
											<td className="col-4">
												{this.state.usuariosfront.map((UsuarioFront, index) => (
													this.state.perfil.role == 1 ?
														<Usuario
															index={index}
															usuario={UsuarioFront}
															accion={2}
															verModalFormularioUsuarioColaboraProyecto={this.verModalFormularioUsuarioColaboraProyecto} />
														:
														<Usuario
															index={index}
															usuario={UsuarioFront}
															accion={2} />
												))}
											</td>
										</tr>}
								</table>
							</div>
							{/*////////// Sección de colaboradores backend //////////*/}
							<div className="col-12">
								<table className="tableContenidoDetalles">
									<tr className="trTabla">
										<td className="col-4">
											<span className="tituloEncabezado">Backend</span>
										</td>
									</tr>
									{this.state.visualizarusuariosback === false ?
										<div>
											<em style={{ fontSize: '13px' }}>El proyecto aún no cuenta con usuarios backend.</em>
										</div>
										:
										<tr className="trTabla">
											<td className="col-4">
												{this.state.usuariosback.map((UsuarioBack, index) => (
													this.state.perfil.role == 1 ?
														<Usuario
															index={index}
															usuario={UsuarioBack}
															accion={2}
															verModalFormularioUsuarioColaboraProyecto={this.verModalFormularioUsuarioColaboraProyecto} />
														:
														<Usuario
															index={index}
															usuario={UsuarioBack}
															accion={2} />
												))}
											</td>
										</tr>}
								</table>
							</div>
							{/*////////// Sección de colaboradores frontend y backend //////////*/}
							<div className="col-12">
								<table className="tableContenidoDetalles">
									<tr className="trTabla">
										<td className="col-4">
											<span className="tituloEncabezado">Fullstack</span>
										</td>
									</tr>
									{this.state.visualizarusuariosfrontback === false ?
										<div>
											<em style={{ fontSize: '13px' }}>El proyecto aún no cuenta con usuarios full stack.</em>
										</div>
										:
										<tr className="trTabla">
											<td className="col-4">
												{this.state.usuariosfrontback.map((UsuarioFrontBack, index) => (
													this.state.perfil.role == 1 ?
														<Usuario
															index={index}
															usuario={UsuarioFrontBack}
															accion={2}
															verModalFormularioUsuarioColaboraProyecto={this.verModalFormularioUsuarioColaboraProyecto} />
														:
														<Usuario
															index={index}
															usuario={UsuarioFrontBack}
															accion={2} />
												))}
											</td>
										</tr>}
								</table>
							</div>
						</div>
					</CardBody>
				</Card>
			</>
		);
	};
}
