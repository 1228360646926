import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

interface props {
    estado:string
    vermsgerror:boolean,
    msgerror:string,
    estadoSeleccionado: (estado:string) => void;
}

interface states {
    estado:string
}

export default class SelectEstado extends React.Component<props,states>{
    constructor(props:props) {
        super(props);
        this.state = {
            estado:this.props.estado
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.estado !== this.props.estado) {
          this.setState({ estado: this.props.estado });
        }
    }
    
    //Función para cambiar el estado del select de tipo solicitud.
	estadoSeleccionado = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const estado = event.target.value;
		this.setState( {estado:estado});
        this.props.estadoSeleccionado(estado)
	};

    render() {
        return (
            <FormGroup className='formContenedor'>
                <Label className='labelInputModal'>
                    Estado <span className="spanObligatorio">*</span>
                </Label>
                <Input type="select" value={this.state.estado} onChange={this.estadoSeleccionado}>
                    <option disabled value="">Seleccione una opción</option>
                    <option value="En desarrollo">En desarrollo</option>
                    <option value="Completada">Completada</option>
                </Input>
                {this.props.vermsgerror ? 
                    <div className="row">
                        <div className="col-10 contenedorMensajeError">
                        <span className="mensajeError">{this.props.msgerror}</span>
                        </div>
                    </div>
                : null}
            </FormGroup>
        );
    }
}